import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Preloader from "./Preloader";

const AdminProtectedRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useSelector((state) => state.admin);
    const [redirect, setRedirect] = useState(false);
    const navigate = useNavigate();
    const [triggerAnimation, setTriggerAnimation] = useState(false);

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            const timer = setTimeout(() => {
                setTriggerAnimation(true);
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [isAuthenticated, isLoading]);

    useEffect(() => {
        if (triggerAnimation) {
            const timer = setTimeout(() => {
                navigate("/admin/login");
            }, 900);
            return () => clearTimeout(timer);
        }
    }, [triggerAnimation, navigate]);

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (!isAuthenticated) {
        return (
            <div className={`fade-container ${triggerAnimation ? "fade-out" : ""}`}>
                <Preloader />
            </div>
        );
    }
    return children;
}
export default AdminProtectedRoute;