import axios from "axios"

export async function getHomeFeaturedProducts() {
    try {
        const queryParams = new URLSearchParams({
            per_page: 10,
            page: 1,
            status: 'publish',
            min_price: 5000,
        });
        const response = await axios.get(process.env.REACT_APP_WC_API_BASE + `products?${queryParams}`, {
            headers: {
                'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                'Content-Type': 'application/json'
            }
        })
        return response.data
    } catch (error) {
        
    }
}
export async function getSingleProductBySlug(slug = null) {
    if (slug == null)
        return
    try {
        const queryParams = new URLSearchParams({
            per_page: 1,
            slug: slug,
            status: 'publish',
        });
        const response = await axios.get(process.env.REACT_APP_WC_API_BASE + `products?${queryParams}`, {
            headers: {
                'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                'Content-Type': 'application/json',
            }
        })
        return response.data
    } catch (error) {
        
    }
}

export async function getProductsByCategorySlug(slug = null, page = 1, taxQuery = null) {
    if (slug == null)
        return
    if( taxQuery == null )
        taxQuery = []
    try {
        const queryParams = new URLSearchParams({
            page: page,
            taxQuery: JSON.stringify( taxQuery )
        });
        // console.warn(process.env.REACT_APP_TONYETRADE_API_BASE + `products/${slug}?${queryParams}`)
        const response = await axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `products/${slug}?${queryParams}`)
        return response.data
    } catch (error) {
        
    }
}

export async function getSets(inventory_id, page, skipYears = []) {
    if (inventory_id < 1){
        return
    }
    try {
        const response = await axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `sets/${inventory_id}?page=${page}&skipyears=${JSON.stringify(skipYears)}`)
        return response.data
    } catch (error) {
        
    }
}

export async function getSinglePostBySlug(slug = null) {
    if (slug == null)
        return
    try {
        const queryParams = new URLSearchParams({
            slug: slug,
            per_page: 1,
            _embed: true,
        });
        const response = await axios.get(process.env.REACT_APP_WP_API_BASE + `wp/v2/posts?${queryParams}`)
        return response.data
    } catch (error) {
        
    }
}

export async function getBlogPosts() {
    try {
        const queryParams = new URLSearchParams({
            per_page: 100,
            _embed: true,
        });
        const response = await axios.get(process.env.REACT_APP_WP_API_BASE + `wp/v2/posts?${queryParams}`)
        return response.data
    } catch (error) {
        
    }
}
export async function getRelatedProducts(productIDs) {
    if (productIDs?.length < 1)
        return
    try {
        const queryParams = new URLSearchParams({
            ids: productIDs,
        });
        const response = await axios.get( process.env.REACT_APP_TONYETRADE_API_BASE + `products/related?${queryParams}` )
        return response.data
    } catch (error) {
        
    }
}

export async function addToCartAPI(product) {
    if (!product)
        return
    try {
    
        const args = new FormData();
        args.append('id', '' + product?.id);
        args.append('quantity', '1');
        const response = await axios.post(process.env.REACT_APP_CC_API_BASE + `cart/add-item`, args, {
            headers: {
                'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                'Content-Type': 'application/json'
            }
        })
        return response.data
    } catch (error) {

    }
}

export async function loginUser(userData) {
    try {
        const args = {
            username: userData?.username,
            password: userData?.password,
        }
        const response = await axios.post( process.env.REACT_APP_TONYETRADE_API_BASE + `login` ).then(res => {
            return res
        }).catch(err => {
            return err
        })
        return response
    } catch (error) {

    }
}

export async function getCollectionProducts(collectionID) {
    if( collectionID < 1 )
        return
    try {
        const queryParams = new URLSearchParams({
            collectionid: collectionID,
        });
        const response = await axios.post( process.env.REACT_APP_WC_API_BASE + `collection?${queryParams}` ).then(res => {
            return res
        }).catch(err => {
            return err
        })
        return response
    } catch (error) {

    }
}

export async function getWishlistProductIDs(){
    try {
        let wishlistProductIDs = localStorage.getItem('tonyetradewishlistlocal')
        wishlistProductIDs = await JSON.parse(wishlistProductIDs)
        return wishlistProductIDs
    } catch (error) {
        
    }
}

export async function getZelleDetails_API() {
    try {
        const response = await axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `zelle`)
        return response
    } catch (error) {
        console.log(error)
    }
}

export async function sendContactForm_API(contactData) {
    try {
        const response = await axios.post(process.env.REACT_APP_TONYETRADE_API_BASE + `submitcontactform`, contactData)
        return response
    } catch (error) {
        console.log(error)
    }
}