import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import '../../assets/css/admin.dropzone.css'
import '../../assets/css/admin.editor.css'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Accordion, Stack, DatePicker, Uploader, TagPicker } from 'rsuite';
import 'rsuite/Accordion/styles/index.css';
import 'rsuite/DatePicker/styles/index.css';
import 'rsuite/Uploader/styles/index.css';
import "rsuite/dist/rsuite.css";
import { List } from "react-virtualized";
import Input from "../../components/Input";
import he from 'he';
import {
    MDXEditor, headingsPlugin, UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, markdownShortcutPlugin,
    thematicBreakPlugin,
    quotePlugin,
    listsPlugin,
    BlockTypeSelect,
    CreateLink,
    InsertImage,
    ListsToggle,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { getProductVariations, getProudct, getTags, saveProductVariationBatch, updateProudct } from "../../api/adminapi";
import { getDropdownOptions, getItemTypes, productDataTabs, showToast } from "../../utils/utils";
import { getCache, setCache } from "../../cacheUtil";
import Button from "../../components/Button";
import Select from "../../components/Select";
import Space from "../../components/Space";
import EditTagCanvas from "../../components/EditTagCanvas";
import { site_base } from "../../constants";
import Notice from "../../components/Notice";
const keysToRemove = ['date_created', 'date_created_gmt', 'date_modified', 'date_modified_gmt', 'downloads', 'meta_data', 'menu_order', 'variations', 'related_ids', 'shipping_class_id', 'shipping_taxable', 'shipping_required', 'backordered', 'backorders_allowed', 'total_sales', 'purchasable', 'on_sale', 'price_html', 'price'];
const CACHE_KEY_CATEGORIES = 'categories_data';
const CACHE_KEY_CONDITION = 'condition_data';
const CACHE_KEY_TEAM = 'team_data';
const CACHE_KEY_SPORTS = 'sports_data';
const CACHE_KEY_MANUFACTURERS = 'manufacturers_data';
const CACHE_KEY_YEAR = 'year_data';
const CACHE_KEY_PLAYER = 'player_data';
const CACHE_KEY_PRODUCT = 'product_data';
const CACHE_KEY_GRADES = 'product_grades';

const isTermInTerms = (term, terms, _match_type = 'term_id') => {

    if ('slug' == _match_type) {
        if (Array.isArray(terms)) {
            return terms?.some(t => t.slug === term.slug);
        }
        return term?.slug == terms?.slug
    }

    let is_there = false;
    terms?.map(t => {
        if (t.id == term.term_id)
            is_there = true
    })
    return is_there;
}

const fillMissingData = (data) => {
    data.stock_quantity = data.stock_quantity ? data.stock_quantity : 1;
    data.low_stock_amount = data.low_stock_amount ? data.low_stock_amount : 1;
    return data;
}

const RenderRow = ({ index, key, style, items, save, taxonomy, product }) => {
    let _sep = '',
        item = items[index];
    if (item.level > 0) {
        for (var i = 1; i <= item.level; i++) {
            _sep += '—';
        }
    }
    return <label key={key} style={style} class="list-group-item mb-2">
        <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
        <input class="form-check-input me-1" name={`${taxonomy}[]`} type="checkbox" value={item.term.term_id}
            {...(isTermInTerms(item.term, product ? product[taxonomy] : null, 'slug') ? { checked: true } : {})}
            onChange={(e) => {
                save(taxonomy, e.target.value)
            }}
        /> {he.decode(item.term.name)}
    </label>
}

const RenderTaxonomyBox = ({ items, title, save, taxonomy, product, width = 332, onClick }) => {
    let _taxKey = ''
    switch (taxonomy) {
        case 'categories':
            _taxKey = 'product_cat';
            break;
        case 'year':
            _taxKey = 'c_year';
            break;
        default:
            _taxKey = taxonomy;
    }
    return <div className="col">
        <div class="admin-card mb-3">
            <div class="admin-card-header">
                <h5 class="admin-card-title mb-0">{title}</h5>
            </div>
            <div class="admin-card-body">
                <div className="taxonomies_wrapper h-px-250 overflow-auto">
                    <List
                        width={width}
                        height={250}
                        rowHeight={30}
                        rowCount={items.length}
                        rowRenderer={({ index, key, style }) => {
                            return <RenderRow index={index} key={key} style={style} items={items} save={save} taxonomy={taxonomy} product={product} />
                        }}
                    />
                </div>
            </div>
            <Button text={`Add New ${taxonomy}`} icon='plus' className='mr-0 btn-link pl-0 pr-0 bg-transparent b-0 br-0 b-shadow-0 text-primary pb-2 pt-2'
                onClick={() => {
                    onClick && onClick(_taxKey);
                }}
            />
            <Space height={5} />
        </div>
    </div>
}

const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    };

    const date = new Date(dateString);
    return date.toLocaleString('en-US', options).replace(',', ' at');
}

const AccordionHeader = props => {
    const { avatarUrl, title, subtitle, id, variation, ...rest } = props;
    return (
        <Stack {...rest} spacing={10} alignItems="flex-start"
        >
            <Stack spacing={2} direction="column" alignItems="flex-start">
                <div className="row">
                    <div className="col">
                        <div>#{id} {variation?.attributes ? `| ${variation?.attributes[0]?.option}` : ''}</div>
                    </div>
                </div>
            </Stack>
        </Stack>
    );
};

const AttributeHeader = props => {
    const { id, attribute, onRemove, ...rest } = props;
    return (
        <Stack {...rest} spacing={10} justifyContent="space-between" alignItems="flex-start">
            <Stack spacing={2} direction="column" alignItems="flex-start">
                <div className="row">
                    <div className="col">
                        <div>{attribute?.name}</div>
                    </div>
                    <div className="col">
                        <a href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onRemove(attribute);
                            }}
                            style={{
                                color: 'red',
                                fontWeight: '400',
                                fontSize: 14,
                            }}
                        >
                            Remove
                        </a>
                    </div>
                </div>
            </Stack>
        </Stack>
    );
};

const initialProductState = {
    backordered: false,
    backorders_allowed: false,
    downloadable: false,
    manage_stock: true,
    shipping_required: true,
    shipping_taxable: true,
    on_sale: false,
    virtual: false,
    type: 'simple',
    name: '',
    slug: '',
    sku: '',
    date_created: new Date(),
    description: '',
    short_description: '',
    shipping_class: '',
    shipping_class_id: '',
    status: 'publish',
    tax_class: '',
    tax_status: 'taxable',
    backorders: 'no',
    catalog_visibility: 'visible',
    categories: [],
    images: [],
    variations: [],
    manufacturer: [],
    meta_data: [],
    attributes: [],
    price: '0.00',
    regular_price: '0.00',
    sale_price: '0.00',
    stock_quantity: 1,
    low_stock_amount: 1,
    stock_status: 'instock',
    year: {},
    card_number: '',
    sports: [],
    ebay_shipping: 0,
    gallery: [],
    featured_image: 0,
    amazon_price: 0,
    ebay_price: 0,
    website_price: 0,
    list_on_amazon: false,
    list_on_ebay: false,
    ebay_listings: [],
    amazon_listings: [],
};

const AddProduct = () => {
    const location = useLocation();
    const getQueryParams = (search) => {
        return Object.fromEntries(new URLSearchParams(search));
    };
    const queryParams = getQueryParams(location.search);
    const product_id = queryParams.product_id;
    const [categories, setCategories] = useState([])
    const [condition, setCondition] = useState([])
    const [team, setTeam] = useState([])
    const [sports, setSports] = useState([])
    const [manufacturers, setManufacturers] = useState([])
    const [player, setPlayer] = useState([])
    const [year, setYear] = useState([])
    const [variations, setVariations] = useState([])
    const [searchParams] = useSearchParams();
    const [editing, setEditing] = useState(false)
    const [editingDate, setEditingDate] = useState(false)
    const [product, setProduct] = useState(undefined)
    const [activeTab, setActiveTab] = useState('general')
    const [productInformation, setProductInformation] = useState(initialProductState);

    useEffect(() => {
        if (searchParams.get('action') == 'edit' && searchParams.get('product_id')) {
            setEditing(true)
        } else {
            setEditing(false)
            setProduct(undefined)
        }
        fetchAllTaxonomies();
    }, []);

    const fetchAndCacheData = async (cacheKey, fetchFn, setFn) => {
        const cachedData = getCache(cacheKey);
        if (cachedData) {
            setFn(cachedData);
        }
        const response = await fetchFn();
        if (response.status === 200) {
            setFn(response.data.terms);
            setCache(cacheKey, response.data.terms);
        }
    };

    const fetchAllTaxonomies = async () => {
        fetchAndCacheData(CACHE_KEY_CATEGORIES, () => getTags('product_cat', 1, 4000), setCategories);
        fetchAndCacheData(CACHE_KEY_CONDITION, () => getTags('condition', 1, 4000), setCondition);
        fetchAndCacheData(CACHE_KEY_TEAM, () => getTags('team', 1, 4000), setTeam);
        fetchAndCacheData(CACHE_KEY_SPORTS, () => getTags('sports', 1, 4000), setSports);
        fetchAndCacheData(CACHE_KEY_MANUFACTURERS, () => getTags('manufacturer', 1, 4000), setManufacturers);
        fetchAndCacheData(CACHE_KEY_YEAR, () => getTags('c_year', 1, 4000), setYear);
        fetchAndCacheData(CACHE_KEY_PLAYER, () => getTags('player', 1, 4000), setPlayer);
    }
    const [featureImage, setFeaturedImage] = useState({});
    const setProductAndDetails = (productData) => {
        setProduct(productData);
        setProductInformation({
            ...productInformation,
            backordered: productData.backordered,
            backorders_allowed: productData.backorders_allowed,
            downloadable: productData.downloadable,
            manage_stock: productData.manage_stock,
            shipping_required: productData.shipping_required,
            shipping_taxable: productData.shipping_taxable,
            on_sale: productData.on_sale,
            virtual: productData.virtual,
            type: productData.type,
            name: productData.name,
            slug: productData.slug,
            sku: productData.sku,
            attributes: productData.attributes,
            backorders: productData.backorders,
            catalog_visibility: productData.catalog_visibility,
            categories: productData.categories,
            date_created: productData.date_created,
            description: productData.description,
            short_description: productData.short_description,
            images: productData.images,
            variations: productData.variations,
            manufacturer: productData.manufacturer,
            meta_data: productData.meta_data,
            price: productData.price,
            regular_price: productData.regular_price,
            sale_price: productData.sale_price,
            shipping_class: productData.shipping_class,
            shipping_class_id: productData.shipping_class_id,
            status: productData.status ? productData.status : 'publish',
            stock_quantity: productData.stock_quantity ? productData.stock_quantity : 1,
            stock_status: productData.stock_status,
            tax_class: productData.tax_class,
            tax_status: productData.tax_status,
            year: productData.year,
            card_number: productData.card_number,
            ebay_shipping: productData.ebay_shipping,
            low_stock_amount: productData.low_stock_amount ? productData.low_stock_amount : 1,
            ebay_listings: productData.ebay_listings ? productData.ebay_listings : [],
            amazon_listings: productData.amazon_listings ? productData.amazon_listings : [],
        });
        if (productData.type === 'variable') {
            fetchProductVariations(productData.id);
        }
        if (productData?.images?.length) {
            setFeaturedImage({
                attachment_id: productData?.images[0]?.id,
                url: productData?.images[0]?.src,
            });
            if (productData?.images?.length > 1) {
                setProductGallery(prevGallery => [
                    ...productData?.images?.map(image => {
                        return {
                            attachment_id: image?.id,
                            url: image?.src,
                        }
                    })
                ])
            }
        }
    };

    const fetchProductVariations = async (product_id) => {
        await getProductVariations(product_id).then(response => {
            if (response.status == 200) {
                const _data = response.data;
                setVariations(_data)
            }
        })
    }

    const setProductAttributes = (attributes) => {
        setAttributes(attributes)
    }

    const fetchProduct = async () => {
        try {
            setFormProcessing(true)

            const productId = searchParams.get('product_id');
            const cachedProduct = getCache(`${CACHE_KEY_PRODUCT}_${productId}`);
            if (cachedProduct) {
                setProductAttributes(cachedProduct.attributes)
                setProductAndDetails(cachedProduct);
            }

            await getProudct(productId).then(async response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setProductAttributes(_data.attributes)
                    setCache(`${CACHE_KEY_PRODUCT}_${productId}`, _data);
                    setProductAndDetails(_data);
                }
            })

        } catch (error) {
            setFormProcessing(false)
        } finally {
            setFormProcessing(false)
        }
    }

    useEffect(() => {
        if (editing) {
            fetchProduct()
        }
    }, [editing])

    const ref = React.useRef(null)
    const dateRef = React.useRef(null)
    const navigate = useNavigate();
    const [formProcessing, setFormProcessing] = useState(false)

    const prepareBatchVariations = (variations) => {
        const create = variations
            .filter((v) => v.isNew)
            .map((v) => {
                const { id, ...rest } = v;
                return rest;
            });

        const update = variations.filter((v) => v.isUpdated && !v.isDeleted);
        const deleteIds = variations
            .filter((v) => v.isDeleted && !v.isNew)
            .map((v) => v.id);

        return {
            create,
            update,
            delete: deleteIds,
        };
    };
    const [simpleProItemType, setSimpleProItemType] = useState("");
    const createProduct = async () => {
        if (formProcessing)
            return;
        setFormProcessing(true)
        setSavingAttribute(true)
        let filteredInfo = {
            ...productInformation,
            attributes: attributes.map(attr => {
                const formattedOptions = attr.options.map(option =>
                    typeof option === "object" && option.label ? option.label : option
                );
                const { type, ...rest } = attr;
                return { ...rest, options: formattedOptions };
            }),
        };
        keysToRemove.forEach(key => {
            delete filteredInfo[key];
        });
        filteredInfo = fillMissingData(filteredInfo);
        if (productInformation.type == 'simple') {
            filteredInfo.meta_data = [{
                key: 'simple_pro_item_type',
                value: simpleProItemType,
            }];
        }
        await updateProudct(product?.id, filteredInfo, editing).then(async res => {
            if (res.status == 200 || res.status == 201) {
                if (variations.length) {
                    const batchData = prepareBatchVariations(variations);
                    try {
                        await saveProductVariationBatch(batchData, res.data.id).then(response => {
                            const _data = response.data;

                            if (_data?.create?.length || _data?.update?.length) {
                                // setVariations((prevVariations) => {
                                //     let _d = [];
                                //     if (_data?.create?.length) {
                                //         const createdVariations = _data.create.map((variation) => ({
                                //             ...variation,
                                //             isNew: false,
                                //             isUpdated: false,
                                //             isDeleted: false,
                                //         }));
                                //         _d = [...createdVariations];
                                //     }
                                //     if (_data.update?.length) {
                                //         const updatedVariations = _data.update.map((variation) => ({
                                //             ...variation,
                                //             isNew: false,
                                //             isUpdated: false,
                                //             isDeleted: false,
                                //         }));
                                //         _d = [..._d, ...updatedVariations];
                                //     }
                                //     return _d;
                                // });
                                // console.warn(variations)
                            }

                        }).catch(error => { }).finally(() => { });
                    } catch (error) { }
                }
            }
            if (res.status == 200) {
                showToast('Product updated successfully');
            } else if (!editing && res.status == 201) {
                navigate(`/admin/products/add?product_id=${res.data.id}&action=edit`)
            }
        }).catch(err => {
            console.warn(err)
        }).finally(() => {
            setFormProcessing(false)
            setSavingAttribute(false);
        })
    }

    const handleFormSubmit = async (event) => {
        if (event.nativeEvent.submitter && event.nativeEvent.submitter.name != 'save_product')
            return;
        createProduct();
    }

    const saveProductInformation = (key, value) => {
        setProductInformation({
            ...productInformation,
            [key]: value,
        })
    }

    const saveVariationInformation = (key, value, index) => {
        setVariations((prevVariations) => {
            const updatedVariations = [...prevVariations];
            const variation = { ...updatedVariations[index], [key]: value };
            if (!variation.isNew) {
                variation.isUpdated = true;
            }
            updatedVariations[index] = variation;
            return updatedVariations;
        });
    };


    const [productGallery, setProductGallery] = useState([]);
    const handleRemoveFeaturedImage = (e) => {
        e.preventDefault()
        setFeaturedImage({})
    }

    const markVariationForDeletion = (index) => {
        setVariations((prevVariations) => {
            const updatedVariations = [...prevVariations];
            const variation = updatedVariations[index];

            if (variation.isNew) {
                updatedVariations.splice(index, 1);
            } else {
                variation.isDeleted = true;
            }

            return updatedVariations;
        });
    };

    const addNewVariation = () => {
        setSavingAttribute(true);
        const newVariation = {
            id: Date.now(),
            price: '',
            sale_price: '',
            stock_quantity: 1,
            stock_status: 'instock',
            attributes: {},
            status: 'private',
            downloadable: false,
            virtual: false,
            manage_stock: false,
            description: '',
            amazon_price: '',
            ebay_price: '',
            website_price: '',
            isNew: true,
        };
        setVariations(prevVariations => [...prevVariations, newVariation]);
        setSavingAttribute(false)
    };

    const [attributeIdCounter, setAttributeIdCounter] = useState(1);

    const [attributes, setAttributes] = useState([])
    const addAttribute = (type) => {

        // if( 'grade' == type ){

        //     if( _gradedAttributes.length ){
        //         showToast('Grade')
        //         return;
        //     }
        // }

        setAttributes((prevAttributes) => [
            ...prevAttributes,
            {
                id: attributeIdCounter,
                name: type === 'grade' ? 'Grade' : 'Name',
                options: [],
                position: attributeIdCounter,
                type: type,
                visible: false,
                variation: false,
            }
        ]);
        setAttributeIdCounter((prevId) => prevId + 1);
    }

    const updateAttributeName = (index, value) => {
        setAttributes(prevAttributes => {
            const updatedAttributes = [...prevAttributes];
            updatedAttributes[index] = {
                ...updatedAttributes[index],
                name: value
            };
            return updatedAttributes;
        });
    };

    const updateAttributeByKey = (id, key, value) => {
        setAttributes(prevAttributes =>
            prevAttributes.map(attribute => {
                if (attribute.id === id) {
                    return {
                        ...attribute,
                        [key]: value,
                    };
                }
                return attribute;
            })
        );
    }

    const updateVariationByKey = (variationId, key, value, index, attributeName = 'Grade') => {
        setVariations((prevVariations) => {
            return prevVariations.map((variation, i) => {
                if (i === index && variation.id === variationId) {
                    if (key === "attributes") {
                        const updatedAttributes = variation.attributes.map((attr) => {
                            if (attr.name === attributeName) {
                                return { ...attr, option: value };
                            }
                            return attr;
                        });
                        return { ...variation, attributes: updatedAttributes };
                    }

                    return { ...variation, [key]: value };
                }
                return variation;
            });
        });
    };

    const updateAttributeValues = (index, value) => {
        const valuesArray = value.split('|').filter(val => val.trim() !== '');
        setAttributes(prevAttributes => {
            const updatedAttributes = [...prevAttributes];
            updatedAttributes[index] = {
                ...updatedAttributes[index],
                options: valuesArray
            };
            return updatedAttributes;
        });
    };

    const [grades, setGrades] = useState([])
    const fetchGrades = async () => {
        const cachedGrades = getCache(CACHE_KEY_GRADES);
        if (cachedGrades) {
            setGrades(cachedGrades)
            return
        }
        await getTags('pa_grade', 1, -1).then(response => {
            if (response.status == 200) {
                const _data = response.data;
                setGrades(_data.terms)
                const data = _data.terms.map(
                    term => ({ label: term.term.name, value: term.term.term_id })
                );
                setCache(CACHE_KEY_GRADES, data);
            }
        }).catch((err) => {

        }).finally(() => {

        });
    }

    const updateAttributeGradeValues = (index, selectedIds) => {
        const selectedGrades = grades.filter(grade => selectedIds.includes(grade.value));
        setAttributes(prevAttributes => {
            const updatedAttributes = [...prevAttributes];
            updatedAttributes[index] = {
                ...updatedAttributes[index],
                options: selectedGrades
            };
            return updatedAttributes;
        });
    };

    useEffect(() => {
        fetchGrades();
    }, [])

    const [savingAttribute, setSavingAttribute] = useState(false)

    const updateAttributeVariation = (type = 'attribute') => {
        setSavingAttribute(true)
        // setTimeout(() => {
        //     setSavingAttribute(false)
        // }, 2500)
    }

    const [newTaxonomy, setNewTaxonomy] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setNewTaxonomy(null);
        setIsModalOpen(false);
    };

    const openModal = (taxonomy) => {
        setIsModalOpen(true);
        setNewTaxonomy(taxonomy)
    };

    const _gradedAttributes = attributes.filter(attr => { return attr.name == 'Grade' || attr.type == 'grade' })

    const handleRefresh = (taxonomy) => {
        switch (taxonomy) {
            case 'product_cat':
                fetchAndCacheData(CACHE_KEY_CATEGORIES, () => getTags('product_cat', 1, 4000), setCategories);
                break;
            case 'condition':
                fetchAndCacheData(CACHE_KEY_CONDITION, () => getTags('condition', 1, 4000), setCondition);
                break;
            case 'team':
                fetchAndCacheData(CACHE_KEY_TEAM, () => getTags('team', 1, 4000), setTeam);
                break;
            case 'sports':
                fetchAndCacheData(CACHE_KEY_SPORTS, () => getTags('sports', 1, 4000), setSports);
                break;
            case 'manufacturer':
                fetchAndCacheData(CACHE_KEY_MANUFACTURERS, () => getTags('manufacturer', 1, 4000), setManufacturers);
                break;
            case 'c_year':
                fetchAndCacheData(CACHE_KEY_YEAR, () => getTags('c_year', 1, 4000), setYear);
                break;
            case 'player':
                fetchAndCacheData(CACHE_KEY_PLAYER, () => getTags('player', 1, 4000), setPlayer);
                break;
        }
        closeModal();
    }

    useEffect(() => {
        if (!product_id) {
            setProduct(undefined)
            setProductInformation(initialProductState);
        }
    }, [location])

    const [visible, setVisible] = useState(true);
    const showNotice = () => {
        setVisible(true);
    };

    const hideNotice = () => {
        setVisible(false);
    };

    const handleOnAttributeRemove = (attributeToRemove) => {
        setAttributes((prevAttributes) => {
            return prevAttributes.filter((attribute) => attribute.id !== attributeToRemove.id);
        });
    };

    useEffect(() => {
        if (product?.meta_data) {
            const meta = product.meta_data.find((item) => item.key === "simple_pro_item_type");
            if (meta) {
                setSimpleProItemType(meta.value);
            }
        }
    }, [product]);

    return <div class="layout-wrapper layout-content-navbar light-styleclass">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleFormSubmit(e)
                }}>
                    <Input type="hidden" name="product_id" value={editing && product ? product.id : 0} />
                    <div className="content-wrapper">
                        <div className="flex-grow-1 container-p-y container-fluid">
                            {/* <Notice
                                type="success" // 'success' or 'error'
                                message="This is a success message!"
                                visible={visible}
                                onClose={hideNotice}
                            /> */}
                            <div className="app-ecommerce">
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
                                    <div class="d-flex flex-column justify-content-center">
                                        <h4 class="mb-1">{editing ? 'Edit' : 'Add a new'} Product</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-12 col-lg-8">
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header">
                                                <h5 class="admin-card-tile mb-0">Product information</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                <div class="col mb-4">
                                                    <Input
                                                        label='Title'
                                                        type="text"
                                                        placeholder="Product title" name="title" value={`${productInformation.name}`}
                                                        onChange={(e) => {
                                                            saveProductInformation('name', e.target.value)
                                                        }}
                                                    />
                                                    {
                                                        product && product_id ?
                                                            <div className="mt-2">
                                                                <span>Permalink: </span>
                                                                {/* <a href={`${site_base}${product?.slug}`} target="_blank"> */}
                                                                <a href={`${product?.permalink}`} target="_blank">
                                                                    {product?.permalink}
                                                                </a>
                                                            </div> : null
                                                    }
                                                </div>
                                                <div>
                                                    <label class="mb-1">Description</label>
                                                    <div class="form-control p-0">
                                                        <MDXEditor
                                                            markdown={`${product ? product.description : ''}`}
                                                            plugins={[
                                                                toolbarPlugin({
                                                                    toolbarContents: () => (
                                                                        <>
                                                                            {' '}
                                                                            <UndoRedo />
                                                                            <BoldItalicUnderlineToggles />
                                                                            <BlockTypeSelect />
                                                                            <CreateLink />
                                                                            <InsertImage />
                                                                            <ListsToggle />
                                                                        </>
                                                                    )
                                                                }),
                                                                headingsPlugin(),
                                                                listsPlugin(),
                                                                quotePlugin(),
                                                                thematicBreakPlugin(),
                                                                markdownShortcutPlugin()
                                                            ]}
                                                            ref={ref}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header">
                                                <div className="rows d-flex gap-3">
                                                    <div className="cols">
                                                        <h5 class="admin-card-title mb-0">Product data</h5>
                                                    </div>
                                                    <div className="cols d-flex gap-4 align-center">
                                                        <div class="position-relative">
                                                            <Select
                                                                options={getDropdownOptions('product_types')}
                                                                value={productInformation.type}
                                                                name='type'
                                                                onChange={(e) => {
                                                                    saveProductInformation('type', e.target.value)
                                                                }}
                                                                defaultText="Product type"
                                                            />
                                                        </div>
                                                        <div className="rows d-flex gap-3">
                                                            <div className="cols">
                                                                <div class="form-check">
                                                                    <Input
                                                                        labelClass='form-check-label'
                                                                        label='Virtual'
                                                                        type="checkbox"
                                                                        name='virtual'
                                                                        onChange={(e) => {
                                                                            saveProductInformation('virtual', e.target.checked)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="cols">
                                                                <div class="form-check">
                                                                    <Input
                                                                        labelClass='form-check-label'
                                                                        label='Downloadable'
                                                                        type="checkbox"
                                                                        name='downloadable'
                                                                        onChange={(e) => {
                                                                            saveProductInformation('downloadable', e.target.checked)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="admin-card-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-3 col-xl-3 col-xxl-3 mx-auto card-separator">
                                                        <div class="d-flex justify-content-between flex-column mb-4 mb-md-0 pe-md-4">
                                                            <div class="nav-align-left">
                                                                <ul class="nav nav-pills flex-column w-100" role="tablist">
                                                                    {
                                                                        productDataTabs
                                                                            .filter(tab => !(productInformation.type === 'simple' && tab.id === 'variations') && !(productInformation.type === 'variable' && tab.id === 'price_adjustment'))
                                                                            .map(tab => {
                                                                                return (
                                                                                    <li class="nav-item" role="presentation">
                                                                                        <button type="button" className={`nav-link waves-effect waves-light ${activeTab == tab.id ? 'active' : ''}`} data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" onClick={() => {
                                                                                            setActiveTab(tab.id)
                                                                                        }}>
                                                                                            <i class="ti ti-box ti-sm me-1_5"></i>
                                                                                            <span class="align-middle">
                                                                                                {tab.title}
                                                                                            </span>
                                                                                        </button>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-9 col-xl-9 col-xxl-9 pt-6 pt-md-0">
                                                        <div class={`tab-content p-0 ps-md-4 ${savingAttribute ? 'processing' : ''}`}>
                                                            <div className={`tab-pane fade ${activeTab == 'general' ? 'active show' : ''}`} id="general" role="tabpanel">
                                                                {
                                                                    productInformation.type == 'simple' ?
                                                                        <>
                                                                            <div class="mb-3">
                                                                                <label class="form-label">Regular Price ($)</label>
                                                                                <input type="number" class="form-control" placeholder="Price" name="price" value={productInformation.regular_price}
                                                                                    onChange={(e) => {
                                                                                        saveProductInformation('regular_price', e.target.value)
                                                                                        // saveProductInformation('price', e.target.value)
                                                                                    }}
                                                                                    required="required"
                                                                                />
                                                                            </div>
                                                                            <div class="mb-3">
                                                                                <label class="form-label">Sale Price ($)</label>
                                                                                <input type="number" class="form-control" placeholder="Price" name="sale_price" value={productInformation.sale_price}
                                                                                    onChange={(e) => {
                                                                                        saveProductInformation('sale_price', e.target.value)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <hr />
                                                                        </> : null
                                                                }
                                                                <div class="mb-3 col ecommerce-select2-dropdown">
                                                                    <label class="form-label mb-1">Tax status</label>
                                                                    <div class="position-relative">
                                                                        <select class="select2 form-select select2-hidden-accessible" name="tax_status" value={`${productInformation.tax_status}`}
                                                                            onChange={(e) => {
                                                                                saveProductInformation('tax_status', e.target.value)
                                                                            }}
                                                                        >
                                                                            <option value="taxable">Taxable</option>
                                                                            <option value="shipping">Shipping only</option>
                                                                            <option value="none">None</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3 col ecommerce-select2-dropdown">
                                                                    <label class="form-label mb-1">Tax class</label>
                                                                    <div class="position-relative">
                                                                        <select class="select2 form-select select2-hidden-accessible" name="tax_class" value={`${productInformation.tax_class}`}
                                                                            onChange={(e) => {
                                                                                saveProductInformation('tax_class', e.target.value)
                                                                            }}
                                                                        >
                                                                            <option value="standard">Standard</option>
                                                                            <option value="reduced-rate">Reduced rate</option>
                                                                            <option value="zero-rate">Zero rate</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    productInformation.type === 'simple' && (
                                                                        <div class="mb-3 col ecommerce-select2-dropdown">
                                                                            <label class="form-label mb-1">Item Type</label>
                                                                            <div class="position-relative">
                                                                                <select
                                                                                    class="select2 form-select select2-hidden-accessible"
                                                                                    name="tax_class"
                                                                                    value={simpleProItemType}
                                                                                    onChange={(e) => {
                                                                                        setSimpleProItemType(e.target.value);
                                                                                    }}
                                                                                >
                                                                                    <option disabled={true}>
                                                                                        Select item type
                                                                                    </option>
                                                                                    {
                                                                                        getItemTypes().map(option => (
                                                                                            <option value={option.id}>
                                                                                                {option.value}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className={`tab-pane fade ${activeTab == 'inventory' ? 'active show' : ''}`} id="inventory" role="tabpanel">
                                                                <div>
                                                                    <div class="col mb-3">
                                                                        <label class="form-label">SKU</label>
                                                                        <input type="text" class="form-control" name="sku" value={`${productInformation.sku}`}
                                                                            onChange={(e) => {
                                                                                saveProductInformation('sku', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="d-flex justify-content-between align-items-center">

                                                                        <div class="form-check">
                                                                            <label class="form-check-label">Manage stock level (quantity)</label>
                                                                            <input type="checkbox" class="form-check-input" name="manage_stock_level" onChange={(e) => {
                                                                                saveProductInformation('manage_stock', e.target.checked)
                                                                            }}
                                                                                {...(productInformation.manage_stock === true ? { checked: true } : {})}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        productInformation.manage_stock ?
                                                                            <>
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Stock quantity</label>
                                                                                    <input type="number" class="form-control" placeholder="Stock quantity" name="stock" value={productInformation.stock_quantity}
                                                                                        onChange={(e) => {
                                                                                            saveProductInformation('stock_quantity', e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div class="mb-3 col ecommerce-select2-dropdown">
                                                                                    <label class="form-label mb-1">Allow backorders?</label>
                                                                                    <div class="position-relative">
                                                                                        <select class="select2 form-select select2-hidden-accessible" name="allow_backorders" value={`${productInformation.backorders}`}
                                                                                            onChange={(e) => {
                                                                                                saveProductInformation('backorders', e.target.value)
                                                                                            }}
                                                                                        >
                                                                                            <option value="no">No</option>
                                                                                            <option value="notify">Allow, but notify customer</option>
                                                                                            <option value="yes">Allow</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Low stock threshold</label>
                                                                                    <input type="number" min="1" class="form-control" placeholder="Low stock threshold" name="low_stock_amount" value={productInformation.low_stock_amount}
                                                                                        onChange={(e) => {
                                                                                            saveProductInformation('low_stock_amount', e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </> : <div class="mb-3 col ecommerce-select2-dropdown">
                                                                                <label class="form-label mb-1">Stock status</label>
                                                                                <div class="position-relative">
                                                                                    <select class="select2 form-select select2-hidden-accessible" name="stock_status" value={`${productInformation.stock_status}`}
                                                                                        onChange={(e) => {
                                                                                            saveProductInformation('stock_status', e.target.value)
                                                                                        }}
                                                                                    >
                                                                                        <option value="instock">In stock</option>
                                                                                        <option value="outofstock">Out of stock</option>
                                                                                        <option value="onbackorder">On backorder</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={`tab-pane fade ${activeTab == 'attributes' ? 'active show' : ''}`} id="attributes" role="tabpanel">
                                                                <div className="flex gap-2">
                                                                    {
                                                                        attributes.length < 1 && (
                                                                            <button type="button" onClick={() => {
                                                                                addAttribute('custom')
                                                                            }} className="btn btn-primary">
                                                                                Add custom attribute
                                                                            </button>
                                                                        )
                                                                    }
                                                                    {
                                                                        _gradedAttributes.length < 1 && attributes.length < 1 && (
                                                                            <button type="button" onClick={() => {
                                                                                addAttribute('grade')
                                                                            }} className="btn btn-primary">
                                                                                Add Grade
                                                                            </button>
                                                                        )
                                                                    }
                                                                </div>
                                                                <hr />
                                                                {
                                                                    attributes.length ?
                                                                        <Accordion bordered>
                                                                            {
                                                                                attributes.map((attribute, index) => {
                                                                                    return <Accordion.Panel
                                                                                        header={
                                                                                            <AttributeHeader
                                                                                                id={attribute.id}
                                                                                                attribute={attribute}
                                                                                                onRemove={handleOnAttributeRemove}
                                                                                            />
                                                                                        }
                                                                                        eventKey={1}
                                                                                        key={index}
                                                                                    >
                                                                                        <div className="product_attribute_wrapper flex gap-3">
                                                                                            <div className="col-4">
                                                                                                <label class="form-label">Name:</label>
                                                                                                {
                                                                                                    (attribute.type == 'custom' || attribute.name != 'Grade') && (
                                                                                                        <input type="text" class="form-control" name="title"
                                                                                                            onChange={(e) => updateAttributeName(index, e.target.value)}
                                                                                                            value={attribute.name}
                                                                                                        />
                                                                                                    )
                                                                                                }
                                                                                                {
                                                                                                    (attribute.type != 'custom' && attribute.name == 'Grade') && (
                                                                                                        <>
                                                                                                            <br />
                                                                                                            <strong>Grade</strong>
                                                                                                        </>
                                                                                                    )
                                                                                                }

                                                                                                <div className="d-flex gap-2 mt-4">
                                                                                                    <input
                                                                                                        type="checkbox" class="form-check-input"
                                                                                                        id={`visible_on_product_page_${attribute.id}`}
                                                                                                        name="visible_on_product_page"
                                                                                                        {...(attribute.visible ? { checked: true } : "")}
                                                                                                        onChange={(e) => {
                                                                                                            updateAttributeByKey(attribute.id, 'visible', e.target.checked)
                                                                                                        }}
                                                                                                    />
                                                                                                    <label class="form-check-label" htmlFor={`visible_on_product_page_${attribute.id}`}>Visible on the product page</label>
                                                                                                </div>
                                                                                                {
                                                                                                    productInformation.type === 'variable' && (
                                                                                                        <div className="d-flex gap-2 mt-2">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                class="form-check-input"
                                                                                                                id={`used_for_variations_${index}`}
                                                                                                                name="used_for_variations"
                                                                                                                onChange={(e) => {
                                                                                                                    updateAttributeByKey(attribute.id, 'variation', e.target.checked)
                                                                                                                }}
                                                                                                                {...(attribute.variation ? { checked: true } : "")}
                                                                                                            />
                                                                                                            <label class="form-check-label" htmlFor={`used_for_variations_${index}`}>Use for variations</label>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-8">
                                                                                                <label className="form-label">
                                                                                                    Value(s):
                                                                                                </label><br />
                                                                                                {
                                                                                                    attribute.type == 'custom' || attribute.name != 'Grade' ?
                                                                                                        <textarea class="form-control"
                                                                                                            placeholder="Enter some text, or some attributes by &quot;|&quot; separating values."
                                                                                                            value={attribute.options.join('|')}
                                                                                                            onChange={(e) => updateAttributeValues(index, e.target.value)}
                                                                                                            rows={'3'}
                                                                                                        /> : <TagPicker
                                                                                                            data={grades}
                                                                                                            onChange={(selectedItems) => {
                                                                                                                updateAttributeGradeValues(index, selectedItems)
                                                                                                            }}
                                                                                                            virtualized={true}
                                                                                                            labelKey="label"
                                                                                                            valueKey="value"
                                                                                                            style={{ width: '99%' }}
                                                                                                        />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Accordion.Panel>
                                                                                })
                                                                            }
                                                                        </Accordion> : null
                                                                }
                                                                <hr />
                                                                <button type="button" disabled={attributes.length < 1} onClick={updateAttributeVariation} className="btn btn-primary">
                                                                    Save attributes
                                                                </button>
                                                            </div>
                                                            <div className={`tab-pane fade ${activeTab == 'variations' ? 'active show' : ''}`} id="variations" role="tabpanel">
                                                                {
                                                                    !attributes?.length ?
                                                                        (
                                                                            <p className="">Before you can add a variation you need to add some variation attributes on the <strong>Attributes</strong> tab.</p>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                }
                                                                {
                                                                    attributes.length > 0 && (
                                                                        <div className="flex gap-2 mb-4">
                                                                            <select id="add_var_type">
                                                                                <option value="add_var">
                                                                                    Add variation
                                                                                </option>
                                                                                <option value="add_all_var">
                                                                                    Create variations from all attributes
                                                                                </option>
                                                                            </select>
                                                                            <button type="button" className="btn btn-primary" onClick={addNewVariation}>Go</button>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    variations?.length ?
                                                                        <Accordion bordered>
                                                                            {
                                                                                variations.map((variation, index) => {
                                                                                    return <>
                                                                                        <Accordion.Panel header={
                                                                                            <AccordionHeader
                                                                                                id={variation.id}
                                                                                                variation={variation}
                                                                                            />
                                                                                        }
                                                                                            eventKey={1}>
                                                                                            <div className="row">
                                                                                                <div class="mb-6 col-4">
                                                                                                    <label class="form-label" for="form-repeater-1-1">Options</label>
                                                                                                    <div class="position-relative">
                                                                                                        <select
                                                                                                            value={variation?.attributes ? variation?.attributes[0]?.option : []}
                                                                                                            class="select2 form-select select2-hidden-accessible"
                                                                                                            onChange={(e) => {
                                                                                                                const newValue = e.target.value;
                                                                                                                updateVariationByKey(variation.id, "attributes", newValue, index, variation?.attributes[0]?.name);
                                                                                                                updateVariationByKey(variation.id, "isUpdated", true, index);
                                                                                                            }}
                                                                                                        >
                                                                                                            <option value="">Any Grade…</option>
                                                                                                            {
                                                                                                                product?.attributes[0]?.options?.map(option => {
                                                                                                                    return <option value={option}>
                                                                                                                        {option}
                                                                                                                    </option>
                                                                                                                })
                                                                                                            }
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr />
                                                                                                {/* <label class="form-label" for="form-repeater-1-1">Featured Image</label> */}
                                                                                                {
                                                                                                    false && Object.keys(featureImage).length ?
                                                                                                        <div>
                                                                                                            <img src={featureImage.url} />
                                                                                                            <a className="remove_featured_image mt-4 d-inline-block" href="#" onClick={handleRemoveFeaturedImage}>Remove featured image</a>
                                                                                                        </div> : null
                                                                                                }
                                                                                                {/* <Uploader action="https://staging.tonyetrade.com/uploader.php" draggable>
                                                                                                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                                        <span>Click or Drag image here to upload</span>
                                                                                                    </div>
                                                                                                </Uploader>
                                                                                                <hr /> */}
                                                                                                {/* <label class="form-label" for="form-repeater-1-1">Additional Images</label>
                                                                                                <Uploader action="https://staging.tonyetrade.com/uploader.php" draggable>
                                                                                                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                                        <span>Click or Drag images here to upload</span>
                                                                                                    </div>
                                                                                                </Uploader>
                                                                                                <hr /> */}
                                                                                                <div className="row mb-3">
                                                                                                    <div className="col d-flex gap-4">
                                                                                                        <div class="form-check">
                                                                                                            <label class="form-check-label"
                                                                                                                htmlFor={`var_enabled_${variation.id}`}
                                                                                                            >Enabled</label>
                                                                                                            <input
                                                                                                                id={`var_enabled_${variation.id}`}
                                                                                                                type="checkbox" class="form-check-input" name="enabled"
                                                                                                                onChange={(e) => {
                                                                                                                    saveVariationInformation('status', e.target.checked === true ? 'publish' : 'private', index)
                                                                                                                }}
                                                                                                                {...(variation.status === 'publish' ? { checked: true } : {})}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div class="form-check">
                                                                                                            <label class="form-check-label" htmlFor={`var_downloadable_${variation.id}`}>Downloadable</label>
                                                                                                            <input
                                                                                                                id={`var_downloadable_${variation.id}`}
                                                                                                                type="checkbox"
                                                                                                                class="form-check-input"
                                                                                                                name="enabled"
                                                                                                                onChange={(e) => {
                                                                                                                    saveVariationInformation('downloadable', e.target.checked, index)
                                                                                                                }}
                                                                                                                {...(variation.downloadable === true ? { checked: true } : {})}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div class="form-check">
                                                                                                            <label
                                                                                                                class="form-check-label"
                                                                                                                htmlFor={`var_virtual_${variation.id}`}
                                                                                                            >Virtual</label>
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                class="form-check-input"
                                                                                                                name="enabled"
                                                                                                                id={`var_virtual_${variation.id}`}
                                                                                                                onChange={(e) => {
                                                                                                                    saveVariationInformation('virtual', e.target.checked, index)
                                                                                                                }}
                                                                                                                {...(variation.virtual === true ? { checked: true } : {})}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div class="form-check">
                                                                                                            <label class="form-check-label"
                                                                                                                htmlFor={`var_manage_stock_${variation.id}`}
                                                                                                            >Manage Stock?</label>
                                                                                                            <input type="checkbox" class="form-check-input" name="enabled"
                                                                                                                id={`var_manage_stock_${variation.id}`}
                                                                                                                onChange={(e) => {
                                                                                                                    saveVariationInformation('manage_stock', e.target.checked, index)
                                                                                                                }}
                                                                                                                {...(variation.manage_stock === true ? { checked: true } : {})}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr />
                                                                                                <div class="row mb-3">
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Regular price ($)</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Variation Price (required)" name={`regular_price[${index}]`}
                                                                                                            value={variation.price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('price', e.target.value, index);
                                                                                                                saveVariationInformation('regular_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Sale price ($)</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Variation Price (required)" name={`sale_price[${index}]`}
                                                                                                            value={variation.sale_price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('sale_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row mb-3">
                                                                                                    <div className="col">
                                                                                                        {
                                                                                                            variation?.manage_stock != true ?
                                                                                                                <>
                                                                                                                    <label class="form-label" for="form-repeater-1-1">Stock Status</label>
                                                                                                                    <select className="select2 form-select select2-hidden-accessible" value={variation.stock_status} onChange={(e) => {
                                                                                                                        saveVariationInformation('stock_status', e.target.value, index);
                                                                                                                    }}>
                                                                                                                        <option value="instock" selected="selected">In stock</option>
                                                                                                                        <option value="outofstock">Out of stock</option>
                                                                                                                        <option value="onbackorder">On backorder</option>
                                                                                                                    </select>
                                                                                                                </> :
                                                                                                                <>
                                                                                                                    <label class="form-label" for="form-repeater-1-1">Stock quantity</label>
                                                                                                                    <input class="form-control" type="number" min="1" placeholder="Stock quantity" name={`stock_quantity[${index}]`}
                                                                                                                        value={variation.stock_quantity}
                                                                                                                        onChange={(e) => {
                                                                                                                            saveVariationInformation('stock_quantity', e.target.value, index);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Shipping class</label>
                                                                                                        <select className="select2 form-select select2-hidden-accessible" value={variation.shipping_class} onChange={(e) => {
                                                                                                            saveVariationInformation('shipping_class', e.target.value, index);
                                                                                                        }}>
                                                                                                            <option value={'parent'}>
                                                                                                                Same as parent
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Tax class</label>
                                                                                                        <select className="select2 form-select select2-hidden-accessible" value={variation.tax_class} onChange={(e) => {
                                                                                                            saveVariationInformation('tax_class', e.target.value, index);
                                                                                                        }}>
                                                                                                            <option value={'parent'}>
                                                                                                                Same as parent
                                                                                                            </option>
                                                                                                            <option value="">Standard</option>
                                                                                                            <option value="reduced-rate">Reduced rate</option>
                                                                                                            <option value="zero-rate">Zero rate</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    variation?.manage_stock === true ?
                                                                                                        <>
                                                                                                            <div class="row mb-6">
                                                                                                                <div className="col">
                                                                                                                    <label class="form-label" for="form-repeater-1-1">Allow backorders?</label>
                                                                                                                    <select className="select2 form-select select2-hidden-accessible" value={variation.backorders} onChange={(e) => {
                                                                                                                        saveVariationInformation('backorders', e.target.value, index);
                                                                                                                    }}>
                                                                                                                        <option value="no">Do not allow</option>
                                                                                                                        <option value="notify">Allow, but notify customer</option>
                                                                                                                        <option value="yes">Allow</option>
                                                                                                                    </select>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </> : null
                                                                                                }
                                                                                                <div class="row mb-6">
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Description</label>
                                                                                                        <textarea class="form-control" type="number" min="1" name=""
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('description', e.target.value, index);
                                                                                                            }}
                                                                                                            value={variation.description}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr />
                                                                                                <label class="form-label" for="form-repeater-1-1">Price Adjustment</label>
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Amazon Price</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Amazon Price" name={`variable_amazon_price_adjustment[${index}]`}
                                                                                                            value={variation.amazon_price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('amazon_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Ebay Price</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Ebay Price" name={`variable_ebay_price_adjustment[${index}]`}
                                                                                                            value={variation.ebay_price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('ebay_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Website Price</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Website Price" name={`variable_website_price_adjustment[${index}]`}
                                                                                                            value={variation.website_price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('website_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Accordion.Panel >
                                                                                    </>
                                                                                })
                                                                            }
                                                                        </Accordion> : null
                                                                }
                                                            </div>
                                                            <div className={`tab-pane fade ${activeTab == 'price_adjustment' ? 'active show' : ''}`} id="price_adjustment" role="tabpanel">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Amazon Price</label>
                                                                    <input type="number" class="form-control" placeholder="Amazon Price" name="amazon_price" value={productInformation.amazon_price}
                                                                        onChange={(e) => {
                                                                            saveProductInformation('amazon_price', e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label class="form-label">eBay Price</label>
                                                                    <input type="number" class="form-control" placeholder="eBay Price" name="ebay_price" value={productInformation.ebay_price}
                                                                        onChange={(e) => {
                                                                            saveProductInformation('ebay_price', e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label class="form-label">Website Price</label>
                                                                    <input type="number" class="form-control" placeholder="Website Price" name="website_price" value={productInformation.website_price}
                                                                        onChange={(e) => {
                                                                            saveProductInformation('website_price', e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="admin-card mb-3">
                                            <div class="admin-card-header">
                                                <h5 class="admin-card-title mb-0">Additional Information</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div class="mb-3">
                                                            <label class="form-label mb-1">Shipping for eBay</label>
                                                            <select class="select2 form-select select2-hidden-accessible" name="ebay_shipping" value={productInformation.shipping_for_ebay}
                                                                onChange={(e) => {
                                                                    saveProductInformation('shipping_for_ebay', e.target.value)
                                                                }}
                                                            >
                                                                <option value="0">Standard Shipping</option>
                                                                <option value="14.95">Heavy Shipping ($14.95)</option>
                                                                <option value="24.95">Heavy Shipping ($24.95)</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div class="mb-3 col ecommerce-select2-dropdown">
                                                            <label class="form-label mb-1">Mark this Product as Badass?</label>
                                                            <div class="position-relative">
                                                                <select class="select2 form-select select2-hidden-accessible" name="badass_product" value={`${productInformation.badass}`}
                                                                    onChange={(e) => {
                                                                        saveProductInformation('badass', e.target.value)
                                                                    }}
                                                                >
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Card Number</label>
                                                    <input type="number" min="1" class="form-control" placeholder="Card Number" name="card_number" value={productInformation.card_number}
                                                        onChange={(e) => {
                                                            saveProductInformation('card_number', e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-6">
                                            <RenderTaxonomyBox
                                                items={categories}
                                                save={saveProductInformation}
                                                taxonomy='categories'
                                                product={product}
                                                title='Categories'
                                                onClick={openModal}
                                            />
                                            <RenderTaxonomyBox
                                                items={condition}
                                                save={saveProductInformation}
                                                taxonomy='condition'
                                                product={product}
                                                title='Condition'
                                                onClick={openModal}
                                            />
                                        </div>
                                        <div className="row mt-3">
                                            <RenderTaxonomyBox
                                                items={team}
                                                save={saveProductInformation}
                                                taxonomy='team'
                                                product={product}
                                                title='Team'
                                                onClick={openModal}
                                            />
                                            <RenderTaxonomyBox
                                                items={sports}
                                                save={saveProductInformation}
                                                taxonomy='sports'
                                                product={product}
                                                title='Sports'
                                                onClick={openModal}
                                            />
                                        </div>
                                        <div className="row mt-3">
                                            <RenderTaxonomyBox
                                                items={manufacturers}
                                                save={saveProductInformation}
                                                taxonomy='manufacturer'
                                                product={product}
                                                title='Manufacturers'
                                                onClick={openModal}
                                            />
                                            <RenderTaxonomyBox
                                                items={year}
                                                save={saveProductInformation}
                                                taxonomy='year'
                                                product={product}
                                                title='Year'
                                                onClick={openModal}
                                            />
                                        </div>
                                        <div className="row mt-3">
                                            <RenderTaxonomyBox
                                                items={player}
                                                save={saveProductInformation}
                                                taxonomy='player'
                                                product={product}
                                                title='Player'
                                                width={735}
                                                onClick={openModal}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header d-flex row align-center">
                                                <div className="col">
                                                    <h5 class="admin-card-title mb-0">Publish</h5>
                                                </div>
                                                <div style={{
                                                    minWidth: 256 + 'px',
                                                }} className="row col d-flex">
                                                    <div className="col d-flex align-center justify-content-end gap-3">
                                                        {
                                                            formProcessing ?
                                                                <div>
                                                                    <div class="spinner-border text-primary" role="status">
                                                                        <span class="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        <button type="submit" name="save_product" class="btn btn-primary waves-effect waves-light">{editing ? 'Save' : 'Publish'} product</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="admin-card-body">
                                                <div class="mb-6 col ecommerce-select2-dropdown">
                                                    <label class="form-label mb-1" for="status-org">Status </label>
                                                    <div class="position-relative">
                                                        <Select
                                                            options={getDropdownOptions('post_status')}
                                                            value={`${productInformation.status}`}
                                                            name="status"
                                                            onChange={(e) => {
                                                                saveProductInformation('status', e.target.value)
                                                            }}
                                                            defaultText="Select..."
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    !editingDate ?
                                                        <div class="mb-3 col ecommerce-select2-dropdown">
                                                            <label class="form-check-label">Publish{product && editing ? `ed on: ${formatDate(product?.date_created)}` : ` ${productInformation.date_created.toLocaleString('en-US', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).replace(',', '')}`}</label> <a style={{ textDecoration: 'underline' }} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setEditingDate(true)
                                                            }}>Edit</a>
                                                        </div> : null
                                                }
                                                {
                                                    editingDate ?
                                                        <div class="mb-6 d-flex gap-3 col ecommerce-select2-dropdown">
                                                            <DatePicker
                                                                ref={dateRef}
                                                                format="dd MMM yyyy hh:mm:ss aa"
                                                                style={{ width: 100 + '%' }}
                                                                block
                                                                cleanable={false}
                                                                value={editing ? new Date(product?.date_created) : productInformation.date_created}
                                                                onChange={(e) => {
                                                                    let _date = new Date(e);
                                                                    _date = _date.toISOString().split('.')[0]
                                                                    setProductInformation({
                                                                        ...productInformation,
                                                                        date_created: _date,
                                                                    });
                                                                }}
                                                            />
                                                            <button type="button" className="btn btn-primary waves-effect waves-light active" onClick={() => {
                                                                let _date = dateRef.current.target.value ? new Date(dateRef.current.target.value) : new Date()
                                                                _date = _date.toISOString().split('.')[0]
                                                                if (editing && product) {
                                                                    setProductInformation({
                                                                        ...productInformation,
                                                                        date_created: _date,
                                                                    });
                                                                    setEditingDate(false)
                                                                }
                                                            }}>
                                                                Save
                                                            </button>
                                                        </div> : null
                                                }
                                                {
                                                    !productInformation?.ebay_listings?.length ?
                                                        <div class="form-check">
                                                            <Input
                                                                id={'list_on_ebay'}
                                                                label={'List on eBay'}
                                                                type="checkbox"
                                                                name="list_on_ebay"
                                                                onChange={(e) => {
                                                                    saveProductInformation('list_on_ebay', e.target.checked ? 'yes' : '')
                                                                }}
                                                                labelClass='form-check-label'
                                                            />
                                                        </div> :
                                                        <>
                                                            <p>
                                                                <strong>This product is linked to {productInformation.ebay_listings.length} eBay listings:</strong>
                                                            </p>
                                                            {
                                                                productInformation.ebay_listings.map(listing => (
                                                                    <p>
                                                                        <strong>{listing.ebay_id}</strong> <em>{listing.status}</em> <a href={listing.ViewItemURL} target="_blank">View on eBay</a>
                                                                    </p>
                                                                ))
                                                            }
                                                        </>

                                                }

                                                {
                                                    !productInformation?.amazon_listings?.length ?
                                                        <div class="form-check">
                                                            <Input
                                                                id={'list_on_amazon'}
                                                                label={'List on Amazon'}
                                                                type="checkbox"
                                                                name="list_on_amazon"
                                                                onChange={(e) => {
                                                                    saveProductInformation('list_on_amazon', e.target.checked ? 'yes' : '')
                                                                }}
                                                                labelClass='form-check-label'
                                                            />
                                                        </div> :
                                                        <>
                                                            <p>
                                                                <strong>This product is linked to {productInformation.amazon_listings.length} amazon listings:</strong>
                                                            </p>
                                                            {
                                                                productInformation.amazon_listings.map(listing => (
                                                                    <p>
                                                                        <strong>{listing.asin}</strong> <em>{listing.status}</em> <a href={`https://www.amazon.com/dp/${listing.asin}`} target="_blank">View on Amazon</a>
                                                                    </p>
                                                                ))
                                                            }
                                                        </>

                                                }

                                                <div class="col-md-12 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                    <label class="form-label">Catalog visibility</label>
                                                    <div class="position-relative">
                                                        <select class="select2 form-select select2-hidden-accessible" name="type" value={productInformation.catalog_visibility}
                                                            onChange={(e) => {
                                                                saveProductInformation('catalog_visibility', e.target.value)
                                                            }}
                                                        >
                                                            <option value="visible">Shop and search results</option>
                                                            <option value="catalog">Shop only</option>
                                                            <option value="search">Search results only</option>
                                                            <option value="hidden">Hidden</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header d-flex justify-content-between align-items-center">
                                                <h5 class="mb-0 card-title">Featured Image</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                {
                                                   false && Object.keys(featureImage).length ?
                                                        <div>
                                                            <img src={featureImage.url} />
                                                            <a className="remove_featured_image mt-4 d-inline-block" href="#" onClick={handleRemoveFeaturedImage}>Remove featured image</a>
                                                        </div> : null
                                                }
                                                {
                                                    !Object.keys(featureImage).length && (
                                                        <Uploader
                                                            action="https://staging.tonyetrade.com/uploader.php"
                                                            draggable
                                                            onSuccess={
                                                                (uploadedImageData) => {
                                                                    if (uploadedImageData?.status == 'success' && uploadedImageData?.uploads?.length) {
                                                                        setFeaturedImage(uploadedImageData?.uploads[0]);
                                                                        saveProductInformation('images', [
                                                                            ...productInformation.images,
                                                                            {
                                                                                id: uploadedImageData?.uploads[0]?.attachment_id
                                                                            }
                                                                        ])
                                                                    }
                                                                }
                                                            }
                                                        >
                                                            <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <span>Click or Drag image here to upload</span>
                                                            </div>
                                                        </Uploader>
                                                    )
                                                }
                                                {/* <Uploader onSuccess={(uploadedImageData) => {
                                                    if (uploadedImageData?.status == 'success' && uploadedImageData?.uploads?.length) {
                                                        setFeaturedImage(uploadedImageData?.uploads[0]);
                                                    }
                                                }} action={process.env.FILE_UPLOADING_PATH} draggable>
                                                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <span>Click or Drag image here to upload</span>
                                                    </div>
                                                </Uploader> */}
                                            </div>
                                        </div>
                                        <div class="admin-card mb-6">
                                            <Input type="hidden" name="gallery" value={productInformation.gallery} />
                                            <Input type="hidden" name="featured_image" value={productInformation.featured_image} />
                                            <div class="admin-card-header d-flex justify-content-between align-items-center">
                                                <h5 class="mb-0 card-title">Product Gallery</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                {
                                                    productGallery?.length ?
                                                        <div className="product_gallery_wrapper d-flex flex-wrap gap-4 mb-5">
                                                            {
                                                                productGallery.map(gallery => {
                                                                    return <div className="product_gallery_single col-3">
                                                                        <img src={gallery.url} />
                                                                    </div>
                                                                })
                                                            }
                                                        </div> : null
                                                }
                                                <Uploader
                                                    action="https://staging.tonyetrade.com/uploader.php"
                                                    draggable
                                                    multiple={true}
                                                    onSuccess={(uploadedImageData) => {
                                                        if (uploadedImageData?.status == 'success' && uploadedImageData?.uploads?.length) {
                                                            setProductGallery(prevGallery => [
                                                                ...prevGallery,
                                                                uploadedImageData.uploads[0]
                                                            ]);
                                                        }
                                                    }}
                                                >
                                                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <span>Click or Drag images here to upload</span>
                                                    </div>
                                                </Uploader>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <EditTagCanvas taxonomy={newTaxonomy} term={null} isOpen={isModalOpen} onClose={closeModal} refresh={() => {
            handleRefresh(newTaxonomy);
        }} />
    </div>
}
export default AddProduct;