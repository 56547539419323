const Select = ({ options = [], className, name, value, onChange, defaultText = '' }) => {
    return <select
        class="form-select"
        name={name}
        value={value}
        onChange={onChange}
    >
        <option value="" data-select2-id="94" disabled="disabled" selected>{defaultText}</option>
        {
            options.map(option => (
                <option
                    value={option.key}
                >{option.value}</option>
            ))
        }
    </select>
}
export default Select;