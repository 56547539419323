import toast from 'react-hot-toast';
import '../Contact/contact.css'
import React, { useEffect, useState } from "react";
import { scrollToTop, showToast } from '../../utils/utils';
import { sendContactForm_API } from '../../api/api';

const Contact = () => {
    const [isProcessing, setIsProcessing] = useState(false)
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        phone: '',
        description: '',
    })
    const handleContactFormSubmit = async () => {
        if (isProcessing)
            return;
        if (contactData?.name?.trim()?.length < 1 || contactData?.email?.trim()?.length < 1 || contactData?.description?.trim()?.length < 1) {
            showToast('Fill out required fields', 'error', 'bottom-left');
            return
        }
        setIsProcessing(true)
        await sendContactForm_API(contactData).then(response => {
            const _data = response?.data;
            if (response?.status == 200) {
                if (_data?.message == 'success') {
                    showToast('Message sent successfully', 'success', 'top-center');
                    setContactData({
                        name: '',
                        email: '',
                        phone: '',
                        description: '',
                    })
                    scrollToTop()
                } else {
                    showToast(_data?.message, 'error', 'top-center');
                }
            }
        }).catch(error => {
            showToast('Something went wrong', 'error', 'top-center');
        }).finally(() => {
            setIsProcessing(false)
        });
    }

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <div className='single_page_header_wrapper contact pl-10 pr-10'>
                <div className='container wide'>
                    <h3 className='single_page_header_title'>
                        <span>GET IN TOUCH WITH TONYETRADE TODAY</span>
                    </h3>
                </div>
            </div>
            <div className="container p-0">
                <div className="contact-sections flex space-between pl-10 pr-10 wrap-small">
                    <div className="contact-left flex-50 full-width-small full-width-small">
                        <h2 className="form-heading">HAVE GENERAL QUESTIONS / COMMENTS?</h2>
                        <form id="contact_form" className={`${isProcessing ? 'processing' : ''}`} onSubmit={event => {
                            event.preventDefault()
                            handleContactFormSubmit()
                        }}>
                            <div>
                                <input className="input_text" type="text" value={contactData?.name} placeholder="Name" required="required" onChange={event => {
                                    setContactData({
                                        ...contactData,
                                        name: event.target.value,
                                    })
                                }} />
                                <input className="input_text" type="email" value={contactData?.email} placeholder="Email Address" required="required" onChange={event => {
                                    setContactData({
                                        ...contactData,
                                        email: event.target.value?.trim(),
                                    })
                                }} />
                                <input className="input_text" type="tel" value={contactData?.phone} placeholder="Phone Number" onChange={event => {
                                    setContactData({
                                        ...contactData,
                                        phone: event.target.value,
                                    })
                                }} />
                            </div>
                            <div>
                                <textarea className="input_area" placeholder="Description" value={contactData?.description} required="required" onChange={event => {
                                    setContactData({
                                        ...contactData,
                                        description: event.target.value,
                                    })
                                }}></textarea>
                            </div>
                            <div>
                                <button className="submit_btn" type="submit">SUBMIT</button>
                            </div>
                        </form>
                    </div>
                    <div className="contact-right pt-50-small flex-33 pt-0 full-width-small pl-10-small pr-10-small">
                        <div>
                            <h2 className="contact-office">OFFICE</h2>
                            <p className="contact-info">(513) 616-2224</p>
                        </div>
                        <div>
                            <h2 className="contact-office">Address</h2>
                            <p className="contact-info">Tonyetrade Enterprises<br />
                                15029 N Thompson Peak Pkwy<br />
                                Ste B111 #618<br />
                                Scottsdale, AZ 85260-2568</p>
                        </div>
                        <div>
                            <h2 className="contact-office">SALES</h2>
                            <p className="contact-info">SALES@TONYETRADE.COM</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact