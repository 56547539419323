import { Link } from 'react-router-dom'
import '../ProductCard/productcard.css'
import React, { useCallback, useState } from 'react'
import { addToWishlist, removeFromWishlist, setCart } from '../../redux/action'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Switch from "react-switch";
import { useAuth } from '../../Hooks/useAuth'
import he from 'he'
import { convertToCommaSeparate, showToast } from '../../utils/utils'
import $ from 'jquery'

const openProductPopup = (event) => {
    document.body.classList.add('newlist_view_popupactive')
    const _this = $(event.target)
    const pricesloginner = _this.closest('.pricesloginner')
    const incart = pricesloginner.attr('data-incart')
    const _productID = parseInt(pricesloginner.attr('data-id'))
    $('#active_product_id').val(_productID);
    $('#newlist_view_popup').addClass('horizontal');
    $('#newlist_view_addcart_btn').removeClass('processing');
    var _parent = _this.closest('.newlinelog');
    var _grand_parent = _parent.closest('.single_product_wrapper');
    var _type = _this.attr('data-type');
    var _image = _this.attr('data-image');
    var _firstimage = '', _lastimage = ''
    if (_type == 'front') {
        _firstimage = _image;
        _lastimage = _parent.find('a[data-type="back"]').attr('data-image');
    } else if (_type == 'back') {
        _firstimage = _image;
        _lastimage = _parent.find('a[data-type="front"]').attr('data-image');
    }
    var _heading = _grand_parent.find('.title_wrapper .product_title .product_title_inner').text();
    var _sub_heading = _grand_parent.find('.product_subtitle').text();
    var _var_name = _parent.find('.cgrade span').text();
    var _var_price = _parent.find('.price_wrapper').html();
    var _detail_link = _this.attr('data-popuplink');

    var _popup = $('#newlist_view_popup');
    var _slider = $('#newlist_view_popup_slider_holder');
    _slider.removeClass();
    _slider.trigger('destroy.owl.carousel');
    // _slider.owlCarousel('destroy');
    _popup.addClass('loaded');

    _slider.html('').append('<div className="single_image"><img src="' + _firstimage + '" /></div>');
    _slider.append('<div className="single_image"><img src="' + _lastimage + '" /></div>');
    _popup.find('.header h2').html(_heading);
    _popup.find('.header .left_side').html(_sub_heading);
    _popup.find('.footer .meta .var_name_holder').text(_var_name);
    _popup.find('.footer .meta .var_price_holder').html(_var_price);
    $('#popup_view_detailer').attr('href', _detail_link);
    $('#newlist_view_addcart_btn').attr('data-var_id', _parent.find('.add_to_cart_button').attr('data-var_id'));

    if ('yes' == incart) {
        $('#newlist_view_addcart_btn').addClass('incart').text('View Cart →')
    } else {
        $('#newlist_view_addcart_btn').removeClass('incart').text('Add To Cart')
    }

    // $("#newlist_view_popup_slider_holder").owlCarousel();

    const scriptContent = `
        $("#newlist_view_popup_slider_holder").addClass('owl-carousel owl-loaded');
        
        setTimeout(()=>{
            $("#newlist_view_popup_slider_holder").owlCarousel({
                items: 1,
                nav: true,
                loop: true,
                dots: false,
                mouseDrag: false,
                touchDrag: false
            });
            $('#newlist_popup_closer').on('click',function(){
                $("#newlist_view_popup_slider_holder").trigger('destroy.owl.carousel')
            });
        },100);
        
    `;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptContent;
    script.setAttribute('id', 'productpreviewscript')
    // setTimeout(()=>{
    //     // document.body.appendChild(script);
    // },5000)

    // $('#newlist_view_popup_slider_holder .single_image img').on('load', function () {
    setTimeout(() => {
        document.body.appendChild(script);
    }, 50)
    // });

}

const ProductCard = (props) => {
    let product = props?.product

    if (product?.type == 'variable' && product?.variations?.length > 0) {
        product?.variations.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    }

    const dispatcher = useDispatch()
    const auth = useAuth()
    const cart = useSelector((state) => state.cartReducer?.items)
    const cartReducer = useSelector((state) => state.cartReducer)
    const isInCart = useCallback((product) => {
        return cart.some(item => item?.id === product?.id);
    })
    const wishlistProducts = useSelector((state) => state?.wishlistReducer?.products)
    const handleAddToCart = async (product, event, type = 'simple') => {
        event.target.classList.add('processing');

        let headers = {}
        const userInner = await auth.getCurrentUser()

        if (userInner) {
            headers = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                }
            }
        }

        if (userInner || 1) {

            let cartKeyParam = ''
            if (!userInner && cartReducer && cartReducer?.cart_key) {
                cartKeyParam = `?cart_key=${cartReducer?.cart_key}`
            }

            await axios.post(process.env.REACT_APP_CC_API_BASE + `cart/add-item${cartKeyParam}`, {
                "id": '' + product?.id,
                "quantity": "1"
            }, headers).then(res => {
                if (res?.status == 200 && undefined != res?.data?.cart_key) {
                    dispatcher(setCart(res?.data))
                }
            }).catch(err => {

                if (err?.response?.data) {
                    showToast(he.decode(err?.response?.data?.message), 'error','bottom-left');
                }
            }).finally(() => {
                event.target.classList.remove('processing');
            })
        }
    }
    const images = React.useMemo(() => {
        let imageSet = []
        if (Array.isArray(product?.thumbnails) && product?.thumbnails?.length > 0) {
            imageSet = [...product.thumbnails]
        }
        if (Array.isArray(product?.variations) && product?.variations?.length > 0) {
            product?.variations?.forEach(variation => {
                if (variation?.thumbnails) {
                    imageSet = [...imageSet, ...variation?.thumbnails]
                }
            })
        }
        return [...new Set(imageSet)]
    }, [product])

    const handleAddToWishlist = (product, event) => {
        const incart = wishlistProducts?.includes(product?.id)
        if (incart) {
            event.currentTarget.classList.remove('incart')
            dispatcher(removeFromWishlist(product?.id))
        } else {
            event.currentTarget.classList.add('incart')
            dispatcher(addToWishlist(product?.id))
        }
    }

    const isProductInCart = (product, event) => {
        return wishlistProducts?.includes(product?.id)
    }

    const [flipSwitches, setFlipSwitches] = useState({
        variants: false,
        variantsnotavailable: false,
        pricechange: false,
    })

    const [backVisible, setBackVisible] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    return (
        <div className={`full-width-small single_product_wrapper product_type_${product?.type} ${backVisible ? 'flipped' : ''}`} data-testid={product?.id}>
            <div className="single_product_wrapper_inner">
                <div className="product-box">
                    <div className={`product_backside ${isProcessing ? 'processing' : ''}`}>
                        <div className='product_backside_inner'>
                            <div className='product_backside_inner_header'>
                                <h3>
                                    <span>
                                        Notifications & Alerts
                                    </span>
                                    <span className="">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.6893 14.7239H17.8112L16.653 13.5658C16.3389 13.2516 16.1624 12.8256 16.1624 12.3814V9.77767C16.1624 7.62402 14.786 5.79185 12.8649 5.11283V4.83138C12.8649 3.92079 12.1267 3.18262 11.2161 3.18262C10.3056 3.18262 9.56738 3.92079 9.56738 4.83138V5.11283C7.64625 5.79185 6.26986 7.62402 6.26986 9.77767V12.3814C6.26986 12.8256 6.09339 13.2516 5.77929 13.5658L4.62109 14.7239H8.743M13.6893 14.7239V15.5483C13.6893 16.9142 12.582 18.0215 11.2161 18.0215C9.85026 18.0215 8.743 16.9142 8.743 15.5483V14.7239M13.6893 14.7239H8.743" stroke="#525357" strokeWidth="1.77006" strokeLinecap="round" strokeLinejoin="round"></path> </svg>
                                    </span>
                                </h3>
                                <div className='product_backside_inner_header_body'>
                                    <div className='single_alert_type for_single'>
                                        <span>
                                            When new variants added
                                        </span>
                                        <Switch onChange={(val) => {
                                            setFlipSwitches({
                                                ...flipSwitches,
                                                variants: val,
                                            })
                                        }}
                                            checked={flipSwitches?.variants}
                                            offColor='#b3b5bd'
                                            onColor="#b3b5bd"
                                            onHandleColor="#a21d0b"
                                            offHandleColor="#b3b5bd"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={10}
                                            width={43}
                                            className="react-switch"
                                            id="material-switch"
                                        />
                                    </div>
                                    <div className='single_alert_type for_single'>
                                        <span>
                                            When variants no longer available.
                                        </span>
                                        <Switch onChange={(val) => {
                                            setFlipSwitches({
                                                ...flipSwitches,
                                                variantsnotavailable: val,
                                            })
                                        }}
                                            checked={flipSwitches?.variantsnotavailable}
                                            offColor='#b3b5bd'
                                            onColor="#b3b5bd"
                                            onHandleColor="#a21d0b"
                                            offHandleColor="#b3b5bd"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={10}
                                            width={43}
                                            className="react-switch"
                                            id="material-switch"
                                        />
                                    </div>
                                    <div className='single_alert_type for_single'>
                                        <span>
                                            If there is a price change
                                        </span>
                                        <Switch onChange={(val) => {
                                            setFlipSwitches({
                                                ...flipSwitches,
                                                pricechange: val,
                                            })
                                        }}
                                            checked={flipSwitches?.pricechange}
                                            offColor='#b3b5bd'
                                            onColor="#b3b5bd"
                                            onHandleColor="#a21d0b"
                                            offHandleColor="#b3b5bd"
                                            handleDiameter={20}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={10}
                                            width={43}
                                            className="react-switch"
                                            id="material-switch"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='product_backside_inner_footer'>
                                <a href='#' className='confirm' onClick={(event) => {
                                    event.preventDefault()
                                    setIsProcessing(true)
                                    // setBackVisible(false)
                                }}>Confirm</a>
                                <a className='cancel' href='#' onClick={(event) => {
                                    event.preventDefault()
                                    setBackVisible(false)
                                }}>Cancel</a>
                            </div>
                        </div>
                    </div>
                    <div className="product_frontend">
                        <div className="title_wrapper">
                            <div className="fornew_listview">
                                <h3 className="product_title">
                                    <Link to={`/${product?.slug}`}>
                                        {
                                            product?.cardnumber > 0 ?
                                                <span className="card_number">
                                                    #{product?.cardnumber}
                                                </span> : null
                                        }
                                        <span className='product_title_inner'>{product?.title}</span>
                                    </Link>
                                </h3>
                                <h4 className="product_subtitle">
                                    {product?.year} {product?.manu}
                                    <span className="teamname">

                                    </span>
                                </h4>
                                {
                                    product?.year > 0 ?
                                        <div className="c_year">
                                            <Link to={`/c_year/${product?.year}`}>
                                                {product?.year}
                                            </Link>
                                        </div> : null
                                }
                                <div className="newdesign_top_actions">
                                    <a href="#" className={`new_design_wishlist ${isProductInCart(product) ? 'incart' : ''}`} onClick={event => {
                                        event.preventDefault()
                                        handleAddToWishlist(product, event)
                                    }}>
                                        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="24" height="24" fill="white" /> <path d="M21 8.99998C21 12.7539 15.7156 17.9757 12.5857 20.5327C12.2416 20.8137 11.7516 20.8225 11.399 20.5523C8.26723 18.1523 3 13.1225 3 8.99998C3 2.00001 12 2.00002 12 8C12 2.00001 21 1.99999 21 8.99998Z" stroke="#000000" strokeinecap="round" strokeLinejoin="round" /> </svg>
                                    </a>
                                    <a href="#" className="new_design_alert" onClick={(event) => {
                                        event.preventDefault()
                                        setBackVisible(true)
                                    }}>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.6893 14.7239H17.8112L16.653 13.5658C16.3389 13.2516 16.1624 12.8256 16.1624 12.3814V9.77767C16.1624 7.62402 14.786 5.79185 12.8649 5.11283V4.83138C12.8649 3.92079 12.1267 3.18262 11.2161 3.18262C10.3056 3.18262 9.56738 3.92079 9.56738 4.83138V5.11283C7.64625 5.79185 6.26986 7.62402 6.26986 9.77767V12.3814C6.26986 12.8256 6.09339 13.2516 5.77929 13.5658L4.62109 14.7239H8.743M13.6893 14.7239V15.5483C13.6893 16.9142 12.582 18.0215 11.2161 18.0215C9.85026 18.0215 8.743 16.9142 8.743 15.5483V14.7239M13.6893 14.7239H8.743" stroke="#525357" strokeWidth="1.77006" strokeLinecap="round" strokeLinejoin="round"></path> </svg>
                                    </a>
                                </div>
                            </div>
                            {
                                product?.type == 'variable' && product?.collectionid ?
                                    <div className='fornew_listview collection_btn_wrapper'>
                                        <Link className='collection_btn' to={`/collection/${product?.collectionid}`}>
                                            View Full Collection
                                        </Link>
                                    </div> : null
                            }
                        </div>
                        <div className="boxdimage">
                            {
                                images?.length > 0 ?
                                    <OwlCarousel items={1} className="owl-theme single-product-thumbnails-slider" loop margin={8} dots={true} lazyLoad={true} nav={true}>
                                        {
                                            images?.map((image, index) => {
                                                return (
                                                    <div key={index} className='pro_slider_item'>
                                                        <Link className='overall_product_link' to={`/${product?.slug}`}>
                                                            {
                                                                image ? <img src={image?.replace('staging.', 'www.')} loading='lazy' /> : null
                                                            }
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </OwlCarousel>
                                    : null
                            }
                        </div>
                        <div className="box-text-products">
                            {
                                product?.team?.trim()?.length > 0 ?
                                    <div className='customdetails single_card'>
                                        <div className='c_team'>
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.8203 10.4424L11.6133 10.0596C11.9141 10.6885 12.3242 11.2627 12.8164 11.7275C13.7461 10.5791 14.293 9.10254 14.293 7.48926C14.293 5.90332 13.7461 4.42676 12.8164 3.27832C12.3242 3.74316 11.9414 4.29004 11.6407 4.8916L10.8477 4.50879C11.2032 3.79785 11.668 3.16895 12.2422 2.62207C11.0117 1.44629 9.37112 0.708008 7.53909 0.708008C5.70706 0.708008 4.03909 1.44629 2.80862 2.62207C3.38284 3.16895 3.84769 3.79785 4.20316 4.50879L3.41019 4.8916C3.10941 4.29004 2.72659 3.74316 2.23441 3.27832C1.30472 4.42676 0.757843 5.90332 0.757843 7.48926C0.757843 9.10254 1.30472 10.5791 2.23441 11.7275C2.72659 11.2354 3.13675 10.6885 3.43753 10.0596L4.2305 10.4424C3.87503 11.1807 3.41019 11.8096 2.83597 12.3838C4.03909 13.5596 5.70706 14.2705 7.53909 14.2705C9.34378 14.2705 11.0117 13.5596 12.2149 12.3838C11.6407 11.8369 11.1758 11.1807 10.8203 10.4424ZM4.58597 9.56738L3.73831 9.32129C4.14847 8.03613 4.09378 6.75098 3.73831 5.65723L4.55862 5.38379C4.96878 6.61426 5.05081 8.09082 4.58597 9.56738ZM10.4922 5.38379L11.3125 5.65723C10.9571 6.75098 10.9024 8.03613 11.3125 9.32129L10.4649 9.59473C10 8.11816 10.0821 6.61426 10.4922 5.38379Z" fill="#090F25"></path> </svg>
                                            <Link to={`/team/${product?.team_slug}`}>
                                                {product?.team}
                                            </Link>
                                        </div>
                                    </div> : null
                            }
                            <div className="priceslog">
                                {
                                    product?.type == 'variable' && product?.variations?.length > 0 ?
                                        product?.variations?.map((variation, index) => {
                                            let frontimage = '', backimage = ''
                                            if (variation?.frontimage)
                                                frontimage = variation?.frontimage?.replace('staging.', '');
                                            if (variation?.backimage)
                                                backimage = variation?.backimage?.replace('staging.', '');
                                            return (
                                                <div key={index} className='newlinelog' data-fimage={variation?.frontimage} data-bimage={variation?.backimage}>
                                                    <div className='cgrade'>
                                                        <span>{variation?.grade?.name}</span>
                                                    </div>
                                                    <div>
                                                        <Link to={`/${product?.slug}/?variation=${variation?.id}`} className='priceLink'>
                                                            ${convertToCommaSeparate(variation?.price)}
                                                        </Link>
                                                    </div>
                                                    <div className='fornew_listview'>
                                                        <a href="#" className="newlist_popup_opener" data-popuplink={`/${product?.slug}/`} data-type="front" data-image={frontimage} onClick={(e) => {
                                                            e.preventDefault()
                                                            openProductPopup(e)
                                                        }}>View Front</a>
                                                    </div>
                                                    <div className='fornew_listview'>
                                                        <a href="#" data-popuplink={`/${product?.slug}/`} className="newlist_popup_opener" data-type="back" data-image={backimage} onClick={(e) => {
                                                            e.preventDefault()
                                                            openProductPopup(e)
                                                        }}>View Back</a>
                                                    </div>
                                                    <div className='fornew_listview newactions'>

                                                    </div>
                                                    <div className='fornew_listview add_to_cart_wrapper'>
                                                        {
                                                            isInCart(variation) ?
                                                                <Link className='view_cart' to={`/cart`}>VIEW CART → </Link> :
                                                                <a data-product-id={variation?.id} className="newlist_custom_addcart" href="#" onClick={(event) => {
                                                                    event.preventDefault()
                                                                    handleAddToCart(variation, event, 'variation')
                                                                }}>
                                                                    Add To Cart
                                                                </a>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : null
                                }
                                {
                                    product?.type == 'simple' ?
                                        <div className='pricesloginner' data-id={product?.id} data-incart={isInCart(product) ? 'yes' : ''} data-gallery={product?.thumbnails[0]} data-fimage={product?.thumbnails[0]} data-bimage={product?.thumbnails[1]} cardnumber="newlinelog is_simple_product has_gallery">
                                            <div className="price_wrapper">
                                                <span className="price">
                                                    ${convertToCommaSeparate(product?.price)}
                                                </span>
                                            </div>
                                            <div className="fornew_listview open_link front">
                                                {/* <a data-image={ product?.thumbnails?.length > 0 && product?.thumbnails[0]?.replace('staging.', '')} data-type="front" className="newlist_popup_opener" href="#" data-popuplink={`/${product?.slug}`} onClick={(e) => { */}
                                                <a data-image={
                                                    Array.isArray(product?.thumbnails) && product?.thumbnails.length > 0 && typeof product?.thumbnails[0] === 'string'
                                                        ? product?.thumbnails[0].replace('staging.', '')
                                                        : ''
                                                } data-type="front" className="newlist_popup_opener" href="#" data-popuplink={`/${product?.slug}`} onClick={(e) => {
                                                    e.preventDefault()
                                                    openProductPopup(e)
                                                }}>
                                                    View Front
                                                </a>
                                            </div>
                                            <div className="fornew_listview open_link front">
                                                {/* <a data-image={ product?.thumbnails?.length > 0 && product?.thumbnails[1]?.replace('staging.', '')} data-type="back" className="newlist_popup_opener" href="#" data-popuplink={`/${product?.slug}`} onClick={(e) => { */}
                                                <a data-image={
                                                    Array.isArray(product?.thumbnails) && product?.thumbnails.length > 1 && typeof product?.thumbnails[1] === 'string'
                                                        ? product?.thumbnails[1].replace('staging.', '')
                                                        : ''
                                                } data-type="back" className="newlist_popup_opener" href="#" data-popuplink={`/${product?.slug}`} onClick={(e) => {
                                                    e.preventDefault()
                                                    openProductPopup(e)
                                                }}>
                                                    View Back
                                                </a>
                                            </div>
                                            <div className="fornew_listview add_to_cart_wrapper">
                                                {
                                                    isInCart(product) ?
                                                        <Link className='view_cart' to={`/cart`}>VIEW CART → </Link> :
                                                        <a data-product-id={product?.id} className="newlist_custom_addcart" href="#" onClick={(event) => {
                                                            event.preventDefault()
                                                            handleAddToCart(product, event)
                                                        }}>
                                                            Add To Cart
                                                        </a>
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard