import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { Link, useLocation } from "react-router-dom";
import { getPostStatusCounts, getProudcts } from "../../api/adminapi";
import { convertToCommaSeparate, getBulkActions, getPlaceholderImage, NumberFormatter, scrollToTop } from "../../utils/utils";
import { getCache, setCache } from "../../cacheUtil";
import PostStatuses from "../../components/PostStatuses";
import AdminTableMeta from "../../components/AdminTableMeta";

const CACHE_KEY_PRODUCTS = 'products_data';
const CACHE_KEY_COUNTERS = 'products_counters';

const Products = () => {

    const location = useLocation();
    const getQueryParams = (search) => {
        return Object.fromEntries(new URLSearchParams(search));
    };
    const queryParams = getQueryParams(location.search);
    const taxonomy = queryParams.taxonomy;
    const term = queryParams.term;

    const [activeFilters, setActiveFilters] = useState({
        status: 'all',
        sortBy: null,
        sortOrder: "asc",
        ...(taxonomy && { taxonomy }),
        ...(term && { term })
    });
    
    const [counters, setCounters] = useState({
        all: { c: 0, t: 'All' },
        published: { c: 0, t: 'Published' },
        trash: { c: 0, t: 'Trash' },
        draft: { c: 0, t: 'Draft' },
        amazon: { c: 0, t: 'On Aamzon' },
        notamazon: { c: 0, t: 'Not on Amazon' },
        ebay: { c: 0, t: 'On eBay' },
        notebay: { c: 0, t: 'Not on eBay' },
    });

    const [products, setProducts] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [searching, setSeaching] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [total, setTotal] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')

    const handleSort = (field) => {
        if (loading)
            return;
        setActiveFilters((prev) => {
            const isSameField = prev.sortBy === field;
            return {
                ...prev,
                sortBy: field,
                sortOrder: isSameField ? (prev.sortOrder === "asc" ? "desc" : "asc") : "asc",
            };
        });
    };

    const fetchProducts = async (page = null, refreshCounter = false, isSearching = false) => {
        if (loading)
            return
        if (!isSearching)
            setSearchQuery('')
        try {
            setLoading(true);
            scrollToTop();
            await getProudcts(activeFilters, page ? page : currentPage, isSearching ? searchQuery : '').then(response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setTotalPages(_data.total_pages)
                    setTotal(_data.total)
                    setCurrentPage(_data.current_page)
                    setProducts(_data.products)
                    if (!_data.nocache) {
                        setCache(CACHE_KEY_PRODUCTS, _data);
                    }
                    if (refreshCounter) {
                        getProductCounts()
                    }
                    setSeaching(isSearching);
                    scrollToTop();
                }
            })
            setLoading(false)
        } catch (error) {

        }
    }

    const getProductCounts = async () => {
        try {
            getPostStatusCounts('product').then(response => {
                const _counts = response.data.counts;
                if (_counts) {
                    setCounters(prevCounters => {
                        const updatedCounters = { ...prevCounters };
                        Object.entries(_counts)?.forEach(([key, value]) => {
                            if (updatedCounters[key]) {
                                updatedCounters[key] = {
                                    ...updatedCounters[key],
                                    c: value,
                                };
                            }
                        });
                        setCache(CACHE_KEY_COUNTERS, updatedCounters);
                        return updatedCounters;
                    });
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        const loadCachedProducts = async () => {
            const cachedData = getCache(CACHE_KEY_PRODUCTS);
            if (cachedData) {
                setProducts(cachedData.products);
                setTotalPages(cachedData.total_pages);
                setTotal(cachedData.total);
                setCurrentPage(cachedData.current_page);
            }
        };
        loadCachedProducts();
        fetchProducts();
    }, [activeFilters])

    useEffect(() => {
        const cachedCounters = getCache(CACHE_KEY_COUNTERS);
        if (cachedCounters) {
            setCounters(cachedCounters);
        }
        getProductCounts();
    }, [])

    let startPage = Math.max(1, currentPage - Math.floor(totalPages / 2));
    let endPage = startPage + totalPages - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - totalPages + 1);
    }

    const handleStatusFilter = (status) => {
        setActiveFilters({
            ...activeFilters,
            status: status,
        })
    }


    const [selectedProducts, setSelectedProducts] = useState([]);
    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allProductIds = products.map((product) => product.id);
            setSelectedProducts(allProductIds);
        } else {
            setSelectedProducts([]);
        }
    }

    const handleProductCheckbox = (e, productId) => {
        if (e.target.checked) {
            setSelectedProducts((prev) => [...prev, productId]);
        } else {
            setSelectedProducts((prev) => prev.filter((id) => id !== productId));
        }
    };

    const handleSearchFormSubmit = (query) => {
        if (loading || !query?.trim()?.length)
            return;
        setLoading(true)
        setSearchQuery(query);
        fetchProducts(null, false, true);
    }

    const getSortClass = (field) => {
        if (activeFilters.sortBy === field) {
            return activeFilters.sortOrder === "asc" ? "sorting_asc" : "sorting_desc";
        }
        return "sorting";
    };

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="admin-card">
                            <AdminTableMeta showPagination={true}
                                pagination={{
                                    total: total,
                                    per_page: 20,
                                    currentpage: currentPage,
                                    setCurrentPage: setCurrentPage,
                                }}
                                refresh={fetchProducts}
                                loading={loading}
                                showSearch={true}
                                searching={searching}
                                search={{
                                    searchQuery: searchQuery,
                                    setSearchQuery: setSearchQuery,
                                    placeholder: 'Search products...'
                                }}
                                showBulkActions={true}
                                bulkActions={{
                                    actions: getBulkActions('product')
                                }}
                                selectedItems={selectedProducts}
                                setLoading={setLoading}
                                post_type={'product'}
                                handleSearchFormSubmit={handleSearchFormSubmit}
                            />
                            <div class="card-datatable table-responsive">
                                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <PostStatuses counters={counters} activeFilters={activeFilters} handleStatusFilter={handleStatusFilter} />
                                    <table class="datatables-products table dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                        <thead class="border-top">
                                            <tr>
                                                <th class="control sorting_disabled dtr-hidden" rowspan="1" colspan="1" style={{
                                                    width: 22.3594 + 'px',
                                                    display: 'none',
                                                }} aria-label=""></th>
                                                <th class="sorting_disabled pr-0 dt-checkboxes-cell dt-checkboxes-select-all" rowspan="1" colspan="1" style={{
                                                    width: 18.6328 + 'px',
                                                }} data-col="1" aria-label="">
                                                    <input type="checkbox" class="form-check-input" onChange={handleAllSelector}
                                                        checked={
                                                            products && selectedProducts.length === products.length
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className={getSortClass("title")}
                                                    tabindex="0"
                                                    aria-controls="DataTables_Table_0"
                                                    rowspan="1"
                                                    colspan="1"
                                                    style={{
                                                        width: 113.82 + 'px',
                                                    }}
                                                    onClick={() => handleSort("title")}
                                                >Name</th>
                                                <th className={getSortClass("sku")} tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61.4375 + 'px',
                                                }}
                                                    onClick={() => handleSort("sku")}
                                                >sku</th>
                                                <th class="sorting_disabled" rowspan="1" colspan="1" style={{
                                                    width: 84.5703 + 'px',
                                                }} aria-label="stock">stock</th>
                                                <th className={getSortClass("price")} tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 78.8125 + 'px',
                                                }} aria-label="price: activate to sort column ascending"
                                                    onClick={() => handleSort("price")}
                                                >price</th>
                                                <th tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 124.781 + 'px',
                                                }} aria-label="category: activate to sort column ascending">categories</th>
                                                <th className={getSortClass("cat_count")} tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61 + 'px',
                                                }} aria-label="qty: activate to sort column ascending"
                                                    onClick={() => handleSort("cat_count")}
                                                >Category Count</th>
                                                <th className={getSortClass("date")} tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61 + 'px',
                                                }} aria-label="qty: activate to sort column ascending"
                                                    onClick={() => handleSort("date")}
                                                >Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                'undefined' == typeof products ?
                                                    (
                                                        <>
                                                            <tr class="odd">
                                                                <td valign="top" colspan="9" class="dataTables_empty">Loading...</td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        !products.length ?
                                                            <p>No</p> :
                                                            products.map(product => {
                                                                if (!product?.image?.length) {
                                                                    product.image = getPlaceholderImage()
                                                                }
                                                                return <tr class="odd">
                                                                    <td class="dt-checkboxes-cell">
                                                                        <input type="checkbox" class="dt-checkboxes form-check-input"
                                                                            checked={selectedProducts.includes(product.id)}
                                                                            onChange={(e) =>
                                                                                handleProductCheckbox(e, product.id)
                                                                            } />
                                                                    </td>
                                                                    <td class="sorting_1">
                                                                        <Link
                                                                            to={`/admin/products/add?product_id=${product.id}&action=edit`}
                                                                            style={{
                                                                                textDecoration: 'none'
                                                                            }}
                                                                        >
                                                                            <div class="d-flex justify-content-start align-items-center product-name">
                                                                                <div class="avatar-wrapper">
                                                                                    <div class="avatar avatar me-4 rounded-2 bg-label-secondary">
                                                                                        {
                                                                                            product?.image ?
                                                                                                <img src={product?.image?.replace('staging.', 'admin.')} alt="Product-9" class="rounded-2" /> : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div class="d-flex flex-column">
                                                                                    <h6 class="mb-0">
                                                                                        {product.name}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {product.sku ? product.sku : '–'}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span class="text-truncate">
                                                                            <span class={`badge ${product.stock_status == 'outofstock' ? 'bg-label-danger' : 'bg-label-success'}`} text-capitalized="">
                                                                                {product.stock_status == 'outofstock' ? 'Out of Stock' : 'In Stock'}
                                                                                {
                                                                                    product?.manage_stock ? ` (${product.stock})` : ''
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                    <td><span>${convertToCommaSeparate(product.price)}</span></td>
                                                                    <td>
                                                                        <span className="d-flex align-items-center text-heading">
                                                                            {
                                                                                product?.cats?.length ? (
                                                                                    <div className="product_cats">
                                                                                        {
                                                                                            product?.cats?.map((cat, index) => {
                                                                                                return (
                                                                                                    <React.Fragment key={cat.term_id}>
                                                                                                        <Link to={`/admin/edit-tags/product_cat/?term_id=${cat.term_id}`}>
                                                                                                            {cat.name}
                                                                                                        </Link>
                                                                                                        {
                                                                                                            index < product.cats.length - 1 && <span>, </span>
                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {product?.cats?.length}
                                                                    </td>
                                                                    <td class="dtr-hidden">
                                                                        <div class="d-flex flex-column">
                                                                            <span class="text-truncate">
                                                                                <span class={`badge ${product.status == 'draft' ? 'bg-label-danger' : 'bg-label-success'} mb-1 text-capitalize`} text-capitalized="">
                                                                                    {
                                                                                        product.status ? `${product.status}` : ''
                                                                                    }
                                                                                </span>
                                                                            </span>
                                                                            <span className="product_date">
                                                                                {product.date}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            })
                                                    )
                                            }
                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                                Showing <strong>{products ? products.length : 0}</strong> of <strong>{NumberFormatter(total)}</strong> products
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <AdminTableMeta showPagination={true}
                                                pagination={{
                                                    total: total,
                                                    per_page: 20,
                                                    currentpage: currentPage,
                                                    refresh: fetchProducts,
                                                    setCurrentPage: setCurrentPage,
                                                }}
                                                loading={loading}
                                                refresh={fetchProducts}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default Products