import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { Link } from "react-router-dom";
import { getUsers_API, getUsersCounts_API } from "../../api/adminapi";
import { convertToCommaSeparate, getBulkActions, getPlaceholderImage, NumberFormatter, scrollToTop } from "../../utils/utils";
import { getCache, setCache } from "../../cacheUtil";
import PostStatuses from "../../components/PostStatuses";
import AdminTableMeta from "../../components/AdminTableMeta";

const CACHE_KEY_USERS = 'users_data';
const CACHE_KEY_COUNTERS = 'users_count';

const Users = () => {

    const [activeFilters, setActiveFilters] = useState({
        role: null,
        sortBy: null,
        sortOrder: "asc",
    });

    const [counters, setCounters] = useState({
        all: { c: 0, t: 'All' },
        administrator: { c: 0, t: 'Administrator' },
        customer: { c: 0, t: 'Customer' },
        report_viewer: { c: 0, t: 'Report Viewer' },
    });

    const [users, setUsers] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [searching, setSeaching] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [total, setTotal] = useState(0)
    const [searchQuery, setSearchQuery] = useState('')

    const handleSort = (field) => {
        if (loading)
            return;
        setActiveFilters((prev) => {
            const isSameField = prev.sortBy === field;
            return {
                ...prev,
                sortBy: field,
                sortOrder: isSameField ? (prev.sortOrder === "asc" ? "desc" : "asc") : "asc",
            };
        });
    };

    const fetchUsers = async (page = null, placeholder = false, isSearching = false) => {
        if (loading)
            return
        if (!isSearching)
            setSearchQuery('')
        try {
            setLoading(true);
            scrollToTop();
            await getUsers_API(activeFilters, page ? page : currentPage, isSearching ? searchQuery : '').then(response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setTotalPages(_data.total_pages)
                    setTotal(_data.total_users)
                    setCurrentPage(_data.current_page)
                    setUsers(_data.users)
                    if (!_data.nocache) {
                        setCache(CACHE_KEY_USERS, _data);
                    }
                    setSeaching(isSearching);
                    scrollToTop();
                }
            })
            setLoading(false)
        } catch (error) {

        }
    }

    const getUsersCounts = async () => {
        try {
            const response = await getUsersCounts_API();
            const { avail_roles: roleCounts, total_users: totalUsers } = response.data;

            if (roleCounts) {
                setCounters((prevCounters) => {
                    const updatedCounters = {
                        ...prevCounters, all: {
                            c: totalUsers,
                            t: 'All',
                        }
                    };
                    Object.entries(roleCounts).forEach(([role, count]) => {
                        updatedCounters[role] = {
                            ...updatedCounters[role],
                            c: count,
                        };
                    });
                    setCache(CACHE_KEY_COUNTERS, updatedCounters);
                    return updatedCounters;
                });
            }
        } catch (error) {
            console.error("Error fetching user counts:", error); // Log the error for debugging
        }
    };


    useEffect(() => {
        const loadCachedUsers = async () => {
            const cachedData = getCache(CACHE_KEY_USERS);
            if (cachedData) {
                setUsers(cachedData.users);
                setTotalPages(cachedData.total_pages);
                setTotal(cachedData.total);
                setCurrentPage(cachedData.current_page);
            }
        };
        loadCachedUsers();
        fetchUsers();
    }, [activeFilters])

    useEffect(() => {
        const cachedCounters = getCache(CACHE_KEY_COUNTERS);
        if (cachedCounters) {
            setCounters(cachedCounters);
        }
        getUsersCounts();
    }, [])

    let startPage = Math.max(1, currentPage - Math.floor(totalPages / 2));
    let endPage = startPage + totalPages - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - totalPages + 1);
    }

    const handleStatusFilter = (status) => {
        setActiveFilters({
            ...activeFilters,
            status: status,
        })
    }

    const [selectedUsers, setSelectedUsers] = useState([]);
    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allUsersIds = users.map((user) => user.id);
            setSelectedUsers(allUsersIds);
        } else {
            setSelectedUsers([]);
        }
    }

    const handleUserCheckbox = (e, userId) => {
        if (e.target.checked) {
            setSelectedUsers((prev) => [...prev, userId]);
        } else {
            setSelectedUsers((prev) => prev.filter((id) => id !== userId));
        }
    };

    const handleSearchFormSubmit = (query) => {
        if (loading || !query?.trim()?.length)
            return;
        setLoading(true)
        setSearchQuery(query);
        fetchUsers(null, false, true);
    }

    const getSortClass = (field) => {
        if (activeFilters.sortBy === field) {
            return activeFilters.sortOrder === "asc" ? "sorting_asc" : "sorting_desc";
        }
        return "sorting";
    };

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="admin-card">
                            <AdminTableMeta showPagination={true}
                                pagination={{
                                    total: total,
                                    per_page: 50,
                                    currentpage: currentPage,
                                    setCurrentPage: setCurrentPage,
                                }}
                                refresh={fetchUsers}
                                loading={loading}
                                showSearch={true}
                                searching={searching}
                                search={{
                                    searchQuery: searchQuery,
                                    setSearchQuery: setSearchQuery,
                                    placeholder: 'Search users...'
                                }}
                                showBulkActions={true}
                                bulkActions={{
                                    actions: getBulkActions('users')
                                }}
                                selectedItems={selectedUsers}
                                setLoading={setLoading}
                                post_type={'users'}
                                handleSearchFormSubmit={handleSearchFormSubmit}
                            />
                            <div class="card-datatable table-responsive">
                                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <PostStatuses counters={counters} activeFilters={activeFilters} handleStatusFilter={handleStatusFilter} />
                                    <table class="datatables-products table dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                        <thead class="border-top">
                                            <tr>
                                                <th class="sorting_disabled pr-0 dt-checkboxes-cell dt-checkboxes-select-all" rowspan="1" colspan="1" style={{
                                                    width: 18.6328 + 'px',
                                                }} data-col="1" aria-label="">
                                                    <input type="checkbox" class="form-check-input" onChange={handleAllSelector}
                                                        checked={
                                                            users && selectedUsers.length === users.length
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className={getSortClass("login")}
                                                    style={{
                                                        width: 113.82 + 'px',
                                                    }}
                                                    onClick={() => handleSort("login")}
                                                >Username</th>
                                                <th style={{
                                                    width: 61.4375 + 'px',
                                                }}>Name</th>
                                                <th
                                                    className={getSortClass("email")}
                                                    style={{
                                                        width: 84.5703 + 'px',
                                                    }}
                                                    onClick={() => handleSort("email")}
                                                >Email</th>
                                                <th style={{
                                                    width: 78.8125 + 'px',
                                                }}>Role</th>
                                                <th style={{
                                                    width: 28.8125 + 'px',
                                                }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                'undefined' == typeof users ?
                                                    (
                                                        <>
                                                            <tr class="odd">
                                                                <td valign="top" colspan="9" class="dataTables_empty">Loading...</td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        !users.length ?
                                                            <p>No users found</p> :
                                                            users.map(user => {
                                                                if (!user?.image?.length) {
                                                                    user.image = getPlaceholderImage()
                                                                }
                                                                return <tr class="odd">
                                                                    <td class="dt-checkboxes-cell">
                                                                        <input type="checkbox" class="dt-checkboxes form-check-input"
                                                                            checked={selectedUsers.includes(user.id)}
                                                                            onChange={(e) =>
                                                                                handleUserCheckbox(e, user.id)
                                                                            } />
                                                                    </td>
                                                                    <td class="sorting_1">
                                                                        <Link
                                                                            to={`/admin/users/${user.id}`}
                                                                            style={{
                                                                                textDecoration: 'none'
                                                                            }}
                                                                        >
                                                                            <div class="d-flex justify-content-start align-items-center product-name">
                                                                                <div class="avatar-wrapper">
                                                                                    <div class="avatar avatar me-4 rounded-2 bg-label-secondary">
                                                                                        {
                                                                                            user?.image ?
                                                                                                <img src={user?.image?.replace('staging.', 'admin.')} alt="Product-9" class="rounded-2" /> : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div class="d-flex flex-column">
                                                                                    <h6 class="mb-0">
                                                                                        {user.username}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </td>
                                                                    <td>
                                                                        {user.name}
                                                                    </td>
                                                                    <td>
                                                                        <a href={`mailto:${user.email}`}>
                                                                            {user.email}
                                                                        </a>
                                                                    </td>
                                                                    <td className="text-capitalize">
                                                                        {user.role.replace('_', ' ')}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <Link
                                                                            to={`/admin/users/${user.id}`}
                                                                            style={{
                                                                                textDecoration: 'none'
                                                                            }}
                                                                            className="btn btn-icon btn-text-secondary rounded-pill"
                                                                        >
                                                                            {/* <a href="app-user-view-account.html" class="btn btn-icon btn-text-secondary waves-effect waves-light rounded-pill"></a> */}
                                                                            <i class="ti ti-eye ti-md"></i>
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            })
                                                    )
                                            }
                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                                Showing <strong>{users ? users.length : 0}</strong> of <strong>{NumberFormatter(total)}</strong> users
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <AdminTableMeta
                                                showPagination={true}
                                                pagination={{
                                                    total: total,
                                                    per_page: 50,
                                                    currentpage: currentPage,
                                                    refresh: fetchUsers,
                                                    setCurrentPage: setCurrentPage,
                                                }}
                                                loading={loading}
                                                refresh={fetchUsers}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default Users