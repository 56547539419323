import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { Link } from "react-router-dom";
import { fetchEbayListings, getEbayListingStatusCount } from "../../api/adminapi";
import { convertToCommaSeparate, getBulkActions, NumberFormatter, scrollToTop } from "../../utils/utils";
import Pagination from "../../components/Pagination";
import { getCache, setCache } from "../../cacheUtil";
import PostStatuses from "../../components/PostStatuses";
import AdminTableMeta from "../../components/AdminTableMeta";

const CACHE_KEY_EBAY_LISTINGS = 'ebay_listings_data';
const CACHE_KEY_EBAY_COUNTERS = 'ebay_counters_data';

const getActions = (status, isLocked = false) => {
    let _status = []
    switch (status) {
        case 'prepared':
            _status.push('Preview')
            _status.push('Verify')
            _status.push('Publish')
            break;
        case 'published':
            _status.push('Preview')
            if (isLocked)
                _status.push('Unlock')
            else
                _status.push('Lock')
            _status.push('View on eBay')
            _status.push('End Listing')
            break;
        case 'sold':
            _status.push('Preview')
            if (isLocked)
                _status.push('Unlock')
            else
                _status.push('Lock')
            _status.push('Relist')
            break;
    }
    return _status
}

const EbayListings = () => {
    const [listings, setListings] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [total, setTotal] = useState(0)
    const [selectedListings, setSelectedListings] = useState([]);

    const getListingsCounts = async () => {
        try {
            getEbayListingStatusCount().then(response => {
                if (response.status == 200) {
                    const _counts = response.data;
                    // return
                    if (_counts) {
                        setCounters(prevCounters => {
                            const updatedCounters = { ...prevCounters };
                            Object.entries(_counts)?.forEach(([key, value]) => {
                                switch (key) {
                                    case 'total_items':
                                        key = 'all';
                                        break;
                                }
                                if (updatedCounters[key]) {
                                    updatedCounters[key] = {
                                        ...updatedCounters[key],
                                        c: value,
                                    };
                                }
                            });
                            setCache(CACHE_KEY_EBAY_COUNTERS, updatedCounters);
                            return updatedCounters;
                        });
                    }
                }
            })
        } catch (error) {

        }
    }

    const fetchListings = async (page = null) => {
        if (loading)
            return
        try {
            setLoading(true);
            await fetchEbayListings(activeFilters, page ? page : currentPage).then(response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setTotalPages(_data.listings.total_items)
                    setTotal(_data.listings.total_items)
                    setCurrentPage(_data.current_page)
                    setListings(_data.listings.items)
                    setCache(CACHE_KEY_EBAY_LISTINGS, _data);
                    scrollToTop()
                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false);
            })
        } catch (error) {

        }
    }
    const [activeFilters, setActiveFilters] = useState({
        status: 'all',
    })

    useEffect(() => {
        const loadCachedListings = () => {
            const cachedData = getCache(CACHE_KEY_EBAY_LISTINGS);
            if (cachedData) {
                setTotalPages(cachedData.listings.total_items);
                setTotal(cachedData.listings.total_items);
                setCurrentPage(cachedData.current_page);
                setListings(cachedData.listings.items);
            }
            fetchListings();
        };

        loadCachedListings();
    }, [activeFilters]);

    useEffect(() => {
        const cachedCounters = getCache(CACHE_KEY_EBAY_COUNTERS);
        if (cachedCounters) {
            setCounters(cachedCounters);
        }
        getListingsCounts();
    }, [])

    let startPage = Math.max(1, currentPage - Math.floor(totalPages / 2));
    let endPage = startPage + totalPages - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - totalPages + 1);
    }
    const [counters, setCounters] = useState({
        all: { c: 0, t: 'All' },
        prepared: { c: 0, t: 'Prepared' },
        verified: { c: 0, t: 'Verified' },
        published: { c: 0, t: 'Published' },
        changed: { c: 0, t: 'Changed' },
        ended: { c: 0, t: 'Ended' },
        with_errors: { c: 0, t: 'With Errors' },
        archived: { c: 0, t: 'Archived' },
        sold: { c: 0, t: 'Sold' },
        relist: { c: 0, t: 'Relist' },
        locked: { c: 0, t: 'Locked' },
        unlocked: { c: 0, t: 'Unlocked' },
    });

    const handleStatusFilter = (status) => {
        setActiveFilters({
            ...activeFilters,
            status: status,
        })
    }

    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allListingIds = listings.map(listing => listing.id);
            setSelectedListings(allListingIds);
        } else {
            setSelectedListings([]);
        }
    };

    const handleListingCheckbox = (e, listingId) => {
        if (e.target.checked) {
            setSelectedListings(prev => [...prev, listingId]);
        } else {
            setSelectedListings(prev => prev.filter(id => id !== listingId));
        }
    };

    const [searchQuery, setSearchQuery] = useState('')

    const handleSort = (field) => {
        if (loading)
            return;
        setActiveFilters((prev) => {
            const isSameField = prev.sortBy === field;
            return {
                ...prev,
                sortBy: field,
                sortOrder: isSameField ? (prev.sortOrder === "asc" ? "desc" : "asc") : "asc",
            };
        });
    };

    const getSortClass = (field) => {
        if (activeFilters.sortBy === field) {
            return activeFilters.sortOrder === "asc" ? "sorting_asc" : "sorting_desc";
        }
        return "sorting";
    };

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="admin-card">
                            <AdminTableMeta showPagination={true}
                                pagination={{
                                    total: total,
                                    per_page: 20,
                                    currentpage: currentPage,
                                    refresh: fetchListings,
                                    setCurrentPage: setCurrentPage,
                                }}
                                refresh={fetchListings}
                                loading={loading}
                                showSearch={true}
                                search={{
                                    searchQuery: searchQuery,
                                    setSearchQuery: setSearchQuery,
                                    placeholder: 'Search listings...'
                                }}
                                showBulkActions={true}
                                bulkActions={{
                                    actions: getBulkActions('ebay')
                                }}
                            />
                            <div class="card-datatable table-responsive">
                                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <PostStatuses counters={counters} handleStatusFilter={handleStatusFilter} activeFilters={activeFilters} />
                                    <table class="datatables-products table dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                        <thead class="border-top">
                                            <tr>
                                                <th class="control sorting_disabled dtr-hidden" rowspan="1" colspan="1" style={{
                                                    width: 22.3594 + 'px',
                                                    display: 'none',
                                                }} aria-label=""></th>
                                                <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" rowspan="1" colspan="1" style={{
                                                    width: 18.6328 + 'px',
                                                }} data-col="1" aria-label="">
                                                    <input type="checkbox" class="form-check-input"
                                                        onChange={handleAllSelector}
                                                        checked={listings && selectedListings.length === listings.length}
                                                    />
                                                </th>
                                                <th
                                                    style={{
                                                        width: 113.82 + 'px',
                                                    }}
                                                    className={getSortClass("auction_title")}
                                                    onClick={() => handleSort("auction_title")}
                                                >Title</th>
                                                <th style={{
                                                    width: 61.4375 + 'px',
                                                }}
                                                    className={getSortClass("sku")}
                                                    onClick={() => handleSort("sku")}
                                                >sku</th>
                                                <th style={{
                                                    width: 84.5703 + 'px',
                                                }}
                                                    className={getSortClass("quantity")}
                                                    onClick={() => handleSort("quantity")}
                                                >quantity</th>
                                                <th style={{
                                                    width: 78.8125 + 'px',
                                                }}
                                                    className={getSortClass("quantity_sold")}
                                                    onClick={() => handleSort("quantity_sold")}
                                                >sold</th>
                                                <th style={{
                                                    width: 124.781 + 'px',
                                                }}
                                                    className={getSortClass("price")}
                                                    onClick={() => handleSort("price")}
                                                >price</th>
                                                <th style={{
                                                    width: 61 + 'px',
                                                }}
                                                    className={getSortClass("date_published")}
                                                    onClick={() => handleSort("date_published")}
                                                >created at</th>
                                                <th style={{
                                                    width: 61 + 'px',
                                                }}
                                                    className={getSortClass("status")}
                                                    onClick={() => handleSort("status")}
                                                >status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                'undefined' == typeof listings ?
                                                    (
                                                        <>
                                                            <tr class="odd">
                                                                <td valign="top" colspan="9" class="dataTables_empty">Loading...</td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        !listings.length ?
                                                            <p>No</p> :
                                                            listings.map(listing => {
                                                                return <tr class="odd">
                                                                    <td class="dt-checkboxes-cell">
                                                                        <input type="checkbox" class="dt-checkboxes form-check-input"
                                                                            checked={selectedListings.includes(listing.id)}
                                                                            onChange={(e) => handleListingCheckbox(e, listing.id)}
                                                                        />
                                                                    </td>
                                                                    <td class="sorting_1">
                                                                        <div class="d-flex justify-content-start align-items-center product-name">
                                                                            <Link to={`/admin/products/add?product_id=${listing.parent_id}&action=edit`}>
                                                                                {
                                                                                    listing.GalleryURL ?
                                                                                        <div class="avatar-wrapper">
                                                                                            <div class="avatar avatar me-4 rounded-2 bg-label-secondary">
                                                                                                <img src={listing.GalleryURL} alt="Product-9" class="rounded-2" />
                                                                                            </div>
                                                                                        </div> : null
                                                                                }
                                                                                <div class="d-flex flex-column">
                                                                                    <h6 class="mb-0">
                                                                                        <span dangerouslySetInnerHTML={{ __html: listing.name }} />
                                                                                    </h6>
                                                                                    <div className="action">

                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    getActions(listing.status, listing.locked).map(status => {
                                                                                        return <a href="#" onClick={(e) => {
                                                                                            e.preventDefault()
                                                                                            e.stopPropagation()
                                                                                        }}>
                                                                                            {status}
                                                                                        </a>
                                                                                    })
                                                                                }
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {listing.sku}
                                                                        </span>
                                                                        {
                                                                            listing.ebay_id ?
                                                                                <><br /><span>{listing.ebay_id}</span></> : null
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            listing.status == 'sold' ?
                                                                                `${listing.quantity_sold} / ${listing.quantity}` : listing.quantity
                                                                        }
                                                                    </td>
                                                                    <td><span>{listing.quantity_sold}</span></td>
                                                                    <td><span>{convertToCommaSeparate(listing.price)}</span></td>
                                                                    <td>
                                                                        {listing.date_created}
                                                                    </td>
                                                                    <td class="dtr-hidden">
                                                                        <div class="d-flex flex-column">
                                                                            <span class={`badge ${listing.status == 'sold' ? 'bg-label-danger' : ''} ${listing.status == 'published' ? 'bg-label-success' : ''} ${listing.status == 'changed' || listing.status == 'prepared' ? 'bg-label-warning' : ''}`} text-capitalized="">
                                                                                {listing.status}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            })
                                                    )
                                            }
                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                                Showing <strong>{listings ? listings?.length : 0}</strong> of <strong>{NumberFormatter(totalPages)}</strong> listings
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <AdminTableMeta showPagination={true}
                                                pagination={{
                                                    total: total,
                                                    per_page: 20,
                                                    currentpage: currentPage,
                                                    refresh: fetchListings,
                                                    setCurrentPage: setCurrentPage,
                                                }}
                                                loading={loading}
                                                refresh={fetchListings}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default EbayListings