import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store'
import ConnectivityStatus from './components/ConnectivityStatus';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://445eb142efe593212ff52b388aa3c581@o4508649021243392.ingest.us.sentry.io/4508949312110592",
    integrations: [
        Sentry.browserTracingIntegration()
    ],
    tracesSampleRate: 1.0
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ConnectivityStatus />
        <App />
    </Provider>
);
reportWebVitals();