import { performBulkAction_API } from "../api/adminapi";
import { showToast } from "../utils/utils";

const BulkActions = ({ bulkActions, actionCallback, selectedItems, post_type = null, setLoading, loading, bulkFn, refresh, resetSelectedItems }) => {

    const handleButtonClick = async () => {
        if (loading)
            return
        const _selectedAction = document.getElementById('bulkactions').value
        if (-1 == _selectedAction) {
            showToast('Invalid action', 'error');
            return;
        }

        if (!selectedItems?.length) {
            showToast('Select an item first', 'error');
            return;
        }
        if ('edit_in_bulk' == _selectedAction || 'edit_one_by_one' == _selectedAction) {
            bulkFn && bulkFn(_selectedAction)
            return
        }

        if (setLoading)
            setLoading(true)

        await performBulkAction_API(_selectedAction, post_type, selectedItems).then(response => {
            if (response.status == 200) {
                if (refresh)
                    refresh(null, true)
                if (response.data?.message) {
                    showToast(response.data?.message);
                }
                if (resetSelectedItems)
                    resetSelectedItems();
            }
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            setLoading(false)
        })

    }

    return <div class="mb-6 col ecommerce-select2-dropdown">
        <div class="position-relative d-flex gap-3" style={{ top: 8 }}>
            {
                bulkActions?.actions?.length ?
                    <select id="bulkactions" className="select2 form-select select2-hidden-accessible"
                        onChange={(e) => {
                            actionCallback && actionCallback(e.target.value)
                        }}
                    >
                        <option value="-1">Bulk actions</option>
                        {
                            bulkActions.actions.map((action, index) => {
                                return <option key={index} value={action.key}>
                                    {action.value}
                                </option>
                            })
                        }
                    </select> : null
            }
            <button class="btn add-new btn-primary ms-2 ms-sm-0" type="button" onClick={handleButtonClick}>
                Apply
            </button>
        </div>
    </div>
}
export default BulkActions;