import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../Hooks/useAuth'
import { scrollToTop, showToast } from '../../../utils/utils'
import toast from 'react-hot-toast'

const ChangePassword = () => {
    const [isProcessing, setIsProcessing] = useState(false)
    const auth = useAuth()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
    })
    const handleFormSubmit = async () => {
        if (isProcessing)
            return
        const userInner = await auth.getCurrentUser()
        if (!userInner)
            return
        setIsProcessing(true)

        setTimeout(() => {
            if (userInner?.password != formData?.currentPassword) {
                showToast("Invalid current password", 'error');
                setIsProcessing(false)
                scrollToTop()
                return
            }
            
        }, 2000)

    }
    useEffect(() => {
        scrollToTop()
    }, [])
    return (
        <div className="change_password_container my-account">
            <div className="container mid wrap-small">
                <div className="account-details justify-center full-width-small">
                    <div className="left-panel flex-65 full-width-small">
                        <div className={`left-account-detail ${isProcessing ? 'processing' : ''}`}>
                            <h3 className="account-title">Change Password</h3>
                            <form onSubmit={event => {
                                event.preventDefault()
                                handleFormSubmit()
                            }}>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="currentpassword">Current Password</label>
                                    <input type="password" name="currentpassword" value={formData?.currentPassword} required="required" onChange={event => {
                                        setFormData({
                                            ...formData,
                                            currentPassword: event.target.value.trim()
                                        })
                                    }} />
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="newpassword">New Password</label>
                                    <input type="password" name="newpassword" value={formData?.newPassword} required="required" onChange={event => {
                                        setFormData({
                                            ...formData,
                                            newPassword: event.target.value.trim()
                                        })
                                    }} />
                                    <p className='field_desc'>Use 8 or more characters with a mix of letter, numbers and symbols.</p>
                                </div>
                                <div className="btn-form flex wrap gap-20">
                                    <input type="submit" className="submit-btn" value="Save Changes" />
                                    <Link className="cancel-btn" to={`/my-account`}>
                                        Cancel
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword