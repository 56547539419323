import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { getTags } from "../../api/adminapi";
import { Link, useLocation, useParams } from "react-router-dom";
import placeholder from "../../assets/images/placeholder.png";
import EditTagCanvas from "../../components/EditTagCanvas";
import { getCache, setCache } from "../../cacheUtil";
import he from 'he';
import AdminTableMeta from "../../components/AdminTableMeta";
import { getBulkActions, scrollToTop, tagsMapping } from "../../utils/utils";
import { product_taxonomies } from "../../constants";
import BulkActions from "../../components/BulkActions";
import Button from "../../components/Button";
import Input from "../../components/Input";
import SearchForm from "../../components/SearchForm";

const CACHE_KEY_EDIT_TAGS = 'edit_tags_data';
const EditTags = () => {

    const location = useLocation();
    const getQueryParams = (search) => {
        return Object.fromEntries(new URLSearchParams(search));
    };
    const queryParams = getQueryParams(location.search);
    const term_id = queryParams.term_id;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { taxonomy } = useParams();
    const [tags, setTags] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [perPage, setPerPage] = useState(20)
    const [total, setTotal] = useState(0)
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [selectedTags, setSelectedTags] = useState([]);
    const [searching, setSearching] = useState(false)

    const fetchTags = async (page = null, skip_cache = false, isSearching = false) => {
        if (loading)
            return;
        setLoading(true)
        try {
            await getTags(taxonomy, page ? page : currentPage, perPage, skip_cache, document.getElementById('search_term').value, term_id).then(response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setTotalPages(_data?.total_pages)
                    setTotal(_data?.total_items)
                    setCurrentPage(_data?.current_page)
                    setTags(_data.terms)
                    if (!_data.nocache) {
                        setCache(`${CACHE_KEY_EDIT_TAGS}_${taxonomy}`, _data);
                    }
                    setSearching(document.getElementById('search_term').value?.trim()?.length > 0)
                    scrollToTop()
                }
            }).finally(() => {
                setLoading(false)
            })
        } catch (error) {
            console.warn(error)
        }
    }

    useEffect(() => {
        scrollToTop()
        const loadCachedTags = () => {
            const cachedData = getCache(`${CACHE_KEY_EDIT_TAGS}_${taxonomy}`);
            if (cachedData) {
                setTotalPages(cachedData.total_items);
                setTotal(cachedData.length);
                setCurrentPage(cachedData?.current_page)
                setTags(cachedData.terms);
            }
        };
        loadCachedTags();
        fetchTags();
    }, [taxonomy])

    const openModal = (term) => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedTerm(null);
        setIsModalOpen(false);
    };

    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allTagIds = tags.map(tag => tag?.term.id ? tag?.term.id : tag?.term.term_id);
            setSelectedTags(allTagIds);
        } else {
            setSelectedTags([]);
        }
    };

    const handleTagCheckbox = (e, tagId) => {
        if (e.target.checked) {
            setSelectedTags(prev => [...prev, tagId]);
        } else {
            setSelectedTags(prev => prev.filter(id => id !== tagId));
        }
    };

    const handleSearchFormSubmit = (query) => {
        if (loading || !query?.trim()?.length)
            return;
        setLoading(true)
        fetchTags(null, false, true);
    }

    const resetSelectedItems = () => {
        setSelectedTags([]);
    }

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="container-xxl flex-grow-1 container-p-y">
                    <div class="row">
                        <div class="admin-card mb-6">
                            <div class="admin-card-header p-0 d-flex justify-content-between align-items-center">
                                <BulkActions
                                    bulkActions={{
                                        actions: getBulkActions('tag')
                                    }}
                                    selectedItems={selectedTags}
                                    setLoading={setLoading}
                                    loading={loading}
                                    refresh={fetchTags}
                                    post_type={taxonomy}
                                    resetSelectedItems={resetSelectedItems}
                                />
                                <div class="d-flex justify-content-center justify-content-md-end align-items-baseline">
                                    <div class="dt-action-buttons d-flex justify-content-center flex-md-row align-items-center pt-0">
                                        <div class={`spinner-border text-primary opacity-${loading ? '1' : '0'} me-3`} role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <SearchForm
                                            taxonomy={taxonomy}
                                            searching={searching}
                                            refresh={fetchTags}
                                            onSubmit={(query) => {
                                                handleSearchFormSubmit(query)
                                            }}
                                        />
                                        <Button
                                            onClick={() => {
                                                if (loading)
                                                    return;
                                                setSelectedTerm(null)
                                                openModal();
                                            }}
                                            className='btn-secondary add-new btn-primary ms-2'
                                            text={`Add ${tagsMapping[taxonomy]}`}
                                            icon='plus'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="card-datatable table-responsive">
                                <table class="datatables-products table dataTable no-footer dtr-column">
                                    <thead>
                                        <tr>
                                            <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all w-5" rowspan="1" colspan="1" data-col="1" aria-label="">
                                                <Input
                                                    type="checkbox"
                                                    onChange={handleAllSelector}
                                                    checked={tags && selectedTags.length === tags.length}
                                                />
                                            </th>
                                            <th class="w-10">Image</th>
                                            <th class="w-25">Name</th>
                                            <th class="w-20">Description</th>
                                            <th class="w-20">Slug</th>
                                            <th class="w-10">Count</th>
                                            <th class="w-10">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            'undefined' == typeof tags ?
                                                (<p>Processing</p>) : (
                                                    tags.length ?
                                                        tags?.map(tag => {
                                                            let term = tag.term,
                                                                _sep = '';
                                                            if (tag.level > 0) {
                                                                for (var i = 1; i <= tag.level; i++) {
                                                                    _sep += '— ';
                                                                }
                                                            }
                                                            return (
                                                                <tr>
                                                                    <td className="w-5">
                                                                        <Input
                                                                            type="checkbox"
                                                                            checked={selectedTags.includes(term?.id ? term.id : term.term_id)}
                                                                            onChange={(e) => handleTagCheckbox(e, term?.id ? term.id : term.term_id)}
                                                                        />
                                                                    </td>
                                                                    <td className="w-10">
                                                                        <img width={40} src={placeholder} />
                                                                    </td>
                                                                    <td class="w-25">
                                                                        <span>
                                                                            {
                                                                                !searching ? _sep : ''
                                                                            }
                                                                            {
                                                                                he.decode(term.name)
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="w-20">
                                                                        <span dangerouslySetInnerHTML={{ __html: term.description ? term.description : '—' }} />
                                                                    </td>
                                                                    <td className="w-20">
                                                                        <span>
                                                                            {
                                                                                term.slug
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td className="w-10">
                                                                        <span>
                                                                            {
                                                                                product_taxonomies.includes(taxonomy) ?
                                                                                    <Link to={`/admin/products?taxonomy=${taxonomy}&term=${term?.id ? term.id : term.term_id}`}>
                                                                                        {term.count}
                                                                                    </Link> :
                                                                                    term.count
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <div class="d-flex align-items-sm-center justify-content-sm-center gap-2">
                                                                            <button
                                                                                class="btn btn-icon btn-text-secondary rounded-pill waves-effect waves-light"
                                                                                onClick={() => {
                                                                                    setSelectedTerm(term)
                                                                                    openModal(true)
                                                                                }}>
                                                                                <i class="ti ti-edit"></i>
                                                                            </button>
                                                                            <div class={`dropdown-menu dropdown-menu-end m-0`}>
                                                                                <a href="javascript:0;" class="dropdown-item">Delete</a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : <p>No items</p>
                                                )
                                        }
                                    </tbody>
                                </table>
                                <EditTagCanvas taxonomy={taxonomy} term={selectedTerm} isOpen={isModalOpen} onClose={closeModal} refresh={fetchTags} />
                                <AdminTableMeta showPagination={true}
                                    pagination={{
                                        total: total,
                                        per_page: 20,
                                        currentpage: currentPage,
                                        refresh: fetchTags,
                                        setCurrentPage: setCurrentPage,
                                    }}
                                    refresh={fetchTags}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default EditTags;