import { Link } from 'react-router-dom'
import '../EditAccountDetails/editaccountdetails.css'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Hooks/useAuth'
import { encryptData, scrollToTop, showToast } from '../../../utils/utils'
import axios from 'axios'
import toast from 'react-hot-toast'

const EditAccountDetails = () => {
    const auth = useAuth()
    const [user, setUser] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    useEffect(() => {
        const getUserdata = async () => {
            const userInner = await auth.getCurrentUser()
            if (userInner) {
                setFormData({
                    username: userInner?.display_name,
                    firstName: userInner?.first_name,
                    lastName: userInner?.last_name,
                    email: userInner?.email,
                })
                setUser(userInner)
            }
        }
        getUserdata()
        scrollToTop()
    }, [])
    const [formData, setFormData] = useState({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
    })
    const handleFormSubmit = async () => {
        if (isProcessing)
            return
        setIsProcessing(true)
        let userInner = await auth.getCurrentUser()
        if (userInner) {
            userInner.first_name = formData?.firstName
            userInner.last_name = formData?.lastName

            await axios.post(process.env.REACT_APP_WC_API_BASE + `customers/${userInner?.id}`, userInner, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                }
            }).then(async res => {
                if (res.status == 200) {
                    showToast('Account details updated successfully');
                    scrollToTop()
                    const encryptedUser = await encryptData(userInner)
                    await auth.setCurrentUser(encryptedUser)
                } else {
                    showToast("Error occured, try again", 'error');
                }
            }).catch(err => {
                showToast(err?.data?.msg, 'error');
            }).finally(() => {
                setIsProcessing(false)
            })
        }
    }
    return (
        <div className="container mid">
            <div className="account-details justify-center">
                <div className="left-panel flex-65 full-width-small">
                    <div className={`left-account-detail`}>
                        <h3 className="account-title">Your Account Details</h3>
                        <form className={`flex wrap space-between ${isProcessing ? 'processing' : ''}`} onSubmit={event => {
                            event.preventDefault()
                            handleFormSubmit()
                        }}>
                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="firstname">First Name</label>
                                <input type="text" name="firstname" value={formData?.firstName} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        firstName: event.target.value
                                    })
                                }} required="required" />
                            </div>

                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="lastname">Last Name</label>
                                <input type="text" name="lastname" value={formData?.lastName} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        lastName: event.target.value
                                    })
                                }} required="required" />
                            </div>

                            <div className="form-group flex-100">
                                <label className="form-label" htmlFor="email">Email</label>
                                <input type="email" name="email" value={formData?.email} readOnly="readOnly" onChange={event => {
                                    setFormData({
                                        ...formData,
                                        email: event.target.value
                                    })
                                }} required="required" />
                            </div>

                            <div className="form-group flex-100">
                                <label className="form-label" htmlFor="username">Username</label>
                                <input type="text" id="username" name="username" readOnly="readOnly" value={formData?.username} required="required" onChange={event => {
                                    setFormData({
                                        ...formData,
                                        username: event.target.value
                                    })
                                }} />
                            </div>

                            <div className="btn-form mt-0 grouped-form-btns align-center flex gap-15">
                                <input type="submit" className="submit-btn" value="Save Changes" />
                                <Link className="cancel-btn" to={`/my-account`}>
                                    Cancel
                                </Link>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAccountDetails