import '../MyAccount/myaccount.css'
import React, { useEffect, useState } from 'react'
import myaccount_img1 from '../../assets/images/myaccount_img1.png'
import myaccount_img2 from '../../assets/images/myaccount_img2.png'
import myaccount_img3 from '../../assets/images/myaccount_img3.png'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../Hooks/useAuth'
import { encryptData, scrollToTop, showToast } from '../../utils/utils'

const getFormHeading = (formType) => {
    let text = ''
    switch (formType) {
        case 'login':
            text = 'Login To Your Account'
            break
        case 'register':
            text = 'First, Create An Account.'
            break
        case 'reset_password':
            text = 'Lost Your Password?.'
            break
    }
    return text
}

const MyAccount = () => {

    const auth = useAuth()
    const [user, setUser] = useState(null)
    useEffect(() => {
        const validateUser = async () => {
            const userResponse = await auth.isUserLoggedIn()
            setIsUserLoggedIn(userResponse)
        }
        validateUser()

        const getUserdata = async () => {
            const userInner = await auth.getCurrentUser()
            
            if (userInner) {
                setUserInformation({
                    username: userInner?.display_name,
                    firstName: userInner?.first_name,
                    lastName: userInner?.last_name,
                    email: userInner?.email,
                    shipping: {
                        firstName: userInner?.shipping?.first_name,
                        lastName: userInner?.shipping?.last_name,
                        address1: userInner?.shipping?.address_1,
                        city: userInner?.shipping?.city,
                        country: userInner?.shipping?.country,
                        postcode: userInner?.shipping?.postcode,
                        state: userInner?.shipping?.state,
                    },
                    billing: {
                        firstName: userInner?.billing?.first_name,
                        lastName: userInner?.billing?.last_name,
                        address1: userInner?.billing?.address_1,
                        city: userInner?.billing?.city,
                        country: userInner?.billing?.country,
                        postcode: userInner?.billing?.postcode,
                        state: userInner?.billing?.state,
                        email: userInner?.billing?.email,
                        phone: userInner?.billing?.phone,
                    }
                })
                setUser(userInner)
            }
        }
        getUserdata()
        scrollToTop()
    }, [])

    const [formType, setFormType] = useState('login')
    const [formData, setFormData] = useState({
        login: {
            email: '',
            password: '',
        },
        reset: {
            username: '',
        },
        register: {
            email: '',
            username: '',
            password: '',
            firstName: '',
            lastName: '',
        }
    })
    const [isProcessing, setIsProcessing] = useState(false)
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const navigate = useNavigate()
    const [userInformation, setUserInformation] = useState({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        shipping: {
            firstName: '',
            lastName: '',
            address1: '',
            city: '',
            country: '',
            postcode: '',
            state: '',
        },
        billing: {
            firstName: '',
            lastName: '',
            address1: '',
            city: '',
            country: '',
            postcode: '',
            state: '',
        }
    })

    const [registerFormData, setRegisterFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
    })

    const handleLoginForm = async () => {
        if (isProcessing)
            return
        if (formData?.login?.email?.trim()?.length < 1 || formData?.login?.password?.trim()?.length < 1) {
            // empty error
        } else {
            setIsProcessing(true)
            const loginData = new FormData()
            loginData.append('username', formData?.login?.email?.trim())
            loginData.append('password', formData?.login?.password?.trim())
            await axios.post(process.env.REACT_APP_TONYETRADE_API_BASE + `login`, loginData).then(async res => {
                if (res?.status == 200 && undefined != res?.data?.user) {

                    if (typeof res?.data?.user == 'object') {
                        res.data.user.password = formData?.login?.password?.trim()
                    }
                    const encryptedUser = await encryptData(res?.data?.user)
                    if (auth.setCurrentUser(encryptedUser)) {
                        navigate(0)
                    }
                }
            }).catch(err => {
                showToast(err?.response?.data?.msg, 'error');
            }).finally(res => {
                setIsProcessing(false)
            })
        }
    }

    const handleRegisterForm = async () => {
        if (isProcessing)
            return
        if (registerFormData?.firstName?.trim()?.length < 1 || registerFormData?.lastName?.trim()?.length < 1 || registerFormData?.email?.trim()?.length < 1 || registerFormData?.username?.trim()?.length < 1 || registerFormData?.password?.trim()?.length < 1) {
            // empty error
        } else {
            setIsProcessing(true)
            const registerData = new FormData()
            registerData.append('first_name', registerFormData?.firstName)
            registerData.append('last_name', registerFormData?.lastName)
            registerData.append('email', registerFormData?.email)
            registerData.append('username', registerFormData?.username)
            registerData.append('password', registerFormData?.password)
            await axios.post(process.env.REACT_APP_TONYETRADE_API_BASE + `register`, registerData).then(async res => {
                if (res?.status == 200) {
                    if (res?.data?.success) {
                        showToast(res?.data?.data?.msg);
                        setRegisterFormData({
                            firstName: '',
                            lastName: '',
                            email: '',
                            username: '',
                            password: '',
                        })
                        setFormType('login')
                        scrollToTop()
                    } else {
                        showToast(res?.data?.data?.msg, 'error');
                    }
                }
            }).catch(err => {
                showToast(err?.response?.data?.msg, 'error');
            }).finally(() => {
                setIsProcessing(false)
            })
        }
    }

    return (
        <>
            {
                !isUserLoggedIn ?
                    <div className="main-containerr">
                        <div className="container p-0-small mt-0-small">
                            <div className="main-inner p-0-small">
                                <div className="left-account full-width-small pr-0-small">
                                    <div className={`account-login-inner ${isProcessing ? 'processing' : ''}`}>
                                        <img src="https://res.cloudinary.com/dzhclyn7c/image/upload/v1725515711/logo.11382c5ec95cfd207528_1_r310yc.png" className="img-login" />
                                        <h2 className="account-login-title">
                                            {getFormHeading(formType)}
                                        </h2>
                                        {
                                            formType == 'login' ?
                                                <form id="login_form" onSubmit={(event) => {
                                                    event.preventDefault()
                                                    handleLoginForm()
                                                }}>
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="email">Email Address</label>
                                                        <input className="form-input" type="email" id="email" name="email" placeholder="john.dowry@example.com" value={formData?.login?.email} onChange={(event) => {
                                                            setFormData({
                                                                ...formData,
                                                                login: {
                                                                    ...formData.login,
                                                                    email: event.target.value.trim()
                                                                }
                                                            })
                                                        }} required />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="password">Password:</label>
                                                        <input className="form-input" type="password" id="password" value={formData?.login?.password} onChange={(event) => {
                                                            setFormData({
                                                                ...formData,
                                                                login: {
                                                                    ...formData.login,
                                                                    password: event.target.value.trim()
                                                                }
                                                            })
                                                        }} name="password" required />
                                                    </div>
                                                    <div className="form-group">
                                                        <a href="#" className="forgot-password" onClick={event => {
                                                            event.preventDefault()
                                                            setFormType('reset_password')
                                                        }}>Forgot Password?</a>
                                                        <div className="checkbox-container">
                                                            <input type="checkbox" id="remember-me" name="remember-me" />
                                                            <label className="form-label" htmlFor="remember-me">Remember Me</label>
                                                        </div>
                                                    </div>
                                                    <button className="login-btn" type="submit">Login</button>
                                                    <div className="form-group">
                                                        <span className="form-row-last">Not a Member? <a href="re" onClick={event => {
                                                            event.preventDefault()
                                                            setFormType('register')
                                                        }}>Create Account</a></span>
                                                    </div>
                                                </form> : null
                                        }
                                        {
                                            formType == 'register' ?
                                                <form id="register_form" onSubmit={(event) => {
                                                    event.preventDefault()
                                                    handleRegisterForm()
                                                }}>
                                                    <div className="form-row">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="firstname">First name</label>
                                                            <input className="form-input" type="firstname" id="firstname" name="firstname" required="required" value={registerFormData?.firstName} onInput={event => {
                                                                setRegisterFormData({
                                                                    ...registerFormData,
                                                                    firstName: event.target.value,
                                                                })
                                                            }} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="lastname">Last name</label>
                                                            <input className="form-input" type="lastname" id="lastname" name="text" required="required" value={registerFormData?.lastName} onInput={event => {
                                                                setRegisterFormData({
                                                                    ...registerFormData,
                                                                    lastName: event.target.value,
                                                                })
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="email">Email </label>
                                                        <input className="form-input" type="email" id="email" name="email" required="required" value={registerFormData?.email} onInput={event => {
                                                            setRegisterFormData({
                                                                ...registerFormData,
                                                                email: event.target.value,
                                                            })
                                                        }} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="email">Username </label>
                                                        <input className="form-input" type="Username" id="Username" name="Username" required="required" value={registerFormData?.username} onInput={event => {
                                                            setRegisterFormData({
                                                                ...registerFormData,
                                                                username: event.target.value,
                                                            })
                                                        }} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="password">Password:</label>
                                                        <input className="form-input" type="password" id="password" name="password" required="required" value={registerFormData?.password} onInput={event => {
                                                            setRegisterFormData({
                                                                ...registerFormData,
                                                                password: event.target.value,
                                                            })
                                                        }} />
                                                    </div>

                                                    <button className="login-btn" type="submit">Create Your Account</button>
                                                    <div className="form-group">
                                                        <span className="form-row-last">Already an TonyeTrade member? <a href="#" onClick={event => {
                                                            event.preventDefault()
                                                            setFormType('login')
                                                        }}>Sign In Here</a></span>
                                                    </div>
                                                </form> : null
                                        }
                                        {
                                            formType == 'reset_password' ?
                                                <form id="password_reset_form">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="email">Username or email</label>
                                                        <input className="form-input" type="text" id="email" name="username" placeholder="" required />
                                                    </div>
                                                    <button className="login-btn" type="submit">Reset Password</button>
                                                    <div className="form-group">
                                                        <a id="backtologin" href="re" onClick={event => {
                                                            event.preventDefault()
                                                            setFormType('login')
                                                        }}>Back to login</a>
                                                    </div>
                                                </form> : null
                                        }
                                    </div>
                                </div>
                                <div className="right-account">
                                    <div className="login-image-row">
                                        <img className="login-img" src={myaccount_img1} alt="Image 3" />
                                        <img className="login-img2" src={myaccount_img2} alt="Image 2" />
                                        <img className="login-img3" src={myaccount_img3} alt="Image 1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
            {
                isUserLoggedIn ?
                    <div className="my-account-container">
                        <div className="container wide">
                            <div className="account-details space-between wrap-small">
                                <div className="left-panel full-width-small">
                                    <h3 className="account-title">Account Details</h3>
                                    <div className="left-account-detail">
                                        <div className="left-side-detail">
                                            <p className="account-det-heading">Username</p>
                                            <p className="account-det-text">{userInformation?.username}</p>
                                        </div>
                                        <div className="left-side-detail">
                                            <p className="account-det-heading">Name</p>
                                            <p className="account-det-text">{userInformation?.firstName} {userInformation?.lastName}</p>
                                        </div>
                                        <div className="left-side-detail">
                                            <p className="account-det-heading">Email</p>
                                            <p className="account-det-text">{userInformation?.email}</p>
                                        </div>
                                        <hr className="divider" />
                                        <Link to={`/my-account/accountdetails`} className="custom_dashboard_button">
                                            Update account details
                                        </Link>
                                    </div>
                                </div>
                                <div className="right-panel full-width-small">
                                    <h3 className="account-title">Addresses</h3>
                                    <div className="left-account-detail">
                                        <div className="left-side-detail">
                                            <h5 className="shipping-heading">
                                                Shipping Information
                                            </h5>
                                            <div className="left_inner_boxes">
                                                <div className='billing_shipping_information'>
                                                    {userInformation?.shipping?.firstName} {userInformation?.shipping?.lastName}<br />
                                                    {userInformation?.shipping?.address1}<br />
                                                    {userInformation?.shipping?.city} {userInformation?.shipping?.state} {userInformation?.shipping?.postcode}<br />
                                                    {userInformation?.shipping?.country}<br />
                                                </div>
                                                <div>
                                                    <Link to={`/my-account/updateshippingdetails`} className="update-shipping">
                                                        Update Shipping Information
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="left-side-detail">
                                            <h5 className="shipping-heading">
                                                Billing Information
                                            </h5>
                                            <div className="left_inner_boxes">
                                                <div className='billing_shipping_information'>
                                                    {userInformation?.billing?.firstName} {userInformation?.billing?.lastName}<br />
                                                    {userInformation?.billing?.address1}<br />
                                                    {userInformation?.billing?.city} {userInformation?.billing?.state} {userInformation?.billing?.postcode}<br />
                                                    {userInformation?.billing?.country}<br />
                                                </div>
                                                <div>
                                                    <Link className="update-shipping" to={`/my-account/updatebillingdetails`}>
                                                        Update Billing Information
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
        </>
    )
}

export default MyAccount