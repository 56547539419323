import { Link } from "react-router-dom";
import Pagination from "../Pagination";
import BulkActions from "../BulkActions";
import SearchForm from "../SearchForm";

const AdminTableMeta = ({ searching, showSearch, search, actions, showPagination, setLoading, loading, pagination, showBulkActions, bulkActions, selectedItems, post_type, refresh, bulkFn, actionCallback, handleSearchFormSubmit }) => {

    return <div class="admin-card-header d-flex p-0">
        {
            showBulkActions ?
                <div className="col-4">
                    <BulkActions
                        bulkActions={bulkActions}
                        actionCallback={actionCallback}
                        selectedItems={selectedItems}
                        post_type={post_type}
                        setLoading={setLoading}
                        loading={loading}
                        bulkFn={bulkFn}
                        refresh={refresh}
                    />
                </div> : null
        }
        <div className="col align-center d-flex justify-content-end gap-4">
            <div class={`spinner-border text-primary opacity-${loading ? '1' : '0'} me-3`} role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            {
                showSearch ?
                    <SearchForm
                        onSubmit={query => {
                            handleSearchFormSubmit && handleSearchFormSubmit(query)
                        }}
                        value={search.searchQuery}
                        taxonomy={search.placeholder}
                        name='s'
                        inputId='search_query'
                        onChange={(e) => {
                            search.setSearchQuery(e.target.value)
                        }}
                        refresh={refresh}
                        searching={searching}
                    />
                    : null
            }
            {
                actions && actions?.length ?
                    <div class="d-flex justify-content-start justify-content-md-end align-items-baseline">
                        <div class="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
                            <div class="dt-buttons btn-group flex-wrap d-flex mb-6 mb-sm-0">
                                <Link to={'/admin/products/add'} class="btn add-new btn-primary ms-2 ms-sm-0 waves-effect waves-light" tabindex="0" aria-controls="DataTables_Table_0" type="button">
                                    <span><i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">Add Product</span></span>
                                </Link>
                            </div>
                        </div>
                    </div> : null
            }
            {
                showPagination ?
                    <Pagination
                        totalItems={pagination.total}
                        itemsPerPage={pagination.per_page}
                        currentPage={pagination.currentpage}
                        onPageChange={(number) => {
                            pagination.setCurrentPage(number)
                        }}
                        loading={loading}
                        refresh={refresh}
                    /> : null
            }

        </div>
    </div>
}

export default AdminTableMeta; 