import React, { useCallback, useEffect } from "react";
import '../Footer/footer.css'
import { Link, useNavigate } from "react-router-dom";
import { toggleMobileMenu, zoomButtons, menuItems, showToast } from "../../utils/utils";
import $ from 'jquery'
import { useAuth } from "../../Hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../redux/action";
import axios from "axios";
import he from 'he'
import SearchBar from '../../components/Searchbar'
import { ReactComponent as FB } from '../../assets/svgs/fb.svg';
import { ReactComponent as Twitter } from '../../assets/svgs/twitter.svg';
import { ReactComponent as Insta } from '../../assets/svgs/insta.svg';
import { ReactComponent as Baseball } from '../../assets/svgs/baseball.svg';
import { ReactComponent as Basketball } from '../../assets/svgs/basketball.svg';
import { ReactComponent as Boxing } from '../../assets/svgs/boxing.svg';
import { ReactComponent as Comics } from '../../assets/svgs/comics.svg';
import { ReactComponent as Football } from '../../assets/svgs/football.svg';
import { ReactComponent as Hockey } from '../../assets/svgs/hockey.svg';
import { ReactComponent as Memo } from '../../assets/svgs/memo.svg';
import { ReactComponent as NonSport } from '../../assets/svgs/nonsport.svg';
import { ReactComponent as Unopened } from '../../assets/svgs/unopened.svg';
import { ReactComponent as Sets } from '../../assets/svgs/sets.svg';
import { ReactComponent as Closer } from '../../assets/svgs/closer.svg';
import { ReactComponent as SearchCloser } from '../../assets/svgs/searchcloser.svg';

const iconComponents = {
    Baseball,
    Basketball,
    Boxing,
    Comics,
    Football,
    Hockey,
    Memo,
    NonSport,
    Unopened,
    Sets,
};

const removeScripts = () => {
    const izoomremover = document.getElementById('izoomremover')
    const izoomsetter = document.getElementById('izoomsetter')
    if (izoomremover)
        izoomremover.remove()
    if (izoomsetter)
        izoomsetter.remove()
}

const toggleImageZoomOn = (zoom = 2) => {
    removeScripts()
    if (window.innerWidth < 768) {

    } else {
        const scriptContent = `
            jQuery('#newlist_view_popup_slider_holder .single_image').each(function(){
                var _image = jQuery(this).find('img').attr('src');
                jQuery(this).izoomify({
                url: _image,
                magnify: `+ zoom + `
                });
            });
        `;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = scriptContent;
        script.setAttribute('id', 'izoomsetter');
        document.body.appendChild(script);
    }
}

const toggleImageZoomOff = () => {
    removeScripts()
    const scriptContent = `
        jQuery('#newlist_view_popup_slider_holder .single_image img').each(function(){
            var _image = jQuery(this).attr('src');
            jQuery(this).trigger('izoomify.destroy');
        });
        jQuery('#newlist_view_popup_slider_holder .single_image img').trigger('zoom.destroy');
    `;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptContent;
    script.setAttribute('id', 'izoomremover');
    document.body.appendChild(script);
}

const toggleZoomButtons = () => {
    document.querySelectorAll('#mag_zoom_btns button').forEach(button => {
        button.addEventListener('click', function (e) {
            document.querySelectorAll('#mag_zoom_btns button').forEach(btn => {
                btn.classList.remove('active');
            });
            this.classList.add('active');
            let _zoom = this.getAttribute('data-zoom')
            if (_zoom)
                _zoom = parseInt(_zoom)

            if (_zoom && _zoom > 0) {
                if (window.innerWidth < 768) {

                } else {
                    toggleImageZoomOn(_zoom)
                }
            }

        });
    });
}

const closeProductPopup = () => {
    document.body.classList.remove('newlist_view_popupactive')
    $('#newlist_view_popup').removeClass('loaded');
    $('div#mag_zoom_btns button').removeClass('active');
    $('div#mag_zoom_btns button:first-child').addClass('active');
    $('#toggle_zoom_glass').prop('checked', false).trigger('change');
    $('div#mag_zoom_btns,.zomm_mag_msg').removeClass('loaded');
    $('#popup_view_detailer').attr('href', '#');
    $('div#newlist_view_popup .inner').removeAttr('style');
    $('#newlist_view_popup_slider_holder,#newlist_view_popup .header h2,#newlist_view_popup .header .header_meta .left_side,.var_price_holder').html('');
    setTimeout(function () {
        $('#newlist_view_popup').removeClass('horizontal');
    }, 800);
    $("#newlist_view_popup_slider_holder").trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
    const productpreviewscript = document.getElementById('productpreviewscript')
    if (productpreviewscript)
        productpreviewscript.remove()
    $('#active_product_id').val(0);
}

const Footer = React.memo(() => {
    useEffect(() => {
        document.getElementById('open_mobile_menu')?.addEventListener('click', toggleMobileMenu);
        document.getElementById('mobile_menu_closer')?.addEventListener('click', toggleMobileMenu);

        document.querySelectorAll('#mobile_menu > li.has_submenu .toggle_mobile_menu_dropdown').forEach(function (element) {
            element.addEventListener('click', function () {
                var parentElement = this.closest('.has_submenu');
                if (parentElement) {
                    parentElement.classList.toggle('submenu_opened');
                }
            });
        });

    }, [])
    const navigate = useNavigate();
    const auth = useAuth()
    const dispatcher = useDispatch()
    const cartReducer = useSelector((state) => state.cartReducer)
    const handleAddToCart = useCallback(
        async (product, event) => {
            if (product < 1)
                return
            event.target.classList.add('processing');
            let headers = {}
            const userInner = await auth.getCurrentUser()
            if (userInner) {
                headers = {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                    }
                }
            }
            if (userInner || 1) {

                let cartKeyParam = ''
                if (!userInner && cartReducer && cartReducer?.cart_key) {
                    cartKeyParam = `?cart_key=${cartReducer?.cart_key}`
                }

                await axios.post(process.env.REACT_APP_CC_API_BASE + `cart/add-item${cartKeyParam}`, {
                    "id": '' + product,
                    "quantity": "1"
                }, headers).then(res => {
                    if (res?.status == 200 && undefined != res?.data?.cart_key) {
                        closeProductPopup()
                        dispatcher(setCart(res?.data))
                    }
                }).catch(err => {
                    if (err?.response?.data) {
                        showToast(he.decode(err?.response?.data?.message), 'error','bottom-left');
                    }
                }).finally(() => {
                    event.target.classList.remove('processing');
                })
            }
        })

    return (
        <>
            <footer>
                <div className="top_footer pt-30">
                    <div className="container mt-0-small">
                        <div className="row">
                            <div className="footer_widget">
                                <div className="footer_widget_inner text-center">
                                    <h2>Interested in selling your sports collectibles?</h2>
                                    <p>If you have a baseball card or collection you think might be valuable, we recommend contacting us.</p>
                                    <Link to={`/contact`} className="button secondary">
                                        CONTACT US!
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <p className="text-center pb-0-small">Or call us directly at <a href="tel:(513) 616-2224">(513) 616-2224</a></p>
                        </div>
                    </div>
                </div>
                <div className="bottom_footer p-10">
                    <div className="container">
                        <div className="inner flex space-between wrap block-small">
                            <div className="left_side_footer flex-60">
                                <Link to={`/`}>
                                    <img loading="lazy" width="181" height="37" src="https://res.cloudinary.com/dzhclyn7c/image/upload/v1725515711/logo.11382c5ec95cfd207528_1_r310yc.png" className="header_logo header-logo lazyloaded" alt="TonyeTrade" />
                                </Link>
                                <div className="new_footer_menu pt-30">
                                    <ul id="menu-new-footer-menu" className="menu">
                                        <li>
                                            <Link to={`/the-most-wanted-list`}>
                                                About
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/blog`}>
                                                Blog
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/terms`}>
                                                Terms and Conditions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/cookie-policy`}>
                                                Cookies Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/privacy-policy`}>
                                                Privacy Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <p className="text-center-small">Copyright © {(new Date().getFullYear())} TONYeTRADE Enterprises, LLC. All Rights Reserved.</p>
                            </div>
                            <div className="right_side_footer flex-40 text-right">
                                <img height="50" src="https://res.cloudinary.com/dzhclyn7c/image/upload/v1725516705/ebay_a2d3ji.png" loading="lazy" />
                            </div>
                            <div className="bottom_side_footer block-small flex space-between flex-100 pt-30">
                                <div className="bottom_left_footer">
                                    <ul className="social_icons text-center-small">
                                        <li><a href="https://www.facebook.com/tonyetrade" target="_blank"><FB /></a></li>
                                        <li>
                                            <a href="https://x.com/tonyetrade" target="_blank">
                                                <Twitter />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/tonyetrade/" target="_blank">
                                                <Insta />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="bottom_right_footer text-center-small">
                                    <ul>
                                        <li>
                                            <Link to={`/terms`}>
                                                Terms of Service
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`/privacy-policy`}>
                                                Privacy Policy
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div id="mobile_menu_overlay">
                <div id="mobile_menu_wrapper">
                    <ul id="mobile_menu">
                        {menuItems.map((item, index) => (
                            <li key={index} className={item.hasSubmenu ? "has_submenu" : ""}>
                                <Link to={item.path}>{item.label}</Link>
                                {item.hasSubmenu && (
                                    <>
                                        <button className="toggle_mobile_menu_dropdown"></button>
                                        <ul className="dropdown">
                                            {item.submenu.map((subItem, subIndex) => {
                                                const IconComponent = iconComponents[subItem.icon]; // Dynamically get the icon component

                                                return (
                                                    <li key={subIndex}>
                                                        <Link to={subItem.path}>
                                                            {IconComponent && (
                                                                <span className="icon">
                                                                    <IconComponent />
                                                                </span>
                                                            )}
                                                            <span className="text">{subItem.label}</span>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <a id="mobile_menu_closer">
                    &times;
                </a>
            </div>
            <div id="newlist_view_popup">
                <div className="inner">
                    <div className="header">
                        <label className="switch">
                            <input type="checkbox" id="toggle_zoom_glass" onChange={(event) => {
                                if (event.target.checked) {
                                    $('#mag_zoom_btns').addClass('loaded');
                                    const buttons = document.querySelectorAll('#mag_zoom_btns button');
                                    buttons.forEach(button => {
                                        button.classList.remove('active');
                                    });
                                    if (buttons.length > 0) {
                                        buttons[0].classList.add('active');
                                    }
                                    toggleImageZoomOn()
                                    $('.zomm_mag_msg').addClass('loaded')
                                } else {
                                    $('#mag_zoom_btns').removeClass('loaded');
                                    $('.zomm_mag_msg').removeClass('loaded')
                                    toggleImageZoomOff()
                                }
                            }} />
                            <span className="slider"></span>
                            <span className="text">Turn On/Off Zoom</span>
                        </label>

                        <div id="mag_zoom_btns">
                            {
                                zoomButtons.map((b) => (
                                    <button className="active" data-zoom={b} onClick={() => {
                                        toggleZoomButtons();
                                    }}>
                                        {b}x
                                    </button>
                                ))
                            }
                        </div>

                        <button id="newlist_popup_closer" onClick={(event) => {
                            event.preventDefault()
                            closeProductPopup()
                        }}>
                            <Closer />
                        </button>
                        <h2></h2>
                        <div className="header_meta">
                            <div className="left_side"></div>
                            <div className="right_side"></div>
                        </div>
                    </div>
                    <div className="body">
                        <div id="newlist_view_popup_slider_holder">

                        </div>
                    </div>
                    <div className="footer">
                        <div className="zomm_mag_msg">
                            Hover(desktop) Or Touch-hold-Drag (mobile)
                        </div>
                        <div className="meta">
                            <div className="var_name_holder"></div>
                            <div className="var_price_holder"></div>
                        </div>
                        <div className="actions_holder">
                            <a id="popup_view_detailer" href="#" onClick={(event) => {
                                event.preventDefault()
                                navigate(`${event.target.getAttribute('href')}`)
                                closeProductPopup()
                            }}>View Details</a>
                            <a id="newlist_view_addcart_btn" href="#" onClick={(event) => {
                                event.preventDefault()
                                const _this = $(event.target)
                                if (_this.hasClass('incart')) {
                                    navigate('/cart')
                                    closeProductPopup()
                                } else {
                                    handleAddToCart(parseInt($('#active_product_id').val()), event)
                                }
                            }}>Add To Cart</a>
                        </div>
                    </div>
                </div>
                <input id="active_product_id" value={0} type="hidden" />
            </div>
            <div id="newlist_view_popup_dropshadow"></div>
            <div id="mobile_search_container">
                <a onClick={(e) => {
                    e.preventDefault()
                    document.body.classList.remove('mobile_search_opened')
                    document.getElementsByTagName('html')[0].classList.remove('noscroll');
                }}>
                    <SearchCloser />
                </a>
                <div id="mobile_search_form_container">
                    <SearchBar />
                </div>
            </div>
        </>
    )
})

export default Footer