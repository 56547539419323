import { tagsMapping } from "../utils/utils";
import Button from "./Button";
import Input from "./Input";

const SearchForm = ({ taxonomy, searching, refresh, onSubmit, value, name, inputId, onChange }) => {
    const handleSearchFormSubmit = (query) => {
        onSubmit && onSubmit(query)
    }

    return <form onSubmit={(e) => {
        e.preventDefault();
        handleSearchFormSubmit(document.getElementById(`${inputId ? inputId : 'search_term'}`).value)
    }}
        className="d-inline-flex gap-1"
    >
        <Input
            type="search"
            id={`${inputId ? inputId : 'search_term'}`}
            className="form-control ms-0"
            placeholder={`${tagsMapping[taxonomy] ? `Search ${tagsMapping[taxonomy]}...` : taxonomy}`}
            value={value}
            name={name}
            onChange={onChange}
        />
        {
            searching && (
                <Button
                    onClick={() => {
                        document.getElementById(`${inputId ? inputId : 'search_term'}`).value = '';
                        if (refresh)
                            refresh()
                    }}
                    text='&times;'
                />
            )
        }
    </form>
}
export default SearchForm;