import React, { useEffect, useState } from "react";
import AdminTopbar from "../../components/AdminTopbar";
import AdminSidebar from "../../components/AdminSidebar";
import { useParams } from "react-router-dom";
import { getUserDetail_API } from "../../api/adminapi";
import { getCache, setCache } from "../../cacheUtil";
import { countriesStates, scrollToTop } from "../../utils/utils";
import he from 'he';

const CACHE_KEY_USER_DATA = 'user_data';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    return date?.toLocaleString('en-US', options);
}

const tabs = [{ name: 'Overview', icon: 'user' }, { name: 'Security', icon: 'lock' }, { name: 'Billing & Shipping', icon: 'map-pin' },];


const ViewUser = () => {

    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const { user_id } = useParams();
    const fetchUserDetail = async () => {
        if (loading)
            return;
        setLoading(true)
        await getUserDetail_API(user_id).then(res => {
            if (res.status == 200) {
                const _data = res.data;
                setUserData(_data);
                setCache(`${CACHE_KEY_USER_DATA}_${user_id}`, _data);
                console.warn(_data)
            }
        }).catch(err => {
            console.warn(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (user_id) {
            const loadCachedUsers = async () => {
                const cachedData = getCache(`${CACHE_KEY_USER_DATA}_${user_id}`);
                if (cachedData) {
                    setUserData(cachedData);
                }
            };
            loadCachedUsers();
            fetchUserDetail();
            scrollToTop();
        }
    }, [user_id])

    console.warn(userData)

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">

                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div
                            class="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between mb-6 text-center text-sm-start gap-2">
                            <div class="mb-2 mb-sm-0">
                                <h4 class="mb-1">User ID #{user_id}</h4>
                                <p class="mb-0">
                                    Registered: {formatDate(userData?.registered_date)}
                                </p>
                            </div>
                            <div className="d-flex align-center">
                                <div class={`spinner-border text-primary opacity-${loading ? '1' : '0'} me-3`} role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                                <div class="admin-card mb-6">
                                    <div class="admin-card-body pt-12">
                                        <div class="customer-avatar-section">
                                            <div class="d-flex align-items-center flex-column">
                                                <img
                                                    class="img-fluid rounded mb-4"
                                                    src={userData?.avatar}
                                                    height="120"
                                                    width="120" />
                                                <div class="customer-info text-center mb-6">
                                                    <h5 className="mb-0 text-capitalize">
                                                        {userData?.name}
                                                    </h5>
                                                    <span>User ID #{user_id}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-around flex-wrap mb-6 gap-0 gap-md-3 gap-lg-4">
                                            <div class="d-flex align-items-center gap-4 me-5">
                                                <div class="avatar">
                                                    <div class="avatar-initial rounded bg-label-primary">
                                                        <i class="ti ti-shopping-cart ti-lg"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 class="mb-0">{userData?.count ? userData?.count : 0}</h5>
                                                    <span>Total Orders</span>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center gap-4">
                                                <div class="avatar">
                                                    <div class="avatar-initial rounded bg-label-primary">
                                                        <i class="ti ti-currency-dollar ti-lg"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 class="mb-0">
                                                        {userData?.spent ? he.decode(userData?.spent) : 0}
                                                    </h5>
                                                    <span>Total Spent</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="info-container">
                                            <h5 class="pb-4 border-bottom text-capitalize mt-6 mb-4">User Details</h5>
                                            <ul class="list-unstyled mb-6">
                                                <li class="mb-2">
                                                    <span class="h6 me-1">Username:</span>
                                                    <span>{userData?.username}</span>
                                                </li>
                                                <li class="mb-2">
                                                    <span class="h6 me-1">Email:</span>
                                                    <span>{userData?.email}</span>
                                                </li>
                                                <li class="mb-2">
                                                    <span class="h6 me-1">Phone:</span>
                                                    <span>
                                                        {userData?.meta?.billing_phone ? userData?.meta?.billing_phone : 'N/A'}
                                                    </span>
                                                </li>

                                                <li class="mb-2">
                                                    <span class="h6 me-1">Country:</span>
                                                    <span>{countriesStates[userData?.meta?.billing_country]?.name ? countriesStates[userData?.meta?.billing_country]?.name : 'N/A'}</span>
                                                </li>
                                            </ul>
                                            <div class="d-flex justify-content-center">
                                                <a
                                                    href="javascript:;"
                                                    class="btn btn-primary w-100"
                                                    data-bs-target="#editUser"
                                                    data-bs-toggle="modal"
                                                >Delete User</a
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                                <div class="nav-align-top">
                                    <ul class="nav nav-pills flex-column flex-md-row mb-6 row-gap-2">
                                        {
                                            tabs.map((tab, index) => {
                                                return (
                                                    <li class="nav-item">
                                                        <a
                                                            className={`nav-link ${index == activeTab ? 'active' : ''}`}
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setActiveTab(index);
                                                            }}
                                                        >
                                                            <i className={`ti ti-${tab.icon} ti-sm me-1_5`}></i>{tab.name}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                {
                                    activeTab == 0 && (
                                        <>
                                            <h4 class="mt-0">Recent Orders</h4>
                                            <div class="admin-card mb-6">
                                                <div class="table-responsive mb-4">
                                                    <table class="table datatables-customer-order border-top">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Order</th>
                                                                <th>Date</th>
                                                                <th>Status</th>
                                                                <th>Spent</th>
                                                                <th class="text-md-center">Actions</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    activeTab == 1 && (
                                        <div class="admin-card mb-6">
                                            <h5 class="admin-card-header">Change Password</h5>
                                            <div class="admin-card-body">
                                                <form id="formChangePassword" method="GET" onsubmit="return false">
                                                    <div class="alert alert-warning alert-dismissible" role="alert">
                                                        <h5 class="alert-heading mb-1">Ensure that these requirements are met</h5>
                                                        <span>Minimum 8 characters long, uppercase & symbol</span>
                                                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                    </div>
                                                    <div class="row gx-6">
                                                        <div class="mb-4 col-12 col-sm-6 form-password-toggle">
                                                            <label class="form-label" for="newPassword">New Password</label>
                                                            <div class="input-group input-group-merge">
                                                                <input
                                                                    class="form-control"
                                                                    type="password"
                                                                    id="newPassword"
                                                                    name="newPassword"
                                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                                                                <span class="input-group-text cursor-pointer"><i class="ti ti-eye-off"></i></span>
                                                            </div>
                                                        </div>

                                                        <div class="mb-4 col-12 col-sm-6 form-password-toggle">
                                                            <label class="form-label" for="confirmPassword">Confirm New Password</label>
                                                            <div class="input-group input-group-merge">
                                                                <input
                                                                    class="form-control"
                                                                    type="password"
                                                                    name="confirmPassword"
                                                                    id="confirmPassword"
                                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" />
                                                                <span class="input-group-text cursor-pointer"><i class="ti ti-eye-off"></i></span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button type="submit" class="btn btn-primary me-2">Change Password</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ViewUser