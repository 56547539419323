import React, { useState, useEffect } from "react";

const ConnectivityStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);

    return (
        <div>
            {!isOnline && (
                <div style={{
                    backgroundColor: "#a21d0b",
                    color: "white",
                    padding: "6px",
                    textAlign: "center",
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    zIndex: 99999999,
                }}>
                    You have lost your internet connection.
                </div>
            )}
        </div>
    );
};

export default ConnectivityStatus;
