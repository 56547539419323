import React, { useMemo } from 'react';

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange, refresh, loading }) => {
    const totalPages = useMemo(() => Math.ceil(totalItems / itemsPerPage), [totalItems, itemsPerPage]);
    
    const handlePageChange = (newPage) => {
        if(loading)
            return;
        if (newPage !== currentPage) {
            onPageChange(newPage);
            refreshData(newPage);
        }
    };
    const handleFirstPage = () => handlePageChange(1);
    const handlePreviousPage = () => handlePageChange(currentPage - 1);
    const handleNextPage = () => handlePageChange(currentPage + 1);
    const handleLastPage = () => handlePageChange(totalPages);

    const refreshData = (page) => {
        
        if (refresh) {
            refresh(page);
        }
    };

    return (
        <div className="pagination-container d-flex align-items-center gap-3 justify-content-end">
            <span>{totalItems?.toLocaleString()} items</span>
            <div className="pagination-controls d-flex justify-content-center align-items-center gap-50">
                <button
                    onClick={handleFirstPage}
                    disabled={currentPage === 1}
                    className="pagination-button"
                >
                    «
                </button>
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="pagination-button"
                >
                    ‹
                </button>
                <span className='me-3 ms-3'>{currentPage} of {totalPages}</span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                >
                    ›
                </button>
                <button
                    onClick={handleLastPage}
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                >
                    »
                </button>
            </div>
        </div>
    );
};

export default Pagination;
