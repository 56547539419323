import axios from 'axios';

const REACT_APP_WC_CONSUMER_KEY_STAGING = 'ck_086a4fc531bf64086ca30235ff35f64a048a5c69';
const REACT_APP_WC_CONSUMER_SECRET_STAGING = 'cs_faccb5133a22ba908b52a6f696f710d131953ad6';

const adminapi = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_WP_API_BASE,
    headers: {
        'Content-Type': 'application/json',
    },
});
const adminapi_wc = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_BASE + "/wp-json/wc/v3/",
    headers: {
        'Authorization': 'Basic ' + btoa(REACT_APP_WC_CONSUMER_KEY_STAGING + ':' + REACT_APP_WC_CONSUMER_SECRET_STAGING),
        'Content-Type': 'application/json',
    },
});

export const saveProductVariationBatch = async (batchData, productID) => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi_wc.post(`products/${productID}/variations/batch?${queryParams}`, batchData);
        return response;
    } catch (error) {
        console.warn(error)
        throw error.response ? error.response.data.message : error.message;
    }
}

export const getSalesReport_API = async (period = 'year', date_min = '', date_max = '') => {
    try {
        const queryParams = new URLSearchParams({
            period: period,
            date_min: date_min,
            date_max: date_max,
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi_wc.get(`reports/sales?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getBestSellingReport_API = async (period = 'year', date_min = '', date_max = '') => {
    try {
        const queryParams = new URLSearchParams({
            period: period,
            date_min: date_min,
            date_max: date_max,
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi_wc.get(`reports/top_sellers?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getPaidNonPaidCustomersCount_API = async () => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi_wc.get(`reports/customers/totals?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const login = async (username, password) => {
    try {
        const response = await adminapi.post('login?t=' + Math.floor(Date.now() / 1000), {
            username: username,
            password: password,
        });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const processEbayLineData = async (line, batch_file) => {
    if (!line)
        return
    try {
        const response = await adminapi.post('ebay/processline?t=' + Math.floor(Date.now() / 1000), {
            line: line,
            batch_file: batch_file,
        });
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
}

export const startEbayBulkImporterBatchFile = async () => {
    try {
        const response = await adminapi.post('ebay/startbatch?t=' + Math.floor(Date.now() / 1000), {});
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
}

export const getUsers_API = async (filters = [], page = 1, query = '') => {
    try {
        const queryParams = new URLSearchParams({
            filters: JSON.stringify(filters),
            page: page,
            query: query,
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`users?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getUsersCounts_API = async () => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`users/count?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getProudcts = async (filters = [], page = 1, query = '') => {
    try {
        const queryParams = new URLSearchParams({
            filters: JSON.stringify(filters),
            page: page,
            query: query,
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`products?${queryParams}`, {
            filters: filters,
            page: page,
        });
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getBatchFileData_API = async () => {
    try {
        const response = await adminapi.get('ebay/lister/batchdata?t=' + Math.floor(Date.now() / 1000), {});
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getPostStatusCounts = async (post_type = 'post') => {
    try {
        const response = await adminapi.get(`getpoststatuscounts?post_type=${post_type}&t=${Math.floor(Date.now() / 1000)}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getProudct = async (productID) => {
    if (!productID || productID < 1)
        return;
    try {
        const response = await axios.get(`https://staging.tonyetrade.com/wp-json/wc/v3/products/${productID}`, {
            headers: {
                'Authorization': 'Basic ' + btoa('ck_822865ff5baa8b427841248a78ce81f146f8c17a' + ':' + 'cs_3cb61c90fcd81c5cde7de15dbd82a84e28681c13'),
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const updateProudct = async (productID, productData, updating = false) => {
    if (updating && (!productID || productID < 1))
        return;
    console.warn(productData)
    try {
        let method = updating ? 'PUT' : 'POST';
        const url = method === 'POST'
            ? `https://staging.tonyetrade.com/wp-json/wc/v3/products`
            : `https://staging.tonyetrade.com/wp-json/wc/v3/products/${productID}`;
        const response = await axios({
            method: method.toLowerCase(),
            url: url,
            data: productData,
            headers: {
                'Authorization': 'Basic ' + btoa('ck_822865ff5baa8b427841248a78ce81f146f8c17a' + ':' + 'cs_3cb61c90fcd81c5cde7de15dbd82a84e28681c13'),
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getProductVariations = async (productID) => {
    if (!productID || productID < 1)
        return;
    try {
        const response = await axios.get(`https://staging.tonyetrade.com/wp-json/wc/v3/products/${productID}/variations`, {
            headers: {
                'Authorization': 'Basic ' + btoa('ck_822865ff5baa8b427841248a78ce81f146f8c17a' + ':' + 'cs_3cb61c90fcd81c5cde7de15dbd82a84e28681c13'),
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const fetchEbayListings = async (filters = [], page = 1) => {
    try {
        const queryParams = new URLSearchParams({
            filters: JSON.stringify(filters),
            page: page,
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`ebay/listings?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getPost = async (postID) => {
    if (!postID || postID < 1)
        return;
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`/admin/posts/${postID}?${queryParams}`);
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const fetchAmazonListings = async (filters = [], page = 1) => {
    try {
        const queryParams = new URLSearchParams({
            filters: JSON.stringify(filters),
            page: page,
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`amazon/listings?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getWCOrders = async (filters = [], page = 1) => {
    try {
        const queryParams = new URLSearchParams({
            filters: JSON.stringify(filters),
            page: page,
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`orders?${queryParams}`, {
            filters: filters,
            page: page,
        });
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getEbayOrders = async (filters = [], page = 1) => {
    try {
        const queryParams = new URLSearchParams({
            filters: JSON.stringify(filters),
            page: page,
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`ebay/orders?${queryParams}`);
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getAmazonOrders = async (filters = [], page = 1) => {
    try {
        const queryParams = new URLSearchParams({
            filters: JSON.stringify(filters),
            page: page,
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`amazon/orders?${queryParams}`, {
            headers: {
                'Authorization': 'Basic ' + btoa('ck_822865ff5baa8b427841248a78ce81f146f8c17a' + ':' + 'cs_3cb61c90fcd81c5cde7de15dbd82a84e28681c13'),
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getEbayOrderStatusCount = async () => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`ebay/orders/statuscount?${queryParams}`, {});
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getAmazonOrderStatusCount = async () => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`amazon/orders/statuscount?${queryParams}`, {});
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getEbayListingStatusCount = async () => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`ebay/listings/statuscount?${queryParams}`, {});
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getAmazonListingStatusCount = async () => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`amazon/listings/statuscount?${queryParams}`, {});
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getSingleOrderDetail = async (order_id) => {
    try {
        const response = await axios.get(`https://staging.tonyetrade.com/wp-json/wc/v3/orders/${order_id}`, {
            headers: {
                'Authorization': 'Basic ' + btoa('ck_822865ff5baa8b427841248a78ce81f146f8c17a' + ':' + 'cs_3cb61c90fcd81c5cde7de15dbd82a84e28681c13'),
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getTags = async (taxonomy, page = 1, per_page = 20, skip_cache = false, query = '', term_id = null) => {
    try {
        const queryParams = new URLSearchParams({
            taxonomy: taxonomy,
            page: page,
            per_page: per_page,
            query: query,
            skip_cache: skip_cache ? 'yes' : '',
            ...(term_id && { term_id })
        });
        const response = await axios.get(`https://staging.tonyetrade.com/wp-json/tonyadmin/v1/terms?${queryParams}`, {
            headers: {
                'Authorization': 'Basic ' + btoa('ck_822865ff5baa8b427841248a78ce81f146f8c17a' + ':' + 'cs_3cb61c90fcd81c5cde7de15dbd82a84e28681c13'),
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const performBulkAction_API = async (action, post_type, items) => {
    try {
        const response = await adminapi.post('admin/bulkaction?t=' + Math.floor(Date.now() / 1000), {
            action: action,
            post_type: post_type,
            items: items,
        });
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getBlogPosts = async (page = 1, status = 'any', filters = []) => {
    try {
        const queryParams = new URLSearchParams({
            page: page,
            status: status,
            t: Math.floor(Date.now() / 1000),
            filters: JSON.stringify(filters),
        });
        const response = await adminapi.get(`admin/getblogposts?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getBlogPostsStatuses = async () => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`admin/getblogpoststatuses?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getPriceAdjustmentItems = async (date) => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
            date: date,
        });
        const response = await adminapi.get(`admin/priceadjustment?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getPriceAdjustmentDateGroups = async () => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`admin/dategroups?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getWPMedia = async (page = 1, per_page = 30) => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
            page: page,
            per_page: per_page,
        });
        const response = await adminapi.get(`admin/media?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const updatePost = async (postData) => {
    try {
        const response = await adminapi.post('addupdatepost?t=' + Math.floor(Date.now() / 1000), postData);
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};


export const updatePriceAdjustment = async (data) => {
    try {
        const response = await adminapi.post('priceadjustmentupdate?t=' + Math.floor(Date.now() / 1000), data);
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const updateTerm = async (termData) => {
    try {
        const response = await adminapi.post('updateterm?t=' + Math.floor(Date.now() / 1000), termData);
        return response
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getUsersStatuses_API = async () => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`users/count?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export const getUserDetail_API = async (user_id) => {
    try {
        const queryParams = new URLSearchParams({
            t: Math.floor(Date.now() / 1000),
        });
        const response = await adminapi.get(`users/${user_id}?${queryParams}`);
        return response;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
};

export default adminapi;