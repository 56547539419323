import Logo from '../assets/images/logo.png';
const Preloader = () => {
    return <div
        style={{
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 20,
            zIndex: 99999999,
            background:'#EAEFF8'
        }}
    >
        <img width={200} src={Logo} />
        <div className={`dgwt-wcas-preloader search_form_preloader loaded`}
            style={{
                margin: 0,
                position: 'relative',
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
                height: 'auto',
            }}
        >
            <svg className="dgwt-wcas-loader-circular" viewBox="25 25 50 50"
                style={{
                    width: 40,
                    height: 'auto',
                }}
            >
                <circle className="dgwt-wcas-loader-circular-path" cx="50" cy="50" r="20" fill="none" strokeMiterlimit="10" style={{
                    stroke: '#a21d0b'
                }}></circle>
            </svg>
        </div>
    </div>
}

export default Preloader;