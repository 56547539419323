import { useState } from 'react';
import './priceadjustment.css';
import toast from 'react-hot-toast';
import { updatePriceAdjustment } from '../../api/adminapi';
import $ from 'jquery';
import { showToast } from '../../utils/utils';

const PriceAdjustmentEditor = ({ items, visible, setVisible, isInline = false, refresh }) => {

    const [selectedMarketplaces, setSelectedMarketPlaces] = useState([])
    const [showDateRange, setShowDateRange] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [priceBy, setPriceBy] = useState('')
    const [processing, setProcessing] = useState(false)
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        setSelectedMarketPlaces((prevSelected) => {
            if (checked) {
                return [...prevSelected, value];
            } else {
                return prevSelected.filter((marketplace) => marketplace !== value);
            }
        });
        validateButtonDisabled()
    };

    const validateButtonDisabled = () => {
        let _condition = !selectedMarketplaces?.length
        setDisabled(_condition)
    }

    const handleSubmitForm = async (e) => {
        if (processing)
            return;
        if (!selectedMarketplaces?.length) {
            showToast('Select at least one marketplace', 'error');
            return;
        }
        if (!items?.length) {
            showToast('Invalid selected items', 'error');
            return;
        }
        const _edit_price_by = document.getElementById('edit_price_by').value;
        if (_edit_price_by == 0 || _edit_price_by == '0') {
            showToast('Invalid option selected', 'error');
            return;
        }

        if (
            (['entering_price', 'add_to_current_amount', 'reduce_by_amount'].includes(_edit_price_by) && !parseInt(document.getElementById('price_amount').value)) ||
            (['add_to_current_percent', 'reduce_by_percentage'].includes(_edit_price_by) && !parseInt(document.getElementById('price_percentange').value))
        ) {
            showToast('Invalid amount entered', 'error');
            return;
        }

        if (document.getElementById('set_time_duration').checked && !document.getElementById('price_changes_starting').value.length) {
            showToast('Invalid starting date', 'error');
            return;
        }

        setProcessing(true);
        const _formData = new FormData(e.target);
        const _items = items.map(item => {
            return parseInt(item.ID);
        })
        _formData.append('items', _items)

        await updatePriceAdjustment(_formData).then(response => {
            if( response.status == 200 ){
                showToast('Updated successfully');
                refresh && refresh();
                if( isInline ){
                    $(e.target).closest('tr').remove();
                    return;
                }
                $('.close_price_adjustment_editor_popup').trigger('click');
                setVisible(false)
            }
        }).catch(err => {

        }).finally(() => {
            setProcessing(false)
        });
    }

    const handleClose = () => {
        setSelectedMarketPlaces([])
        setShowDateRange(false)
        $('#price_adjustment_editor_popup input[type="checkbox"]').prop('checked', false);
        $('#edit_price_by').val('0');
        $('#price_amount, #price_percentange').val('');
        setVisible(false)
    }

    return <div id="price_adjustment_editor_popup_wrapper" className={`${visible ? 'loaded' : ''} ${isInline ? 'inline p-5' : ''}`}>
        <div id="price_adjustment_editor_popup">
            {
                !isInline ?
                    <a href='#' className='close_price_adjustment_editor_popup' onClick={(e) => {
                        e.preventDefault()
                        handleClose()
                    }}>
                        &times;
                    </a> : null
            }
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitForm(e)
                }}
                className={`${processing ? 'processing' : ''}`}
            >
                <div className={`inline-edit-wrapper ${isInline ? 'flex flex-wrap' : ''}`}>
                    <fieldset class={`inline-edit-col-left ${isInline ? 'col-3' : ''}`}>
                        <div class="inline-edit-col">
                            <label>
                                <span class="title d-flex mb-1">Select Marketplaces</span>
                                <span class="input-text-wrap marketplaces d-flex gap-3">
                                    <div class="form-check">
                                        <label class="form-check-label" htmlFor='amazon'>Amazon</label>
                                        <input type="checkbox" class="form-check-input" name="marketplace[]" id="amazon" value="amazon"
                                            {...selectedMarketplaces.includes('amazon') ? 'checked="checked"' : ''}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label" htmlFor='ebay'>Ebay</label>
                                        <input type="checkbox" class="form-check-input" id="ebay" name="marketplace[]" value="ebay"
                                            {...selectedMarketplaces.includes('ebay') ? 'checked="checked"' : ''}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label" htmlFor='website'>Website</label>
                                        <input type="checkbox" class="form-check-input" id="website" name="marketplace[]" value="website"
                                            {...selectedMarketplaces.includes('website') ? 'checked="checked"' : ''}
                                            onChange={handleCheckboxChange}
                                        />
                                    </div>
                                </span>
                                {
                                    !selectedMarketplaces?.length ?
                                        <span class="error">
                                            Select at least one marketplace.
                                        </span> : null
                                }
                            </label>
                        </div>
                    </fieldset>
                    <fieldset class={`inline-edit-col-left ${isInline ? 'col-4' : ''}`}>
                        <legend class="inline-edit-legend">Time/Duration</legend>
                        <div class="inline-edit-col mb-2">
                            <label>
                                <span class="input-text-wrap">
                                    <div class="form-check">
                                        <label class="form-check-label" htmlFor='set_time_duration'>Do you want to apply price changes to up to certain date?</label>
                                        <input type="checkbox" class="form-check-input" id="set_time_duration" name="set_time_duration" onChange={(e) => {
                                            setShowDateRange(e.target.checked)
                                        }} />
                                    </div>
                                    {
                                        showDateRange ?
                                            <>
                                                <div class="dates_holder d-flex align-center gap-3 mb-1">
                                                    <input type="date" style={{backgroundColor: isInline ? '#fff' : 'transparent'}} id="price_changes_starting" name="price_changes_starting" placeholder="Starting Date (Leave empty for immediate)" className='form-control' /> To <input type="date" style={{backgroundColor: isInline ? '#fff' : 'transparent'}} id="price_changes_deadline" name="price_changes_deadline" className='form-control' placeholder="Last Date" />
                                                </div>
                                                <small>(Leave starting date empty for immedidate effect.)</small>
                                            </> : null
                                    }
                                </span>
                            </label>
                        </div>
                    </fieldset>
                    <fieldset class={`inline-edit-col-left ${isInline ? 'col-5' : ''}`}>
                        <legend class="inline-edit-legend">Edit Price By</legend>
                        <div class="inline-edit-col">
                            <label className='d-flex w-100'>
                                <div class="input-text-wrap d-flex gap-4 w-100">
                                    <select id="edit_price_by" name="edit_price_by" style={{backgroundColor: isInline ? '#fff' : 'transparent'}} className='select2 form-select select2-hidden-accessible'
                                        onChange={(e) => {
                                            setPriceBy(e.target.value)
                                        }}
                                    >
                                        <option value="0">Select an option</option>
                                        <option value="entering_price">Entering Price</option>
                                        <option value="add_to_current_amount">Add Amount To Current Price</option>
                                        <option value="add_to_current_percent">Add Percentage To Current Price</option>
                                        <option value="reduce_by_amount">Reduce price by fixed amount</option>
                                        <option value="reduce_by_percentage">Reduce price by percentage</option>
                                    </select>
                                    {
                                        priceBy == 'entering_price' || priceBy == 'add_to_current_amount' || priceBy == 'reduce_by_amount' ?
                                            <input type="number" min="1" name="price_amount" id="price_amount" style={{backgroundColor: isInline ? '#fff' : 'transparent'}} placeholder="Enter amount here..." className='form-control' /> : null
                                    }
                                    {
                                        priceBy == 'reduce_by_percentage' || priceBy == 'add_to_current_percent' ?
                                            <input type="number" min="1" max="100" name="price_percentange" id="price_percentange" style={{backgroundColor: isInline ? '#fff' : 'transparent'}} placeholder="Enter percentage here..." className='form-control' /> : null
                                    }
                                </div>
                            </label>
                        </div>
                    </fieldset>
                    <button type="submit" className="btn add-new btn-primary ms-2 ms-sm-0 waves-effect waves-light mt-2" >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
}

export default PriceAdjustmentEditor