import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { getEbayOrders, getEbayOrderStatusCount, getPostStatusCounts } from "../../api/adminapi";
import ViewOrderModal from "../../components/AdminModals/ViewOrder";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { getCache, setCache } from "../../cacheUtil";
import PostStatuses from "../../components/PostStatuses";
import AdminTableMeta from "../../components/AdminTableMeta";
import { getBulkActions, NumberFormatter, scrollToTop } from "../../utils/utils";

const CACHE_KEY_EBAY_ORDERS = 'ebay_orders_data';
const CACHE_KEY_EBAY_ORDER_COUNTERS = 'ebay_order_counters_data';

const getCompleteStatus = (order) => {
    let _color = '',
        _value = ''
    switch (order.CompleteStatus) {
        case 'Active':
            _color = 'darkorange';
            _value = 'Active';
            break;
        case 'Completed':
            _color = 'green';
            _value = 'Completed';
            break;
        case 'Cancelled':
            _color = 'gray';
            _value = 'Cancelled';
            break;
        default:
            _color = 'black';
            _value = order.CompleteStatus;
    }
    return `<span style="color:${_color};">${_value}<span>`;
}

const getPaymentMethod = (order) => {
    let _color = '',
        _value = ''
    switch (order.eBayPaymentStatus) {
        case 'NoPaymentFailure':
            _color = 'silver';
            _value = 'Payment complete';
            break;
        case 'PayPalPaymentInProcess':
            _color = 'darkorange';
            _value = 'Payment in Progress';
            break;
        case 'BuyerECheckBounced':
            _color = 'darkred';
            _value = 'The buyer\'s eCheck bounced';
            break;
        default:
            _color = 'darkorange';
            _value = order['eBayPaymentStatus'];
    }
    return `<span style="color:${_color};">${_value}<span>`
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
    return `<span>${formattedDate}<span><br><span>${formattedTime}</span>`;
}

const EbayOrders = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orders, SetOrders] = useState(undefined)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [total, setTotal] = useState(0)
    const [selectedOrders, setSelectedOrders] = useState([]);

    const openEbayOrderModal = (order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
    };

    const fetchOrders = async (page = null) => {
        if (loading)
            return
        try {
            setLoading(true)
            await getEbayOrders(activeFilters, page ? page : currentPage).then(response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setTotalPages(_data.total_pages)
                    setTotal(_data.total_items)
                    setCurrentPage(_data.current_page)
                    SetOrders(_data.orders)
                    setCache(CACHE_KEY_EBAY_ORDERS, _data);
                    scrollToTop()
                }
            }).finally(() => {
                setLoading(false)
            })
        } catch (error) {

        }
    }

    const getOrdersCounts = async () => {
        try {
            getEbayOrderStatusCount().then(response => {
                if (response.status == 200) {
                    const _counts = response.data;
                    if (_counts) {
                        setCounters(prevCounters => {
                            const updatedCounters = { ...prevCounters };
                            Object.entries(_counts)?.forEach(([key, value]) => {
                                switch (key) {
                                    case 'Active':
                                        key = 'active';
                                        break;
                                    case 'total_items':
                                        key = 'all';
                                        break;
                                    case 'Completed':
                                        key = 'completed';
                                        break;
                                    case 'Cancelled':
                                        key = 'cancelled';
                                        break;
                                    case 'Refunded':
                                        key = 'refunded';
                                        break;
                                    case 'has_wc_order':
                                        key = 'inwoocommerce';
                                        break;
                                    case 'amazon':
                                        key = 'placedonamazon';
                                        break;
                                    case 'has_no_wc_order':
                                        key = 'notinwoocommerce';
                                        break;
                                }
                                if (updatedCounters[key]) {
                                    updatedCounters[key] = {
                                        ...updatedCounters[key],
                                        c: value,
                                    };
                                }
                            });
                            setCache(CACHE_KEY_EBAY_ORDER_COUNTERS, updatedCounters);
                            return updatedCounters;
                        });
                    }
                }
            })
        } catch (error) {

        }
    }

    const [activeFilters, setActiveFilters] = useState({
        status: 'all',
    })
    useEffect(() => {
        const loadCachedOrders = () => {
            const cachedData = getCache(CACHE_KEY_EBAY_ORDERS);
            if (cachedData) {
                setTotalPages(cachedData.total_pages);
                setTotal(cachedData.total_items);
                setCurrentPage(cachedData.current_page);
                SetOrders(cachedData.orders);
            }
            fetchOrders();
        };

        loadCachedOrders();
    }, [activeFilters])

    useEffect(() => {
        const cachedCounters = getCache(CACHE_KEY_EBAY_ORDER_COUNTERS);
        if (cachedCounters) {
            setCounters(cachedCounters);
        }
        getOrdersCounts();
    }, [])

    const [counters, setCounters] = useState({
        all: { c: 0, t: 'All' },
        shipped: { c: 0, t: 'Shipped' },
        unshipped: { c: 0, t: 'Unshipped' },
        completed: { c: 0, t: 'Completed' },
        active: { c: 0, t: 'Active' },
        cancelled: { c: 0, t: 'Cancelled' },
        refunded: { c: 0, t: 'Refunded' },
        inwoocommerce: { c: 0, t: 'In WooCommerce' },
        // placedonamazon: { c: 0, t: 'Placed on Aamazon' },
        notinwoocommerce: { c: 0, t: 'Not in WooCommerce' },
    });

    const [loading, setLoading] = useState(false)

    const handleStatusFilter = (status) => {
        setActiveFilters({
            ...activeFilters,
            status: status,
        })
        // fetchOrders()
    }

    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allOrderIds = orders.map(order => order.id);
            setSelectedOrders(allOrderIds);
        } else {
            setSelectedOrders([]);
        }
    };

    const handleOrderCheckbox = (e, orderId) => {
        if (e.target.checked) {
            setSelectedOrders(prev => [...prev, orderId]);
        } else {
            setSelectedOrders(prev => prev.filter(id => id !== orderId));
        }
    };

    const [searchQuery, setSearchQuery] = useState('')

    const handleSort = (field) => {
        if (loading)
            return;
        setActiveFilters((prev) => {
            const isSameField = prev.sortBy === field;
            return {
                ...prev,
                sortBy: field,
                sortOrder: isSameField ? (prev.sortOrder === "asc" ? "desc" : "asc") : "asc",
            };
        });
    };

    const getSortClass = (field) => {
        if (activeFilters.sortBy === field) {
            return activeFilters.sortOrder === "asc" ? "sorting_asc" : "sorting_desc";
        }
        return "sorting";
    };

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="admin-card">
                            <AdminTableMeta showPagination={true}
                                pagination={{
                                    total: total,
                                    per_page: 20,
                                    currentpage: currentPage,
                                    refresh: fetchOrders,
                                    setCurrentPage: setCurrentPage,
                                }}
                                loading={loading}
                                showSearch={true}
                                search={{
                                    searchQuery: searchQuery,
                                    setSearchQuery: setSearchQuery,
                                    placeholder: 'Search orders...'
                                }}
                                showBulkActions={true}
                                bulkActions={{
                                    actions: getBulkActions('ebayOrder')
                                }}
                                refresh={fetchOrders}
                            />
                            <div class="card-datatable table-responsive">
                                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <PostStatuses counters={counters} activeFilters={activeFilters} handleStatusFilter={handleStatusFilter} />
                                    <table class="datatables-products table dataTable no-footer dtr-column">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input type="checkbox" class="form-check-input"
                                                        onChange={handleAllSelector}
                                                        checked={orders && selectedOrders.length === orders.length}
                                                    />
                                                </th>
                                                <th
                                                    className={getSortClass("date_created")}
                                                    onClick={() => handleSort("date_created")}
                                                >created at</th>
                                                <th>buyer</th>
                                                <th
                                                    className={getSortClass("total")}
                                                    onClick={() => handleSort("total")}
                                                >total</th>
                                                <th>payment method</th>
                                                <th
                                                    className={getSortClass("status")}
                                                    onClick={() => handleSort("status")}
                                                >status</th>
                                                <th
                                                    className={getSortClass("ID")}
                                                    onClick={() => handleSort("ID")}
                                                >order id</th>
                                                <th
                                                    className={getSortClass("LastTimeModified")}
                                                    onClick={() => handleSort("LastTimeModified")}
                                                >last change</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {typeof orders === "undefined" ? (
                                                <tr>
                                                    <td colSpan="8">Processing...</td>
                                                </tr>
                                            ) : !orders.length ? (
                                                <tr>
                                                    <td colSpan="8">Not found</td>
                                                </tr>
                                            ) : (
                                                orders.map((order, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <input type="checkbox" className="dt-checkboxes form-check-input" value={order.id}
                                                                    checked={selectedOrders.includes(order.id)}
                                                                    onChange={(e) => handleOrderCheckbox(e, order.id)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <span dangerouslySetInnerHTML={{ __html: formatDate(order.date_created) }} />
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <div>
                                                                        {order.buyer_name} <em>{order.buyer_userid}</em>
                                                                    </div>
                                                                    {
                                                                        order.ShippingService?.length ?
                                                                            <span>Global Shipping Program</span> : null
                                                                    }
                                                                    <div className={order.post_status}>
                                                                        Order #{order.post_id} {order.post_status == 'deleted' ? ' has been deleted.' : ` is ${order.post_status?.replace('wc-', '')}.`}
                                                                    </div>
                                                                    <div className="order_actions">
                                                                        <a href="#" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            openEbayOrderModal(order)
                                                                        }}>Details</a>
                                                                        {
                                                                            order.post_status != 'deleted' ?
                                                                                <> | <Link to={`/admin/orders/view/${order.post_id}`}>View Order</Link></> : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {order.total} {order.currency}
                                                                </div>
                                                                <div>
                                                                    {order.items.length} {order.items.length > 1 || order.items.length < 1 ? 'items' : 'item'} {
                                                                        order.icon.length ?
                                                                            <span dangerouslySetInnerHTML={{ __html: order.icon }} /> : null
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {order.PaymentMethod}
                                                                </div>
                                                                <div dangerouslySetInnerHTML={{ __html: getPaymentMethod(order) }} />
                                                                {
                                                                    order.paid ?
                                                                        <div>
                                                                            {order.paid}
                                                                        </div> : null
                                                                }
                                                            </td>
                                                            <td>
                                                                <div dangerouslySetInnerHTML={{ __html: getCompleteStatus(order) }}>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                {order.order_id}
                                                            </td>
                                                            <td>
                                                                <span dangerouslySetInnerHTML={{ __html: formatDate(order.LastTimeModified) }} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            )}

                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                                Showing <strong>{orders ? orders?.length : 0}</strong> of <strong>{NumberFormatter(total)}</strong> orders
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <AdminTableMeta showPagination={true}
                                                pagination={{
                                                    total: total,
                                                    per_page: 20,
                                                    currentpage: currentPage,
                                                    refresh: fetchOrders,
                                                    setCurrentPage: setCurrentPage,
                                                }}
                                                loading={loading}
                                                refresh={fetchOrders}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <ViewOrderModal type="ebay_order" isOpen={isModalOpen} onClose={closeModal} order={selectedOrder} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default EbayOrders;