import { Link } from 'react-router-dom'
import '../UpdateBillingDetails/updatebillingdetails.css'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Hooks/useAuth'
import { countries, encryptData, scrollToTop, showToast } from '../../../utils/utils'
import axios from 'axios'
import toast from 'react-hot-toast'

const UpdateBillingDetails = () => {
    const auth = useAuth()
    const [user, setUser] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        company: '',
        country: '',
        address1: '',
        city: '',
        email: '',
        state: '',
        zipcode: '',
        phone: '',
    })
    useEffect(() => {
        const getUserdata = async () => {
            const userInner = await auth.getCurrentUser()
            if (userInner) {
                setFormData({
                    firstName: userInner?.billing?.first_name,
                    lastName: userInner?.billing?.last_name,
                    company: userInner?.billing?.company,
                    country: userInner?.billing?.country,
                    address1: userInner?.billing?.address_1,
                    city: userInner?.billing?.city,
                    email: userInner?.billing?.email,
                    state: userInner?.billing?.state,
                    zipcode: userInner?.billing?.postcode,
                    phone: userInner?.billing?.phone,
                })
                setUser(userInner)
            }
        }
        getUserdata()
        scrollToTop()
    }, [])
    const handleFormSubmit = async () => {
        if (isProcessing)
            return

        let userInner = await auth.getCurrentUser()
        if (!userInner) {
            return
        }

        setIsProcessing(true)
        let customerdata = {
            billing: {},
        }

        customerdata.billing.first_name = formData?.firstName
        customerdata.billing.last_name = formData?.lastName
        customerdata.billing.company = formData?.company
        customerdata.billing.address_1 = formData?.address1
        customerdata.billing.city = formData?.city
        customerdata.billing.state = formData?.state
        customerdata.billing.postcode = formData?.zipcode
        customerdata.billing.country = formData?.country
        customerdata.billing.email = formData?.email
        customerdata.billing.phone = formData?.phone

        await axios.post(process.env.REACT_APP_WC_API_BASE + `customers/${userInner?.id}`, customerdata, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
            }
        }).then(async res => {
            if (res.status == 200) {
                showToast('Billing details updated successfully');
                scrollToTop()
                userInner.billing = res?.data?.billing
                const encryptedUser = await encryptData(userInner)
                await auth.setCurrentUser(encryptedUser)
            } else {
                showToast('Error occured, try again', 'error');
            }
        }).catch(err => {
            showToast(err?.data?.msg, 'error');
        }).finally(() => {
            setIsProcessing(false)
        })

    }
    return (
        <div className="container mid">
            <div className="account-details justify-center">
                <div className="left-panel flex-65 full-width-small">
                    <div className="left-account-detail">
                        <h3 className="account-title">Billing Details</h3>
                        <form className={`flex wrap space-between ${isProcessing ? 'processing' : ''}`} onSubmit={event => {
                            event.preventDefault()
                            handleFormSubmit()
                        }}>
                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="firstname">First Name</label>
                                <input type="text" name="firstname" placeholder='Jhon' required="required" value={formData?.firstName} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        firstName: event.target.value
                                    })
                                }} />
                            </div>

                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="lastname">Last Name</label>
                                <input type="text" name="lastname" placeholder='Doe' required="required" value={formData?.lastName} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        lastName: event.target.value
                                    })
                                }} />
                            </div>
                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="company">Company</label>
                                <input type="text" name="company" placeholder="Company" value={formData?.company} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        company: event.target.value
                                    })
                                }} />
                            </div>

                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="country">Country</label>
                                <select name="country" value={formData?.country} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        country: event.target.value
                                    })
                                }}>
                                    {
                                        Object.entries(countries)?.map((country, index) => {
                                            return (
                                                <option key={index} value={country[0]}>
                                                    {country[1]}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group flex-100">
                                <label className="form-label" htmlFor="Address">Address</label>
                                <input type="text" name="Address" placeholder="Address" required="required" value={formData?.address1} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        address1: event.target.value
                                    })
                                }} />
                            </div>

                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="city">City</label>
                                <input type="text" name="city" placeholder="City" required="required" value={formData?.city} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        city: event.target.value
                                    })
                                }} />
                            </div>
                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="state">State</label>
                                <input type="text" name="state" placeholder="State" required="required" value={formData?.state} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        state: event.target.value
                                    })
                                }} />
                            </div>
                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="username">Zipcode</label>
                                <input type="text" name="zipcode" placeholder='Zipcode' required="required" value={formData?.zipcode} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        zipcode: event.target.value
                                    })
                                }} />
                            </div>
                            <div className="form-group flex-49 full-width-small">
                                <label className="form-label" htmlFor="Phone">Phone</label>
                                <input type="text" name="Phone" placeholder='Phone' required="required" value={formData?.phone} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        phone: event.target.value
                                    })
                                }} />
                            </div>
                            <div className="form-group flex-100">
                                <label className="form-label" htmlFor="email">Email</label>
                                <input type="email" name="email" placeholder='Email' required="required" value={formData?.email} onChange={event => {
                                    setFormData({
                                        ...formData,
                                        email: event.target.value
                                    })
                                }} />
                            </div>
                            <div className="btn-form mt-0 grouped-form-btns align-center flex gap-15">
                                <input type="submit" className="submit-btn" value="Save Changes" />
                                <Link className="cancel-btn" to={`/my-account`}>
                                    Cancel
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateBillingDetails