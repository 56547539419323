const Button = ({ text, type = 'button', onClick, btnType, className, icon }) => {
    return <button type={type} className={`btn me-sm-3 me-1 ${className} btn-${btnType ? btnType : 'primary'}`}
        onClick={onClick}
    >
        {
            icon && (
                <i class={`ti ti-${icon} ti-xs me-0 me-sm-2`}></i>
            )
        }
        {text}
    </button>
}
export default Button;