const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // Cache expiration time in milliseconds

export const setCache = (key, value) => {
    try {
        const dataWithTimestamp = {
            value,
            timestamp: Date.now(),
        };
        localStorage.setItem(key, JSON.stringify(dataWithTimestamp));
    } catch (error) {
        console.error(`Error setting cache for key: ${key}`, error);
    }
};

export const getCache = (key) => {
    try {
        const cachedData = localStorage.getItem(key);
        if (!cachedData) return null;

        const { value, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp > CACHE_EXPIRY_TIME) {
            localStorage.removeItem(key);
            return null;
        }
        return value;
    } catch (error) {
        console.error(`Error getting cache for key: ${key}`, error);
        return null;
    }
};

export const removeCache = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error(`Error removing cache for key: ${key}`, error);
    }
};

export const getLocalCacheValue = async (key) => {
    const cacheValue = await localStorage.getItem(key);
    if (cacheValue)
        return cacheValue
    return false;
}

export const setLocalCacheValue = async (key, value) => {
    await localStorage.setItem(key, value);
}