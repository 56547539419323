import '../SingleProduct/singleproduct.css'
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRelatedProducts, getSingleProductBySlug } from "../../api/api";
import he from 'he'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ProductCard from '../../components/ProductCard';
import { convertToCommaSeparate, scrollToTop, showToast } from '../../utils/utils';
import { useAuth } from '../../Hooks/useAuth';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setCart } from '../../redux/action';
import $ from 'jquery'
import { ReactComponent as Ball } from '../../assets/svgs/ball.svg'
import Panzoom from '@panzoom/panzoom'

const parsePrice = (price, divide = true) => {
    let divider = 100
    if (!divide)
        divider = 1
    price = convertToCommaSeparate(price / divider)
    return price
}

const removeScripts = () => {
    const izoomremover = document.getElementById('izoomremover')
    const izoomsetter = document.getElementById('izoomsetter')
    if (izoomremover)
        izoomremover.remove()
    if (izoomsetter)
        izoomsetter.remove()
}

const toggleImageZoomOn = (zoom = 2) => {

    removeScripts()
    if (window.innerWidth < 768) {
        const scriptContent = `
            jQuery('.single-product-thumbnails-slider .pro_slider_item').each(function(){
                var _image = jQuery(this).find('img').attr('src');
                jQuery(this).izoomify({
                url: _image,
                magnify: `+ zoom + `
                });
            });
        `;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = scriptContent;
        script.setAttribute('id', 'izoomsetter');
        document.body.appendChild(script);
    } else {
        const scriptContent = `
            jQuery('.related-card-container .single-product-thumbnails-slider .pro_slider_item').each(function(){
                var _image = jQuery(this).find('img').attr('src');
                jQuery(this).izoomify({
                url: _image,
                magnify: `+ zoom + `
                });
            });
        `;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = scriptContent;
        script.setAttribute('id', 'izoomsetter');
        document.body.appendChild(script);
    }
}

const toggleImageZoomOff = () => {
    removeScripts()
    const scriptContent = `
        jQuery('.pro_slider_item img').each(function(){
            var _image = jQuery(this).attr('src');
            jQuery(this).trigger('izoomify.destroy');
        });
        jQuery('.pro_slider_item img').trigger('zoom.destroy');
    `;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptContent;
    script.setAttribute('id', 'izoomremover');
    document.body.appendChild(script);
}

const toggleZoomButtons = () => {
    if ($) {
        $('#mag_zoom_btns_single button').off().on('click', function () {
            $('#mag_zoom_btns_single button').removeClass('active');
            $(this).addClass('active');
            let _zoom = $(this).attr('data-zoom')
            if (_zoom)
                _zoom = parseInt(_zoom)

            if (_zoom && _zoom > 0) {
                if (window.innerWidth < 768) {
                    toggleImageZoomOn(_zoom)
                } else {
                    toggleImageZoomOn(_zoom)
                }
            }
        });
    }
    document.querySelectorAll('#mag_zoom_btns_single button').forEach(button => {
        button.addEventListener('click', function (e) {
            document.querySelectorAll('#mag_zoom_btns_single button').forEach(btn => {
                btn.classList.remove('active');
            });
            this.classList.add('active');
            let _zoom = this.getAttribute('data-zoom')
            if (_zoom)
                _zoom = parseInt(_zoom)

            if (_zoom && _zoom > 0) {
                if (window.innerWidth < 768) {

                } else {
                    toggleImageZoomOn(_zoom)
                }
            }

        });
    });
}

const RenderPlaceholder = () => {
    return (
        <div className='container'>
            <div className='single_product_preloader_wrapper'>
                <div className="lds-ripple"><div></div><div></div></div>
            </div>
        </div>
    )
}

const SingleProduct = (props) => {
    const [selectedImage, setSelectedImage] = useState('')
    const [selectedZoom, setSelectedZoom] = useState(2)
    let { slug } = useParams()
    const [product, setProduct] = useState(undefined)
    const [price, setPrice] = useState(0)
    const [isChecked, setIsChecked] = useState(false)
    const [relatedProducts, setRelatedProducts] = useState([])
    const [ip, setIP] = useState(null);

    useEffect(() => {
        if (selectedZoom > 0 && selectedImage?.length) {
            let _zoomPan = Panzoom(document.getElementById('mobile_zoom_image_wrapper'), {
                minScale: selectedZoom,
                pinchAndPan: true,
                maxScale: 6
            });
            window._zoomPan = _zoomPan
        }
    }, [selectedZoom])

    const navigate = useNavigate()
    useEffect(() => {
        const getSingleProductDetails = async () => {
            const singleProduct = await getSingleProductBySlug(slug)
            if (Array.isArray(singleProduct) && singleProduct?.length > 0) {
                setProduct(singleProduct[0])
                setPrice(singleProduct[0]?.price)
                if (singleProduct[0]?.related_ids && Array.isArray(singleProduct[0]?.related_ids) && singleProduct[0]?.related_ids?.length > 0) {
                    let images = []
                    if (singleProduct[0]?.type == 'simple' && Array.isArray(singleProduct[0]?.images)) {
                        singleProduct[0]?.images?.map(image => {
                            images.push(image?.src)
                        })
                    }
                    if (singleProduct[0]?.type == 'variable' && Array.isArray(singleProduct[0]?.variations) && singleProduct[0]?.variations?.length > 0) {
                        singleProduct[0]?.images?.map(image => {
                            images.push(image?.src)
                        })

                        await fetchSingleProductVariations(singleProduct[0]?.id)
                    }

                    if (images.length)
                        images = [...new Set(images)]
                    setProductImages(images)
                    const relatedproductInner = await getRelatedProducts(singleProduct[0]?.related_ids)
                    if (Array.isArray(relatedproductInner?.products) && relatedproductInner?.products?.length > 0) {
                        setRelatedProducts(relatedproductInner?.products)
                    }
                }
            } else {
                navigate('/')
            }
        }
        scrollToTop()
        setProduct(undefined)
        getSingleProductDetails()

    }, [navigate])

    useEffect(() => {

        axios.get("https://api.ipify.org/?format=json").then((res) => {
            if (res?.status == 200 && res?.data?.ip)
                setIP(res?.data?.ip)
        }).catch(err => {

        })

    }, [])

    const auth = useAuth()
    const dispatcher = useDispatch()
    const cartReducer = useSelector((state) => state.cartReducer)

    const handleAddToCart = async (product, event, type = 'simple') => {
        if (event) {
            if (event.target.classList.contains('processing'))
                return
            event.target.classList.add('processing');
        }

        const userInner = await auth.getCurrentUser()

        let headers = {}

        if (userInner) {
            headers = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                }
            }
        }

        if (userInner || 1) {

            let cartKeyParam = ''
            if (!userInner && cartReducer && cartReducer?.cart_key) {
                cartKeyParam = `?cart_key=${cartReducer?.cart_key}`
            }

            return await axios.post(process.env.REACT_APP_CC_API_BASE + `cart/add-item${cartKeyParam}`, {
                "id": '' + product?.id,
                "quantity": "1"
            }, headers).then(res => {
                if (res?.status == 200 && undefined != res?.data?.cart_key) {
                    dispatcher(setCart(res?.data))
                }
            }).catch(err => {

                if (err?.response?.data) {
                    showToast(he.decode(err?.response?.data?.message), 'error','bottom-left');
                }
            }).finally(() => {
                if (event) {
                    event.target.classList.remove('processing');
                }
            })
        }
    }

    const [selectedVariation, setSelectedVariation] = useState(undefined)
    const [variations, setVariations] = useState(undefined)

    const fetchSingleProductVariations = useCallback(async (product_id) => {
        await axios.get(process.env.REACT_APP_CC_API_BASE + `products/${product_id}/variations?orderby=date&stock_status=instock`).then(res => {
            if (res?.status == 200 && Array.isArray(res?.data) && res?.data?.length > 0) {

                const sorttedVariations = res?.data?.sort((a, b) => {
                    return parseFloat(b.prices.price) - parseFloat(a.prices.price);
                })

                if (sorttedVariations) {
                    res.data = sorttedVariations;
                }
                setVariations(res?.data)
                setSelectedVariation(res?.data[0])
                if (res?.data[0]?.images && typeof res?.data[0]?.images != undefined && Array.isArray(res?.data[0]?.images)) {
                    let images = []
                    res?.data[0]?.images?.map(image => {
                        images.push(image?.src?.full)
                    })
                    res?.data?.map((d, index) => {
                        if (index != 0 && d?.images && d?.images?.length) {
                            d?.images?.map(image => {
                                images.push(image?.src?.full)
                            })
                        }
                    })
                    setProductImages(images)
                }

                const query = new URLSearchParams(window.location.search);
                const variationParamTemp = query.get('variation');
                if (variationParamTemp && variationParamTemp > 0) {
                    setTimeout(() => {
                        $('.col-inner.has_variations .product-box[data-id="' + variationParamTemp + '"]').trigger('click');
                    }, 200)
                }

            } else {
                setVariations([])
            }
        }).catch(err => {
            console.log(err)
        })
    })

    const cart = useSelector((state) => state.cartReducer?.items)
    const [productImages, setProductImages] = useState(undefined)
    const isInCart = (product) => cart.some(item => item?.id === product?.id);
    const [isProcessing, setIsProcessing] = useState(false)

    useEffect(() => {
        removeScripts()
    }, []);

    const initSlider = () => {
        
        if( ! variations?.length ){
            return
        }

        if (product && product?.type == 'simple') {
            return
        }

        let _this = $('.col-inner.has_variations .product-box.loaded'),
            _images = _this.attr('data-images')
        const _wrapper = $('.img-slider-sec .single-product-thumbnails-slider')
        _wrapper.empty('')
        if (_images && _images?.length) {
            _images = JSON.parse(_images)
            if (_images && _images?.length) {
                $(".img-slider-sec .single-product-thumbnails-slider").trigger('destroy.owl.carousel')
                const _scriptToRemove = document.getElementById('singleproductsliderscript')
                if (_scriptToRemove)
                    _scriptToRemove.remove()
                _images?.map((image, index) => {
                    _wrapper.append(`<div key=${index} class="pro_slider_item">
                            <img src="${image?.src?.full?.replace('staging.', 'www.')}" loading="lazy" class="is_var_image" />
                        </div>`);
                });

                const scriptContent = `
                    setTimeout(()=>{
                        $(".img-slider-sec .single-product-thumbnails-slider").addClass('owl-carousel owl-loaded');
                        $(".img-slider-sec .single-product-thumbnails-slider").owlCarousel({
                            items: 1,
                            nav: true,
                            loop: true,
                            dots: false,
                            mouseDrag: false,
                            touchDrag: false
                        });
                        $('.col-inner.has_variations .product-box').on('click',function(){
                            $(".img-slider-sec .single-product-thumbnails-slider").trigger('destroy.owl.carousel')
                        });
                        $('.is_var_image').off().on('click',function(){
                            const _this = $(this);
                            $('#mobile_zoom_modal_body').empty();
                            $('#mobile_zoom_modal_body').append('<div id="mobile_zoom_image_wrapper"><img src="' + _this.attr('src') + '" /></div>');
                            $('#mobile_zoom_modal_wrapper').addClass('loaded');
                        });
                    },10);
                    
                `;

                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.innerHTML = scriptContent;
                script.setAttribute('id', 'singleproductsliderscript')
                setTimeout(() => {
                    document.body.appendChild(script);
                }, 50)

            }
        }
    }

    useEffect(() => {
        initSlider()
        $('.col-inner.has_variations .product-box').off().on('click', function (e) {
            initSlider()
        });
    }, [productImages])

    return (
        <>
            {
                typeof product !== 'undefined' ? (
                    Object.keys(product)?.length > 0 ?
                        <>
                            <div className="container">
                                {
                                    product?.name ?
                                        <h2 className="product-title" dangerouslySetInnerHTML={{ __html: he.decode(product?.name) }} /> : null
                                }
                                <div className={`related-card-container wrap-small product_type_${product?.type} ${product?.type == 'simple' ? 'wrap' : ''}`}>
                                    <div className="image-section full-width-small">
                                        <div className="img-slider-sec">
                                            <div className="switch-column">
                                                <label className="switch">
                                                    <input type="checkbox" id="zoomSwitch" onChange={(e) => {
                                                        const _ele = document.getElementById('mag_zoom_btns_single');
                                                        if (e.target.checked) {
                                                            _ele.classList.add('active');
                                                            const buttons = document.querySelectorAll('#mag_zoom_btns_single button');
                                                            buttons.forEach(button => {
                                                                button.classList.remove('active');
                                                            });
                                                            if (buttons.length > 0) {
                                                                buttons[0].classList.add('active');
                                                            }
                                                            toggleImageZoomOn()

                                                        } else {
                                                            _ele.classList.remove('active');
                                                            toggleImageZoomOff()
                                                        }
                                                    }} />
                                                    <span className="slider"></span>
                                                    <span className="text" id="zoomText">Turn On/Off Zoom</span>
                                                </label>
                                                <div id="mag_zoom_btns_single" className={isChecked ? 'active' : ''}>
                                                    <button className="active" onClick={() => {
                                                        toggleZoomButtons();
                                                    }} data-zoom="2">2x</button>
                                                    <button data-zoom="3" className="" onClick={() => {
                                                        toggleZoomButtons();
                                                    }}>3x</button>
                                                    <button data-zoom="4" className="" onClick={() => {
                                                        toggleZoomButtons();
                                                    }}>4x</button>
                                                    <button data-zoom="5" className="" onClick={() => {
                                                        toggleZoomButtons();
                                                    }}>5x</button>
                                                </div>
                                            </div>
                                            <div className='single-product-thumbnails-slider'>

                                            </div>
                                            {
                                                product && ( product?.type == 'simple' || product?.type == 'variable' ) ?
                                                    typeof productImages !== 'undefined' ? (
                                                        productImages?.length > 0 ?
                                                            <>
                                                                <OwlCarousel items={1} className="owl-theme single-product-thumbnails-slider" loop margin={8} dots={false} lazyLoad={true} nav={true}>
                                                                    {
                                                                        productImages?.map((image, index) => {
                                                                            return (
                                                                                <div key={index} className='pro_slider_item' onClick={() => {
                                                                                    setSelectedImage(image)
                                                                                }}>
                                                                                    <img src={image?.replace('staging.', 'www.')} loading='lazy' />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </OwlCarousel>
                                                            </> : <p>No product images available</p>
                                                    ) : (
                                                        <p className='images_loading_text'>Images loading...</p>
                                                    )
                                                    : null
                                            }
                                        </div>
                                        {
                                            productImages?.length > 0 ?
                                                <p className='tap_text'>
                                                    Tap Image to Zoom
                                                </p> : null
                                        }
                                    </div>
                                    <div className={`product-details-section full-width-small ${isProcessing ? 'processing' : ''}`}>
                                        <div className="customdetails">
                                            {
                                                product?.player != undefined ?
                                                    <div className="c_player">
                                                        <Link className="c-text" to={`/${product?.player?.slug}`} rel="tag">
                                                            {product?.player?.name}
                                                        </Link>
                                                    </div> : null
                                            }
                                            <div className="c_team">
                                                <Ball />
                                                {
                                                    product?.team != undefined ?
                                                        <Link className="c-text" to={`/team/${product?.team?.slug}`} rel="tag">
                                                            {
                                                                product?.team?.name
                                                            }
                                                        </Link> : null
                                                }

                                            </div>
                                            {
                                                product?.year != undefined ?
                                                    <div className="c_year">
                                                        <Link className="c-text" to={`/c_year/${product?.year?.slug}`} rel="tag">
                                                            {
                                                                product?.year?.name
                                                            }
                                                        </Link>
                                                    </div> : null
                                            }
                                            {
                                                product?.year != undefined ?
                                                    <div className="c_owner">
                                                        <Link className="c-text" to={`/manufacturer/${product?.manufacturer?.slug}`} rel="tag">
                                                            {
                                                                product?.manufacturer?.name
                                                            }
                                                        </Link>
                                                    </div> : null
                                            }
                                        </div>
                                        <p className="product-page-price">
                                            {
                                                product?.type == 'simple' && price > 0 ?
                                                    <span>
                                                        ${convertToCommaSeparate(price)}
                                                    </span> : null
                                            }
                                            {
                                                product?.type == 'variable' && typeof selectedVariation !== 'undefined' ?
                                                    <span>
                                                        ${parsePrice(selectedVariation?.prices?.price)}
                                                    </span> : null
                                            }
                                        </p>

                                        <div className={`col-inner ${product?.type == 'variable' ? 'has_variations' : ''}`}>
                                            {
                                                product?.type == 'variable' ?
                                                    typeof variations !== 'undefined' ? (
                                                        variations?.map(variation => {
                                                            return (
                                                                <div data-images={variation?.images?.length ? JSON.stringify(variation?.images) : ''} data-id={variation?.id} key={variation?.id} className={`product-box ${typeof selectedVariation !== 'undefined' && selectedVariation?.id == variation?.id ? 'loaded' : ''}`} onClick={() => {
                                                                    scrollToTop()
                                                                    if (typeof variation?.images !== 'undefined' && variation?.images?.length > 0) {
                                                                        let images = []
                                                                        variation?.images?.map(image => {
                                                                            images.push(image?.src?.full)
                                                                        })
                                                                        setProductImages(images)
                                                                    }
                                                                    setSelectedVariation(variation)
                                                                }}>
                                                                    <div className="title-wrapper full-width-small">{variation?.name}</div>
                                                                    <div className="box-text-products">
                                                                        <div className="customdetailss">
                                                                            <span className="mprice"><span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">{variation?.prices?.currency?.currency_symbol}</span>{parsePrice(variation?.prices?.price)}</bdi></span></span>
                                                                            <span className="var_data">
                                                                                {
                                                                                    isInCart(variation) ?
                                                                                        <Link className='view_cart' to={`/cart`}>VIEW CART → </Link>
                                                                                        :
                                                                                        <button data-product-id={product?.id} className="cart-btn" onClick={(event) => {
                                                                                            event.preventDefault()
                                                                                            handleAddToCart(variation, event, 'variation')
                                                                                        }}><span>Add to cart</span></button>
                                                                                }
                                                                            </span>
                                                                            <a className="card-view" href="#" onClick={event => {
                                                                                event.preventDefault()
                                                                            }}>View card</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (<p>Loading...</p>)
                                                    : null
                                            }
                                            {
                                                product?.type == 'simple' && product?.stock_status != "outofstock" ?
                                                    <div className='add_to_cart_simple'>
                                                        {
                                                            isInCart(product) ?
                                                                <Link className='view_cart simple add_to_cart_simple_btn' to={`/cart`}>VIEW CART → </Link> :
                                                                <button className='add_to_cart_simple_btn' onClick={(event) => {
                                                                    event.preventDefault()
                                                                    handleAddToCart(product, event)
                                                                }}>
                                                                    Add to cart
                                                                </button>
                                                        }

                                                    </div> : null
                                            }
                                            {
                                                product?.stock_status == "outofstock" ?
                                                    <p className='outofstock'>
                                                        Out of stock
                                                    </p> : null
                                            }
                                        </div>
                                        <div className="product-short-description">
                                            <h3 className="pro-heading">Detail</h3>
                                            {
                                                product?.type == 'simple' && product?.description?.length > 0 ?
                                                    <div className="pro-desc" dangerouslySetInnerHTML={{ __html: he.decode(product?.description) }} /> : null
                                            }
                                            {
                                                product?.type == 'variable' && typeof selectedVariation !== 'undefined' ?
                                                    <div className="pro-desc" dangerouslySetInnerHTML={{ __html: he.decode(selectedVariation?.name) }} /> : null
                                            }
                                        </div>

                                    </div>
                                </div>
                                {
                                    relatedProducts?.length > 0 ?
                                        <div className="related-product">
                                            <h2>Related</h2>
                                            <div className="card-container gap-10 wrap p-0">
                                                {
                                                    relatedProducts?.map((product, index) => {
                                                        return (
                                                            <ProductCard key={index} product={product} />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div> : null
                                }
                            </div>
                            <div id={`mobile_zoom_modal_wrapper`} className={`${selectedImage?.length > 0 ? 'loaded' : ''}`}>
                                <div id="mobile_zoom_modal">
                                    <div id='mobile_zoom_modal_header'>
                                        <a onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedImage('')
                                            setSelectedZoom(2)
                                            if ($('.is_var_image').length) {
                                                $('#mobile_zoom_modal_wrapper').removeClass('loaded')
                                                $('#mobile_zoom_modal_body').empty().removeAttr('style');
                                            }
                                        }} href='#' className='close_mobile_zoom_modal'>
                                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16.25" cy="16.25" r="15.75" stroke="white"></circle><path d="M10.3845 10.3845L21.5288 21.5287" stroke="white" stroke-linecap="round"></path><path d="M21.5289 10.3845L10.3847 21.5287" stroke="white" stroke-linecap="round"></path></svg>
                                        </a>
                                    </div>
                                    <div id='mobile_zoom_modal_body'>
                                        {
                                            selectedImage ?
                                                <div id="mobile_zoom_image_wrapper">
                                                    <img src={selectedImage} id="mobile_zoom_image" />
                                                </div> : null
                                        }
                                    </div>
                                    <div id='mobile_zoom_modal_footer'>
                                        {
                                            [2, 3, 4, 5].map(zoom => {
                                                return (
                                                    <button className={`mobile_zoom_buttons ${zoom == selectedZoom ? 'selected' : ''}`} onClick={(e) => {
                                                        if ($('.is_var_image').length) {
                                                            $('.mobile_zoom_buttons').removeClass('selected');
                                                            $(e.target).addClass('selected');
                                                            let _zoomPan = Panzoom(document.getElementById('mobile_zoom_image_wrapper'), {
                                                                minScale: zoom,
                                                                pinchAndPan: true,
                                                                maxScale: 6
                                                            });
                                                            window._zoomPan = _zoomPan
                                                            return;
                                                        }
                                                        setSelectedZoom(zoom)
                                                    }}>
                                                        {zoom}x
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        : <p>Invalid Product</p>
                ) : (<RenderPlaceholder />)
            }
        </>
    )
}

export default SingleProduct