import React, { useState } from "react";
import Avatar from '../../assets/images/avatar.svg'
import { logoutAdmin } from "../../redux/slices/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AdminTopbar = () => {
    const dispatch = useDispatch()
    const [openMenu, setOpenMenu] = useState(false)
    const { adminInfo } = useSelector((state) => state.admin);
    const navigate = useNavigate()
    const handleLogout = () => {
        dispatch(logoutAdmin());
        navigate('/')
    };

    return <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
        <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
            <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                <i class="ti ti-menu-2 ti-md"></i>
            </a>
        </div>
        <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
            <ul class="navbar-nav flex-row align-items-center ms-auto">
                <li class="nav-item navbar-dropdown dropdown-user dropdown">
                    <span
                        class="nav-link dropdown-toggle hide-arrow p-0"
                        href="#"
                        data-bs-toggle="dropdown"
                        onClick={(e) => {
                            e.preventDefault();
                            setOpenMenu(!openMenu)
                        }}
                    >
                        <div class="avatar">
                            <img src={Avatar} alt class="rounded-circle" />
                        </div>
                    </span>
                    <ul class={`dropdown-menu dropdown-menu-end ${openMenu ? 'show' : ''}`}
                        style={{ right: 0 }}
                    >
                        <li>
                            <span class="dropdown-item mt-0" href="#">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 me-2">
                                        <div class="avatar">
                                            <img src={Avatar} alt class="rounded-circle" />
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h6 class="mb-0" style={{
                                            textTransform: 'capitalize',
                                        }}>
                                            {adminInfo?.fname} {adminInfo?.lname}
                                        </h6>
                                        <small class="text-muted">Admin</small>
                                    </div>
                                </div>
                            </span>
                        </li>
                        <li>
                            <div class="dropdown-divider my-1 mx-n2"></div>
                        </li>
                        {/* <li>
                            <a class="dropdown-item" href="#">
                                <i class="ti ti-user me-3 ti-md"></i><span class="align-middle">My Profile</span>
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="#">
                                <i class="ti ti-settings me-3 ti-md"></i><span class="align-middle">Settings</span>
                            </a>
                        </li> */}

                        {/* <li>
                            <div class="dropdown-divider my-1 mx-n2"></div>
                        </li> */}

                        <li>
                            <div class="d-grid px-2 pt-2 pb-1">
                                <a class="btn btn-sm btn-danger d-flex" href="#" onClick={handleLogout}>
                                    <small class="align-middle">Logout</small>
                                    <i class="ti ti-logout ms-2 ti-14px"></i>
                                </a>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="navbar-search-wrapper search-input-wrapper d-none">
            <input
                type="text"
                class="form-control search-input container-xxl border-0"
                placeholder="Search..."
                aria-label="Search..." />
            <i class="ti ti-x search-toggler cursor-pointer"></i>
        </div>
    </nav>
}

export default AdminTopbar