import React, { useEffect, useState } from "react";
import he from 'he';
import { updateTerm } from "../../api/adminapi";
import { showToast, tagsMapping } from "../../utils/utils";
import Input from "../Input";
import Button from "../Button";

const stripHtml = (html) => {
    return html?.replace(/<\/?[^>]+(>|$)/g, "");
}

const EditTagCanvas = ({ taxonomy, term, isOpen, onClose, refresh }) => {
    const [processing, setProcessing] = useState(false)
    let term_id = term?.term_id
    const isEditing = term_id > 0 ? true : false

    const [formData, setFormData] = useState({
        term_id: term_id ? term_id : 0,
        slug: term?.slug ? term?.slug : '',
        taxonomy: taxonomy,
        description: term?.description ? term?.description : '',
        name: term?.name ? term?.name : '',
    })

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        if (processing)
            return;
        if (!formData?.name?.trim().length) {
            showToast('Title is required', 'error');
            return;
        }
        if (!formData.taxonomy)
            formData.taxonomy = taxonomy;
        setProcessing(true)
        await updateTerm(formData).then(response => {
            if (response.status == 200 || response.status == 201) {
                showToast(response.data.message);
                onClose()
                resetForm();
                refresh && refresh();
                return;
            }
            showToast(response.data.message, 'error');
        }).catch(err => {
            showToast(err, 'error');
        }).finally(() => {
            setProcessing(false)
        })

    }

    useEffect(() => {
        if (term_id) {
            setFormData({
                name: term?.name,
                description: term?.description,
                taxonomy: term?.taxonomy,
                term_id: term?.term_id,
                slug: term?.slug,
            })
            return;
        }
        setProcessing(false)
    }, [term_id])

    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            taxonomy: '',
            term_id: 0,
            slug: '',
        })
    }

    return (
        <>
            <div className={`offcanvas-overlay ${isOpen ?  'enabled' : ''}`}></div>
            <div class={`offcanvas offcanvas-end ${isOpen ? 'show' : ''}`} tabindex="-1" id="offcanvasEcommerceCategoryList" aria-labelledby="offcanvasEcommerceCategoryListLabel">
                <div class="offcanvas-header py-6">
                    <h5 id="offcanvasEcommerceCategoryListLabel" class="offcanvas-title">{isEditing ? 'Save' : 'Add'} {tagsMapping[taxonomy]}</h5>
                    <Button
                        btnType='close'
                        onClick={() => {
                            if (processing)
                                return;
                            onClose()
                            resetForm();
                        }}
                        className='mr-0'
                    />
                </div>
                <div class="offcanvas-body border-top">
                    <form class={`pt-0 ${processing ? 'processing' : ''}`} id="eCommerceCategoryListForm" onsubmit="return true" onSubmit={(e) => { handleFormSubmit(e) }}>
                        <div class="mb-6">
                            <Input onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    name: e.target.value,
                                })
                            }} label='Title' name='categoryTitle' value={he.decode(formData.name ? formData.name : '')}
                            />
                        </div>
                        <div class="mb-6">
                            <Input onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    slug: e.target.value,
                                })
                            }} label='Slug' value={formData.slug} name='slug'
                                readOnly={true}
                            />
                        </div>
                        <div class="mb-6">
                            <Input
                                type="textarea"
                                label={'Description'}
                                value={he.decode(stripHtml(formData?.description ? formData?.description : ''))}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        description: e.target.value,
                                    })
                                }}
                            />
                        </div>
                        <div class="mb-6">
                            <Button type="submit" text={isEditing ? 'Save' : 'Add'} />
                            <Button text='Discard' onClick={onClose} btnType='label-danger' />
                        </div>
                        <Input type="hidden" name='term_id' value={isEditing ? term_id : 0} />
                        <Input type="hidden" name="is_editing" value={isEditing ? 'yes' : ''} />
                    </form>
                </div>
            </div>
        </>
    )
}
export default EditTagCanvas