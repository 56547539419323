const Input = ({ value, onChange, placeholder = 'Enter text', label, type = 'text', name, readOnly = false, className, checked, id, required, labelClass }) => {
    return <>
        {
            label && (
                <label
                    class={`${labelClass ? labelClass : 'form-label'}`}
                    htmlFor={id}
                >{label}</label>
            )
        }
        {
            type == 'textarea' ?
                <textarea
                    className={`form-control ${className}`}
                    onChange={onChange}
                    value={value}
                    rows={10}
                /> :
                <input
                    {...(id ? { id: id } : '')}
                    {...(readOnly ? { readOnly: true } : '')}
                    type={type}
                    className={`form-control ${className} ${type == 'checkbox' ? 'form-check-input' : ''}`}
                    placeholder={placeholder} name={name}
                    value={value}
                    onChange={onChange}
                    {...(checked ? { checked: true } : '')}
                    {...(required ? { required: true } : '')}
                />
        }
    </>
}
export default Input;