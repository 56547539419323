import './login.css'
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkStoredUser, loginAdmin } from '../../redux/slices/adminSlice';
import Logo from '../../assets/images/logo.png'
import { showToast } from '../../utils/utils';

const AdminLogin = () => {

    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    })

    useEffect(() => {
        dispatch(checkStoredUser());
    }, []);

    const handleFormSubmit = () => {
        if (!formData.username.length || !formData.password.length || isLoading)
            return
        setIsLoading(true)
        dispatch(loginAdmin(formData))
    }

    const { isAuthenticated, error } = useSelector((state) => state.admin);

    useEffect(() => {
        if (error) {
            showToast(error, 'error');
            setIsLoading(false)
        }
    }, [error])

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/admin')
        }
    }, [isAuthenticated])

    return <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-6">
            <div className="admin-card">
                <div className="admin-card-body">
                    <div className="app-brand justify-content-center mb-6">
                        <span className="app-brand-logo demo">
                            <img width={150} src={Logo} />
                        </span>
                    </div>
                    <form id="formAuthentication" className="mb-4" onSubmit={(event) => {
                        event.preventDefault()
                        handleFormSubmit()
                    }}>
                        <div className="mb-6">
                            <label htmlFor="email" className="form-label">Username or Email Address</label>
                            <input
                                value={formData.username}
                                onInput={(event) => {
                                    setFormData({
                                        ...formData,
                                        username: event.target.value.trim()
                                    })
                                }}
                                type="text"
                                className="form-control"
                                id="email"
                                name="email-username"
                                placeholder=""
                                autoFocus />
                        </div>
                        <div className="mb-6 form-password-toggle">
                            <label className="form-label" htmlFor="password">Password</label>
                            <div className="input-group input-group-merge">
                                <input
                                    value={formData.password}
                                    onInput={(event) => {
                                        setFormData({
                                            ...formData,
                                            password: event.target.value.trim()
                                        })
                                    }}
                                    type={passwordVisible ? 'text' : 'password'}
                                    id="password"
                                    className="form-control"
                                    name="password"
                                    placeholder=""
                                    aria-describedby="password" />
                                <span className="input-group-text cursor-pointer"
                                    onClick={() => {
                                        setPasswordVisible((prev) => !prev);
                                    }}
                                ><i className={`ti ti-eye${!passwordVisible ? '' : '-off'}`}></i></span>
                            </div>
                        </div>
                        <div className="my-8">
                            <div className="d-flex justify-content-between">
                                <div className="form-check mb-0 ms-2">
                                    <input className="form-check-input" type="checkbox" id="remember-me" />
                                    <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
                                </div>
                                {/* <a href="auth-forgot-password-basic.html">
                                        <p className="mb-0">Lost your password?</p>
                                    </a> */}
                            </div>
                        </div>
                        <div className="mb-6">
                            <button style={{
                                margin: 'auto'
                            }} className={`btn btn-primary d-flex gap-1 w-100`} type="submit">
                                {isLoading ?
                                    <span className="spinner-border me-1" role="status" aria-hidden="true"></span> : 'Login'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}

export default AdminLogin