import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import './orderdetails.css'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { scrollToTop } from '../../../../utils/utils';
import zelle from '../../../../assets/images/zelle.png'
import { QRCodeSVG } from 'qrcode.react'
import { getZelleDetails_API } from '../../../../api/api';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

function ViewOrderDetails() {
    let { id } = useParams()
    const navigate = useNavigate()
    const [order, setOrder] = useState(null)
    const location = useLocation()
    useEffect(() => {
        if( location?.state?.checkoutData ){
            setOrder(location?.state?.checkoutData)
        }else if (parseInt(id) > 0) {
            id = parseInt(id)
            const fetchOrder = async () => {
                await axios.get(process.env.REACT_APP_WC_API_BASE + `orders/${id}`, {
                    headers: {
                        'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    if (200 == res?.status && res?.data) {
                        setOrder(res?.data)
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
            fetchOrder()
        } else {
            // console.log('dsadsad')
        }
        scrollToTop()
    }, [])
    
    let _itemsTotal = 0;
    if( order && Array.isArray( order?.line_items ) && order?.line_items?.length ){
        order?.line_items?.map(line_item=>{
            _itemsTotal += parseFloat( line_item?.subtotal )
        })
    }

    const [zell, setIsZelle] = useState(false);
    const [zellLoading, setIsZelleLoading] = useState(false);
    const [zelleSettings, setZelleSettings] = useState({})

    const fetchZelleDetails = async () => {
        setIsZelleLoading(true);
        await getZelleDetails_API().then(response => {
            if (response.status == 200) {
                const _data = response.data;
                if (_data?.settings) {
                    setZelleSettings(_data?.settings);
                }
            }
        }).catch((error) => {
            console.warn(error);
        }).finally(() => {
            setIsZelleLoading(false)
        });
    }

    useEffect(() => {
        if (order && order?.payment_method == 'zelle') {
            setIsZelle(true);
            fetchZelleDetails();
        }
    }, [order]);

    return (
        <>
            {
                order ?
                    <div className="container wide">
                        <nav className="breadcrumbs">
                            <span className="current">Accounts</span> | <span href="#" className="current">My Orders</span>
                            <span href="#" className="hide-for-small"> | Orders Details</span>
                            <span className="divider "></span>
                        </nav>
                        <h2 className="heading-orders">Orders Details</h2>
                        <div className="order-id">
                            <span className="link-order" href="#">Ordered on {formatDate(order?.date_created_gmt)}</span>
                            <span className="link-order" href="#"> | Order #{order?.id}</span>
                        </div>
                        {
                            zell && Object.keys(zelleSettings)?.length > 0 && (
                                <div class="zelle_order_summary"
                                    style={{
                                        display: 'flex',
                                        padding: 20,
                                        backgroundColor: '#fff',
                                        borderRadius: 12,
                                        marginTop: 20,
                                        gap: 20
                                    }}
                                >
                                    <div>
                                        <img width={120} src={zelle} />
                                        <p>Please send the total amount requested to our store if you haven't yet</p>
                                        <p>Send <strong>${order?.total}</strong> via <a href="https://zellepay.com/" target="_blank">Zelle</a> or from your bank.</p>
                                        <p>Here are the Zelle details you should know for the transfer:</p>
                                        <p>Zelle Name: <strong>{zelleSettings?.ReceiverZelleOwner}</strong></p>
                                        <p>Zelle Email: <strong>{zelleSettings?.ReceiverZELLEEmail}</strong></p>
                                        <p>Zelle Phone: <strong>{zelleSettings?.ReceiverZELLENo}</strong></p>
                                        <p>We are checking our systems to confirm that we received. If you haven't sent the money already, please make sure to do so now.</p>
                                        <p>Once confirmed, we will proceed with the shipping and delivery options you chose.</p>
                                        <p>Thank you for doing business with us! You will be updated regarding your order details soon.</p>
                                    </div>
                                    <div>
                                        <QRCodeSVG size={250} value={`Name: ${zelleSettings?.ReceiverZelleOwner}, Email: ${zelleSettings?.ReceiverZELLEEmail}, Phone: ${zelleSettings?.ReceiverZELLENo}, Amount: $${order?.total}`} />
                                    </div>
                                </div>
                            )
                        }
                        <div className="order-summary">
                            <div className="order-header-single wrap-small">
                                <div className="order-left full-width-small wrap-small">
                                    <div className="order-date">
                                        <span className="order-shipping-title">Shipping Address</span>
                                        <div className="left-detail">
                                            <span className="total-order-info">{order?.shipping?.first_name} {order?.shipping?.last_name}</span>
                                            <span className="total-order-info">{order?.shipping?.address_1}</span>
                                            <span className="total-order-info">{order?.shipping?.city}, {order?.shipping?.state} {order?.shipping?.postcode}</span>
                                            <span className="total-order-info">{order?.shipping?.country}</span>
                                            <span className="total-order-info">Phone: {order?.billing?.phone}</span>
                                        </div>
                                    </div>
                                    <div className="order-total direction-col gap-10">
                                        <span className="order-shipping-title">Payment Information</span>
                                        <span className="order-shipping-subtitle">Payment Method</span>
                                        <span className="total-order-info">{order?.payment_method_title}</span>
                                        <span className="order-shipping-subtitle">Billing Address</span>
                                        {
                                            order?.billing?.company ? 
                                            <span className="order-shipping-subtitle">{order?.billing?.company}</span> : null
                                        }
                                        <span className="total-order-info">{order?.billing?.first_name} {order?.billing?.last_name}</span>
                                        <span className="total-order-info" >{order?.shipping?.address_1}</span>
                                        <span className="total-order-info">{order?.billing?.city}, {order?.billing?.state} {order?.billing?.postcode}</span>
                                        <span className="total-order-info">{order?.billing?.country}</span>
                                        <span className="total-order-info">Phone: {order?.billing?.phone}</span>
                                    </div>
                                </div>
                                <div className="order-right full-width-small">
                                    <div className="order-id">
                                        <span className="order-shipping-title">Order Summary</span>
                                        <div className="main-details-div">
                                            <div className="left-detail">
                                                <span className="total-order-info" href="#">Item(s) Subtotal:</span><br />
                                                <span className="total-order-info">Shipping & Handling:</span> <br />
                                                {
                                                    typeof order?.coupon_lines != 'undefined' && Array.isArray(order?.coupon_lines) && order?.coupon_lines?.length ?
                                                        <>
                                                            <span className="total-order-info">Discount:</span> <br />
                                                        </>
                                                        : null
                                                }
                                                <span className="total-order-info">Total before tax:</span> <br />
                                                <span className="total-order-info">Estimated tax to be collected:</span> <br />
                                                <span className="total-order-info">Grand Total</span>
                                            </div>
                                            <div className="right-detail">
                                                <span className="total-order-info">${parseFloat(_itemsTotal).toFixed(2)}</span><br />
                                                <span className="total-order-info">${order?.shipping_total}</span><br />
                                                {
                                                    typeof order?.coupon_lines != 'undefined' && Array.isArray(order?.coupon_lines) && order?.coupon_lines?.length ? 
                                                    <>
                                                        <span className="total-order-info">-${parseFloat( order?.coupon_lines[0]?.discount ).toFixed(2)} ({order?.coupon_lines[0]?.code})</span><br />
                                                    </>
                                                    : null
                                                }
                                                <span className="total-order-info">${order?.total}</span><br />
                                                <span className="total-order-info">${order?.total_tax}</span><br />
                                                <span className="total-order-info">${order?.total}</span><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="order-summary details">
                            <div className="order-header-det wrap-small">
                                <div className="order-left-side full-width-small">
                                    <div className="order-date2 full-width-small">
                                        <span className="order-date-title">ORDER PLACED</span>
                                        <span className="order-date-dec">{formatDate(order?.date_created_gmt)}</span>
                                    </div>
                                    <div className="order-total1">
                                        <span className="order-date-title">TOTAL</span>
                                        <span className="order-date-dec">${order?.total}</span>
                                    </div>
                                    <div className="order-ship-to">
                                        <select className="month-dropdown" name="ship tp" id="months">
                                            <option value="john" selected>SHIP TO</option>
                                        </select>
                                        <span className="order-date-dec">{order?.shipping?.first_name} {order?.shipping?.last_name}</span>
                                    </div>
                                </div>
                                <div className="order-right-side full-width-small">
                                    <div className="order-id">
                                        <span className="orderid-title text-right">ORDER #{order?.id}</span>
                                        <Link className="link-order" to={`/checkout/order-received/${order?.id}`}>View order details</Link>
                                    </div> 
                                </div>
                            </div>
                            <div className="order-content wrap-small">
                                <div className="header-content-left">
                                    <span className="left-content-heading mb-0-small">{order?.status}</span>
                                    <div className="item">
                                        <img src={order?.line_items[0]?.image?.src} alt="" />
                                        <div className="product-details">
                                            {
                                                order?.line_items?.length > 0 ?
                                                    <ul className='order_items'>
                                                        {
                                                            order?.line_items?.map(item => {
                                                                return (
                                                                    <li>
                                                                        {item?.name}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="header-content-right full-width-small">
                                    <div className="item-details2 full-width-small">
                                        <button className="buy-btn">Buy it again</button>
                                        <button className="view-item">View your item</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
            }
        </>
    )
}

export default ViewOrderDetails