import axios from "axios";
import toast from "react-hot-toast";

const CryptoJS = require("crypto-js")
export function getInventoryID(inventory_name) {
    let inventory_id = 0
    switch (inventory_name) {
        case 'baseball':
            inventory_id = 8047
            break;
        case 'basketball':
            inventory_id = 8348
            break;
        case 'boxing':
            inventory_id = 8774
            break;
        case 'comics':
            inventory_id = 8782
            break;
        case 'football':
            inventory_id = 8095
            break;
        case 'hockey':
            inventory_id = 8412
            break;
        case 'memorabilia':
            inventory_id = 8911
            break;
        case 'non-sport':
            inventory_id = 8726
            break;
        case 'un-opened':
            inventory_id = 8912
            break;
        case 'sets':
            inventory_id = 8794
            break;
    }
    return inventory_id;
}

export const productDataTabs = [
    {
        title: 'General',
        id: 'general',
    },
    {
        title: 'Inventory',
        id: 'inventory',
    }, {
        title: 'Attributes',
        id: 'attributes',
    }, {
        title: 'Variations',
        id: 'variations',
    }, {
        title: 'Price adjustment',
        id: 'price_adjustment',
    },
]

export const countriesStates = {
    "AF": {
        "states": [],
        "name": "Afghanistan"
    },
    "AX": {
        "states": [],
        "name": "Åland Islands"
    },
    "AL": {
        "states": [
            { key: "AL-01", value: "Berat" },
            { key: "AL-09", value: "Dibër" },
            { key: "AL-02", value: "Durrës" },
            { key: "AL-03", value: "Elbasan" },
            { key: "AL-04", value: "Fier" },
            { key: "AL-05", value: "Gjirokastër" },
            { key: "AL-06", value: "Korçë" },
            { key: "AL-07", value: "Kukës" },
            { key: "AL-08", value: "Lezhë" },
            { key: "AL-10", value: "Shkodër" },
            { key: "AL-11", value: "Tirana" },
            { key: "AL-12", value: "Vlorë" }
        ],
        "name": "Albania"
    },
    "DZ": {
        "states": [
            { key: "DZ-01", value: "Adrar" },
            { key: "DZ-02", value: "Chlef" },
            { key: "DZ-03", value: "Laghouat" },
            { key: "DZ-04", value: "Oum El Bouaghi" },
            { key: "DZ-05", value: "Batna" },
            { key: "DZ-06", value: "Béjaïa" },
            { key: "DZ-07", value: "Biskra" },
            { key: "DZ-08", value: "Béchar" },
            { key: "DZ-09", value: "Blida" },
            { key: "DZ-10", value: "Bouira" },
            { key: "DZ-11", value: "Tamanghasset" },
            { key: "DZ-12", value: "Tébessa" },
            { key: "DZ-13", value: "Tlemcen" },
            { key: "DZ-14", value: "Tiaret" },
            { key: "DZ-15", value: "Tizi Ouzou" },
            { key: "DZ-16", value: "Algiers" },
            { key: "DZ-17", value: "Djelfa" },
            { key: "DZ-18", value: "Jijel" },
            { key: "DZ-19", value: "Sétif" },
            { key: "DZ-20", value: "Saïda" },
            { key: "DZ-21", value: "Skikda" },
            { key: "DZ-22", value: "Sidi Bel Abbès" },
            { key: "DZ-23", value: "Annaba" },
            { key: "DZ-24", value: "Guelma" },
            { key: "DZ-25", value: "Constantine" },
            { key: "DZ-26", value: "Médéa" },
            { key: "DZ-27", value: "Mostaganem" },
            { key: "DZ-28", value: "M’Sila" },
            { key: "DZ-29", value: "Mascara" },
            { key: "DZ-30", value: "Ouargla" },
            { key: "DZ-31", value: "Oran" },
            { key: "DZ-32", value: "El Bayadh" },
            { key: "DZ-33", value: "Illizi" },
            { key: "DZ-34", value: "Bordj Bou Arréridj" },
            { key: "DZ-35", value: "Boumerdès" },
            { key: "DZ-36", value: "El Tarf" },
            { key: "DZ-37", value: "Tindouf" },
            { key: "DZ-38", value: "Tissemsilt" },
            { key: "DZ-39", value: "El Oued" },
            { key: "DZ-40", value: "Khenchela" },
            { key: "DZ-41", value: "Souk Ahras" },
            { key: "DZ-42", value: "Tipasa" },
            { key: "DZ-43", value: "Mila" },
            { key: "DZ-44", value: "Aïn Defla" },
            { key: "DZ-45", value: "Naama" },
            { key: "DZ-46", value: "Aïn Témouchent" },
            { key: "DZ-47", value: "Ghardaïa" },
            { key: "DZ-48", value: "Relizane" }
        ],
        "name": "Algeria"
    },
    "AS": {
        "states": [],
        "name": "American Samoa"
    },
    "AD": {
        "states": [],
        "name": "Andorra"
    },
    "AO": {
        "states": [
            { key: "BGO", value: "Bengo" },
            { key: "BLU", value: "Benguela" },
            { key: "BIE", value: "Bié" },
            { key: "CAB", value: "Cabinda" },
            { key: "CNN", value: "Cunene" },
            { key: "HUA", value: "Huambo" },
            { key: "HUI", value: "Huíla" },
            { key: "CCU", value: "Kuando Kubango" },
            { key: "CNO", value: "Kwanza-Norte" },
            { key: "CUS", value: "Kwanza-Sul" },
            { key: "LUA", value: "Luanda" },
            { key: "LNO", value: "Lunda-Norte" },
            { key: "LSU", value: "Lunda-Sul" },
            { key: "MAL", value: "Malanje" },
            { key: "MOX", value: "Moxico" },
            { key: "NAM", value: "Namibe" },
            { key: "UIG", value: "Uíge" },
            { key: "ZAI", value: "Zaire" }
        ],
        "name": "Angola"
    },
    "AI": {
        "states": [],
        "name": "Anguilla"
    },
    "AQ": {
        "states": [],
        "name": "Antarctica"
    },
    "AG": {
        "states": [],
        "name": "Antigua and Barbuda"
    },
    "AR": {
        "states": [
            { key: "C", value: "Ciudad Autónoma de Buenos Aires" },
            { key: "B", value: "Buenos Aires" },
            { key: "K", value: "Catamarca" },
            { key: "H", value: "Chaco" },
            { key: "U", value: "Chubut" },
            { key: "X", value: "Córdoba" },
            { key: "W", value: "Corrientes" },
            { key: "E", value: "Entre Ríos" },
            { key: "P", value: "Formosa" },
            { key: "Y", value: "Jujuy" },
            { key: "L", value: "La Pampa" },
            { key: "F", value: "La Rioja" },
            { key: "M", value: "Mendoza" },
            { key: "N", value: "Misiones" },
            { key: "Q", value: "Neuquén" },
            { key: "R", value: "Río Negro" },
            { key: "A", value: "Salta" },
            { key: "J", value: "San Juan" },
            { key: "D", value: "San Luis" },
            { key: "Z", value: "Santa Cruz" },
            { key: "S", value: "Santa Fe" },
            { key: "G", value: "Santiago del Estero" },
            { key: "V", value: "Tierra del Fuego" },
            { key: "T", value: "Tucumán" }
        ],
        "name": "Argentina"
    },
    "AM": {
        "states": [],
        "name": "Armenia"
    },
    "AW": {
        "states": [],
        "name": "Aruba"
    },
    "AU": {
        "states": [
            { key: "ACT", value: "Australian Capital Territory" },
            { key: "NSW", value: "New South Wales" },
            { key: "NT", value: "Northern Territory" },
            { key: "QLD", value: "Queensland" },
            { key: "SA", value: "South Australia" },
            { key: "TAS", value: "Tasmania" },
            { key: "VIC", value: "Victoria" },
            { key: "WA", value: "Western Australia" }
        ],
        "name": "Australia"
    },
    "AT": {
        "states": [],
        "name": "Austria"
    },
    "AZ": {
        "states": [],
        "name": "Azerbaijan"
    },
    "BS": {
        "states": [],
        "name": "Bahamas"
    },
    "BH": {
        "states": [],
        "name": "Bahrain"
    },
    "BD": {
        "states": [
            { key: "BD-05", value: "Bagerhat" },
            { key: "BD-01", value: "Bandarban" },
            { key: "BD-02", value: "Barguna" },
            { key: "BD-06", value: "Barishal" },
            { key: "BD-07", value: "Bhola" },
            { key: "BD-03", value: "Bogura" },
            { key: "BD-04", value: "Brahmanbaria" },
            { key: "BD-09", value: "Chandpur" },
            { key: "BD-10", value: "Chattogram" },
            { key: "BD-12", value: "Chuadanga" },
            { key: "BD-11", value: "Cox's Bazar" },
            { key: "BD-08", value: "Cumilla" },
            { key: "BD-13", value: "Dhaka" },
            { key: "BD-14", value: "Dinajpur" },
            { key: "BD-15", value: "Faridpur " },
            { key: "BD-16", value: "Feni" },
            { key: "BD-19", value: "Gaibandha" },
            { key: "BD-18", value: "Gazipur" },
            { key: "BD-17", value: "Gopalganj" },
            { key: "BD-20", value: "Habiganj" },
            { key: "BD-21", value: "Jamalpur" },
            { key: "BD-22", value: "Jashore" },
            { key: "BD-25", value: "Jhalokati" },
            { key: "BD-23", value: "Jhenaidah" },
            { key: "BD-24", value: "Joypurhat" },
            { key: "BD-29", value: "Khagrachhari" },
            { key: "BD-27", value: "Khulna" },
            { key: "BD-26", value: "Kishoreganj" },
            { key: "BD-28", value: "Kurigram" },
            { key: "BD-30", value: "Kushtia" },
            { key: "BD-31", value: "Lakshmipur" },
            { key: "BD-32", value: "Lalmonirhat" },
            { key: "BD-36", value: "Madaripur" },
            { key: "BD-37", value: "Magura" },
            { key: "BD-33", value: "Manikganj " },
            { key: "BD-39", value: "Meherpur" },
            { key: "BD-38", value: "Moulvibazar" },
            { key: "BD-35", value: "Munshiganj" },
            { key: "BD-34", value: "Mymensingh" },
            { key: "BD-48", value: "Naogaon" },
            { key: "BD-43", value: "Narail" },
            { key: "BD-40", value: "Narayanganj" },
            { key: "BD-42", value: "Narsingdi" },
            { key: "BD-44", value: "Natore" },
            { key: "BD-45", value: "Nawabganj" },
            { key: "BD-41", value: "Netrakona" },
            { key: "BD-46", value: "Nilphamari" },
            { key: "BD-47", value: "Noakhali" },
            { key: "BD-49", value: "Pabna" },
            { key: "BD-52", value: "Panchagarh" },
            { key: "BD-51", value: "Patuakhali" },
            { key: "BD-50", value: "Pirojpur" },
            { key: "BD-53", value: "Rajbari" },
            { key: "BD-54", value: "Rajshahi" },
            { key: "BD-56", value: "Rangamati" },
            { key: "BD-55", value: "Rangpur" },
            { key: "BD-58", value: "Satkhira" },
            { key: "BD-62", value: "Shariatpur" },
            { key: "BD-57", value: "Sherpur" },
            { key: "BD-59", value: "Sirajganj" },
            { key: "BD-61", value: "Sunamganj" },
            { key: "BD-60", value: "Sylhet" },
            { key: "BD-63", value: "Tangail" },
            { key: "BD-64", value: "Thakurgaon" }
        ],
        "name": "Bangladesh"
    },
    "BB": {
        "states": [],
        "name": "Barbados"
    },
    "BY": {
        "states": [],
        "name": "Belarus"
    },
    "PW": {
        "states": [],
        "name": "Belau"
    },
    "BE": {
        "states": [],
        "name": "Belgium"
    },
    "BZ": {
        "states": [],
        "name": "Belize"
    },
    "BJ": {
        "states": [
            { key: "AL", value: "Alibori" },
            { key: "AK", value: "Atakora" },
            { key: "AQ", value: "Atlantique" },
            { key: "BO", value: "Borgou" },
            { key: "CO", value: "Collines" },
            { key: "KO", value: "Kouffo" },
            { key: "DO", value: "Donga" },
            { key: "LI", value: "Littoral" },
            { key: "MO", value: "Mono" },
            { key: "OU", value: "Ouémé" },
            { key: "PL", value: "Plateau" },
            { key: "ZO", value: "Zou" }
        ],
        "name": "Benin"
    },
    "BM": {
        "states": [],
        "name": "Bermuda"
    },
    "BT": {
        "states": [],
        "name": "Bhutan"
    },
    "BO": {
        "states": [
            { key: "BO-B", value: "Beni" },
            { key: "BO-H", value: "Chuquisaca" },
            { key: "BO-C", value: "Cochabamba" },
            { key: "BO-L", value: "La Paz" },
            { key: "BO-O", value: "Oruro" },
            { key: "BO-N", value: "Pando" },
            { key: "BO-P", value: "Potosí" },
            { key: "BO-S", value: "Santa Cruz" },
            { key: "BO-T", value: "Tarija" }
        ],
        "name": "Bolivia"
    },
    "BQ": {
        "states": [],
        "name": "Bonaire, Saint Eustatius and Saba"
    },
    "BA": {
        "states": [],
        "name": "Bosnia and Herzegovina"
    },
    "BW": {
        "states": [],
        "name": "Botswana"
    },
    "BV": {
        "states": [],
        "name": "Bouvet Island"
    },
    "BR": {
        "states": [
            { key: "AC", value: "Acre" },
            { key: "AL", value: "Alagoas" },
            { key: "AP", value: "Amapá" },
            { key: "AM", value: "Amazonas" },
            { key: "BA", value: "Bahia" },
            { key: "CE", value: "Ceará" },
            { key: "DF", value: "Distrito Federal" },
            { key: "ES", value: "Espírito Santo" },
            { key: "GO", value: "Goiás" },
            { key: "MA", value: "Maranhão" },
            { key: "MT", value: "Mato Grosso" },
            { key: "MS", value: "Mato Grosso do Sul" },
            { key: "MG", value: "Minas Gerais" },
            { key: "PA", value: "Pará" },
            { key: "PB", value: "Paraíba" },
            { key: "PR", value: "Paraná" },
            { key: "PE", value: "Pernambuco" },
            { key: "PI", value: "Piauí" },
            { key: "RJ", value: "Rio de Janeiro" },
            { key: "RN", value: "Rio Grande do Norte" },
            { key: "RS", value: "Rio Grande do Sul" },
            { key: "RO", value: "Rondônia" },
            { key: "RR", value: "Roraima" },
            { key: "SC", value: "Santa Catarina" },
            { key: "SP", value: "São Paulo" },
            { key: "SE", value: "Sergipe" },
            { key: "TO", value: "Tocantins" }
        ],
        "name": "Brazil"
    },
    "IO": {
        "states": [],
        "name": "British Indian Ocean Territory"
    },
    "BN": {
        "states": [],
        "name": "Brunei"
    },
    "BG": {
        "states": [
            { key: "BG-01", value: "Blagoevgrad" },
            { key: "BG-02", value: "Burgas" },
            { key: "BG-08", value: "Dobrich" },
            { key: "BG-07", value: "Gabrovo" },
            { key: "BG-26", value: "Haskovo" },
            { key: "BG-09", value: "Kardzhali" },
            { key: "BG-10", value: "Kyustendil" },
            { key: "BG-11", value: "Lovech" },
            { key: "BG-12", value: "Montana" },
            { key: "BG-13", value: "Pazardzhik" },
            { key: "BG-14", value: "Pernik" },
            { key: "BG-15", value: "Pleven" },
            { key: "BG-16", value: "Plovdiv" },
            { key: "BG-17", value: "Razgrad" },
            { key: "BG-18", value: "Ruse" },
            { key: "BG-27", value: "Shumen" },
            { key: "BG-19", value: "Silistra" },
            { key: "BG-20", value: "Sliven" },
            { key: "BG-21", value: "Smolyan" },
            { key: "BG-23", value: "Sofia District" },
            { key: "BG-22", value: "Sofia" },
            { key: "BG-24", value: "Stara Zagora" },
            { key: "BG-25", value: "Targovishte" },
            { key: "BG-03", value: "Varna" },
            { key: "BG-04", value: "Veliko Tarnovo" },
            { key: "BG-05", value: "Vidin" },
            { key: "BG-06", value: "Vratsa" },
            { key: "BG-28", value: "Yambol" }
        ],
        "name": "Bulgaria"
    },
    "BF": {
        "states": [],
        "name": "Burkina Faso"
    },
    "BI": {
        "states": [],
        "name": "Burundi"
    },
    "KH": {
        "states": [],
        "name": "Cambodia"
    },
    "CM": {
        "states": [],
        "name": "Cameroon"
    },
    "CA": {
        "states": [
            { key: "AB", value: "Alberta" },
            { key: "BC", value: "British Columbia" },
            { key: "MB", value: "Manitoba" },
            { key: "NB", value: "New Brunswick" },
            { key: "NL", value: "Newfoundland and Labrador" },
            { key: "NT", value: "Northwest Territories" },
            { key: "NS", value: "Nova Scotia" },
            { key: "NU", value: "Nunavut" },
            { key: "ON", value: "Ontario" },
            { key: "PE", value: "Prince Edward Island" },
            { key: "QC", value: "Quebec" },
            { key: "SK", value: "Saskatchewan" },
            { key: "YT", value: "Yukon Territory" }
        ],
        "name": "Canada"
    },
    "CV": {
        "states": [],
        "name": "Cape Verde"
    },
    "KY": {
        "states": [],
        "name": "Cayman Islands"
    },
    "CF": {
        "states": [],
        "name": "Central African Republic"
    },
    "TD": {
        "states": [],
        "name": "Chad"
    },
    "CL": {
        "states": [
            { key: "CL-AI", value: "Aisén del General Carlos Ibañez del Campo" },
            { key: "CL-AN", value: "Antofagasta" },
            { key: "CL-AP", value: "Arica y Parinacota" },
            { key: "CL-AR", value: "La Araucanía" },
            { key: "CL-AT", value: "Atacama" },
            { key: "CL-BI", value: "Biobío" },
            { key: "CL-CO", value: "Coquimbo" },
            { key: "CL-LI", value: "Libertador General Bernardo O'Higgins" },
            { key: "CL-LL", value: "Los Lagos" },
            { key: "CL-LR", value: "Los Ríos" },
            { key: "CL-MA", value: "Magallanes" },
            { key: "CL-ML", value: "Maule" },
            { key: "CL-NB", value: "Ñuble" },
            { key: "CL-RM", value: "Región Metropolitana de Santiago" },
            { key: "CL-TA", value: "Tarapacá" },
            { key: "CL-VS", value: "Valparaíso" }
        ],
        "name": "Chile"
    },
    "CN": {
        "states": [
            { key: "CN1", value: "Yunnan / 云南" },
            { key: "CN2", value: "Beijing / 北京" },
            { key: "CN3", value: "Tianjin / 天津" },
            { key: "CN4", value: "Hebei / 河北" },
            { key: "CN5", value: "Shanxi / 山西" },
            { key: "CN6", value: "Inner Mongolia / 內蒙古" },
            { key: "CN7", value: "Liaoning / 辽宁" },
            { key: "CN8", value: "Jilin / 吉林" },
            { key: "CN9", value: "Heilongjiang / 黑龙江" },
            { key: "CN10", value: "Shanghai / 上海" },
            { key: "CN11", value: "Jiangsu / 江苏" },
            { key: "CN12", value: "Zhejiang / 浙江" },
            { key: "CN13", value: "Anhui / 安徽" },
            { key: "CN14", value: "Fujian / 福建" },
            { key: "CN15", value: "Jiangxi / 江西" },
            { key: "CN16", value: "Shandong / 山东" },
            { key: "CN17", value: "Henan / 河南" },
            { key: "CN18", value: "Hubei / 湖北" },
            { key: "CN19", value: "Hunan / 湖南" },
            { key: "CN20", value: "Guangdong / 广东" },
            { key: "CN21", value: "Guangxi Zhuang / 广西壮族" },
            { key: "CN22", value: "Hainan / 海南" },
            { key: "CN23", value: "Chongqing / 重庆" },
            { key: "CN24", value: "Sichuan / 四川" },
            { key: "CN25", value: "Guizhou / 贵州" },
            { key: "CN26", value: "Shaanxi / 陕西" },
            { key: "CN27", value: "Gansu / 甘肃" },
            { key: "CN28", value: "Qinghai / 青海" },
            { key: "CN29", value: "Ningxia Hui / 宁夏" },
            { key: "CN30", value: "Macao / 澳门" },
            { key: "CN31", value: "Tibet / 西藏" },
            { key: "CN32", value: "Xinjiang / 新疆" }
        ],
        "name": "China"
    },
    "CX": {
        "states": [],
        "name": "Christmas Island"
    },
    "CC": {
        "states": [],
        "name": "Cocos (Keeling) Islands"
    },
    "CO": {
        "states": [
            { key: "CO-AMA", value: "Amazonas" },
            { key: "CO-ANT", value: "Antioquia" },
            { key: "CO-ARA", value: "Arauca" },
            { key: "CO-ATL", value: "Atlántico" },
            { key: "CO-BOL", value: "Bolívar" },
            { key: "CO-BOY", value: "Boyacá" },
            { key: "CO-CAL", value: "Caldas" },
            { key: "CO-CAQ", value: "Caquetá" },
            { key: "CO-CAS", value: "Casanare" },
            { key: "CO-CAU", value: "Cauca" },
            { key: "CO-CES", value: "Cesar" },
            { key: "CO-CHO", value: "Chocó" },
            { key: "CO-COR", value: "Córdoba" },
            { key: "CO-CUN", value: "Cundinamarca" },
            { key: "CO-DC", value: "Capital District" },
            { key: "CO-GUA", value: "Guainía" },
            { key: "CO-GUV", value: "Guaviare" },
            { key: "CO-HUI", value: "Huila" },
            { key: "CO-LAG", value: "La Guajira" },
            { key: "CO-MAG", value: "Magdalena" },
            { key: "CO-MET", value: "Meta" },
            { key: "CO-NAR", value: "Nariño" },
            { key: "CO-NSA", value: "Norte de Santander" },
            { key: "CO-PUT", value: "Putumayo" },
            { key: "CO-QUI", value: "Quindío" },
            { key: "CO-RIS", value: "Risaralda" },
            { key: "CO-SAN", value: "Santander" },
            { key: "CO-SAP", value: "San Andrés & Providencia" },
            { key: "CO-SUC", value: "Sucre" },
            { key: "CO-TOL", value: "Tolima" },
            { key: "CO-VAC", value: "Valle del Cauca" },
            { key: "CO-VAU", value: "Vaupés" },
            { key: "CO-VID", value: "Vichada" }
        ],
        "name": "Colombia"
    },
    "KM": {
        "states": [],
        "name": "Comoros"
    },
    "CG": {
        "states": [],
        "name": "Congo (Brazzaville)"
    },
    "CD": {
        "states": [],
        "name": "Congo (Kinshasa)"
    },
    "CK": {
        "states": [],
        "name": "Cook Islands"
    },
    "CR": {
        "states": [
            { key: "CR-A", value: "Alajuela" },
            { key: "CR-C", value: "Cartago" },
            { key: "CR-G", value: "Guanacaste" },
            { key: "CR-H", value: "Heredia" },
            { key: "CR-L", value: "Limón" },
            { key: "CR-P", value: "Puntarenas" },
            { key: "CR-SJ", value: "San José" }
        ],
        "name": "Costa Rica"
    },
    "HR": {
        "states": [],
        "name": "Croatia"
    },
    "CU": {
        "states": [],
        "name": "Cuba"
    },
    "CW": {
        "states": [],
        "name": "Cura&ccedil;ao"
    },
    "CY": {
        "states": [],
        "name": "Cyprus"
    },
    "CZ": {
        "states": [],
        "name": "Czech Republic"
    },
    "DK": {
        "states": [],
        "name": "Denmark"
    },
    "DJ": {
        "states": [],
        "name": "Djibouti"
    },
    "DM": {
        "states": [],
        "name": "Dominica"
    },
    "DO": {
        "states": [
            { key: "DO-01", value: "Distrito Nacional" },
            { key: "DO-02", value: "Azua" },
            { key: "DO-03", value: "Baoruco" },
            { key: "DO-04", value: "Barahona" },
            { key: "DO-33", value: "Cibao Nordeste" },
            { key: "DO-34", value: "Cibao Noroeste" },
            { key: "DO-35", value: "Cibao Norte" },
            { key: "DO-36", value: "Cibao Sur" },
            { key: "DO-05", value: "Dajabón" },
            { key: "DO-06", value: "Duarte" },
            { key: "DO-08", value: "El Seibo" },
            { key: "DO-37", value: "El Valle" },
            { key: "DO-07", value: "Elías Piña" },
            { key: "DO-38", value: "Enriquillo" },
            { key: "DO-09", value: "Espaillat" },
            { key: "DO-30", value: "Hato Mayor" },
            { key: "DO-19", value: "Hermanas Mirabal" },
            { key: "DO-39", value: "Higüamo" },
            { key: "DO-10", value: "Independencia" },
            { key: "DO-11", value: "La Altagracia" },
            { key: "DO-12", value: "La Romana" },
            { key: "DO-13", value: "La Vega" },
            { key: "DO-14", value: "María Trinidad Sánchez" },
            { key: "DO-28", value: "Monseñor Nouel" },
            { key: "DO-15", value: "Monte Cristi" },
            { key: "DO-29", value: "Monte Plata" },
            { key: "DO-40", value: "Ozama" },
            { key: "DO-16", value: "Pedernales" },
            { key: "DO-17", value: "Peravia" },
            { key: "DO-18", value: "Puerto Plata" },
            { key: "DO-20", value: "Samaná" },
            { key: "DO-21", value: "San Cristóbal" },
            { key: "DO-31", value: "San José de Ocoa" },
            { key: "DO-22", value: "San Juan" },
            { key: "DO-23", value: "San Pedro de Macorís" },
            { key: "DO-24", value: "Sánchez Ramírez" },
            { key: "DO-25", value: "Santiago" },
            { key: "DO-26", value: "Santiago Rodríguez" },
            { key: "DO-32", value: "Santo Domingo" },
            { key: "DO-41", value: "Valdesia" },
            { key: "DO-27", value: "Valverde" },
            { key: "DO-42", value: "Yuma" }
        ],
        "name": "Dominican Republic"
    },
    "EC": {
        "states": [
            { key: "EC-A", value: "Azuay" },
            { key: "EC-B", value: "Bolívar" },
            { key: "EC-F", value: "Cañar" },
            { key: "EC-C", value: "Carchi" },
            { key: "EC-H", value: "Chimborazo" },
            { key: "EC-X", value: "Cotopaxi" },
            { key: "EC-O", value: "El Oro" },
            { key: "EC-E", value: "Esmeraldas" },
            { key: "EC-W", value: "Galápagos" },
            { key: "EC-G", value: "Guayas" },
            { key: "EC-I", value: "Imbabura" },
            { key: "EC-L", value: "Loja" },
            { key: "EC-R", value: "Los Ríos" },
            { key: "EC-M", value: "Manabí" },
            { key: "EC-S", value: "Morona-Santiago" },
            { key: "EC-N", value: "Napo" },
            { key: "EC-D", value: "Orellana" },
            { key: "EC-Y", value: "Pastaza" },
            { key: "EC-P", value: "Pichincha" },
            { key: "EC-SE", value: "Santa Elena" },
            { key: "EC-SD", value: "Santo Domingo de los Tsáchilas" },
            { key: "EC-U", value: "Sucumbíos" },
            { key: "EC-T", value: "Tungurahua" },
            { key: "EC-Z", value: "Zamora-Chinchipe" }
        ],
        "name": "Ecuador"
    },
    "EG": {
        "states": [
            { key: "EGALX", value: "Alexandria" },
            { key: "EGASN", value: "Aswan" },
            { key: "EGAST", value: "Asyut" },
            { key: "EGBA", value: "Red Sea" },
            { key: "EGBH", value: "Beheira" },
            { key: "EGBNS", value: "Beni Suef" },
            { key: "EGC", value: "Cairo" },
            { key: "EGDK", value: "Dakahlia" },
            { key: "EGDT", value: "Damietta" },
            { key: "EGFYM", value: "Faiyum" },
            { key: "EGGH", value: "Gharbia" },
            { key: "EGGZ", value: "Giza" },
            { key: "EGIS", value: "Ismailia" },
            { key: "EGJS", value: "South Sinai" },
            { key: "EGKB", value: "Qalyubia" },
            { key: "EGKFS", value: "Kafr el-Sheikh" },
            { key: "EGKN", value: "Qena" },
            { key: "EGLX", value: "Luxor" },
            { key: "EGMN", value: "Minya" },
            { key: "EGMNF", value: "Monufia" },
            { key: "EGMT", value: "Matrouh" },
            { key: "EGPTS", value: "Port Said" },
            { key: "EGSHG", value: "Sohag" },
            { key: "EGSHR", value: "Al Sharqia" },
            { key: "EGSIN", value: "North Sinai" },
            { key: "EGSUZ", value: "Suez" },
            { key: "EGWAD", value: "New Valley" }
        ],
        "name": "Egypt"
    },
    "SV": {
        "states": [
            { key: "SV-AH", value: "Ahuachapán" },
            { key: "SV-CA", value: "Cabañas" },
            { key: "SV-CH", value: "Chalatenango" },
            { key: "SV-CU", value: "Cuscatlán" },
            { key: "SV-LI", value: "La Libertad" },
            { key: "SV-MO", value: "Morazán" },
            { key: "SV-PA", value: "La Paz" },
            { key: "SV-SA", value: "Santa Ana" },
            { key: "SV-SM", value: "San Miguel" },
            { key: "SV-SO", value: "Sonsonate" },
            { key: "SV-SS", value: "San Salvador" },
            { key: "SV-SV", value: "San Vicente" },
            { key: "SV-UN", value: "La Unión" },
            { key: "SV-US", value: "Usulután" }
        ],
        "name": "El Salvador"
    },
    "GQ": {
        "states": [],
        "name": "Equatorial Guinea"
    },
    "ER": {
        "states": [],
        "name": "Eritrea"
    },
    "EE": {
        "states": [],
        "name": "Estonia"
    },
    "SZ": {
        "states": [],
        "name": "Eswatini"
    },
    "ET": {
        "states": [],
        "name": "Ethiopia"
    },
    "FK": {
        "states": [],
        "name": "Falkland Islands"
    },
    "FO": {
        "states": [],
        "name": "Faroe Islands"
    },
    "FJ": {
        "states": [],
        "name": "Fiji"
    },
    "FI": {
        "states": [],
        "name": "Finland"
    },
    "FR": {
        "states": [],
        "name": "France"
    },
    "GF": {
        "states": [],
        "name": "French Guiana"
    },
    "PF": {
        "states": [],
        "name": "French Polynesia"
    },
    "TF": {
        "states": [],
        "name": "French Southern Territories"
    },
    "GA": {
        "states": [],
        "name": "Gabon"
    },
    "GM": {
        "states": [],
        "name": "Gambia"
    },
    "GE": {
        "states": [],
        "name": "Georgia"
    },
    "DE": {
        "states": [
            { key: "DE-BW", value: "Baden-Württemberg" },
            { key: "DE-BY", value: "Bavaria" },
            { key: "DE-BE", value: "Berlin" },
            { key: "DE-BB", value: "Brandenburg" },
            { key: "DE-HB", value: "Bremen" },
            { key: "DE-HH", value: "Hamburg" },
            { key: "DE-HE", value: "Hesse" },
            { key: "DE-MV", value: "Mecklenburg-Vorpommern" },
            { key: "DE-NI", value: "Lower Saxony" },
            { key: "DE-NW", value: "North Rhine-Westphalia" },
            { key: "DE-RP", value: "Rhineland-Palatinate" },
            { key: "DE-SL", value: "Saarland" },
            { key: "DE-SN", value: "Saxony" },
            { key: "DE-ST", value: "Saxony-Anhalt" },
            { key: "DE-SH", value: "Schleswig-Holstein" },
            { key: "DE-TH", value: "Thuringia" }
        ],
        "name": "Germany"
    },
    "GH": {
        "states": [
            { key: "AF", value: "Ahafo" },
            { key: "AH", value: "Ashanti" },
            { key: "BA", value: "Brong-Ahafo" },
            { key: "BO", value: "Bono" },
            { key: "BE", value: "Bono East" },
            { key: "CP", value: "Central" },
            { key: "EP", value: "Eastern" },
            { key: "AA", value: "Greater Accra" },
            { key: "NE", value: "North East" },
            { key: "NP", value: "Northern" },
            { key: "OT", value: "Oti" },
            { key: "SV", value: "Savannah" },
            { key: "UE", value: "Upper East" },
            { key: "UW", value: "Upper West" },
            { key: "TV", value: "Volta" },
            { key: "WP", value: "Western" },
            { key: "WN", value: "Western North" }
        ],
        "name": "Ghana"
    },
    "GI": {
        "states": [],
        "name": "Gibraltar"
    },
    "GR": {
        "states": [
            { key: "I", value: "Attica" },
            { key: "A", value: "East Macedonia and Thrace" },
            { key: "B", value: "Central Macedonia" },
            { key: "C", value: "West Macedonia" },
            { key: "D", value: "Epirus" },
            { key: "E", value: "Thessaly" },
            { key: "F", value: "Ionian Islands" },
            { key: "G", value: "West Greece" },
            { key: "H", value: "Central Greece" },
            { key: "J", value: "Peloponnese" },
            { key: "K", value: "North Aegean" },
            { key: "L", value: "South Aegean" },
            { key: "M", value: "Crete" }
        ],
        "name": "Greece"
    },
    "GL": {
        "states": [],
        "name": "Greenland"
    },
    "GD": {
        "states": [],
        "name": "Grenada"
    },
    "GP": {
        "states": [],
        "name": "Guadeloupe"
    },
    "GU": {
        "states": [],
        "name": "Guam"
    },
    "GT": {
        "states": [
            { key: "GT-AV", value: "Alta Verapaz" },
            { key: "GT-BV", value: "Baja Verapaz" },
            { key: "GT-CM", value: "Chimaltenango" },
            { key: "GT-CQ", value: "Chiquimula" },
            { key: "GT-PR", value: "El Progreso" },
            { key: "GT-ES", value: "Escuintla" },
            { key: "GT-GU", value: "Guatemala" },
            { key: "GT-HU", value: "Huehuetenango" },
            { key: "GT-IZ", value: "Izabal" },
            { key: "GT-JA", value: "Jalapa" },
            { key: "GT-JU", value: "Jutiapa" },
            { key: "GT-PE", value: "Petén" },
            { key: "GT-QZ", value: "Quetzaltenango" },
            { key: "GT-QC", value: "Quiché" },
            { key: "GT-RE", value: "Retalhuleu" },
            { key: "GT-SA", value: "Sacatepéquez" },
            { key: "GT-SM", value: "San Marcos" },
            { key: "GT-SR", value: "Santa Rosa" },
            { key: "GT-SO", value: "Sololá" },
            { key: "GT-SU", value: "Suchitepéquez" },
            { key: "GT-TO", value: "Totonicapán" },
            { key: "GT-ZA", value: "Zacapa" }
        ],
        "name": "Guatemala"
    },
    "GG": {
        "states": [],
        "name": "Guernsey"
    },
    "GN": {
        "states": [],
        "name": "Guinea"
    },
    "GW": {
        "states": [],
        "name": "Guinea-Bissau"
    },
    "GY": {
        "states": [],
        "name": "Guyana"
    },
    "HT": {
        "states": [],
        "name": "Haiti"
    },
    "HM": {
        "states": [],
        "name": "Heard Island and McDonald Islands"
    },
    "HN": {
        "states": [
            { key: "HN-AT", value: "Atlántida" },
            { key: "HN-IB", value: "Bay Islands" },
            { key: "HN-CH", value: "Choluteca" },
            { key: "HN-CL", value: "Colón" },
            { key: "HN-CM", value: "Comayagua" },
            { key: "HN-CP", value: "Copán" },
            { key: "HN-CR", value: "Cortés" },
            { key: "HN-EP", value: "El Paraíso" },
            { key: "HN-FM", value: "Francisco Morazán" },
            { key: "HN-GD", value: "Gracias a Dios" },
            { key: "HN-IN", value: "Intibucá" },
            { key: "HN-LE", value: "Lempira" },
            { key: "HN-LP", value: "La Paz" },
            { key: "HN-OC", value: "Ocotepeque" },
            { key: "HN-OL", value: "Olancho" },
            { key: "HN-SB", value: "Santa Bárbara" },
            { key: "HN-VA", value: "Valle" },
            { key: "HN-YO", value: "Yoro" }
        ],
        "name": "Honduras"
    },
    "HK": {
        "states": [
            { key: "HONG KONG", value: "Hong Kong Island" },
            { key: "KOWLOON", value: "Kowloon" },
            { key: "NEW TERRITORIES", value: "New Territories" }
        ],
        "name": "Hong Kong"
    },
    "HU": {
        "states": [
            { key: "BK", value: "Bács-Kiskun" },
            { key: "BE", value: "Békés" },
            { key: "BA", value: "Baranya" },
            { key: "BZ", value: "Borsod-Abaúj-Zemplén" },
            { key: "BU", value: "Budapest" },
            { key: "CS", value: "Csongrád-Csanád" },
            { key: "FE", value: "Fejér" },
            { key: "GS", value: "Győr-Moson-Sopron" },
            { key: "HB", value: "Hajdú-Bihar" },
            { key: "HE", value: "Heves" },
            { key: "JN", value: "Jász-Nagykun-Szolnok" },
            { key: "KE", value: "Komárom-Esztergom" },
            { key: "NO", value: "Nógrád" },
            { key: "PE", value: "Pest" },
            { key: "SO", value: "Somogy" },
            { key: "SZ", value: "Szabolcs-Szatmár-Bereg" },
            { key: "TO", value: "Tolna" },
            { key: "VA", value: "Vas" },
            { key: "VE", value: "Veszprém" },
            { key: "ZA", value: "Zala" }
        ],
        "name": "Hungary"
    },
    "IS": {
        "states": [],
        "name": "Iceland"
    },
    "IN": {
        "states": [
            { key: "AP", value: "Andhra Pradesh" },
            { key: "AR", value: "Arunachal Pradesh" },
            { key: "AS", value: "Assam" },
            { key: "BR", value: "Bihar" },
            { key: "CT", value: "Chhattisgarh" },
            { key: "GA", value: "Goa" },
            { key: "GJ", value: "Gujarat" },
            { key: "HR", value: "Haryana" },
            { key: "HP", value: "Himachal Pradesh" },
            { key: "JK", value: "Jammu and Kashmir" },
            { key: "JH", value: "Jharkhand" },
            { key: "KA", value: "Karnataka" },
            { key: "KL", value: "Kerala" },
            { key: "LA", value: "Ladakh" },
            { key: "MP", value: "Madhya Pradesh" },
            { key: "MH", value: "Maharashtra" },
            { key: "MN", value: "Manipur" },
            { key: "ML", value: "Meghalaya" },
            { key: "MZ", value: "Mizoram" },
            { key: "NL", value: "Nagaland" },
            { key: "OR", value: "Odisha" },
            { key: "PB", value: "Punjab" },
            { key: "RJ", value: "Rajasthan" },
            { key: "SK", value: "Sikkim" },
            { key: "TN", value: "Tamil Nadu" },
            { key: "TS", value: "Telangana" },
            { key: "TR", value: "Tripura" },
            { key: "UK", value: "Uttarakhand" },
            { key: "UP", value: "Uttar Pradesh" },
            { key: "WB", value: "West Bengal" },
            { key: "AN", value: "Andaman and Nicobar Islands" },
            { key: "CH", value: "Chandigarh" },
            { key: "DN", value: "Dadra and Nagar Haveli" },
            { key: "DD", value: "Daman and Diu" },
            { key: "DL", value: "Delhi" },
            { key: "LD", value: "Lakshadweep" },
            { key: "PY", value: "Pondicherry (Puducherry)" }
        ],
        "name": "India"
    },
    "ID": {
        "states": [
            { key: "AC", value: "Daerah Istimewa Aceh" },
            { key: "SU", value: "Sumatera Utara" },
            { key: "SB", value: "Sumatera Barat" },
            { key: "RI", value: "Riau" },
            { key: "KR", value: "Kepulauan Riau" },
            { key: "JA", value: "Jambi" },
            { key: "SS", value: "Sumatera Selatan" },
            { key: "BB", value: "Bangka Belitung" },
            { key: "BE", value: "Bengkulu" },
            { key: "LA", value: "Lampung" },
            { key: "JK", value: "DKI Jakarta" },
            { key: "JB", value: "Jawa Barat" },
            { key: "BT", value: "Banten" },
            { key: "JT", value: "Jawa Tengah" },
            { key: "JI", value: "Jawa Timur" },
            { key: "YO", value: "Daerah Istimewa Yogyakarta" },
            { key: "BA", value: "Bali" },
            { key: "NB", value: "Nusa Tenggara Barat" },
            { key: "NT", value: "Nusa Tenggara Timur" },
            { key: "KB", value: "Kalimantan Barat" },
            { key: "KT", value: "Kalimantan Tengah" },
            { key: "KI", value: "Kalimantan Timur" },
            { key: "KS", value: "Kalimantan Selatan" },
            { key: "KU", value: "Kalimantan Utara" },
            { key: "SA", value: "Sulawesi Utara" },
            { key: "ST", value: "Sulawesi Tengah" },
            { key: "SG", value: "Sulawesi Tenggara" },
            { key: "SR", value: "Sulawesi Barat" },
            { key: "SN", value: "Sulawesi Selatan" },
            { key: "GO", value: "Gorontalo" },
            { key: "MA", value: "Maluku" },
            { key: "MU", value: "Maluku Utara" },
            { key: "PA", value: "Papua" },
            { key: "PB", value: "Papua Barat" }
        ],
        "name": "Indonesia"
    },
    "IR": {
        "states": [
            { key: "KHZ", value: "Khuzestan (خوزستان)" },
            { key: "THR", value: "Tehran (تهران)" },
            { key: "ILM", value: "Ilaam (ایلام)" },
            { key: "BHR", value: "Bushehr (بوشهر)" },
            { key: "ADL", value: "Ardabil (اردبیل)" },
            { key: "ESF", value: "Isfahan (اصفهان)" },
            { key: "YZD", value: "Yazd (یزد)" },
            { key: "KRH", value: "Kermanshah (کرمانشاه)" },
            { key: "KRN", value: "Kerman (کرمان)" },
            { key: "HDN", value: "Hamadan (همدان)" },
            { key: "GZN", value: "Ghazvin (قزوین)" },
            { key: "ZJN", value: "Zanjan (زنجان)" },
            { key: "LRS", value: "Luristan (لرستان)" },
            { key: "ABZ", value: "Alborz (البرز)" },
            { key: "EAZ", value: "East Azarbaijan (آذربایجان شرقی)" },
            { key: "WAZ", value: "West Azarbaijan (آذربایجان غربی)" },
            { key: "CHB", value: "Chaharmahal and Bakhtiari (چهارمحال و بختیاری)" },
            { key: "SKH", value: "South Khorasan (خراسان جنوبی)" },
            { key: "RKH", value: "Razavi Khorasan (خراسان رضوی)" },
            { key: "NKH", value: "North Khorasan (خراسان شمالی)" },
            { key: "SMN", value: "Semnan (سمنان)" },
            { key: "FRS", value: "Fars (فارس)" },
            { key: "QHM", value: "Qom (قم)" },
            { key: "KRD", value: "Kurdistan / کردستان)" },
            { key: "KBD", value: "Kohgiluyeh and BoyerAhmad (کهگیلوییه و بویراحمد)" },
            { key: "GLS", value: "Golestan (گلستان)" },
            { key: "GIL", value: "Gilan (گیلان)" },
            { key: "MZN", value: "Mazandaran (مازندران)" },
            { key: "MKZ", value: "Markazi (مرکزی)" },
            { key: "HRZ", value: "Hormozgan (هرمزگان)" },
            { key: "SBN", value: "Sistan and Baluchestan (سیستان و بلوچستان)" }
        ],
        "name": "Iran"
    },
    "IQ": {
        "states": [],
        "name": "Iraq"
    },
    "IE": {
        "states": [
            { key: "CW", value: "Carlow" },
            { key: "CN", value: "Cavan" },
            { key: "CE", value: "Clare" },
            { key: "CO", value: "Cork" },
            { key: "DL", value: "Donegal" },
            { key: "D", value: "Dublin" },
            { key: "G", value: "Galway" },
            { key: "KY", value: "Kerry" },
            { key: "KE", value: "Kildare" },
            { key: "KK", value: "Kilkenny" },
            { key: "LS", value: "Laois" },
            { key: "LM", value: "Leitrim" },
            { key: "LK", value: "Limerick" },
            { key: "LD", value: "Longford" },
            { key: "LH", value: "Louth" },
            { key: "MO", value: "Mayo" },
            { key: "MH", value: "Meath" },
            { key: "MN", value: "Monaghan" },
            { key: "OY", value: "Offaly" },
            { key: "RN", value: "Roscommon" },
            { key: "SO", value: "Sligo" },
            { key: "TA", value: "Tipperary" },
            { key: "WD", value: "Waterford" },
            { key: "WH", value: "Westmeath" },
            { key: "WX", value: "Wexford" },
            { key: "WW", value: "Wicklow" }
        ],
        "name": "Ireland"
    },
    "IM": {
        "states": [],
        "name": "Isle of Man"
    },
    "IL": {
        "states": [],
        "name": "Israel"
    },
    "IT": {
        "states": [
            { key: "AG", value: "Agrigento" },
            { key: "AL", value: "Alessandria" },
            { key: "AN", value: "Ancona" },
            { key: "AO", value: "Aosta" },
            { key: "AR", value: "Arezzo" },
            { key: "AP", value: "Ascoli Piceno" },
            { key: "AT", value: "Asti" },
            { key: "AV", value: "Avellino" },
            { key: "BA", value: "Bari" },
            { key: "BT", value: "Barletta-Andria-Trani" },
            { key: "BL", value: "Belluno" },
            { key: "BN", value: "Benevento" },
            { key: "BG", value: "Bergamo" },
            { key: "BI", value: "Biella" },
            { key: "BO", value: "Bologna" },
            { key: "BZ", value: "Bolzano" },
            { key: "BS", value: "Brescia" },
            { key: "BR", value: "Brindisi" },
            { key: "CA", value: "Cagliari" },
            { key: "CL", value: "Caltanissetta" },
            { key: "CB", value: "Campobasso" },
            { key: "CE", value: "Caserta" },
            { key: "CT", value: "Catania" },
            { key: "CZ", value: "Catanzaro" },
            { key: "CH", value: "Chieti" },
            { key: "CO", value: "Como" },
            { key: "CS", value: "Cosenza" },
            { key: "CR", value: "Cremona" },
            { key: "KR", value: "Crotone" },
            { key: "CN", value: "Cuneo" },
            { key: "EN", value: "Enna" },
            { key: "FM", value: "Fermo" },
            { key: "FE", value: "Ferrara" },
            { key: "FI", value: "Firenze" },
            { key: "FG", value: "Foggia" },
            { key: "FC", value: "Forlì-Cesena" },
            { key: "FR", value: "Frosinone" },
            { key: "GE", value: "Genova" },
            { key: "GO", value: "Gorizia" },
            { key: "GR", value: "Grosseto" },
            { key: "IM", value: "Imperia" },
            { key: "IS", value: "Isernia" },
            { key: "SP", value: "La Spezia" },
            { key: "AQ", value: "L'Aquila" },
            { key: "LT", value: "Latina" },
            { key: "LE", value: "Lecce" },
            { key: "LC", value: "Lecco" },
            { key: "LI", value: "Livorno" },
            { key: "LO", value: "Lodi" },
            { key: "LU", value: "Lucca" },
            { key: "MC", value: "Macerata" },
            { key: "MN", value: "Mantova" },
            { key: "MS", value: "Massa-Carrara" },
            { key: "MT", value: "Matera" },
            { key: "ME", value: "Messina" },
            { key: "MI", value: "Milano" },
            { key: "MO", value: "Modena" },
            { key: "MB", value: "Monza e della Brianza" },
            { key: "NA", value: "Napoli" },
            { key: "NO", value: "Novara" },
            { key: "NU", value: "Nuoro" },
            { key: "OR", value: "Oristano" },
            { key: "PD", value: "Padova" },
            { key: "PA", value: "Palermo" },
            { key: "PR", value: "Parma" },
            { key: "PV", value: "Pavia" },
            { key: "PG", value: "Perugia" },
            { key: "PU", value: "Pesaro e Urbino" },
            { key: "PE", value: "Pescara" },
            { key: "PC", value: "Piacenza" },
            { key: "PI", value: "Pisa" },
            { key: "PT", value: "Pistoia" },
            { key: "PN", value: "Pordenone" },
            { key: "PZ", value: "Potenza" },
            { key: "PO", value: "Prato" },
            { key: "RG", value: "Ragusa" },
            { key: "RA", value: "Ravenna" },
            { key: "RC", value: "Reggio Calabria" },
            { key: "RE", value: "Reggio Emilia" },
            { key: "RI", value: "Rieti" },
            { key: "RN", value: "Rimini" },
            { key: "RM", value: "Roma" },
            { key: "RO", value: "Rovigo" },
            { key: "SA", value: "Salerno" },
            { key: "SS", value: "Sassari" },
            { key: "SV", value: "Savona" },
            { key: "SI", value: "Siena" },
            { key: "SR", value: "Siracusa" },
            { key: "SO", value: "Sondrio" },
            { key: "SU", value: "Sud Sardegna" },
            { key: "TA", value: "Taranto" },
            { key: "TE", value: "Teramo" },
            { key: "TR", value: "Terni" },
            { key: "TO", value: "Torino" },
            { key: "TP", value: "Trapani" },
            { key: "TN", value: "Trento" },
            { key: "TV", value: "Treviso" },
            { key: "TS", value: "Trieste" },
            { key: "UD", value: "Udine" },
            { key: "VA", value: "Varese" },
            { key: "VE", value: "Venezia" },
            { key: "VB", value: "Verbano-Cusio-Ossola" },
            { key: "VC", value: "Vercelli" },
            { key: "VR", value: "Verona" },
            { key: "VV", value: "Vibo Valentia" },
            { key: "VI", value: "Vicenza" },
            { key: "VT", value: "Viterbo" }
        ],
        "name": "Italy"
    },
    "CI": {
        "states": [],
        "name": "Ivory Coast"
    },
    "JM": {
        "states": [
            { key: "JM-01", value: "Kingston" },
            { key: "JM-02", value: "Saint Andrew" },
            { key: "JM-03", value: "Saint Thomas" },
            { key: "JM-04", value: "Portland" },
            { key: "JM-05", value: "Saint Mary" },
            { key: "JM-06", value: "Saint Ann" },
            { key: "JM-07", value: "Trelawny" },
            { key: "JM-08", value: "Saint James" },
            { key: "JM-09", value: "Hanover" },
            { key: "JM-10", value: "Westmoreland" },
            { key: "JM-11", value: "Saint Elizabeth" },
            { key: "JM-12", value: "Manchester" },
            { key: "JM-13", value: "Clarendon" },
            { key: "JM-14", value: "Saint Catherine" }
        ],
        "name": "Jamaica"
    },
    "JP": {
        "states": [
            { key: "JP01", value: "Hokkaido" },
            { key: "JP02", value: "Aomori" },
            { key: "JP03", value: "Iwate" },
            { key: "JP04", value: "Miyagi" },
            { key: "JP05", value: "Akita" },
            { key: "JP06", value: "Yamagata" },
            { key: "JP07", value: "Fukushima" },
            { key: "JP08", value: "Ibaraki" },
            { key: "JP09", value: "Tochigi" },
            { key: "JP10", value: "Gunma" },
            { key: "JP11", value: "Saitama" },
            { key: "JP12", value: "Chiba" },
            { key: "JP13", value: "Tokyo" },
            { key: "JP14", value: "Kanagawa" },
            { key: "JP15", value: "Niigata" },
            { key: "JP16", value: "Toyama" },
            { key: "JP17", value: "Ishikawa" },
            { key: "JP18", value: "Fukui" },
            { key: "JP19", value: "Yamanashi" },
            { key: "JP20", value: "Nagano" },
            { key: "JP21", value: "Gifu" },
            { key: "JP22", value: "Shizuoka" },
            { key: "JP23", value: "Aichi" },
            { key: "JP24", value: "Mie" },
            { key: "JP25", value: "Shiga" },
            { key: "JP26", value: "Kyoto" },
            { key: "JP27", value: "Osaka" },
            { key: "JP28", value: "Hyogo" },
            { key: "JP29", value: "Nara" },
            { key: "JP30", value: "Wakayama" },
            { key: "JP31", value: "Tottori" },
            { key: "JP32", value: "Shimane" },
            { key: "JP33", value: "Okayama" },
            { key: "JP34", value: "Hiroshima" },
            { key: "JP35", value: "Yamaguchi" },
            { key: "JP36", value: "Tokushima" },
            { key: "JP37", value: "Kagawa" },
            { key: "JP38", value: "Ehime" },
            { key: "JP39", value: "Kochi" },
            { key: "JP40", value: "Fukuoka" },
            { key: "JP41", value: "Saga" },
            { key: "JP42", value: "Nagasaki" },
            { key: "JP43", value: "Kumamoto" },
            { key: "JP44", value: "Oita" },
            { key: "JP45", value: "Miyazaki" },
            { key: "JP46", value: "Kagoshima" },
            { key: "JP47", value: "Okinawa" }
        ],
        "name": "Japan"
    },
    "JE": {
        "states": [],
        "name": "Jersey"
    },
    "JO": {
        "states": [],
        "name": "Jordan"
    },
    "KZ": {
        "states": [],
        "name": "Kazakhstan"
    },
    "KE": {
        "states": [
            { key: "KE01", value: "Baringo" },
            { key: "KE02", value: "Bomet" },
            { key: "KE03", value: "Bungoma" },
            { key: "KE04", value: "Busia" },
            { key: "KE05", value: "Elgeyo-Marakwet" },
            { key: "KE06", value: "Embu" },
            { key: "KE07", value: "Garissa" },
            { key: "KE08", value: "Homa Bay" },
            { key: "KE09", value: "Isiolo" },
            { key: "KE10", value: "Kajiado" },
            { key: "KE11", value: "Kakamega" },
            { key: "KE12", value: "Kericho" },
            { key: "KE13", value: "Kiambu" },
            { key: "KE14", value: "Kilifi" },
            { key: "KE15", value: "Kirinyaga" },
            { key: "KE16", value: "Kisii" },
            { key: "KE17", value: "Kisumu" },
            { key: "KE18", value: "Kitui" },
            { key: "KE19", value: "Kwale" },
            { key: "KE20", value: "Laikipia" },
            { key: "KE21", value: "Lamu" },
            { key: "KE22", value: "Machakos" },
            { key: "KE23", value: "Makueni" },
            { key: "KE24", value: "Mandera" },
            { key: "KE25", value: "Marsabit" },
            { key: "KE26", value: "Meru" },
            { key: "KE27", value: "Migori" },
            { key: "KE28", value: "Mombasa" },
            { key: "KE29", value: "Murang’a" },
            { key: "KE30", value: "Nairobi County" },
            { key: "KE31", value: "Nakuru" },
            { key: "KE32", value: "Nandi" },
            { key: "KE33", value: "Narok" },
            { key: "KE34", value: "Nyamira" },
            { key: "KE35", value: "Nyandarua" },
            { key: "KE36", value: "Nyeri" },
            { key: "KE37", value: "Samburu" },
            { key: "KE38", value: "Siaya" },
            { key: "KE39", value: "Taita-Taveta" },
            { key: "KE40", value: "Tana River" },
            { key: "KE41", value: "Tharaka-Nithi" },
            { key: "KE42", value: "Trans Nzoia" },
            { key: "KE43", value: "Turkana" },
            { key: "KE44", value: "Uasin Gishu" },
            { key: "KE45", value: "Vihiga" },
            { key: "KE46", value: "Wajir" },
            { key: "KE47", value: "West Pokot" }
        ],
        "name": "Kenya"
    },
    "KI": {
        "states": [],
        "name": "Kiribati"
    },
    "KW": {
        "states": [],
        "name": "Kuwait"
    },
    "KG": {
        "states": [],
        "name": "Kyrgyzstan"
    },
    "LA": {
        "states": [
            { key: "AT", value: "Attapeu" },
            { key: "BK", value: "Bokeo" },
            { key: "BL", value: "Bolikhamsai" },
            { key: "CH", value: "Champasak" },
            { key: "HO", value: "Houaphanh" },
            { key: "KH", value: "Khammouane" },
            { key: "LM", value: "Luang Namtha" },
            { key: "LP", value: "Luang Prabang" },
            { key: "OU", value: "Oudomxay" },
            { key: "PH", value: "Phongsaly" },
            { key: "SL", value: "Salavan" },
            { key: "SV", value: "Savannakhet" },
            { key: "VI", value: "Vientiane Province" },
            { key: "VT", value: "Vientiane" },
            { key: "XA", value: "Sainyabuli" },
            { key: "XE", value: "Sekong" },
            { key: "XI", value: "Xiangkhouang" },
            { key: "XS", value: "Xaisomboun" }
        ],
        "name": "Laos"
    },
    "LV": {
        "states": [],
        "name": "Latvia"
    },
    "LB": {
        "states": [],
        "name": "Lebanon"
    },
    "LS": {
        "states": [],
        "name": "Lesotho"
    },
    "LR": {
        "states": [
            { key: "BM", value: "Bomi" },
            { key: "BN", value: "Bong" },
            { key: "GA", value: "Gbarpolu" },
            { key: "GB", value: "Grand Bassa" },
            { key: "GC", value: "Grand Cape Mount" },
            { key: "GG", value: "Grand Gedeh" },
            { key: "GK", value: "Grand Kru" },
            { key: "LO", value: "Lofa" },
            { key: "MA", value: "Margibi" },
            { key: "MY", value: "Maryland" },
            { key: "MO", value: "Montserrado" },
            { key: "NM", value: "Nimba" },
            { key: "RV", value: "Rivercess" },
            { key: "RG", value: "River Gee" },
            { key: "SN", value: "Sinoe" }
        ],
        "name": "Liberia"
    },
    "LY": {
        "states": [],
        "name": "Libya"
    },
    "LI": {
        "states": [],
        "name": "Liechtenstein"
    },
    "LT": {
        "states": [],
        "name": "Lithuania"
    },
    "LU": {
        "states": [],
        "name": "Luxembourg"
    },
    "MO": {
        "states": [],
        "name": "Macao"
    },
    "MG": {
        "states": [],
        "name": "Madagascar"
    },
    "MW": {
        "states": [],
        "name": "Malawi"
    },
    "MY": {
        "states": [
            { key: "JHR", value: "Johor" },
            { key: "KDH", value: "Kedah" },
            { key: "KTN", value: "Kelantan" },
            { key: "LBN", value: "Labuan" },
            { key: "MLK", value: "Malacca (Melaka)" },
            { key: "NSN", value: "Negeri Sembilan" },
            { key: "PHG", value: "Pahang" },
            { key: "PNG", value: "Penang (Pulau Pinang)" },
            { key: "PRK", value: "Perak" },
            { key: "PLS", value: "Perlis" },
            { key: "SBH", value: "Sabah" },
            { key: "SWK", value: "Sarawak" },
            { key: "SGR", value: "Selangor" },
            { key: "TRG", value: "Terengganu" },
            { key: "PJY", value: "Putrajaya" },
            { key: "KUL", value: "Kuala Lumpur" }
        ],
        "name": "Malaysia"
    },
    "MV": {
        "states": [],
        "name": "Maldives"
    },
    "ML": {
        "states": [],
        "name": "Mali"
    },
    "MT": {
        "states": [],
        "name": "Malta"
    },
    "MH": {
        "states": [],
        "name": "Marshall Islands"
    },
    "MQ": {
        "states": [],
        "name": "Martinique"
    },
    "MR": {
        "states": [],
        "name": "Mauritania"
    },
    "MU": {
        "states": [],
        "name": "Mauritius"
    },
    "YT": {
        "states": [],
        "name": "Mayotte"
    },
    "MX": {
        "states": [
            { key: "DF", value: "Ciudad de México" },
            { key: "JA", value: "Jalisco" },
            { key: "NL", value: "Nuevo León" },
            { key: "AG", value: "Aguascalientes" },
            { key: "BC", value: "Baja California" },
            { key: "BS", value: "Baja California Sur" },
            { key: "CM", value: "Campeche" },
            { key: "CS", value: "Chiapas" },
            { key: "CH", value: "Chihuahua" },
            { key: "CO", value: "Coahuila" },
            { key: "CL", value: "Colima" },
            { key: "DG", value: "Durango" },
            { key: "GT", value: "Guanajuato" },
            { key: "GR", value: "Guerrero" },
            { key: "HG", value: "Hidalgo" },
            { key: "MX", value: "Estado de México" },
            { key: "MI", value: "Michoacán" },
            { key: "MO", value: "Morelos" },
            { key: "NA", value: "Nayarit" },
            { key: "OA", value: "Oaxaca" },
            { key: "PU", value: "Puebla" },
            { key: "QT", value: "Querétaro" },
            { key: "QR", value: "Quintana Roo" },
            { key: "SL", value: "San Luis Potosí" },
            { key: "SI", value: "Sinaloa" },
            { key: "SO", value: "Sonora" },
            { key: "TB", value: "Tabasco" },
            { key: "TM", value: "Tamaulipas" },
            { key: "TL", value: "Tlaxcala" },
            { key: "VE", value: "Veracruz" },
            { key: "YU", value: "Yucatán" },
            { key: "ZA", value: "Zacatecas" }
        ],
        "name": "Mexico"
    },
    "FM": {
        "states": [],
        "name": "Micronesia"
    },
    "MD": {
        "states": [
            { key: "C", value: "Chișinău" },
            { key: "BL", value: "Bălți" },
            { key: "AN", value: "Anenii Noi" },
            { key: "BS", value: "Basarabeasca" },
            { key: "BR", value: "Briceni" },
            { key: "CH", value: "Cahul" },
            { key: "CT", value: "Cantemir" },
            { key: "CL", value: "Călărași" },
            { key: "CS", value: "Căușeni" },
            { key: "CM", value: "Cimișlia" },
            { key: "CR", value: "Criuleni" },
            { key: "DN", value: "Dondușeni" },
            { key: "DR", value: "Drochia" },
            { key: "DB", value: "Dubăsari" },
            { key: "ED", value: "Edineț" },
            { key: "FL", value: "Fălești" },
            { key: "FR", value: "Florești" },
            { key: "GE", value: "UTA Găgăuzia" },
            { key: "GL", value: "Glodeni" },
            { key: "HN", value: "Hîncești" },
            { key: "IL", value: "Ialoveni" },
            { key: "LV", value: "Leova" },
            { key: "NS", value: "Nisporeni" },
            { key: "OC", value: "Ocnița" },
            { key: "OR", value: "Orhei" },
            { key: "RZ", value: "Rezina" },
            { key: "RS", value: "Rîșcani" },
            { key: "SG", value: "Sîngerei" },
            { key: "SR", value: "Soroca" },
            { key: "ST", value: "Strășeni" },
            { key: "SD", value: "Șoldănești" },
            { key: "SV", value: "Ștefan Vodă" },
            { key: "TR", value: "Taraclia" },
            { key: "TL", value: "Telenești" },
            { key: "UN", value: "Ungheni" }
        ],
        "name": "Moldova"
    },
    "MC": {
        "states": [],
        "name": "Monaco"
    },
    "MN": {
        "states": [],
        "name": "Mongolia"
    },
    "ME": {
        "states": [],
        "name": "Montenegro"
    },
    "MS": {
        "states": [],
        "name": "Montserrat"
    },
    "MA": {
        "states": [],
        "name": "Morocco"
    },
    "MZ": {
        "states": [
            { key: "MZP", value: "Cabo Delgado" },
            { key: "MZG", value: "Gaza" },
            { key: "MZI", value: "Inhambane" },
            { key: "MZB", value: "Manica" },
            { key: "MZL", value: "Maputo Province" },
            { key: "MZMPM", value: "Maputo" },
            { key: "MZN", value: "Nampula" },
            { key: "MZA", value: "Niassa" },
            { key: "MZS", value: "Sofala" },
            { key: "MZT", value: "Tete" },
            { key: "MZQ", value: "Zambézia" }
        ],
        "name": "Mozambique"
    },
    "MM": {
        "states": [],
        "name": "Myanmar"
    },
    "NA": {
        "states": [
            { key: "ER", value: "Erongo" },
            { key: "HA", value: "Hardap" },
            { key: "KA", value: "Karas" },
            { key: "KE", value: "Kavango East" },
            { key: "KW", value: "Kavango West" },
            { key: "KH", value: "Khomas" },
            { key: "KU", value: "Kunene" },
            { key: "OW", value: "Ohangwena" },
            { key: "OH", value: "Omaheke" },
            { key: "OS", value: "Omusati" },
            { key: "ON", value: "Oshana" },
            { key: "OT", value: "Oshikoto" },
            { key: "OD", value: "Otjozondjupa" },
            { key: "CA", value: "Zambezi" }
        ],
        "name": "Namibia"
    },
    "NR": {
        "states": [],
        "name": "Nauru"
    },
    "NP": {
        "states": [
            { key: "BAG", value: "Bagmati" },
            { key: "BHE", value: "Bheri" },
            { key: "DHA", value: "Dhaulagiri" },
            { key: "GAN", value: "Gandaki" },
            { key: "JAN", value: "Janakpur" },
            { key: "KAR", value: "Karnali" },
            { key: "KOS", value: "Koshi" },
            { key: "LUM", value: "Lumbini" },
            { key: "MAH", value: "Mahakali" },
            { key: "MEC", value: "Mechi" },
            { key: "NAR", value: "Narayani" },
            { key: "RAP", value: "Rapti" },
            { key: "SAG", value: "Sagarmatha" },
            { key: "SET", value: "Seti" }
        ],
        "name": "Nepal"
    },
    "NL": {
        "states": [],
        "name": "Netherlands"
    },
    "NC": {
        "states": [],
        "name": "New Caledonia"
    },
    "NZ": {
        "states": [
            { key: "NTL", value: "Northland" },
            { key: "AUK", value: "Auckland" },
            { key: "WKO", value: "Waikato" },
            { key: "BOP", value: "Bay of Plenty" },
            { key: "TKI", value: "Taranaki" },
            { key: "GIS", value: "Gisborne" },
            { key: "HKB", value: "Hawke’s Bay" },
            { key: "MWT", value: "Manawatu-Wanganui" },
            { key: "WGN", value: "Wellington" },
            { key: "NSN", value: "Nelson" },
            { key: "MBH", value: "Marlborough" },
            { key: "TAS", value: "Tasman" },
            { key: "WTC", value: "West Coast" },
            { key: "CAN", value: "Canterbury" },
            { key: "OTA", value: "Otago" },
            { key: "STL", value: "Southland" }
        ],
        "name": "New Zealand"
    },
    "NI": {
        "states": [
            { key: "NI-AN", value: "Atlántico Norte" },
            { key: "NI-AS", value: "Atlántico Sur" },
            { key: "NI-BO", value: "Boaco" },
            { key: "NI-CA", value: "Carazo" },
            { key: "NI-CI", value: "Chinandega" },
            { key: "NI-CO", value: "Chontales" },
            { key: "NI-ES", value: "Estelí" },
            { key: "NI-GR", value: "Granada" },
            { key: "NI-JI", value: "Jinotega" },
            { key: "NI-LE", value: "León" },
            { key: "NI-MD", value: "Madriz" },
            { key: "NI-MN", value: "Managua" },
            { key: "NI-MS", value: "Masaya" },
            { key: "NI-MT", value: "Matagalpa" },
            { key: "NI-NS", value: "Nueva Segovia" },
            { key: "NI-RI", value: "Rivas" },
            { key: "NI-SJ", value: "Río San Juan" }
        ],
        "name": "Nicaragua"
    },
    "NE": {
        "states": [],
        "name": "Niger"
    },
    "NG": {
        "states": [
            { key: "AB", value: "Abia" },
            { key: "FC", value: "Abuja" },
            { key: "AD", value: "Adamawa" },
            { key: "AK", value: "Akwa Ibom" },
            { key: "AN", value: "Anambra" },
            { key: "BA", value: "Bauchi" },
            { key: "BY", value: "Bayelsa" },
            { key: "BE", value: "Benue" },
            { key: "BO", value: "Borno" },
            { key: "CR", value: "Cross River" },
            { key: "DE", value: "Delta" },
            { key: "EB", value: "Ebonyi" },
            { key: "ED", value: "Edo" },
            { key: "EK", value: "Ekiti" },
            { key: "EN", value: "Enugu" },
            { key: "GO", value: "Gombe" },
            { key: "IM", value: "Imo" },
            { key: "JI", value: "Jigawa" },
            { key: "KD", value: "Kaduna" },
            { key: "KN", value: "Kano" },
            { key: "KT", value: "Katsina" },
            { key: "KE", value: "Kebbi" },
            { key: "KO", value: "Kogi" },
            { key: "KW", value: "Kwara" },
            { key: "LA", value: "Lagos" },
            { key: "NA", value: "Nasarawa" },
            { key: "NI", value: "Niger" },
            { key: "OG", value: "Ogun" },
            { key: "ON", value: "Ondo" },
            { key: "OS", value: "Osun" },
            { key: "OY", value: "Oyo" },
            { key: "PL", value: "Plateau" },
            { key: "RI", value: "Rivers" },
            { key: "SO", value: "Sokoto" },
            { key: "TA", value: "Taraba" },
            { key: "YO", value: "Yobe" },
            { key: "ZA", value: "Zamfara" }
        ],
        "name": "Nigeria"
    },
    "NU": {
        "states": [],
        "name": "Niue"
    },
    "NF": {
        "states": [],
        "name": "Norfolk Island"
    },
    "KP": {
        "states": [],
        "name": "North Korea"
    },
    "MK": {
        "states": [],
        "name": "North Macedonia"
    },
    "MP": {
        "states": [],
        "name": "Northern Mariana Islands"
    },
    "NO": {
        "states": [],
        "name": "Norway"
    },
    "OM": {
        "states": [],
        "name": "Oman"
    },
    "PK": {
        "states": [
            { key: "JK", value: "Azad Kashmir" },
            { key: "BA", value: "Balochistan" },
            { key: "TA", value: "FATA" },
            { key: "GB", value: "Gilgit Baltistan" },
            { key: "IS", value: "Islamabad Capital Territory" },
            { key: "KP", value: "Khyber Pakhtunkhwa" },
            { key: "PB", value: "Punjab" },
            { key: "SD", value: "Sindh" }
        ],
        "name": "Pakistan"
    },
    "PS": {
        "states": [],
        "name": "Palestinian Territory"
    },
    "PA": {
        "states": [
            { key: "PA-1", value: "Bocas del Toro" },
            { key: "PA-2", value: "Coclé" },
            { key: "PA-3", value: "Colón" },
            { key: "PA-4", value: "Chiriquí" },
            { key: "PA-5", value: "Darién" },
            { key: "PA-6", value: "Herrera" },
            { key: "PA-7", value: "Los Santos" },
            { key: "PA-8", value: "Panamá" },
            { key: "PA-9", value: "Veraguas" },
            { key: "PA-10", value: "West Panamá" },
            { key: "PA-EM", value: "Emberá" },
            { key: "PA-KY", value: "Guna Yala" },
            { key: "PA-NB", value: "Ngöbe-Buglé" }
        ],
        "name": "Panama"
    },
    "PG": {
        "states": [],
        "name": "Papua New Guinea"
    },
    "PY": {
        "states": [
            { key: "PY-ASU", value: "Asunción" },
            { key: "PY-1", value: "Concepción" },
            { key: "PY-2", value: "San Pedro" },
            { key: "PY-3", value: "Cordillera" },
            { key: "PY-4", value: "Guairá" },
            { key: "PY-5", value: "Caaguazú" },
            { key: "PY-6", value: "Caazapá" },
            { key: "PY-7", value: "Itapúa" },
            { key: "PY-8", value: "Misiones" },
            { key: "PY-9", value: "Paraguarí" },
            { key: "PY-10", value: "Alto Paraná" },
            { key: "PY-11", value: "Central" },
            { key: "PY-12", value: "Ñeembucú" },
            { key: "PY-13", value: "Amambay" },
            { key: "PY-14", value: "Canindeyú" },
            { key: "PY-15", value: "Presidente Hayes" },
            { key: "PY-16", value: "Alto Paraguay" },
            { key: "PY-17", value: "Boquerón" }
        ],
        "name": "Paraguay"
    },
    "PE": {
        "states": [
            { key: "CAL", value: "El Callao" },
            { key: "LMA", value: "Municipalidad Metropolitana de Lima" },
            { key: "AMA", value: "Amazonas" },
            { key: "ANC", value: "Ancash" },
            { key: "APU", value: "Apurímac" },
            { key: "ARE", value: "Arequipa" },
            { key: "AYA", value: "Ayacucho" },
            { key: "CAJ", value: "Cajamarca" },
            { key: "CUS", value: "Cusco" },
            { key: "HUV", value: "Huancavelica" },
            { key: "HUC", value: "Huánuco" },
            { key: "ICA", value: "Ica" },
            { key: "JUN", value: "Junín" },
            { key: "LAL", value: "La Libertad" },
            { key: "LAM", value: "Lambayeque" },
            { key: "LIM", value: "Lima" },
            { key: "LOR", value: "Loreto" },
            { key: "MDD", value: "Madre de Dios" },
            { key: "MOQ", value: "Moquegua" },
            { key: "PAS", value: "Pasco" },
            { key: "PIU", value: "Piura" },
            { key: "PUN", value: "Puno" },
            { key: "SAM", value: "San Martín" },
            { key: "TAC", value: "Tacna" },
            { key: "TUM", value: "Tumbes" },
            { key: "UCA", value: "Ucayali" }
        ],
        "name": "Peru"
    },
    "PH": {
        "states": [
            { key: "ABR", value: "Abra" },
            { key: "AGN", value: "Agusan del Norte" },
            { key: "AGS", value: "Agusan del Sur" },
            { key: "AKL", value: "Aklan" },
            { key: "ALB", value: "Albay" },
            { key: "ANT", value: "Antique" },
            { key: "APA", value: "Apayao" },
            { key: "AUR", value: "Aurora" },
            { key: "BAS", value: "Basilan" },
            { key: "BAN", value: "Bataan" },
            { key: "BTN", value: "Batanes" },
            { key: "BTG", value: "Batangas" },
            { key: "BEN", value: "Benguet" },
            { key: "BIL", value: "Biliran" },
            { key: "BOH", value: "Bohol" },
            { key: "BUK", value: "Bukidnon" },
            { key: "BUL", value: "Bulacan" },
            { key: "CAG", value: "Cagayan" },
            { key: "CAN", value: "Camarines Norte" },
            { key: "CAS", value: "Camarines Sur" },
            { key: "CAM", value: "Camiguin" },
            { key: "CAP", value: "Capiz" },
            { key: "CAT", value: "Catanduanes" },
            { key: "CAV", value: "Cavite" },
            { key: "CEB", value: "Cebu" },
            { key: "COM", value: "Compostela Valley" },
            { key: "NCO", value: "Cotabato" },
            { key: "DAV", value: "Davao del Norte" },
            { key: "DAS", value: "Davao del Sur" },
            { key: "DAC", value: "Davao Occidental" },
            { key: "DAO", value: "Davao Oriental" },
            { key: "DIN", value: "Dinagat Islands" },
            { key: "EAS", value: "Eastern Samar" },
            { key: "GUI", value: "Guimaras" },
            { key: "IFU", value: "Ifugao" },
            { key: "ILN", value: "Ilocos Norte" },
            { key: "ILS", value: "Ilocos Sur" },
            { key: "ILI", value: "Iloilo" },
            { key: "ISA", value: "Isabela" },
            { key: "KAL", value: "Kalinga" },
            { key: "LUN", value: "La Union" },
            { key: "LAG", value: "Laguna" },
            { key: "LAN", value: "Lanao del Norte" },
            { key: "LAS", value: "Lanao del Sur" },
            { key: "LEY", value: "Leyte" },
            { key: "MAG", value: "Maguindanao" },
            { key: "MAD", value: "Marinduque" },
            { key: "MAS", value: "Masbate" },
            { key: "MSC", value: "Misamis Occidental" },
            { key: "MSR", value: "Misamis Oriental" },
            { key: "MOU", value: "Mountain Province" },
            { key: "NEC", value: "Negros Occidental" },
            { key: "NER", value: "Negros Oriental" },
            { key: "NSA", value: "Northern Samar" },
            { key: "NUE", value: "Nueva Ecija" },
            { key: "NUV", value: "Nueva Vizcaya" },
            { key: "MDC", value: "Occidental Mindoro" },
            { key: "MDR", value: "Oriental Mindoro" },
            { key: "PLW", value: "Palawan" },
            { key: "PAM", value: "Pampanga" },
            { key: "PAN", value: "Pangasinan" },
            { key: "QUE", value: "Quezon" },
            { key: "QUI", value: "Quirino" },
            { key: "RIZ", value: "Rizal" },
            { key: "ROM", value: "Romblon" },
            { key: "WSA", value: "Samar" },
            { key: "SAR", value: "Sarangani" },
            { key: "SIQ", value: "Siquijor" },
            { key: "SOR", value: "Sorsogon" },
            { key: "SCO", value: "South Cotabato" },
            { key: "SLE", value: "Southern Leyte" },
            { key: "SUK", value: "Sultan Kudarat" },
            { key: "SLU", value: "Sulu" },
            { key: "SUN", value: "Surigao del Norte" },
            { key: "SUR", value: "Surigao del Sur" },
            { key: "TAR", value: "Tarlac" },
            { key: "TAW", value: "Tawi-Tawi" },
            { key: "ZMB", value: "Zambales" },
            { key: "ZAN", value: "Zamboanga del Norte" },
            { key: "ZAS", value: "Zamboanga del Sur" },
            { key: "ZSI", value: "Zamboanga Sibugay" },
            { key: "00", value: "Metro Manila" }
        ],
        "name": "Philippines"
    },
    "PN": {
        "states": [],
        "name": "Pitcairn"
    },
    "PL": {
        "states": [],
        "name": "Poland"
    },
    "PT": {
        "states": [],
        "name": "Portugal"
    },
    "PR": {
        "states": [],
        "name": "Puerto Rico"
    },
    "QA": {
        "states": [],
        "name": "Qatar"
    },
    "RE": {
        "states": [],
        "name": "Reunion"
    },
    "RO": {
        "states": [
            { key: "AB", value: "Alba" },
            { key: "AR", value: "Arad" },
            { key: "AG", value: "Argeș" },
            { key: "BC", value: "Bacău" },
            { key: "BH", value: "Bihor" },
            { key: "BN", value: "Bistrița-Năsăud" },
            { key: "BT", value: "Botoșani" },
            { key: "BR", value: "Brăila" },
            { key: "BV", value: "Brașov" },
            { key: "B", value: "București" },
            { key: "BZ", value: "Buzău" },
            { key: "CL", value: "Călărași" },
            { key: "CS", value: "Caraș-Severin" },
            { key: "CJ", value: "Cluj" },
            { key: "CT", value: "Constanța" },
            { key: "CV", value: "Covasna" },
            { key: "DB", value: "Dâmbovița" },
            { key: "DJ", value: "Dolj" },
            { key: "GL", value: "Galați" },
            { key: "GR", value: "Giurgiu" },
            { key: "GJ", value: "Gorj" },
            { key: "HR", value: "Harghita" },
            { key: "HD", value: "Hunedoara" },
            { key: "IL", value: "Ialomița" },
            { key: "IS", value: "Iași" },
            { key: "IF", value: "Ilfov" },
            { key: "MM", value: "Maramureș" },
            { key: "MH", value: "Mehedinți" },
            { key: "MS", value: "Mureș" },
            { key: "NT", value: "Neamț" },
            { key: "OT", value: "Olt" },
            { key: "PH", value: "Prahova" },
            { key: "SJ", value: "Sălaj" },
            { key: "SM", value: "Satu Mare" },
            { key: "SB", value: "Sibiu" },
            { key: "SV", value: "Suceava" },
            { key: "TR", value: "Teleorman" },
            { key: "TM", value: "Timiș" },
            { key: "TL", value: "Tulcea" },
            { key: "VL", value: "Vâlcea" },
            { key: "VS", value: "Vaslui" },
            { key: "VN", value: "Vrancea" }
        ],
        "name": "Romania"
    },
    "RU": {
        "states": [],
        "name": "Russia"
    },
    "RW": {
        "states": [],
        "name": "Rwanda"
    },
    "ST": {
        "states": [],
        "name": "S&atilde;o Tom&eacute; and Pr&iacute;ncipe"
    },
    "BL": {
        "states": [],
        "name": "Saint Barth&eacute;lemy"
    },
    "SH": {
        "states": [],
        "name": "Saint Helena"
    },
    "KN": {
        "states": [],
        "name": "Saint Kitts and Nevis"
    },
    "LC": {
        "states": [],
        "name": "Saint Lucia"
    },
    "SX": {
        "states": [],
        "name": "Saint Martin (Dutch part)"
    },
    "MF": {
        "states": [],
        "name": "Saint Martin (French part)"
    },
    "PM": {
        "states": [],
        "name": "Saint Pierre and Miquelon"
    },
    "VC": {
        "states": [],
        "name": "Saint Vincent and the Grenadines"
    },
    "WS": {
        "states": [],
        "name": "Samoa"
    },
    "SM": {
        "states": [],
        "name": "San Marino"
    },
    "SA": {
        "states": [],
        "name": "Saudi Arabia"
    },
    "SN": {
        "states": [
            { key: "SNDB", value: "Diourbel" },
            { key: "SNDK", value: "Dakar" },
            { key: "SNFK", value: "Fatick" },
            { key: "SNKA", value: "Kaffrine" },
            { key: "SNKD", value: "Kolda" },
            { key: "SNKE", value: "Kédougou" },
            { key: "SNKL", value: "Kaolack" },
            { key: "SNLG", value: "Louga" },
            { key: "SNMT", value: "Matam" },
            { key: "SNSE", value: "Sédhiou" },
            { key: "SNSL", value: "Saint-Louis" },
            { key: "SNTC", value: "Tambacounda" },
            { key: "SNTH", value: "Thiès" },
            { key: "SNZG", value: "Ziguinchor" }
        ],
        "name": "Senegal"
    },
    "RS": {
        "states": [
            { key: "RS00", value: "Belgrade" },
            { key: "RS14", value: "Bor" },
            { key: "RS11", value: "Braničevo" },
            { key: "RS02", value: "Central Banat" },
            { key: "RS10", value: "Danube" },
            { key: "RS23", value: "Jablanica" },
            { key: "RS09", value: "Kolubara" },
            { key: "RS08", value: "Mačva" },
            { key: "RS17", value: "Morava" },
            { key: "RS20", value: "Nišava" },
            { key: "RS01", value: "North Bačka" },
            { key: "RS03", value: "North Banat" },
            { key: "RS24", value: "Pčinja" },
            { key: "RS22", value: "Pirot" },
            { key: "RS13", value: "Pomoravlje" },
            { key: "RS19", value: "Rasina" },
            { key: "RS18", value: "Raška" },
            { key: "RS06", value: "South Bačka" },
            { key: "RS04", value: "South Banat" },
            { key: "RS07", value: "Srem" },
            { key: "RS12", value: "Šumadija" },
            { key: "RS21", value: "Toplica" },
            { key: "RS05", value: "West Bačka" },
            { key: "RS15", value: "Zaječar" },
            { key: "RS16", value: "Zlatibor" },
            { key: "RS25", value: "Kosovo" },
            { key: "RS26", value: "Peć" },
            { key: "RS27", value: "Prizren" },
            { key: "RS28", value: "Kosovska Mitrovica" },
            { key: "RS29", value: "Kosovo-Pomoravlje" },
            { key: "RSKM", value: "Kosovo-Metohija" },
            { key: "RSVO", value: "Vojvodina" }
        ],
        "name": "Serbia"
    },
    "SC": {
        "states": [],
        "name": "Seychelles"
    },
    "SL": {
        "states": [],
        "name": "Sierra Leone"
    },
    "SG": {
        "states": [],
        "name": "Singapore"
    },
    "SK": {
        "states": [],
        "name": "Slovakia"
    },
    "SI": {
        "states": [],
        "name": "Slovenia"
    },
    "SB": {
        "states": [],
        "name": "Solomon Islands"
    },
    "SO": {
        "states": [],
        "name": "Somalia"
    },
    "ZA": {
        "states": [
            { key: "EC", value: "Eastern Cape" },
            { key: "FS", value: "Free State" },
            { key: "GP", value: "Gauteng" },
            { key: "KZN", value: "KwaZulu-Natal" },
            { key: "LP", value: "Limpopo" },
            { key: "MP", value: "Mpumalanga" },
            { key: "NC", value: "Northern Cape" },
            { key: "NW", value: "North West" },
            { key: "WC", value: "Western Cape" }
        ],
        "name": "South Africa"
    },
    "GS": {
        "states": [],
        "name": "South Georgia/Sandwich Islands"
    },
    "KR": {
        "states": [],
        "name": "South Korea"
    },
    "SS": {
        "states": [],
        "name": "South Sudan"
    },
    "ES": {
        "states": [
            { key: "C", value: "A Coruña" },
            { key: "VI", value: "Araba/Álava" },
            { key: "AB", value: "Albacete" },
            { key: "A", value: "Alicante" },
            { key: "AL", value: "Almería" },
            { key: "O", value: "Asturias" },
            { key: "AV", value: "Ávila" },
            { key: "BA", value: "Badajoz" },
            { key: "PM", value: "Baleares" },
            { key: "B", value: "Barcelona" },
            { key: "BU", value: "Burgos" },
            { key: "CC", value: "Cáceres" },
            { key: "CA", value: "Cádiz" },
            { key: "S", value: "Cantabria" },
            { key: "CS", value: "Castellón" },
            { key: "CE", value: "Ceuta" },
            { key: "CR", value: "Ciudad Real" },
            { key: "CO", value: "Córdoba" },
            { key: "CU", value: "Cuenca" },
            { key: "GI", value: "Girona" },
            { key: "GR", value: "Granada" },
            { key: "GU", value: "Guadalajara" },
            { key: "SS", value: "Gipuzkoa" },
            { key: "H", value: "Huelva" },
            { key: "HU", value: "Huesca" },
            { key: "J", value: "Jaén" },
            { key: "LO", value: "La Rioja" },
            { key: "GC", value: "Las Palmas" },
            { key: "LE", value: "León" },
            { key: "L", value: "Lleida" },
            { key: "LU", value: "Lugo" },
            { key: "M", value: "Madrid" },
            { key: "MA", value: "Málaga" },
            { key: "ML", value: "Melilla" },
            { key: "MU", value: "Murcia" },
            { key: "NA", value: "Navarra" },
            { key: "OR", value: "Ourense" },
            { key: "P", value: "Palencia" },
            { key: "PO", value: "Pontevedra" },
            { key: "SA", value: "Salamanca" },
            { key: "TF", value: "Santa Cruz de Tenerife" },
            { key: "SG", value: "Segovia" },
            { key: "SE", value: "Sevilla" },
            { key: "SO", value: "Soria" },
            { key: "T", value: "Tarragona" },
            { key: "TE", value: "Teruel" },
            { key: "TO", value: "Toledo" },
            { key: "V", value: "Valencia" },
            { key: "VA", value: "Valladolid" },
            { key: "BI", value: "Biscay" },
            { key: "ZA", value: "Zamora" },
            { key: "Z", value: "Zaragoza" }
        ],
        "name": "Spain"
    },
    "LK": {
        "states": [],
        "name": "Sri Lanka"
    },
    "SD": {
        "states": [],
        "name": "Sudan"
    },
    "SR": {
        "states": [],
        "name": "Suriname"
    },
    "SJ": {
        "states": [],
        "name": "Svalbard and Jan Mayen"
    },
    "SE": {
        "states": [],
        "name": "Sweden"
    },
    "CH": {
        "states": [
            { key: "AG", value: "Aargau" },
            { key: "AR", value: "Appenzell Ausserrhoden" },
            { key: "AI", value: "Appenzell Innerrhoden" },
            { key: "BL", value: "Basel-Landschaft" },
            { key: "BS", value: "Basel-Stadt" },
            { key: "BE", value: "Bern" },
            { key: "FR", value: "Fribourg" },
            { key: "GE", value: "Geneva" },
            { key: "GL", value: "Glarus" },
            { key: "GR", value: "Graubünden" },
            { key: "JU", value: "Jura" },
            { key: "LU", value: "Luzern" },
            { key: "NE", value: "Neuchâtel" },
            { key: "NW", value: "Nidwalden" },
            { key: "OW", value: "Obwalden" },
            { key: "SH", value: "Schaffhausen" },
            { key: "SZ", value: "Schwyz" },
            { key: "SO", value: "Solothurn" },
            { key: "SG", value: "St. Gallen" },
            { key: "TG", value: "Thurgau" },
            { key: "TI", value: "Ticino" },
            { key: "UR", value: "Uri" },
            { key: "VS", value: "Valais" },
            { key: "VD", value: "Vaud" },
            { key: "ZG", value: "Zug" },
            { key: "ZH", value: "Zürich" }
        ],
        "name": "Switzerland"
    },
    "SY": {
        "states": [],
        "name": "Syria"
    },
    "TW": {
        "states": [],
        "name": "Taiwan"
    },
    "TJ": {
        "states": [],
        "name": "Tajikistan"
    },
    "TZ": {
        "states": [
            { key: "TZ01", value: "Arusha" },
            { key: "TZ02", value: "Dar es Salaam" },
            { key: "TZ03", value: "Dodoma" },
            { key: "TZ04", value: "Iringa" },
            { key: "TZ05", value: "Kagera" },
            { key: "TZ06", value: "Pemba North" },
            { key: "TZ07", value: "Zanzibar North" },
            { key: "TZ08", value: "Kigoma" },
            { key: "TZ09", value: "Kilimanjaro" },
            { key: "TZ10", value: "Pemba South" },
            { key: "TZ11", value: "Zanzibar South" },
            { key: "TZ12", value: "Lindi" },
            { key: "TZ13", value: "Mara" },
            { key: "TZ14", value: "Mbeya" },
            { key: "TZ15", value: "Zanzibar West" },
            { key: "TZ16", value: "Morogoro" },
            { key: "TZ17", value: "Mtwara" },
            { key: "TZ18", value: "Mwanza" },
            { key: "TZ19", value: "Coast" },
            { key: "TZ20", value: "Rukwa" },
            { key: "TZ21", value: "Ruvuma" },
            { key: "TZ22", value: "Shinyanga" },
            { key: "TZ23", value: "Singida" },
            { key: "TZ24", value: "Tabora" },
            { key: "TZ25", value: "Tanga" },
            { key: "TZ26", value: "Manyara" },
            { key: "TZ27", value: "Geita" },
            { key: "TZ28", value: "Katavi" },
            { key: "TZ29", value: "Njombe" },
            { key: "TZ30", value: "Simiyu" }
        ],
        "name": "Tanzania"
    },
    "TH": {
        "states": [
            { key: "TH-37", value: "Amnat Charoen" },
            { key: "TH-15", value: "Ang Thong" },
            { key: "TH-14", value: "Ayutthaya" },
            { key: "TH-10", value: "Bangkok" },
            { key: "TH-38", value: "Bueng Kan" },
            { key: "TH-31", value: "Buri Ram" },
            { key: "TH-24", value: "Chachoengsao" },
            { key: "TH-18", value: "Chai Nat" },
            { key: "TH-36", value: "Chaiyaphum" },
            { key: "TH-22", value: "Chanthaburi" },
            { key: "TH-50", value: "Chiang Mai" },
            { key: "TH-57", value: "Chiang Rai" },
            { key: "TH-20", value: "Chonburi" },
            { key: "TH-86", value: "Chumphon" },
            { key: "TH-46", value: "Kalasin" },
            { key: "TH-62", value: "Kamphaeng Phet" },
            { key: "TH-71", value: "Kanchanaburi" },
            { key: "TH-40", value: "Khon Kaen" },
            { key: "TH-81", value: "Krabi" },
            { key: "TH-52", value: "Lampang" },
            { key: "TH-51", value: "Lamphun" },
            { key: "TH-42", value: "Loei" },
            { key: "TH-16", value: "Lopburi" },
            { key: "TH-58", value: "Mae Hong Son" },
            { key: "TH-44", value: "Maha Sarakham" },
            { key: "TH-49", value: "Mukdahan" },
            { key: "TH-26", value: "Nakhon Nayok" },
            { key: "TH-73", value: "Nakhon Pathom" },
            { key: "TH-48", value: "Nakhon Phanom" },
            { key: "TH-30", value: "Nakhon Ratchasima" },
            { key: "TH-60", value: "Nakhon Sawan" },
            { key: "TH-80", value: "Nakhon Si Thammarat" },
            { key: "TH-55", value: "Nan" },
            { key: "TH-96", value: "Narathiwat" },
            { key: "TH-39", value: "Nong Bua Lam Phu" },
            { key: "TH-43", value: "Nong Khai" },
            { key: "TH-12", value: "Nonthaburi" },
            { key: "TH-13", value: "Pathum Thani" },
            { key: "TH-94", value: "Pattani" },
            { key: "TH-82", value: "Phang Nga" },
            { key: "TH-93", value: "Phatthalung" },
            { key: "TH-56", value: "Phayao" },
            { key: "TH-67", value: "Phetchabun" },
            { key: "TH-76", value: "Phetchaburi" },
            { key: "TH-66", value: "Phichit" },
            { key: "TH-65", value: "Phitsanulok" },
            { key: "TH-54", value: "Phrae" },
            { key: "TH-83", value: "Phuket" },
            { key: "TH-25", value: "Prachin Buri" },
            { key: "TH-77", value: "Prachuap Khiri Khan" },
            { key: "TH-85", value: "Ranong" },
            { key: "TH-70", value: "Ratchaburi" },
            { key: "TH-21", value: "Rayong" },
            { key: "TH-45", value: "Roi Et" },
            { key: "TH-27", value: "Sa Kaeo" },
            { key: "TH-47", value: "Sakon Nakhon" },
            { key: "TH-11", value: "Samut Prakan" },
            { key: "TH-74", value: "Samut Sakhon" },
            { key: "TH-75", value: "Samut Songkhram" },
            { key: "TH-19", value: "Saraburi" },
            { key: "TH-91", value: "Satun" },
            { key: "TH-17", value: "Sing Buri" },
            { key: "TH-33", value: "Sisaket" },
            { key: "TH-90", value: "Songkhla" },
            { key: "TH-64", value: "Sukhothai" },
            { key: "TH-72", value: "Suphan Buri" },
            { key: "TH-84", value: "Surat Thani" },
            { key: "TH-32", value: "Surin" },
            { key: "TH-63", value: "Tak" },
            { key: "TH-92", value: "Trang" },
            { key: "TH-23", value: "Trat" },
            { key: "TH-34", value: "Ubon Ratchathani" },
            { key: "TH-41", value: "Udon Thani" },
            { key: "TH-61", value: "Uthai Thani" },
            { key: "TH-53", value: "Uttaradit" },
            { key: "TH-95", value: "Yala" },
            { key: "TH-35", value: "Yasothon" }
        ],
        "name": "Thailand"
    },
    "TL": {
        "states": [],
        "name": "Timor-Leste"
    },
    "TG": {
        "states": [],
        "name": "Togo"
    },
    "TK": {
        "states": [],
        "name": "Tokelau"
    },
    "TO": {
        "states": [],
        "name": "Tonga"
    },
    "TT": {
        "states": [],
        "name": "Trinidad and Tobago"
    },
    "TN": {
        "states": [],
        "name": "Tunisia"
    },
    "TR": {
        "states": [
            { key: "TR01", value: "Adana" },
            { key: "TR02", value: "Adıyaman" },
            { key: "TR03", value: "Afyon" },
            { key: "TR04", value: "Ağrı" },
            { key: "TR05", value: "Amasya" },
            { key: "TR06", value: "Ankara" },
            { key: "TR07", value: "Antalya" },
            { key: "TR08", value: "Artvin" },
            { key: "TR09", value: "Aydın" },
            { key: "TR10", value: "Balıkesir" },
            { key: "TR11", value: "Bilecik" },
            { key: "TR12", value: "Bingöl" },
            { key: "TR13", value: "Bitlis" },
            { key: "TR14", value: "Bolu" },
            { key: "TR15", value: "Burdur" },
            { key: "TR16", value: "Bursa" },
            { key: "TR17", value: "Çanakkale" },
            { key: "TR18", value: "Çankırı" },
            { key: "TR19", value: "Çorum" },
            { key: "TR20", value: "Denizli" },
            { key: "TR21", value: "Diyarbakır" },
            { key: "TR22", value: "Edirne" },
            { key: "TR23", value: "Elazığ" },
            { key: "TR24", value: "Erzincan" },
            { key: "TR25", value: "Erzurum" },
            { key: "TR26", value: "Eskişehir" },
            { key: "TR27", value: "Gaziantep" },
            { key: "TR28", value: "Giresun" },
            { key: "TR29", value: "Gümüşhane" },
            { key: "TR30", value: "Hakkari" },
            { key: "TR31", value: "Hatay" },
            { key: "TR32", value: "Isparta" },
            { key: "TR33", value: "İçel" },
            { key: "TR34", value: "İstanbul" },
            { key: "TR35", value: "İzmir" },
            { key: "TR36", value: "Kars" },
            { key: "TR37", value: "Kastamonu" },
            { key: "TR38", value: "Kayseri" },
            { key: "TR39", value: "Kırklareli" },
            { key: "TR40", value: "Kırşehir" },
            { key: "TR41", value: "Kocaeli" },
            { key: "TR42", value: "Konya" },
            { key: "TR43", value: "Kütahya" },
            { key: "TR44", value: "Malatya" },
            { key: "TR45", value: "Manisa" },
            { key: "TR46", value: "Kahramanmaraş" },
            { key: "TR47", value: "Mardin" },
            { key: "TR48", value: "Muğla" },
            { key: "TR49", value: "Muş" },
            { key: "TR50", value: "Nevşehir" },
            { key: "TR51", value: "Niğde" },
            { key: "TR52", value: "Ordu" },
            { key: "TR53", value: "Rize" },
            { key: "TR54", value: "Sakarya" },
            { key: "TR55", value: "Samsun" },
            { key: "TR56", value: "Siirt" },
            { key: "TR57", value: "Sinop" },
            { key: "TR58", value: "Sivas" },
            { key: "TR59", value: "Tekirdağ" },
            { key: "TR60", value: "Tokat" },
            { key: "TR61", value: "Trabzon" },
            { key: "TR62", value: "Tunceli" },
            { key: "TR63", value: "Şanlıurfa" },
            { key: "TR64", value: "Uşak" },
            { key: "TR65", value: "Van" },
            { key: "TR66", value: "Yozgat" },
            { key: "TR67", value: "Zonguldak" },
            { key: "TR68", value: "Aksaray" },
            { key: "TR69", value: "Bayburt" },
            { key: "TR70", value: "Karaman" },
            { key: "TR71", value: "Kırıkkale" },
            { key: "TR72", value: "Batman" },
            { key: "TR73", value: "Şırnak" },
            { key: "TR74", value: "Bartın" },
            { key: "TR75", value: "Ardahan" },
            { key: "TR76", value: "Iğdır" },
            { key: "TR77", value: "Yalova" },
            { key: "TR78", value: "Karabük" },
            { key: "TR79", value: "Kilis" },
            { key: "TR80", value: "Osmaniye" },
            { key: "TR81", value: "Düzce" }
        ],
        "name": "Turkey"
    },
    "TM": {
        "states": [],
        "name": "Turkmenistan"
    },
    "TC": {
        "states": [],
        "name": "Turks and Caicos Islands"
    },
    "TV": {
        "states": [],
        "name": "Tuvalu"
    },
    "UG": {
        "states": [
            { key: "UG314", value: "Abim" },
            { key: "UG301", value: "Adjumani" },
            { key: "UG322", value: "Agago" },
            { key: "UG323", value: "Alebtong" },
            { key: "UG315", value: "Amolatar" },
            { key: "UG324", value: "Amudat" },
            { key: "UG216", value: "Amuria" },
            { key: "UG316", value: "Amuru" },
            { key: "UG302", value: "Apac" },
            { key: "UG303", value: "Arua" },
            { key: "UG217", value: "Budaka" },
            { key: "UG218", value: "Bududa" },
            { key: "UG201", value: "Bugiri" },
            { key: "UG235", value: "Bugweri" },
            { key: "UG420", value: "Buhweju" },
            { key: "UG117", value: "Buikwe" },
            { key: "UG219", value: "Bukedea" },
            { key: "UG118", value: "Bukomansimbi" },
            { key: "UG220", value: "Bukwa" },
            { key: "UG225", value: "Bulambuli" },
            { key: "UG416", value: "Buliisa" },
            { key: "UG401", value: "Bundibugyo" },
            { key: "UG430", value: "Bunyangabu" },
            { key: "UG402", value: "Bushenyi" },
            { key: "UG202", value: "Busia" },
            { key: "UG221", value: "Butaleja" },
            { key: "UG119", value: "Butambala" },
            { key: "UG233", value: "Butebo" },
            { key: "UG120", value: "Buvuma" },
            { key: "UG226", value: "Buyende" },
            { key: "UG317", value: "Dokolo" },
            { key: "UG121", value: "Gomba" },
            { key: "UG304", value: "Gulu" },
            { key: "UG403", value: "Hoima" },
            { key: "UG417", value: "Ibanda" },
            { key: "UG203", value: "Iganga" },
            { key: "UG418", value: "Isingiro" },
            { key: "UG204", value: "Jinja" },
            { key: "UG318", value: "Kaabong" },
            { key: "UG404", value: "Kabale" },
            { key: "UG405", value: "Kabarole" },
            { key: "UG213", value: "Kaberamaido" },
            { key: "UG427", value: "Kagadi" },
            { key: "UG428", value: "Kakumiro" },
            { key: "UG101", value: "Kalangala" },
            { key: "UG222", value: "Kaliro" },
            { key: "UG122", value: "Kalungu" },
            { key: "UG102", value: "Kampala" },
            { key: "UG205", value: "Kamuli" },
            { key: "UG413", value: "Kamwenge" },
            { key: "UG414", value: "Kanungu" },
            { key: "UG206", value: "Kapchorwa" },
            { key: "UG236", value: "Kapelebyong" },
            { key: "UG126", value: "Kasanda" },
            { key: "UG406", value: "Kasese" },
            { key: "UG207", value: "Katakwi" },
            { key: "UG112", value: "Kayunga" },
            { key: "UG407", value: "Kibaale" },
            { key: "UG103", value: "Kiboga" },
            { key: "UG227", value: "Kibuku" },
            { key: "UG432", value: "Kikuube" },
            { key: "UG419", value: "Kiruhura" },
            { key: "UG421", value: "Kiryandongo" },
            { key: "UG408", value: "Kisoro" },
            { key: "UG305", value: "Kitgum" },
            { key: "UG319", value: "Koboko" },
            { key: "UG325", value: "Kole" },
            { key: "UG306", value: "Kotido" },
            { key: "UG208", value: "Kumi" },
            { key: "UG333", value: "Kwania" },
            { key: "UG228", value: "Kween" },
            { key: "UG123", value: "Kyankwanzi" },
            { key: "UG422", value: "Kyegegwa" },
            { key: "UG415", value: "Kyenjojo" },
            { key: "UG125", value: "Kyotera" },
            { key: "UG326", value: "Lamwo" },
            { key: "UG307", value: "Lira" },
            { key: "UG229", value: "Luuka" },
            { key: "UG104", value: "Luwero" },
            { key: "UG124", value: "Lwengo" },
            { key: "UG114", value: "Lyantonde" },
            { key: "UG223", value: "Manafwa" },
            { key: "UG320", value: "Maracha" },
            { key: "UG105", value: "Masaka" },
            { key: "UG409", value: "Masindi" },
            { key: "UG214", value: "Mayuge" },
            { key: "UG209", value: "Mbale" },
            { key: "UG410", value: "Mbarara" },
            { key: "UG423", value: "Mitooma" },
            { key: "UG115", value: "Mityana" },
            { key: "UG308", value: "Moroto" },
            { key: "UG309", value: "Moyo" },
            { key: "UG106", value: "Mpigi" },
            { key: "UG107", value: "Mubende" },
            { key: "UG108", value: "Mukono" },
            { key: "UG334", value: "Nabilatuk" },
            { key: "UG311", value: "Nakapiripirit" },
            { key: "UG116", value: "Nakaseke" },
            { key: "UG109", value: "Nakasongola" },
            { key: "UG230", value: "Namayingo" },
            { key: "UG234", value: "Namisindwa" },
            { key: "UG224", value: "Namutumba" },
            { key: "UG327", value: "Napak" },
            { key: "UG310", value: "Nebbi" },
            { key: "UG231", value: "Ngora" },
            { key: "UG424", value: "Ntoroko" },
            { key: "UG411", value: "Ntungamo" },
            { key: "UG328", value: "Nwoya" },
            { key: "UG331", value: "Omoro" },
            { key: "UG329", value: "Otuke" },
            { key: "UG321", value: "Oyam" },
            { key: "UG312", value: "Pader" },
            { key: "UG332", value: "Pakwach" },
            { key: "UG210", value: "Pallisa" },
            { key: "UG110", value: "Rakai" },
            { key: "UG429", value: "Rubanda" },
            { key: "UG425", value: "Rubirizi" },
            { key: "UG431", value: "Rukiga" },
            { key: "UG412", value: "Rukungiri" },
            { key: "UG111", value: "Sembabule" },
            { key: "UG232", value: "Serere" },
            { key: "UG426", value: "Sheema" },
            { key: "UG215", value: "Sironko" },
            { key: "UG211", value: "Soroti" },
            { key: "UG212", value: "Tororo" },
            { key: "UG113", value: "Wakiso" },
            { key: "UG313", value: "Yumbe" },
            { key: "UG330", value: "Zombo" }
        ],
        "name": "Uganda"
    },
    "UA": {
        "states": [
            { key: 'UA05', value: 'Vinnychchyna' },
            { key: 'UA07', value: 'Volyn' },
            { key: 'UA09', value: 'Luhanshchyna' },
            { key: 'UA12', value: 'Dnipropetrovshchyna' },
            { key: 'UA14', value: 'Donechchyna' },
            { key: 'UA18', value: 'Zhytomyrshchyna' },
            { key: 'UA21', value: 'Zakarpattia' },
            { key: 'UA23', value: 'Zaporizhzhya' },
            { key: 'UA26', value: 'Prykarpattia' },
            { key: 'UA30', value: 'Kyiv' },
            { key: 'UA32', value: 'Kyivshchyna' },
            { key: 'UA35', value: 'Kirovohradschyna' },
            { key: 'UA40', value: 'Sevastopol' },
            { key: 'UA43', value: 'Crimea' },
            { key: 'UA46', value: 'Lvivshchyna' },
            { key: 'UA48', value: 'Mykolayivschyna' },
            { key: 'UA51', value: 'Odeshchyna' },
            { key: 'UA53', value: 'Poltavshchyna' },
            { key: 'UA56', value: 'Rivnenshchyna' },
            { key: 'UA59', value: 'Sumshchyna' },
            { key: 'UA61', value: 'Ternopilshchyna' },
            { key: 'UA63', value: 'Kharkivshchyna' },
            { key: 'UA65', value: 'Khersonshchyna' },
            { key: 'UA68', value: 'Khmelnychchyna' },
            { key: 'UA71', value: 'Cherkashchyna' },
            { key: 'UA74', value: 'Chernihivshchyna' },
            { key: 'UA77', value: 'Chernivtsi Oblast' }
        ],
        "name": "Ukraine"
    },
    "AE": {
        "states": [],
        "name": "United Arab Emirates"
    },
    "GB": {
        "states": [],
        "name": "United Kingdom (UK)"
    },
    "US": {
        "states": [
            { key: 'AL', value: 'Alabama' },
            { key: 'AK', value: 'Alaska' },
            { key: 'AZ', value: 'Arizona' },
            { key: 'AR', value: 'Arkansas' },
            { key: 'CA', value: 'California' },
            { key: 'CO', value: 'Colorado' },
            { key: 'CT', value: 'Connecticut' },
            { key: 'DE', value: 'Delaware' },
            { key: 'DC', value: 'District Of Columbia' },
            { key: 'FL', value: 'Florida' },
            { key: 'GA', value: 'Georgia' },
            { key: 'HI', value: 'Hawaii' },
            { key: 'ID', value: 'Idaho' },
            { key: 'IL', value: 'Illinois' },
            { key: 'IN', value: 'Indiana' },
            { key: 'IA', value: 'Iowa' },
            { key: 'KS', value: 'Kansas' },
            { key: 'KY', value: 'Kentucky' },
            { key: 'LA', value: 'Louisiana' },
            { key: 'ME', value: 'Maine' },
            { key: 'MD', value: 'Maryland' },
            { key: 'MA', value: 'Massachusetts' },
            { key: 'MI', value: 'Michigan' },
            { key: 'MN', value: 'Minnesota' },
            { key: 'MS', value: 'Mississippi' },
            { key: 'MO', value: 'Missouri' },
            { key: 'MT', value: 'Montana' },
            { key: 'NE', value: 'Nebraska' },
            { key: 'NV', value: 'Nevada' },
            { key: 'NH', value: 'New Hampshire' },
            { key: 'NJ', value: 'New Jersey' },
            { key: 'NM', value: 'New Mexico' },
            { key: 'NY', value: 'New York' },
            { key: 'NC', value: 'North Carolina' },
            { key: 'ND', value: 'North Dakota' },
            { key: 'OH', value: 'Ohio' },
            { key: 'OK', value: 'Oklahoma' },
            { key: 'OR', value: 'Oregon' },
            { key: 'PA', value: 'Pennsylvania' },
            { key: 'RI', value: 'Rhode Island' },
            { key: 'SC', value: 'South Carolina' },
            { key: 'SD', value: 'South Dakota' },
            { key: 'TN', value: 'Tennessee' },
            { key: 'TX', value: 'Texas' },
            { key: 'UT', value: 'Utah' },
            { key: 'VT', value: 'Vermont' },
            { key: 'VA', value: 'Virginia' },
            { key: 'WA', value: 'Washington' },
            { key: 'WV', value: 'West Virginia' },
            { key: 'WI', value: 'Wisconsin' },
            { key: 'WY', value: 'Wyoming' },
            { key: 'AA', value: 'Armed Forces (AA)' },
            { key: 'AE', value: 'Armed Forces (AE)' },
            { key: 'AP', value: 'Armed Forces (AP)' }
        ],
        "name": "United States (US)"
    },
    "UM": {
        "states": [
            { key: '67', value: 'Johnston Atoll' },
            { key: '71', value: 'Midway Atoll' },
            { key: '76', value: 'Navassa Island' },
            { key: '79', value: 'Wake Island' },
            { key: '81', value: 'Baker Island' },
            { key: '84', value: 'Howland Island' },
            { key: '86', value: 'Jarvis Island' },
            { key: '89', value: 'Kingman Reef' },
            { key: '95', value: 'Palmyra Atoll' }
        ],
        "name": "United States (US) Minor Outlying Islands"
    },
    "UY": {
        "states": [
            { key: 'UY-AR', value: 'Artigas' },
            { key: 'UY-CA', value: 'Canelones' },
            { key: 'UY-CL', value: 'Cerro Largo' },
            { key: 'UY-CO', value: 'Colonia' },
            { key: 'UY-DU', value: 'Durazno' },
            { key: 'UY-FS', value: 'Flores' },
            { key: 'UY-FD', value: 'Florida' },
            { key: 'UY-LA', value: 'Lavalleja' },
            { key: 'UY-MA', value: 'Maldonado' },
            { key: 'UY-MO', value: 'Montevideo' },
            { key: 'UY-PA', value: 'Paysandú' },
            { key: 'UY-RN', value: 'Río Negro' },
            { key: 'UY-RV', value: 'Rivera' },
            { key: 'UY-RO', value: 'Rocha' },
            { key: 'UY-SA', value: 'Salto' },
            { key: 'UY-SJ', value: 'San José' },
            { key: 'UY-SO', value: 'Soriano' },
            { key: 'UY-TA', value: 'Tacuarembó' },
            { key: 'UY-TT', value: 'Treinta y Tres' }
        ],
        "name": "Uruguay"
    },
    "UZ": {
        "states": [],
        "name": "Uzbekistan"
    },
    "VU": {
        "states": [],
        "name": "Vanuatu"
    },
    "VA": {
        "states": [],
        "name": "Vatican"
    },
    "VE": {
        "states": [
            { key: 'VE-A', value: 'Capital' },
            { key: 'VE-B', value: 'Anzoátegui' },
            { key: 'VE-C', value: 'Apure' },
            { key: 'VE-D', value: 'Aragua' },
            { key: 'VE-E', value: 'Barinas' },
            { key: 'VE-F', value: 'Bolívar' },
            { key: 'VE-G', value: 'Carabobo' },
            { key: 'VE-H', value: 'Cojedes' },
            { key: 'VE-I', value: 'Falcón' },
            { key: 'VE-J', value: 'Guárico' },
            { key: 'VE-K', value: 'Lara' },
            { key: 'VE-L', value: 'Mérida' },
            { key: 'VE-M', value: 'Miranda' },
            { key: 'VE-N', value: 'Monagas' },
            { key: 'VE-O', value: 'Nueva Esparta' },
            { key: 'VE-P', value: 'Portuguesa' },
            { key: 'VE-R', value: 'Sucre' },
            { key: 'VE-S', value: 'Táchira' },
            { key: 'VE-T', value: 'Trujillo' },
            { key: 'VE-U', value: 'Yaracuy' },
            { key: 'VE-V', value: 'Zulia' },
            { key: 'VE-W', value: 'Federal Dependencies' },
            { key: 'VE-X', value: 'La Guaira (Vargas)' },
            { key: 'VE-Y', value: 'Delta Amacuro' },
            { key: 'VE-Z', value: 'Amazonas' }
        ],
        "name": "Venezuela"
    },
    "VN": {
        "states": [],
        "name": "Vietnam"
    },
    "VG": {
        "states": [],
        "name": "Virgin Islands (British)"
    },
    "VI": {
        "states": [],
        "name": "Virgin Islands (US)"
    },
    "WF": {
        "states": [],
        "name": "Wallis and Futuna"
    },
    "EH": {
        "states": [],
        "name": "Western Sahara"
    },
    "YE": {
        "states": [],
        "name": "Yemen"
    },
    "ZM": {
        "states": [
            { key: 'ZM-01', value: 'Western' },
            { key: 'ZM-02', value: 'Central' },
            { key: 'ZM-03', value: 'Eastern' },
            { key: 'ZM-04', value: 'Luapula' },
            { key: 'ZM-05', value: 'Northern' },
            { key: 'ZM-06', value: 'North-Western' },
            { key: 'ZM-07', value: 'Southern' },
            { key: 'ZM-08', value: 'Copperbelt' },
            { key: 'ZM-09', value: 'Lusaka' },
            { key: 'ZM-10', value: 'Muchinga' }
        ],
        "name": "Zambia"
    },
    "ZW": {
        "states": [],
        "name": "Zimbabwe"
    }
}

export const countries = {
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas (the)",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory (the)",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands (the)",
    "CF": "Central African Republic (the)",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands (the)",
    "CO": "Colombia",
    "KM": "Comoros (the)",
    "CD": "Congo (the Democratic Republic of the)",
    "CG": "Congo (the)",
    "CK": "Cook Islands (the)",
    "CR": "Costa Rica",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "CI": "Côte d'Ivoire",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic (the)",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "SZ": "Eswatini",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (the) [Malvinas]",
    "FO": "Faroe Islands (the)",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories (the)",
    "GA": "Gabon",
    "GM": "Gambia (the)",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See (the)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (the Democratic People's Republic of)",
    "KR": "Korea (the Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic (the)",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands (the)",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (the Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands (the)",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger (the)",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands (the)",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines (the)",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "MK": "Republic of North Macedonia",
    "RO": "Romania",
    "RU": "Russian Federation (the)",
    "RW": "Rwanda",
    "RE": "Réunion",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan (the)",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands (the)",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates (the)",
    "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
    "UM": "United States Minor Outlying Islands (the)",
    "US": "United States of America (the)",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Åland Islands"
}

export function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

export const sportsList = [
    {
        title: 'Baseball',
        slug: 'product-category/inventory/baseball'
    },
    {
        title: 'Basketball',
        slug: 'product-category/inventory/basketball'
    },
    {
        title: 'Boxing',
        slug: 'product-category/inventory/boxing'
    },
    {
        title: 'Comics',
        slug: 'product-category/inventory/comics'
    },
    {
        title: 'Football',
        slug: 'product-category/inventory/football'
    },
    {
        title: 'Hockey',
        slug: 'product-category/inventory/hockey'
    },
    {
        title: 'Memorabilia',
        slug: 'product-category/inventory/memorabilia'
    },
    {
        title: 'Non-Sport',
        slug: 'product-category/inventory/non-sport'
    },
    {
        title: 'Sets',
        slug: 'product-category/inventory/sets'
    },
    {
        title: 'Un-Opened',
        slug: 'product-category/inventory/un-opened'
    },
];

export const priceFilters = [
    { label: "Up to $50", val: "1_50" },
    { label: "$50 to $100", val: "50_100" },
    { label: "$100 to $500", val: "100_500" },
    { label: "$500 to $2,500", val: "500_2500" },
    { label: "$2,500 & above", val: "2500" },
];

export async function encryptData(data) {
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_TONY_HASH).toString()
    return ciphertext
}

export async function decryptData(dataString) {
    try {
        var ciphertext = CryptoJS.AES.decrypt(dataString, process.env.REACT_APP_SECRET_TONY_HASH).toString(CryptoJS.enc.Utf8)
        if (ciphertext) {
            ciphertext = await JSON.parse(ciphertext)
        }
        return ciphertext
    } catch (error) {
        return false
    }
}

export function getCartErrorMessage(error_code) {
    let error = ''
    switch (error_code) {
        case "cocart_cannot_read_cart":
        case "cocart_cart_key_missing":
        case "cocart_cart_in_session_not_valid":
        case "cocart_clear_cart_failed":
            error = 'Invalid cart'
            break;
        case "cocart_customer_missing":
            error = 'Invalid customer'
            break;
        case "cocart_product_id_required":
        case "cocart_product_id_not_numeric":
            error = 'Invalid product'
            break;
        case "cocart_quantity_not_numeric":
        case "cocart_not_enough_in_stock":
        case "cocart_not_enough_stock_remaining":
            error = 'Invalid product quantity'
            break;
        case "cocart_product_does_not_exist":
            error = 'This product is no longer available'
            break;
        case "cocart_cannot_be_purchased":
            error = 'This product is no longer available'
            break;
        case "cocart_product_sold_individually":
            error = 'This product can\'t be added to cart'
            break;
        case "cocart_product_out_of_stock":
            error = 'This product is out of stock'
            break;
    }
    return error
}

export function convertToCommaSeparate(price) {
    price = parseFloat(price)?.toLocaleString('en-US', { minimumFractionDigits: 2 });
    return price
}

export function toggleMobileMenu() {
    if (document.body.classList.contains('mobile_menu_opened')) {
        document.body.classList.remove('mobile_menu_opened')
    } else {
        document.body.classList.add('mobile_menu_opened')
    }
}

export async function getCountries() {
    let countries = []
    await axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `getcountriesstates`).then(res => {
        countries = res?.data?.countries;
        localStorage.setItem('tonye_countries', JSON.stringify(countries))
    }).catch(err => {

    })
    return countries
}

export function getAdminMenu() {
    return [
        {
            id: 'dashboard',
            title: 'Dashboard',
            icon: '',
            link: '/admin'
        },
        {
            id: 'products',
            title: 'All Products',
            icon: '',
            link: '/admin/products',
            children: [
                {
                    title: 'All Products',
                    link: '/admin/products',
                },
                {
                    title: 'Add new product',
                    link: '/admin/products/add',
                },
                {
                    title: 'Categories',
                    link: '/admin/edit-tags/product_cat',
                }, {
                    title: 'Team',
                    link: '/admin/edit-tags/team',
                }, {
                    title: 'Condition',
                    link: '/admin/edit-tags/condition',
                }, {
                    title: 'Sports',
                    link: '/admin/edit-tags/sports',
                }, {
                    title: 'Manufacturers',
                    link: '/admin/edit-tags/manufacturer',
                }, {
                    title: 'Year',
                    link: '/admin/edit-tags/c_year',
                }, {
                    title: 'Player',
                    link: '/admin/edit-tags/player',
                }, {
                    title: 'Attributes',
                    link: '/admin/products/attributes',
                },
            ]
        },
        {
            id: 'orders',
            title: 'Orders',
            icon: '',
            link: '/admin/orders'
        }, {
            id: 'bulk_importer',
            title: 'Bulk Importer',
            icon: '',
            link: '/admin/bulk_importer',
            children: [
                {
                    title: 'Bulk Importer',
                    link: '/admin/bulk_importer',
                }, {
                    title: 'Bulk eBay Lister',
                    link: '/admin/bulk_importer/lister',
                },
            ]
        },
        {
            id: 'ebay',
            title: 'eBay',
            icon: '',
            link: '/admin/ebay/listings',
            children: [
                {
                    title: 'Listings',
                    link: '/admin/ebay/listings'
                }, {
                    title: 'Orders',
                    link: '/admin/ebay/orders'
                },
            ]
        }, {
            id: 'amazon',
            title: 'Amazon',
            icon: '',
            link: '/admin/amazon/listings',
            children: [
                {
                    title: 'Listings',
                    link: '/admin/amazon/listings'
                }, {
                    title: 'Orders',
                    link: '/admin/amazon/orders'
                },
                // {
                //     title: 'Reports',
                //     link: '/admin/amazon/reports'
                // }, {
                //     title: 'Feeds',
                //     link: '/admin/amazon/feeds'
                // },
            ]
        },
        {
            id: 'price_adjustment',
            title: 'Price Adjustment',
            icon: '',
            link: '/admin/price_adjustment',
        },
        {
            id: 'posts',
            title: 'Posts',
            icon: '',
            link: '/admin/posts',
            children: [
                {
                    title: 'All Posts',
                    link: '/admin/posts'
                }, {
                    title: 'Add New Post',
                    link: '/admin/posts/add'
                }, {
                    title: 'Categories',
                    link: '/admin/edit-tags/category'
                },
            ]
        },
        {
            id: 'users',
            title: 'Users',
            icon: '',
            link: '/admin/users',
            children: [
                {
                    title: 'All Users',
                    link: '/admin/users'
                }, {
                    title: 'Add New User',
                    link: '/admin/users/add'
                },
            ]
        },
    ]
}

export function escapeRegExChars(value) {
    return value.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
}

export function getHighlightedSearchString(suggestionValue, phrase) {
    var i, tokens = phrase.split(/ /), highlighted = false, last = '';
    if (tokens) {
        last = tokens[tokens.length - 1];
        tokens = tokens.sort(function (a, b) {
            return b.length - a.length;
        });
        for (i = 0; i < tokens.length; i++) {
            if (tokens[i] && tokens[i].length >= 1) {
                var token = tokens[i].replace(/[\^\@]/g, '');
                if (token.length > 0) {
                    if (token.trim().length === 1 && tokens[i] !== last) {
                        var pattern = '((\\s|^)' + escapeRegExChars(token.trim()) + '\\s)';
                    } else if (token.trim().length === 1 && tokens[i] === last) {
                        var pattern = '((\\s|^)' + escapeRegExChars(token.trim()) + ')';
                    } else {
                        var pattern = '(' + escapeRegExChars(token.trim()) + ')';
                    }
                    suggestionValue = suggestionValue.replace(new RegExp(pattern, 'gi'), '\^\^$1\@\@');
                    highlighted = true;
                }
            }
        }
    }
    if (highlighted) {
        suggestionValue = suggestionValue.replace(/\^\^/g, '<strong>');
        suggestionValue = suggestionValue.replace(/@@/g, '<\/strong>');
    }
    return suggestionValue
}

export const zoomButtons = [2, 3, 4, 5]

export const menuItems = [
    {
        label: "Inventory",
        path: "/shop",
        hasSubmenu: true,
        submenu: [
            { label: "Baseball", path: "/product-category/inventory/baseball", icon: "Baseball" },
            { label: "Basketball", path: "/product-category/inventory/basketball", icon: "Basketball" },
            { label: "Boxing", path: "/product-category/inventory/boxing", icon: "Boxing" },
            { label: "Comics", path: "/product-category/inventory/comics", icon: "Comics" },
            { label: "Football", path: "/product-category/inventory/football", icon: "Football" },
            { label: "Hockey", path: "/product-category/inventory/hockey", icon: "Hockey" },
            { label: "Memorabilia", path: "/product-category/inventory/memorabilia", icon: "Memo" },
            { label: "Non-Sport", path: "/product-category/inventory/non-sport", icon: "NonSport" },
            { label: "Un-Opened", path: "/product-category/inventory/un-opened", icon: "Unopened" },
            { label: "Sets", path: "/product-category/inventory/sets", icon: "Sets" },
        ]
    },
    {
        label: "Featured",
        path: "/featured",
        hasSubmenu: true,
        submenu: [
            { label: "New", path: "/featured" },
            { label: "Badass", path: "/badass" }
        ]
    },
    {
        label: "Sell",
        path: "/sell",
        hasSubmenu: true,
        submenu: [
            { label: "Value", path: "/determining-value" },
            { label: "Sell", path: "/how-to-sell" },
            { label: "Valuate", path: "/how-to-valuate" },
            { label: "Most wanted", path: "/the-most-wanted-list" },
            { label: "Selling cards", path: "/selling-cards" },
            { label: "Memorabilia", path: "/memoribilia" }
        ]
    },
    { label: "Contact", path: "/contact" }
];

export const headerMenu = [
    {
        label: "Inventory",
        path: "/shop",
        submenu: [
            { label: "Baseball", path: "/product-category/inventory/baseball" },
            { label: "Basketball", path: "/product-category/inventory/basketball" },
            { label: "Boxing", path: "/product-category/inventory/boxing" },
            { label: "Comics", path: "/product-category/inventory/comics" },
            { label: "Football", path: "/product-category/inventory/football" },
            { label: "Hockey", path: "/product-category/inventory/hockey" },
            { label: "Memorabilia", path: "/product-category/inventory/memorabilia" },
            { label: "Non-Sport", path: "/product-category/inventory/non-sport" },
            { label: "Un-Opened", path: "/product-category/inventory/un-opened" },
            { label: "Sets", path: "/product-category/inventory/sets" },
        ],
    },
    {
        label: "Featured",
        path: "/featured",
        submenu: [
            { label: "New", path: "/featured" },
            { label: "Badass", path: "/badass" },
        ],
    },
    {
        label: "Sell",
        path: "/sell",
        submenu: [
            {
                label: "Value",
                path: "/determining-value",
                sub: "Determining value of baseball cards",
            },
            {
                label: "Sell",
                path: "/how-to-sell",
                sub: "How to sell your baseball cards",
            },
            {
                label: "Valuate",
                path: "/how-to-valuate",
                sub: "How to valuate collections",
            },
            {
                label: "Most wanted",
                path: "/the-most-wanted-list",
                sub: "We pay top dollar for collections",
            },
            {
                label: "Selling cards",
                path: "/selling-cards",
                sub: "Know the risks of selling sports cards",
            },
            {
                label: "Memorabilia",
                path: "/memoribilia",
                sub: "Avoid sports memorabilia auction sites",
            },
        ],
    },
    { label: "Contact", path: "/contact" },
];

export const getPlaceholderImage = () => {
    return process.env.REACT_APP_ADMIN_BASE + 'wp-content/plugins/woocommerce/assets/images/placeholder.png'
}

export const NumberFormatter = (number) => {
    return new Intl.NumberFormat("en-US").format(number ? number : 0);
}

export const getBulkActions = (key) => {
    const bulkActions = {
        users: [
            {
                key: 'delete_user',
                value: 'Delete',
            },
            {
                key: 'resetpassword',
                value: 'Send password reset',
            },
        ],
        product: [
            {
                key: 'edit',
                value: 'Edit',
            }, {
                key: 'trash',
                value: 'Move to Trash',
            }, {
                key: 'addtolabels',
                value: 'Add to label printer',
            }, {
                key: 'wple_prepare_auction',
                value: 'List on eBay',
            }, {
                key: 'wple_remove_from_ebay',
                value: 'End listings on eBay',
            }, {
                key: 'list_on_amazon',
                value: 'List on Amazon',
            }, {
                key: 'price_adjustment',
                value: 'Price Adjustment',
            }, {
                key: 'remove_from_amazon',
                value: 'Remove from Amazon',
            },
        ],
        order: [
            {
                key: 'trash',
                value: 'Move to Trash',
            }, {
                key: 'mark_processing',
                value: 'Change status to processing',
            }, {
                key: 'mark_on-hold',
                value: 'Change status to on-hold',
            }, {
                key: 'mark_completed',
                value: 'Change status to completed',
            }, {
                key: 'mark_cancelled',
                value: 'Change status to cancelled',
            },
        ],
        ebay: [
            {
                key: 'wple_verify',
                value: 'Verify with eBay',
            }, {
                key: 'wple_publish2e',
                value: 'Publish to eBay',
            }, {
                key: 'wple_update',
                value: 'Update status from eBay',
            }, {
                key: 'wple_reapply',
                value: 'Re-apply profile',
            }, {
                key: 'wple_revise',
                value: 'Revise items',
            }, {
                key: 'wple_end_item',
                value: 'End listings',
            }, {
                key: 'wple_relist',
                value: 'Re-list ended items',
            }, {
                key: 'wple_lock',
                value: 'Lock listings',
            }, {
                key: 'wple_unlock',
                value: 'Unlock listings',
            }, {
                key: 'wple_archive',
                value: 'Move to archive',
            }, {
                key: 'wple_reset_status',
                value: 'Reset ended items',
            }, {
                key: 'wple_clear_eps_data',
                value: 'Clear EPS cache',
            },
        ],
        ebayOrder: [
            {
                key: 'wple_bulk_update_orders',
                value: 'Update selected orders from eBay',
            },
        ],
        amazonOrder: [
            {
                key: 'wpla_create_orders',
                value: 'Create selected orders in WooCommerce',
            }, {
                key: 'wpla_update_orders',
                value: 'Update selected orders from Amazon',
            }, {
                key: 'wpla_delete_orders',
                value: 'Delete selected orders',
            },
        ],
        amazon: [
            {
                key: 'wpla_get_compet_price',
                value: 'Fetch latest prices from Amazon',
            }, {
                key: 'wpla_resubmit',
                value: 'Resubmit items',
            }, {
                key: 'wpla_trash_listing',
                value: 'Remove from Amazon',
            }, {
                key: 'wpla_delete',
                value: 'Delete from database',
            }, {
                key: 'wpla_get_lowest_offers',
                value: 'Get lowest offer listings (beta)',
            }, {
                key: 'wpla_fetch_pdescription',
                value: 'Fetch full description (beta)',
            },
        ],
        post: [
            {
                key: 'edit',
                value: 'Edit',
            },
            {
                key: 'trash',
                value: 'Move to Trash',
            },
        ],
        priceadjustment: [
            {
                key: 'edit_in_bulk',
                value: 'Edit in bulk',
            },
            {
                key: 'edit_one_by_one',
                value: 'Edit One by One',
            },
            {
                key: 'end_pricing',
                value: 'End Price Adjustment',
            },
            {
                key: 'remove_items',
                value: 'Remove',
            },
        ],
        tag: [
            {
                key: 'delete_tag',
                value: 'Delete',
            },
        ]
    }
    return bulkActions[key]
}

export const showToast = (message = '', type = 'success', position = 'top-center') => {
    let style = {
        borderWidth: '1px',
        borderStyle: 'solid',
    };
    if (type == 'error') {
        style.borderColor = 'red';
        toast.error(`${message}`, {
            position: position,
            style: style,
        });
        return;
    }
    style.borderColor = 'green';
    toast.success(`${message}`, {
        position: position,
        style: style,
    });
}

export const tagsMapping = {
    product_cat: "Product Category",
    post_tag: "Post Tag",
    c_year: "Year",
    team: "Team",
    condition: "Condition",
    sports: "Sports",
    manufacturer: "Manufacturer",
    player: "Player",
    category: "Category",
}

export const getDropdownOptions = (type) => {
    const options = {
        post_status: [
            {
                key: 'publish',
                value: 'Publish',
            },
            {
                key: 'pending',
                value: 'Pending Review',
            },
            {
                key: 'draft',
                value: 'Draft',
            },
        ],
        product_types: [
            {
                key: 'simple',
                value: 'Simple',
            },
            {
                key: 'variable',
                value: 'Variable',
            },
        ]
    }
    return options[type];
}

export const getItemTypes = () => {
    return [
        {
            id: 261330,
            value: 'Sports Card Sets',
        },
        {
            id: 183052,
            value: 'Non-Sport Card Sets',
        },
        {
            id: 261331,
            value: 'Sports Sealed Packs',
        },
        {
            id: 183053,
            value: 'Non-Sport Sealed Packs',
        },
        {
            id: 183456,
            value: 'CCG Sealed Packs',
        },
        {
            id: 261329,
            value: 'Sports Card Lots',
        },
        {
            id: 183051,
            value: 'Non-Sport Card Lots',
        },
        {
            id: 261335,
            value: 'Sports - Empty Wrappers / Card Boxes',
        },
        {
            id: 183054,
            value: 'Non-Sport - Empty Wrappers / Card Boxes',
        },
        {
            id: 50129,
            value: 'Sports Photos',
        },
        {
            id: 262421,
            value: 'Celebrity Photos',
        },
        {
            id: 259104,
            value: 'Comic Books',
        },
        {
            id: 64488,
            value: 'Sports Magazines',
        },
        {
            id: 259114,
            value: 'Comic Magazines',
        },
        {
            id: 280,
            value: 'Magazines',
        },
        {
            id: 30,
            value: 'Vintage Toys',
        },
    ]
}