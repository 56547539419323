import './addpost.css';
import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import '../../assets/css/admin.dropzone.css'
import '../../assets/css/admin.editor.css'
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, Uploader } from 'rsuite';
import 'rsuite/Accordion/styles/index.css';
import 'rsuite/DatePicker/styles/index.css';
import 'rsuite/Uploader/styles/index.css';
import he from 'he';
import {
    MDXEditor, headingsPlugin, UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, markdownShortcutPlugin,
    thematicBreakPlugin,
    quotePlugin,
    listsPlugin,
    BlockTypeSelect,
    CreateLink,
    InsertImage,
    ListsToggle,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { getPost, getTags, getWPMedia, updatePost } from "../../api/adminapi";
import toast from "react-hot-toast";
import { getCache, setCache } from "../../cacheUtil";
import { ReactMediaLibrary } from 'react-media-library';
import { useSelector } from 'react-redux';
import { showToast } from '../../utils/utils';

const CACHE_KEY_CATEGORIES = 'post_categories_data';
const CACHE_KEY_TAGS = 'post_tags_data';
const CACHE_KEY_POST = 'post_data';

const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    };

    const date = new Date(dateString);
    return date.toLocaleString('en-US', options).replace(',', ' at');
}

const AddPost = () => {
    const { id } = useParams()
    const [categories, setCategories] = useState([])
    const [tags, setTags] = useState([])
    const [editing, setEditing] = useState(false)
    const [editingDate, setEditingDate] = useState(false)
    const [post, setPost] = useState(undefined)

    const [postInformation, setPostInformation] = useState({
        ID: 0,
        categories: [],
        post_content: '',
        post_date: '',
        post_excerpt: '',
        post_name: '',
        post_status: '',
        post_title: '',
    });

    useEffect(() => {
        if (id) {
            setEditing(true)
        } else {
            setEditing(false)
            setPost(undefined)
            setPostInformation({
                ID: 0,
                categories: [],
                post_content: '',
                post_date: '',
                post_excerpt: '',
                post_name: '',
                post_status: '',
                post_title: '',
            })
            setFeaturedImage({})
        }
        fetchAllTaxonomies();
    }, [id]);

    const fetchAllTaxonomies = async () => {

        const fetchAndCacheData = async (cacheKey, fetchFn, setFn) => {
            const cachedData = getCache(cacheKey);
            if (cachedData) {
                setFn(cachedData);
            }
            const response = await fetchFn();
            if (response.status === 200) {
                setFn(response.data.terms);
                setCache(cacheKey, response.data.terms);
            }
        };

        await fetchAndCacheData(CACHE_KEY_CATEGORIES, () => getTags('category', 1, 20), setCategories);
        await fetchAndCacheData(CACHE_KEY_TAGS, () => getTags('post_tag', 1, 20), setTags);
    }

    const fetchPost = async () => {
        try {
            setFormProcessing(true)

            const cachedPost = getCache(`${CACHE_KEY_POST}_${id}`);
            if (cachedPost) {
                setFormProcessing(false);
                setPost(cachedPost)
                let _catIDs = []
                if (cachedPost.categories.length) {
                    _catIDs = cachedPost.categories.map(cat => {
                        return cat.term_id;
                    })
                }
                if (cachedPost?.thumbnail_url?.length && cachedPost?.thumbnail_id > 0) {
                    setFeaturedImage({
                        attachment_id: cachedPost?.thumbnail_id,
                        url: cachedPost?.thumbnail_url
                    })
                }
                setPostInformation({
                    ...cachedPost,
                    categories: _catIDs
                })
                // setPostInformation(
                //     cachedPost
                // )
            }

            await getPost(id).then(async response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setCache(`${CACHE_KEY_POST}_${id}`, _data.post);
                    setFormProcessing(false)
                    setPost(_data.post)
                    let _catIDs = []
                    if (_data.post.categories.length) {
                        _catIDs = _data.post.categories.map(cat => {
                            return cat.term_id;
                        })
                    }
                    if (_data.post?.thumbnail_url?.length && _data.post?.thumbnail_id > 0) {
                        setFeaturedImage({
                            attachment_id: _data.post?.thumbnail_id,
                            url: _data.post?.thumbnail_url
                        })
                    }
                    setPostInformation({
                        ..._data.post,
                        categories: _catIDs
                    })
                }
            })

        } catch (error) {
            setFormProcessing(false)
        }
    }

    useEffect(() => {
        if (editing) {
            fetchPost()
        }
    }, [editing])

    const ref = React.useRef(null)
    const dateRef = React.useRef(null)
    const navigate = useNavigate();
    const { adminInfo } = useSelector((state) => state.admin);
    const [formProcessing, setFormProcessing] = useState(false)
    const handleFormSubmit = async () => {

        if (formProcessing)
            return;
        setFormProcessing(true)

        const content = ref.current?.getMarkdown() || '';
        document.querySelector('textarea[name="content"]').value = content;
        await updatePost(new FormData(document.getElementById('add_update_post_form'))).then(res => {
            if (res?.status == 200) {
                showToast('Post updated successfully');
            } else if (!editing && res.status == 201) {
                navigate(`/admin/posts/add/${res.data.id}`)
            }
        }).catch(err => {
            console.warn(err)
        })

        setFormProcessing(false)

    }

    const savePostInformation = (key, value) => {
        setPostInformation({
            ...postInformation,
            [key]: value,
        })
    }

    const isTermInTerms = (term, terms, _match_type = 'term_id') => {
        const is_there = postInformation.categories.includes(term.term_id)
        return is_there;
    }

    const [featureImage, setFeaturedImage] = useState({});

    const handleRemoveFeaturedImage = (e) => {
        e.preventDefault()
        setFeaturedImage({})
    }

    const [totalMediaPages, setTotalMediaPages] = useState(1)
    const [totalMediaItems, setTotalMediaItems] = useState(0)
    const [currentMediaPage, setCurrentMediaPage] = useState(1)
    const [mediaData, setMediaData] = useState([]);
    const [mediaOpen, setMediaOpen] = useState(false)

    const fetchMedia = async (page = 1) => {
        await getWPMedia().then(response => {
            if (response.status == 200) {
                const _data = response.data;
                setMediaData(_data.items)
                setTotalMediaPages(_data.total_pages)
                setCurrentMediaPage(_data.current_page)
                totalMediaItems(_data.total_items)
            }
        }).catch(err => {

        }).finally(() => {

        })
    }

    useEffect(() => {
        if (mediaOpen) {
            fetchMedia()
        }
    }, [mediaOpen])

    const handleCategoryChange = (categoryId, isChecked) => {
        setPostInformation(prevState => {
            let updatedCategories;
            if (isChecked) {
                updatedCategories = [...prevState.categories, parseInt(categoryId)];
            } else {
                updatedCategories = prevState.categories.filter(id => id !== parseInt(categoryId));
            }
            return {
                ...prevState,
                categories: updatedCategories,
            };
        });
    };

    return <div class="layout-wrapper layout-content-navbar light-styleclass">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <form id="add_update_post_form" onSubmit={(e) => {
                    e.preventDefault();
                    handleFormSubmit()
                }}>
                    <input type="hidden" name="post_id" value={editing && id ? id : 0} />
                    <input type="hidden" name="user_id" value={adminInfo?.user_id} />
                    <input type="hidden" name="post_date" value={post && editing ? post?.post_date : postInformation?.post_date} />
                    <input type="hidden" name="thumbnail_id" value={Object.keys(featureImage).length ? featureImage.attachment_id : 0} />
                    <div className="content-wrapper">
                        <div className="flex-grow-1 container-p-y container-fluid">
                            <div className="app-ecommerce">
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
                                    <div class="d-flex flex-column justify-content-center">
                                        <h4 class="mb-1">{editing ? 'Edit' : 'Add a new'} Post</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-12 col-lg-8">
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header">
                                                <h5 class="admin-card-tile mb-0">Post information</h5>
                                                <ReactMediaLibrary isOpen={mediaOpen}
                                                    fileLibraryList={mediaData}
                                                    // fileLibraryList={
                                                    //     [
                                                    //         {
                                                    //             _id: '6549e02fb0612d12ca163aaa',
                                                    //             title: "amet qui sunt",
                                                    //             description: 'dasjkdsakjdlas',
                                                    //             size: 434324,
                                                    //             fileName: "qui.jpg",
                                                    //             thumbnailUrl: "https://loremflickr.com/640/360?v=01",
                                                    //             createdAt: 442342344324,
                                                    //         },
                                                    //     ]

                                                    // }
                                                    filesSelectCallback={(s) => {
                                                        
                                                    }}
                                                    modalTitle='Upload a file'
                                                    onClose={() => {

                                                    }}
                                                />
                                            </div>
                                            <div class="admin-card-body">
                                                <div class="col mb-4">
                                                    <label class="form-label">Title</label>
                                                    <input type="text" class="form-control" placeholder="Post title" name="title" value={`${postInformation.post_title}`} required="required"
                                                        onChange={(e) => {
                                                            savePostInformation('post_title', e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <label class="mb-1">Description</label>
                                                    <div class="form-control p-0">
                                                        <MDXEditor
                                                            markdown={`${post?.post_content ? post.post_content : ''}`}
                                                            plugins={[
                                                                toolbarPlugin({
                                                                    toolbarContents: () => (
                                                                        <>
                                                                            {' '}
                                                                            <UndoRedo />
                                                                            <BoldItalicUnderlineToggles />
                                                                            <BlockTypeSelect />
                                                                            <CreateLink />
                                                                            <InsertImage />
                                                                            <ListsToggle />
                                                                        </>
                                                                    )
                                                                }),
                                                                headingsPlugin(),
                                                                listsPlugin(),
                                                                quotePlugin(),
                                                                thematicBreakPlugin(),
                                                                markdownShortcutPlugin()
                                                            ]}
                                                            style={{
                                                                height: 500
                                                            }}
                                                            ref={ref}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header d-flex justify-content-between align-items-center">
                                                <h5 class="mb-0 card-title">Excerpt</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                <textarea
                                                    className="form-control"
                                                    name="excerpt"
                                                    value={postInformation.post_excerpt}
                                                    onChange={(e) => setPostInformation({ ...postInformation, post_excerpt: e.target.value })}
                                                />
                                                <textarea className="form-control" style={{ display: 'none' }} name="content"></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header d-flex row align-center">
                                                <div className="col">
                                                    <h5 class="admin-card-title mb-0">Publish</h5>
                                                </div>
                                                <div style={{
                                                    minWidth: 256 + 'px',
                                                }} className="row col d-flex">
                                                    <div className="col d-flex align-center justify-content-end gap-3">
                                                        {
                                                            formProcessing ?
                                                                <div>
                                                                    <div class="spinner-border text-primary" role="status">
                                                                        <span class="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        <button type="submit" class="btn btn-primary waves-effect waves-light">{editing ? 'Save' : 'Publish'} post</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="admin-card-body">
                                                <div class="mb-6 col ecommerce-select2-dropdown">
                                                    <label class="form-label mb-1" for="status-org">Status </label>
                                                    <div class="position-relative">
                                                        <select class="select2 form-select select2-hidden-accessible" name="post_status" value={`${postInformation.post_status}`}
                                                            onChange={(e) => {
                                                                savePostInformation('post_status', e.target.value)
                                                            }}>
                                                            <option value="publish">Published</option>
                                                            <option value="pending">Pending Review</option>
                                                            <option value="draft">Draft</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {
                                                    !editingDate ?
                                                        <div class="mb-3 col ecommerce-select2-dropdown">
                                                            <label class="form-check-label">Publish{post && editing ? `ed on: ${formatDate(post?.post_date)}` : ` ${postInformation?.post_date?.toLocaleString('en-US', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).replace(',', '')}`}</label> <a style={{ textDecoration: 'underline' }} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setEditingDate(true)
                                                            }}>Edit</a>
                                                        </div> : null
                                                }
                                                {
                                                    editingDate ?
                                                        <div class="mb-6 d-flex gap-3 col ecommerce-select2-dropdown">
                                                            <DatePicker
                                                                ref={dateRef}
                                                                format="dd MMM yyyy hh:mm:ss aa"
                                                                style={{ width: 100 + '%' }}
                                                                block
                                                                cleanable={false}
                                                                value={editing ? new Date(post?.post_date) : postInformation.post_date}
                                                                onChange={(e) => {
                                                                    let _date = new Date(e);
                                                                    _date = _date.toISOString().split('.')[0]
                                                                    setPostInformation({
                                                                        ...postInformation,
                                                                        date_created: _date,
                                                                    });
                                                                }}
                                                            />
                                                            <button type="button" className="btn btn-primary waves-effect waves-light active" onClick={() => {
                                                                let _date = dateRef.current.target.value ? new Date(dateRef.current.target.value) : new Date()
                                                                _date = _date.toISOString().split('.')[0]
                                                                if (editing && post) {
                                                                    setPostInformation({
                                                                        ...postInformation,
                                                                        date_created: _date,
                                                                    });
                                                                    setEditingDate(false)
                                                                }
                                                            }}>
                                                                Save
                                                            </button>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header d-flex justify-content-between align-items-center">
                                                <h5 class="mb-0 card-title">Featured Image</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                {
                                                    Object.keys(featureImage).length ?
                                                        <div>
                                                            <img src={featureImage.url} />
                                                            <a className="remove_featured_image mt-4 d-inline-block" href="#" onClick={handleRemoveFeaturedImage}>Remove featured image</a>
                                                        </div> :
                                                        <Uploader onSuccess={(uploadedImageData) => {
                                                            if (uploadedImageData?.status == 'success' && uploadedImageData?.uploads?.length) {
                                                                setFeaturedImage(uploadedImageData?.uploads[0]);
                                                            }
                                                        }} action="https://staging.tonyetrade.com/uploader.php" draggable multiple={false}>
                                                            <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <span>Click or Drag image here to upload</span>
                                                            </div>
                                                        </Uploader>
                                                }
                                                {/* Next build */}
                                                {/* <button onClick={() => {
                                                    setMediaOpen(true)
                                                }}>
                                                    open
                                                </button> */}
                                            </div>
                                        </div>
                                        <div class="admin-card mb-3">
                                            <div class="admin-card-header">
                                                <h5 class="admin-card-title mb-0">Categories</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                <div className="taxonomies_wrapper h-px-250 overflow-auto">
                                                    {
                                                        categories.map(category => {
                                                            let _sep = '';
                                                            if (category.level > 0) {
                                                                for (var i = 1; i <= category.level; i++) {
                                                                    _sep += '—';
                                                                }
                                                            }
                                                            return (
                                                                <label class="list-group-item mb-2">
                                                                    <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
                                                                    <input class="form-check-input me-1" name="categories[]" type="checkbox" value={category.term.term_id}
                                                                        {...(isTermInTerms(category.term, post?.categories) ? { checked: true } : {})}
                                                                        onChange={(e) => {
                                                                            handleCategoryChange(e.target.value, e.target.checked);
                                                                        }}
                                                                    /> {he.decode(category.term.name)}
                                                                </label>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            false && (
                                                <div class="admin-card mb-3">
                                                    <div class="admin-card-header">
                                                        <h5 class="admin-card-title mb-0">Tags</h5>
                                                    </div>
                                                    <div class="admin-card-body">
                                                        <div className="taxonomies_wrapper h-px-250 overflow-auto">
                                                            {
                                                                tags?.map(tag => {
                                                                    let _sep = '';
                                                                    if (tag.level > 0) {
                                                                        for (var i = 1; i <= tag.level; i++) {
                                                                            _sep += '—';
                                                                        }
                                                                    }
                                                                    return (
                                                                        <label class="list-group-item mb-2">
                                                                            <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
                                                                            <input class="form-check-input me-1" name="categories[]" type="checkbox" value={tag.term.term_id}
                                                                                {...(isTermInTerms(tag.term, post?.tags) ? { checked: true } : {})}
                                                                                onChange={(e) => {
                                                                                    savePostInformation('tags', e.target.value)
                                                                                }}
                                                                            /> {tag.term.name}
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    </div >
}
export default AddPost;