import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { getPostStatusCounts, getWCOrders } from "../../api/adminapi";
import { convertToCommaSeparate, getBulkActions, NumberFormatter, scrollToTop } from "../../utils/utils";
import cart from '../../assets/images/cart.png'
import ViewOrderModal from "../../components/AdminModals/ViewOrder";
import { Link } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { getCache, setCache } from "../../cacheUtil";
import PostStatuses from "../../components/PostStatuses";
import AdminTableMeta from "../../components/AdminTableMeta";

const CACHE_KEY_ORDERS = 'orders_data';
const CACHE_KEY_COUNTERS = 'orders_counters';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };
    return date.toLocaleDateString('en-US', options);
}

const Orders = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orders, SetOrders] = useState(undefined)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [total, setTotal] = useState(0)
    const [selectedOrders, setSelectedOrders] = useState([]);

    const openModal = (order) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedOrder(null);
    };

    const [activeFilters, setActiveFilters] = useState({
        status: 'all',
    })

    const fetchOrders = async (page = null) => {
        if (loading)
            return
        try {
            setLoading(true);
            await getWCOrders(activeFilters, page ? page : currentPage).then(response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setTotalPages(_data.total_pages)
                    setTotal(_data.total_items)
                    setCurrentPage(_data.current_page)
                    SetOrders(_data.orders)
                    setCache(CACHE_KEY_ORDERS, _data);
                    scrollToTop();
                }
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        } catch (error) {

        }
    }

    const getOrdersCounts = async () => {
        try {
            getPostStatusCounts('shop_order').then(response => {
                const _counts = response.data.counts;
                if (_counts) {
                    setCounters(prevCounters => {
                        const updatedCounters = { ...prevCounters };
                        Object.entries(_counts)?.forEach(([key, value]) => {
                            if (updatedCounters[key]) {
                                updatedCounters[key] = {
                                    ...updatedCounters[key],
                                    c: value,
                                };
                            }
                        });
                        setCache(CACHE_KEY_COUNTERS, updatedCounters);
                        return updatedCounters;
                    });
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        const loadCachedOrders = async () => {
            const cachedData = getCache(CACHE_KEY_ORDERS);
            if (cachedData) {
                SetOrders(cachedData.orders);
                setTotalPages(cachedData.total_pages);
                setTotal(cachedData.total_items);
                setCurrentPage(cachedData.current_page);
            }

            const cachedCounters = getCache(CACHE_KEY_COUNTERS);
            if (cachedCounters) {
                setCounters(cachedCounters);
            }

            fetchOrders();
            getOrdersCounts();
        };
        loadCachedOrders();
    }, [activeFilters])


    const [counters, setCounters] = useState({
        all: { c: 0, t: 'All' },
        pendingpayment: { c: 0, t: 'Pending payment' },
        processing: { c: 0, t: 'Processing' },
        onhold: { c: 0, t: 'On hold' },
        completed: { c: 0, t: 'Completed' },
        cancelled: { c: 0, t: 'Cancelled' },
        refunded: { c: 0, t: 'Refunded' },
        failed: { c: 0, t: 'Failed' },
        is_from_amazon: { c: 0, t: 'Placed on Amazon' },
        is_from_amazon_no: { c: 0, t: 'Not placed on Amazon' },
        is_from_ebay: { c: 0, t: 'Placed on Ebay' },
        is_from_ebay_no: { c: 0, t: 'Not placed on Ebay' },
    });
    const [loading, setLoading] = useState(false)

    const handleStatusFilter = (status) => {
        setActiveFilters({
            ...activeFilters,
            status: status,
        })
    }

    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allOrderIds = orders.map(order => order.id);
            setSelectedOrders(allOrderIds);
        } else {
            setSelectedOrders([]);
        }
    };

    const handleOrderCheckbox = (e, orderId) => {
        if (e.target.checked) {
            setSelectedOrders(prev => [...prev, orderId]);
        } else {
            setSelectedOrders(prev => prev.filter(id => id !== orderId));
        }
    };

    const [searchQuery, setSearchQuery] = useState('')

    const handleSort = (field) => {
        if (loading)
            return;
        setActiveFilters((prev) => {
            const isSameField = prev.sortBy === field;
            return {
                ...prev,
                sortBy: field,
                sortOrder: isSameField ? (prev.sortOrder === "asc" ? "desc" : "asc") : "asc",
            };
        });
    };

    const getSortClass = (field) => {
        if (activeFilters.sortBy === field) {
            return activeFilters.sortOrder === "asc" ? "sorting_asc" : "sorting_desc";
        }
        return "sorting";
    };

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="admin-card">
                            <AdminTableMeta showPagination={true}
                                pagination={{
                                    total: total,
                                    per_page: 20,
                                    currentpage: currentPage,
                                    refresh: fetchOrders,
                                    setCurrentPage: setCurrentPage,
                                }}
                                refresh={fetchOrders}
                                loading={loading}
                                showSearch={true}
                                search={{
                                    searchQuery: searchQuery,
                                    setSearchQuery: setSearchQuery,
                                    placeholder: 'Search orders...'
                                }}
                                showBulkActions={true}
                                bulkActions={{
                                    actions: getBulkActions('order')
                                }}
                            />
                            <div class="card-datatable table-responsive">
                                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <PostStatuses counters={counters} activeFilters={activeFilters} handleStatusFilter={handleStatusFilter} />
                                    <table class="datatables-products table dataTable no-footer dtr-column">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input type="checkbox" class="form-check-input"
                                                        onChange={handleAllSelector}
                                                        checked={orders && selectedOrders.length === orders.length}
                                                    />
                                                </th>
                                                <th
                                                    className={getSortClass("ID")}
                                                    onClick={() => handleSort("ID")}
                                                >order</th>
                                                <th
                                                    className={getSortClass("date")}
                                                    onClick={() => handleSort("date")}
                                                >date</th>
                                                <th>view</th>
                                                <th>customer</th>
                                                <th>status</th>
                                                <th>
                                                    <img width={18} src={cart} />
                                                </th>
                                                <th>Ship to</th>
                                                <th>Tracking Info</th>
                                                <th
                                                    className={getSortClass("total")}
                                                    onClick={() => handleSort("total")}
                                                >total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {typeof orders === "undefined" ? (
                                                <tr>
                                                    <td colSpan="8">Processing...</td>
                                                </tr>
                                            ) : !orders.length ? (
                                                <tr>
                                                    <td colSpan="8">Not found</td>
                                                </tr>
                                            ) : (
                                                orders.map((order, index) => {
                                                    let address = [order.shipping.address_1 + ' ' + order.shipping.address_2 + ' ' + order.shipping.city.trim(), order.shipping.state + ' ' + order.shipping.postcode + ' ' + order.shipping.country.trim()].join(', ');
                                                    if (!address.replace(',', '').trim().length)
                                                        address = '-';
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    className="dt-checkboxes form-check-input"
                                                                    checked={selectedOrders.includes(order.id)}
                                                                    onChange={(e) => handleOrderCheckbox(e, order.id)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Link to={`/admin/orders/view/${order.id}`}>
                                                                    <span>#{order.id}</span>
                                                                </Link>
                                                            </td>
                                                            <td className="sorting_1">
                                                                <span className="text-nowrap">{formatDate(order.date_created)}</span>
                                                            </td>
                                                            <td className="sorting_1">
                                                                <a onClick={(e) => {
                                                                    e.preventDefault();
                                                                    openModal(order)
                                                                }} href="#" class="btn btn-icon btn-text-secondary waves-effect waves-light rounded-pill" data-bs-toggle="tooltip" aria-label="Preview" data-bs-original-title="Preview">
                                                                    <i class="ti ti-eye ti-md"></i>
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex justify-content-start align-items-center order-name">
                                                                    <div class="d-flex flex-column">
                                                                        <h6 class="m-0">
                                                                            <span href="pages-profile-user.html" class="text-heading">
                                                                                {order.billing.first_name} {order.billing.last_name}
                                                                            </span>
                                                                        </h6>
                                                                        <small>{order.billing.email}</small>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span class={`badge px-2 ${order.status == 'processing' ? 'bg-label-success' : ''} ${order.status == 'on-hold' || order.status == 'refunded' ? 'bg-label-warning' : ''} ${order.status == 'completed' ? 'bg-label-primary' : ''} ${order.status == 'cancelled' || order.status == 'failed' ? 'bg-label-danger' : ''} ${order.status == 'pending' ? 'bg-label-info' : ''}`} text-capitalized="">
                                                                    {order.status}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {/* {order.status} */}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <a href={`https://maps.google.com/maps?&q=${address}`} target="_blank">
                                                                        {order.shipping.first_name} {order.shipping.last_name}<br />
                                                                        {address}
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {/* trackiing */}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-sm-start align-items-sm-center">
                                                                    ${convertToCommaSeparate(order.total)}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            )}

                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                                Showing <strong>{orders ? orders?.length : 0}</strong> of <strong>{NumberFormatter(total)}</strong> orders
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <AdminTableMeta showPagination={true}
                                                pagination={{
                                                    total: total,
                                                    per_page: 20,
                                                    currentpage: currentPage,
                                                    refresh: fetchOrders,
                                                    setCurrentPage: setCurrentPage,
                                                }}
                                                loading={loading}
                                                refresh={fetchOrders}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <ViewOrderModal isOpen={isModalOpen} onClose={closeModal} order={selectedOrder} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default Orders;